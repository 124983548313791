.signature {

	&__pad-container {
		position: relative;
		width: 100%;
		padding-top: 50%; // maintain 2:1 aspect ratio
	}

	&__text-container {
		position: absolute;
		top: 0; 
		right: 0; 
		bottom: 0; 
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		transition: opacity 200ms;
		color: $col-greyConnection;

		&--invisible {
			opacity: 0;
		}
	}
	
	&__pad {
		border: 1px dashed $col-greyMid;
		border-radius: $val-borderRadius;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
	}

	&__clear-button {
		position: absolute;
		top: 0; 
		right: 0; 
		display: flex;
		color: $col-blue;
		width: fit-content;
		height: fit-content;
		cursor: pointer;
		margin-top: $val-spacing-s;
		margin-right: $val-spacing-s;
		gap: $val-spacing-xs;
		z-index: 10;

		&__icon {
			margin-top: $val-spacing-xxs;
			font-size: $val-fontSizeBig;
		}
	}
}