.form-control {
	&--open-left {
		border-left: none !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}

	&--connected-right {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
}

textarea {
	&.form-control {
		height: auto !important;
	}
}

input,
textarea,
select {
	&.form-control {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		background-color: $col-white;
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightRegular;
		outline: none;
	}
}

input,
select {
	&.form-control {
		height: $val-fieldSize;
	}
}
