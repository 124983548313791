$var-datePickerMinWidth: 100px;

.status-color-dropdown {
	width: 100%;

	&::before {
		content: ' ';
	}

	&--option{
		padding: $val-spacing-xs;
	}
	
	&--selected {
		margin-right: $val-spacing-l;
		line-height: $val-lineHeightRegular;
	}
}

.deliverable-status {
	width: fit-content;
	padding: $val-spacing-s;
	font-weight: bold;
	border-radius: $val-borderRadius;
	line-height: $val-fontSizeSmall;
}

.deliverable-dashboard {

	& .table-container__buttons {
		margin-left: 0;
	}

	& .react-datepicker-wrapper {
		min-width: $var-datePickerMinWidth;
	}

	& .expander {
		font-size: $val-fontSizeXXBig;
		text-align: center;
		user-select: none;
	}

	& .unresizable {
		flex-basis: 0 !important;
		flex-grow: 0 !important;
	}
	
	.link {
		text-decoration: underline;
		color: $col-blue;
	}

	&__bulk-actions {
		height: 0;
		background-color: $col-orange;
		color: $col-white;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		padding: 0;
		transition: height 0.5s cubic-bezier(0.45, 0.04, 0.15, 0.9);
		border-bottom: none;

		[class^="icon-"] {
			margin-right: $val-spacing-s;
			margin-left: $val-spacing-xs;
		}

		&--expanded {
			height: $val-inputHeight;
		}
	}

	&__bulk_action_link {
		font-size: $val-fontSizeBig;
		margin-left: $val-spacing-l;
		cursor: pointer;
	}

	&__bulk_action_label {
		font-weight: bold;
	}
}

.notes-icon {
	font-size: $val-fontSizeXXBig;
	color: $col-blue;
}

.chat-icon {
	font-size: $val-fontSizeXBig;
	color: $col-blue;
}

.deliverable-form {
	hr {
		margin: 0;
	}

	& .form-box {
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		& h4 {
			font-weight: 700;
			font-size: $val-fontSizeXBig;
			text-align: left;
			margin: 0;
			color: $col-greyDark;
		}
	}

	&__details {
		border-radius: $val-borderRadius;
		border: 1px solid $col-greyConnection;
		background-color: $col-greyLight;
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&__no-comments-message {
		color: $col-greyFontLight;
		font-weight: bold;
		padding: 0 $val-spacing-l $val-spacing-l $val-spacing-l;
	}

	&__comment {
		padding: 0 $val-spacing-s;
		margin: 0 $val-spacing-s;
		border-left: 2px solid $col-grey;
		border-radius: $val-borderRadius;
		margin-bottom: $val-spacing-xs;
		margin-right: $val-spacing-xs;
		background: $col-greyConnection;
	}

	&__comment-input, &__comment-submit {
		padding-bottom: $val-spacing-s;
	}

	&__status-option {
		display: flex;
		align-items: center;

		.icon-notifications_active, .icon-daily_report {
			margin-left: $val-spacing-s;
			color: $col-greyInactive;

			&:hover {
				color: $col-greyDark;
			}
		}
	}
}

.deliverable-status-history-modal-details {
	background-color: $col-greyLight;
	padding: $val-spacing-s $val-spacing-l;
}

.deliverable-table {
	&__filter-button {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		display: flex;
		align-items: center;
		background: $col-white;
	}
}

.deliverable-status-history-table {
	& td {
		vertical-align: middle;
	}
}