@import "../../../src/styles/Constants.scss";

$var-dropzone-height: 80px;

.dropzone-element {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: $var-dropzone-height;
	border: 2px dashed $col-greyConnection;
	color: $col-greyFontLight;
	width: 100%;


	&--disabled {
		cursor: no-drop;
	}

	&--column {
		flex-flow: column nowrap;
	}
	
	&__text {
		margin-left: $val-spacing-m;
		margin-right: $val-spacing-m;
		
		&__bold {
			color: $col-black;
			font-weight: bold;
		}
		
		&__types {
			color: $col-greyFontLight;
			font-size: smaller;
		}
	}
	
	&__error {
		color: $col-red;
	}

	&__uploading-icon {
		[class^="icon-upload"] {
			color: $col-black;
			font-size: $val-icon48;
		}
	}
}

.progress-bar {
	background-color: $col-blueBorder;
}
