@import "./Colors.scss";
@import "./Constants.scss";

$var-ccTreeMinHeight: 124px;
$var-inputPadding: 33px;
$var-backgroundPositionX: 10px;
$var-labelPaddingTop: 6px;
$var-maxIndent: 4;
$var-indentSize: 16px;
$var-stickyHeaderTop: 87px;
$var-stickyPaddingBottom: 14px;
$var-replacementHeaderHeight: 198px;

#equipment-cost-create-form {
	width: 100%;
	max-width: $val-maxBoxSize;
	height: calc(100vh - #{$val-headerHeight});

	& .equipment-cost-form {
		position: relative;
		
		& .replacement-header {
			height: $var-replacementHeaderHeight;
			width: 100%;
		}

		& .header {

			& .soc-tree {
				display: flex;

				& .form-group {
					width: 50%;
					
					& > div {
						display: flex;
						width: 100%;
					}

				}


				& .rbt {
					min-width: calc(100% - #{$val-fieldSize});

				}
				
				& .rbt-input-hint-container {
					width: 100%;
					& input {
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						width: 100%;
					}

				}

				& .rbt-aux .close.rbt-close {
					margin-top: 0;
					
					&:before {
						font-family: 'icons';
						content: "\e90b";
						font-weight: normal;
						font-size: $val-fontSizeBig;
					}
				}

				& .rbt-aux .close.rbt-close span {
					display: none;
				}
			}

			& > div {
				background: $col-blueBackground;
			}

			&.sticky-header {
				position: fixed;
				top: $var-stickyHeaderTop;
				right: $val-spacing-l;
				left: $val-sidebarWidthMin + $val-spacing-l;
				max-width: calc(#{$val-maxBoxSize} - 2 * #{$val-spacing-l});
				margin: auto;
				border: 1px solid $col-greyConnection;
				padding-bottom: $var-stickyPaddingBottom;
				width: calc(100vw - #{$val-sidebarWidthMin} - 2 * #{$val-spacing-l});

				& > div {
					border: none;
					padding: 0;
				}

				& .equipment-cost-info {
					padding-top: $val-spacing-s!important;
				}

				& .equipment-cost-name, & .equipment-cost-info {
					padding: 0;
				}
			}
		}
	}

	& .equipment-cost-category {
		background: $col-blueBackground;
		margin-top: -$val-spacing-l;
		padding-top: $val-spacing-l;
		padding-bottom: $val-spacing-m;
		padding-left: $val-spacing-m;
		padding-right: $val-spacing-m;

		& input.rbt-input-main {
			padding-left: $var-inputPadding;
			background-size: $val-icon16!important;
			background-color: $col-white!important;
			background-repeat: no-repeat!important;
			background-position-x: $var-backgroundPositionX!important;
			background-position-y: center!important;
			background-image: url('/images/elements/ic_folder_full_open.svg')!important;
		}
	}
}