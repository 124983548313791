@import "./Colors.scss";
@import "./Shared.scss";

.text-blue {
	color: $col-blue !important;
}

.text-green {
	color: $col-green !important;
}

.text-red {
	color: $col-red !important;
}

.text-orange {
	color: $col-orange !important;
}

.text-grey {
	color: $col-greyFontLight !important;
}

.text-white {
	color: $col-white !important;
}

.text-black {
	color: $col-blackDark !important;
}

// Background Colors

.bg-yellow {
	background: $col-yellow !important;
}

.bg-red {
	background: $col-canceledRed !important;
}

.bg-green {
	background: $col-green !important;
}

.bg-grey {
	background: $col-greyBackground !important;
}

.bg-darkGrey {
	background: $col-greyInactive !important;
}

.bg-white {
	background: $col-white !important;
}

.bg-blue {
	background: $col-blue !important;
}

// Schedule Board Colors

.bg-transparent-pattern {
	@extend .transparent-bg-pattern;
}

.bg-pastel-greyBackground {
	background: $col-greyBackground !important;
	color: $col-black !important;
}

.bg-pastel-grey {
	background: $col-grey !important;
	color: $col-white !important;
}

.bg-pastel-greyConnection {
	background: $col-greyConnection !important;
	color: $col-black !important;
}

.bg-pastel-midGrey {
	background: $col-greyMid !important;
	color: $col-black !important;
}

.bg-pastel-greyUndefined {
	background: $col-greyMid !important;
	color: $col-black !important;
}

.bg-pastel-darkGrey {
	background: $col-greyMid !important;
	color: $col-white !important;
}

.bg-pastel-darkerGrey {
	background: $col-darkGrey !important;
	color: $col-white !important;
}

.bg-pastel-black {
	background: $col-blackDark !important;
	color: $col-white !important;
}

.text-pastel-lightGrey {
	color: $col-lightGrey !important;
}

.text-pastel-darkGrey {
	color: $col-darkGrey !important;
}

.text-pastel-greyUndefined {
	color: $col-grey !important;
}

// 24 color palette
.bg-pastel-red {
	background: $col-grey;
}

.bg-pastel-brown {
	background: $color-brown !important;
	color: $col-white !important;
}

.bg-pastel-bronze {
	background: $color-bronze !important;
}

.bg-pastel-tan {
	background: $color-tan !important;
}

.bg-pastel-maroon {
	background: $color-maroon !important;
	color: $col-white !important;
}

.bg-pastel-red {
	background: $color-red !important;
	color: $col-white !important;
}

.bg-pastel-peach {
	background: $color-peach !important;
}

.bg-pastel-rust {
	background: $color-rust !important;
	color: $col-white !important;
}

.bg-pastel-orange {
	background: $color-orange !important;
}

.bg-pastel-apricot {
	background: $color-apricot !important;
}

.bg-pastel-ocher {
	background: $color-ocher !important;
	color: $col-white !important;
}

.bg-pastel-gold {
	background: $color-gold !important;
}

.bg-pastel-lemon {
	background: $color-lemon !important;
}

.bg-pastel-forrestgreen {
	background: $color-forrestgreen !important;
	color: $col-white !important;
}

.bg-pastel-green {
	background: $color-green !important;
}

.bg-pastel-palegreen {
	background: $color-palegreen !important;
}

.bg-pastel-ultramarine {
	background: $color-ultramarine !important;
	color: $col-white !important;
}

.bg-pastel-blue {
	background: $color-blue !important;
	color: $col-white !important;
}

.bg-pastel-babyblue {
	background: $color-babyblue !important;
}

.bg-pastel-eggplant {
	background: $color-eggplant !important;
	color: $col-white !important;
}

.bg-pastel-purple {
	background: $color-purple !important;
	color: $col-white !important;
}

.bg-pastel-violet {
	background: $color-violet !important;
}

.bg-pastel-crimson {
	background: $color-crimson !important;
	color: $col-white !important;
}

.bg-pastel-ruby {
	background: $color-ruby !important;
}

.bg-pastel-cerise {
	background: $color-cerise !important;
}

.text-pastel-white {
	color: $col-white;
}

.text-pastel-black {
	color: $col-black !important;
}

.text-pastel-grey {
	color: $col-grey !important;
}

.text-pastel-brown {
	color: $color-brown !important;
}

.text-pastel-bronze {
	color: $color-bronze !important;
}

.text-pastel-tan {
	color: $color-tan !important;
}

.text-pastel-maroon {
	color: $color-maroon !important;
}

.text-pastel-red {
	color: $color-red !important;
}

.text-pastel-peach {
	color: $color-peach !important;
}

.text-pastel-rust {
	color: $color-rust !important;
}

.text-pastel-orange {
	color: $color-orange !important;
}

.text-pastel-apricot {
	color: $color-apricot !important;
}

.text-pastel-ocher {
	color: $color-ocher !important;
}

.text-pastel-gold {
	color: $color-gold !important;
}

.text-pastel-lemon {
	color: $color-lemon !important;
}

.text-pastel-forrestgreen {
	color: $color-forrestgreen !important;
}

.text-pastel-green {
	color: $color-green !important;
}

.text-pastel-palegreen {
	color: $color-palegreen !important;
}

.text-pastel-ultramarine {
	color: $color-ultramarine !important;
}

.text-pastel-blue {
	color: $color-blue !important;
}

.text-pastel-babyblue {
	color: $color-babyblue !important;
}

.text-pastel-eggplant {
	color: $color-eggplant !important;
}

.text-pastel-purple {
	color: $color-purple !important;
}

.text-pastel-violet {
	color: $color-violet !important;
}

.text-pastel-crimson {
	color: $color-crimson !important;
}

.text-pastel-ruby {
	color: $color-ruby !important;
}

.text-pastel-cerise {
	color: $color-cerise !important;
}