// .bulk-upload, .bulk-update {
.bulk-upsert {
	width: 100%;

	&__box {
		padding-bottom: $val-spacing-l;
	}

	&__instructions-header {
		display: flex;
		flex-flow: row nowrap;
	}

	&__instruction-text {
		flex: 1;
		padding-right: $val-spacing-l;

		& span {
			font-size: $val-fontSizeRegular;
		}
	}

	&__manual {
		margin-bottom: $val-spacing-m;
	}

	&__manual-step {
		font-weight: bold;
		color: $col-orange;
		margin-right: $val-spacing-s;
	}

	&__note {
		margin-top: $val-spacing-s;
	}

	&__note-label {
		font-weight: bold;
		margin-right: $val-spacing-s;
	}
}