$var-lineHeight: 75px;
$var-singleGrid: 24;
$var-iconSize: 14px;
$var-offsetBottom: 51px;
$var-blockWidth: 6px;
$var-dashedLineLeftSpacing: 24px;
$var-dashedLineLeftSpacingDenominator: 700;

#location-end {
	position: relative;

	&::before {
		@media (min-width: $bp-xs-high) {
			content: '';
			display: block;
			height: calc(100% - 2 * #{$var-iconSize});
			width: $var-blockWidth;
			bottom: $var-offsetBottom;
			left: calc(100% / #{$var-dashedLineLeftSpacingDenominator} + #{$var-dashedLineLeftSpacing});
			background: none;
			border-right: 2px dashed $col-black;
			position: absolute;
		}
	}
}

#location-start .map-element {
	padding-left: calc(100% / #{$var-singleGrid});
}

#location-end, #location-start {
	@media (max-width: $bp-xxs-high) {
		& .col-md-1 {
			display: none;

			& img {
				display: none;
			}
		}

		& .col-sm-16 {
			padding-left: $val-spacing-l;
		}
	}

	@media (min-width: $bp-xs-high) {
		& .col-md-1 {
			display: flex;
			text-align: center;
			height: $var-lineHeight;

			& img {
				width: $var-iconSize;
			}
		}
	}
}