@import '../../../../../../styles/Constants.scss';
@import '../../../../../../styles/Colors.scss';

.job-cell {
	background-color: $col-blueBackground;
	padding: $val-spacing-s;
	margin: $val-spacing-xs;
	font-weight: bold;
	color: $col-blue;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.subjob-flag {
	padding-right: $val-spacing-s;
	padding-left: $val-spacing-s;
	margin: $val-spacing-xxs;
	color: $col-blue;
	background-color: $col-design-blue7;
	margin-right: $val-spacing-xs;
}