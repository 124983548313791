$sb-statistic-width: 180px; // should correspond to value of SB_STATISTIC_WIDTH

.statistics-carousel {
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all $val-toggleAnimation;

	&__office {
		background: $col-white;
		padding: $val-spacing-xs $val-spacing-s;
		font-size: $val-fontSizeRegular;
		margin-bottom: 0;
		display: flex;
		flex-flow: column;
		text-align: center;
		width: $sb-statistic-width;

		& + & {
			border-left: 1px solid $col-greyConnection;
		}

	}

	&__stats-container {
		height: 100%;
		display: flex;
		justify-content: space-between;
		width: 100%;
		color: $col-greyDark !important;
		flex-flow: row wrap;

		& div {
			height: inherit;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
		}

	}

	&__revenue-stats,
	&__labor-stats {
		display: flex;
		justify-content: space-between;
		flex-flow: column nowrap;
		font-size: $val-fontSizeSmall;

		& span {
			display: inline;
		}
	}

	&__labor-stats span {
		text-align: left;
	}

	&__revenue-stats span {
		text-align: right;
	}

	&__totals {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: $val-spacing-m;
	}

	&--weekly {
		flex-flow: wrap;
		overflow: auto;

		.statistics-carousel__office {
			margin: $val-spacing-xs;
			border-right: none;
		}
	}

}