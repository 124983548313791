$heavy-border-width: 4px;

.code-field {
	border-radius: $val-borderRadius;

	& pre {
		border-radius: $val-borderRadius;
		border-left: $heavy-border-width solid $col-greyMid;
	}

	&--blue {
		background: $col-blueBackground;

		& pre {
			background: $col-blueBackground;
			border: 1px solid $col-blueBorder;
			border-left: $heavy-border-width solid $col-blue;
		}
	}
}