$var-shadow: 0 2px 2px 0 $col-shadow;
$var-border: 1px solid $col-greyConnection;
$var-dot-icon-size: 6px;

.time-sheet-list {
	&__time-zone {
		background: $col-blueBackground;
		padding: $val-spacing-s $val-spacing-m;
		font-size: $val-fontSizeSmall;
		color: $col-greyDark;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__banner {
			display: flex;
			align-items: center;

			b {
				margin: 0 $val-spacing-xs;
			}

			.icon-clock {
				font-size: $val-fontSizeRegular;
				margin-right: $val-spacing-s;
				line-height: $val-fontSizeSmall;
			}
		}

		&__switch {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}

	&__row {
		display: flex;
		flex-direction: column;
		margin-bottom: $val-spacing-s;
		box-shadow: $var-shadow;

		&__info {
			background: $col-white;
			border-radius: $val-borderRadius;
			padding: $val-spacing-l $val-spacing-s;
			display: flex;
			flex-direction: row;
			gap: $val-spacing-m;

			&--modified {
				background-color: $col-orangeHover;
			}

			&+& {
				border-top: $var-border;
			}

			&__name-section {
				flex: 1;
			}

			&__total-time-section {
				flex: 2;
			}

			&__user-name {
				color: $col-greyDark;
				font-size: $val-fontSizeXBig;
				font-weight: bold;
				text-transform: uppercase;
				display: flex;
				flex-direction: row;
				margin-bottom: $val-spacing-xs;

				&__warning-icon {
					margin-right: $val-spacing-s;
					color: $col-grey;
					cursor: pointer;

					&--not-approved {
						color: $col-canceledRed;
					}
				}

				&__tooltip {
					&__title-user {
						padding-bottom: $val-spacing-xs;
						font-weight: bold;
						text-align: left;

						.icon-warning {
							margin-right: $val-spacing-xs;
							font-size: $val-fontSizeRegular;
						}
					}

					&__title-entry {
						margin-bottom: $val-spacing-s;
						font-weight: bold;
						text-align: left;
						display: flex;
						align-items: center;

						.icon-warning {
							margin-right: $val-spacing-xs;
							font-size: $val-fontSizeRegular;
						}
					}

					&__entry {
						width: 100%;
						display: flex;
						justify-content: space-between;

						&__icon {
							font-size: $val-fontSizeRegular;
						}
					}
				}

				&--owner {
					color: $col-blue;
				}
			}

			&__signature-status {
				margin-top: $val-spacing-m;
			}

			&__total-time-container {
				display: flex;
				flex-direction: row;
				gap: $val-spacing-m;
			}

			&__total-time-container-empty-label {
				color: $col-white;
				user-select: none;
			}

			&__approval-actions {
				margin-top: $val-spacing-m;
				display: flex;
			}

			&__work-type-label-container {
				display: flex;
				flex-direction: "row";
				align-items: "center";

				&__icon {
					color: $col-greyMid;
					font-size: $val-fontSizeBig;
					margin-right: $val-spacing-s;

					&::before {
						position: relative;
						top: 2px;
					}

					&--tracked-entry {
						color: $col-blue;
					}
				}
			}

			&__edit_icon {
				font-size: $val-fontSizeXXBig;
				cursor: pointer;
				color: $col-greyFontLight;

				&:hover {
					color: $col-greyDark;
				}

				&--owner {
					color: $col-blue;
				}
			}

			&__no_icon {
				margin-left: $val-spacing-l;
			}

			&__time-split-table-container {
				background-color: $col-greyBackground;
				border-radius: $val-borderRadius;
				border-top-left-radius: 0;

				&__message {
					background-color: $col-canceledRed;
					color: $col-white;
					padding: $val-spacing-s;
					border-radius: $val-borderRadius;
				}

				&--error {
					background-color: $col-redBackground;
				}
			}

			&__time-split-table {
				width: 100%;
			}

			&__time-split-table-left-element {
				float: left;
				padding: $val-spacing-s;
			}

			&__time-split-table-right-element {
				float: right;
				padding: $val-spacing-s;
			}

			&__edit-equipment {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: $val-spacing-s;
				border-radius: $val-borderRadius;

				&--error {
					background-color: $col-redBackground;
				}
			}

			&__add-equipment {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $col-white;
				margin-top: $val-spacing-s;
				border-radius: $val-borderRadius;

				&--greyed {
					background-color: $col-greyBackground;
					margin-top: 0;
				}
			}

			&__cell {
				&__time-split {
					width: 90px;
				}

				&__classification-code {
					width: 130px;
				}
			}

			&__equipment-color-block {
				display: inline-block;
				width: $val-fontSizeSmall;
				height: $val-fontSizeSmall;
				margin-right: $val-spacing-s;
			}

			&__equipment-specification {
				font-weight: normal;
				margin-left: $val-spacing-s;
			}

			&__total-time {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: $val-spacing-s $val-spacing-m;
				border-top-left-radius: $val-borderRadius;
				border-top-right-radius: $val-borderRadius;

				&--greyed {
					background-color: $col-greyBackground;
				}

				&--error {
					background-color: $col-redBackground;
				}

				&__tracked-entry {
					color: $col-blue;
					margin-right: $val-spacing-m;
					text-align: right;
					font-weight: bold;
				}
			}

			&__note {
				background-color: $col-greyLight;
				padding: $val-spacing-s;
				margin-top: $val-spacing-m;

				&__edit {
					&__header {
						display: flex;
						justify-content: space-between;
					}

					&__text {
						font-weight: bold;
						margin-top: $val-spacing-m;
					}
				}

				&__add {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&--readonly {
					background-color: $col-white;
				}
			}

			&__total-split-time-container {
				display: flex;
				flex-direction: row;
				gap: $val-spacing-m;
				justify-content: space-between;
			}

			&__split-time-container {
				display: flex;
				flex-direction: row;
				gap: $val-spacing-m;
			}
		}

		&__timeline-action {
			background-color: $col-white;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;
			border-bottom: 2px solid $col-greyBackground;

			&__button {
				color: $col-blue;
				cursor: pointer;
				margin-bottom: $val-spacing-s;

				&__icon {
					font-size: $val-fontSizeBig;
					margin-left: $val-spacing-s;
				}
			}
		}

		&__timeline {
			background-color: $col-white;
			padding: $val-spacing-l;
			display: flex;
			align-items: center;
			flex-direction: row;

			&__title {
				width: 10%;
				font-weight: bold;
			}

			&__bar {
				display: flex;
				width: 90%;
				margin-left: $val-spacing-m;

				&__gap {
					margin: 0px $val-spacing-xxxs;
				}

				&__tooltip {
					display: flex;
					flex-direction: column;
					align-items: center;

					&__work-type {
						display: flex;
						align-items: center;
						font-weight: bold;

						>span {
							font-size: $val-fontSizeBig;
							margin-left: $val-spacing-s;
						}
					}

					&__time {
						margin-top: $val-spacing-xs;
						width: 100%;
						display: flex;
						justify-content: space-between;

						&__value {
							margin-left: $val-spacing-s;
						}
					}
				}

				&__section-container {
					display: flex;
					flex-direction: column;
					width: 100%;
				}

				&__section {
					height: 8px;
					border-right: 1px solid $col-white;

					&__icon {
						display: flex;
						justify-content: center;
						height: $val-fontSizeBig;
						font-size: $val-fontSizeBig;
						color: $col-blue;
						margin-bottom: $val-spacing-xs;

						&--unsigned-manual {
							color: $col-orange;
						}

						&--overlap {
							color: $col-canceledRed;
						}
					}

					&__duration {
						display: flex;
						justify-content: center;
						font-weight: bold;
						margin-top: $val-spacing-xs;
					}

					&--entry {
						background-color: $col-blue;
					}

					&--occupied {
						background-color: $col-greyFontLight;
					}

					&--unsigned-manual {
						background-color: $col-orange;
					}

					&--overlap {
						background-color: $col-canceledRed;
					}

					&--rounded-left {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
					}

					&--rounded-right {
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
					}
				}
			}
		}
	}

	&__approval-modal-signature {
		margin: $val-spacing-m 0;
	}

	&__reject-modal-input {
		margin-top: $val-spacing-m;
	}

	&__employee-list-item {
		display: flex;
		align-items: center;

		.icon-dot {
			font-size: $var-dot-icon-size;
			margin-right: $val-spacing-xs;
		}
	}

	&__equipment-modal {
		$var-label-height: $val-spacing-l;
		padding-bottom: $val-spacing-m;
		margin: (-$val-spacing-l) (-$val-spacing-l);

		&--opaque {
			opacity: $opaque-opacity;
		}

		&__time-split-list {
			margin: 0 (-$val-spacing-m) 0 (-$val-spacing-m);
			padding: $val-spacing-s;

			&__row {
				padding: $val-spacing-s;

				&__classification-code {
					.dropdown-menu__selected {
						display: inline;
						text-align: left;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}

				&__allocation {
					.dropdown-menu__selected {
						display: inline;
						text-align: left;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}

				&__delete-button {
					margin-top: $val-spacing-s;
					padding-top: $val-spacing-l;
					cursor: pointer;
				}
			}
		}

		&__add-equipment {
			padding-left: $val-spacing-xl;
		}

		&--loading {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: $val-spacing-l;
		}

		&__total-times {
			display: flex;
			justify-content: space-between;
			background-color: $col-white;
			color: $col-darkBlueBorder;
			font-weight: bold;
			margin-top: $val-spacing-l;
			padding: $val-spacing-m;

			&--error {
				background-color: $col-canceledRed;
				color: $col-white;
			}
		}

		&__equipment-time-warning {
			display: flex;
			background-color: $col-orange;
			padding-left: $val-spacing-s;
			color: $col-white;
			font-weight: bold;
			font-size: $val-fontSizeSmall;

			&>span {
				margin-right: $val-spacing-s;
				font-size: $val-fontSizeBig;
			}
		}

		&__equipment-time-error {
			display: flex;
			background-color: $col-canceledRed;
			padding: $val-spacing-s;
			margin-top: $val-spacing-l;
			color: $col-white;
			font-weight: bold;
			font-size: $val-fontSizeRegular;

			&>span {
				margin-right: $val-spacing-s;
				font-size: $val-fontSizeBig;
			}
		}

		&__equipment-item {
			display: flex;
			align-items: center;
			flex-direction: row;
			font-size: $val-fontSizeRegular;

			
			&__overflow {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				
				&__color {
					display: inline-block;
					height: $val-spacing-m;
					width: $val-spacing-m;
					margin-right: $val-spacing-s;
					vertical-align: text-bottom;
				}
				
				&__code {
					display: inline;
					font-weight: bold;
					margin-right: $val-spacing-s;
				}

				&__spec {
					display: inline;
				}
			}

		}

		&__remove-button {
			color: $col-darkBlueBorder;
			cursor: pointer;
			font-size: $val-fontSizeRegular;
			line-height: $val-fieldSize;

			&__remove-icon {
				font-size: $val-fontSizeBig;
				margin-right: $val-spacing-s;
				position: relative;
				top: 2px;
			}
		}
	}

	&__note-modal-loading {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		margin-right: $val-spacing-xs;
		font-size: $val-fontSizeXXBig;
	}

	&__uniqueId {
		font-size: $val-fontSizeSmall;
		color: gray;
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: $val-spacing-xs;
	}
}