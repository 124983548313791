$status-icon-small-size: 16px;

.status-icon {
	width: $val-fieldSize;
	height: $val-fieldSize;
	border-radius: 50%;
	border: 2px solid $col-greyBackground;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $val-fontSizeBig;
	box-shadow: 0 0 1px 0 $col-greyBackground inset, 0 0 1px 0 $col-greyBackground;

	[class^="icon-"]{
		margin: 0 !important;

		&:before {
			position: relative;
			top: -1px;
			margin: 0;
		}
	}

	&--no-bg {
		background: none;
		box-shadow: none;
		border: none;
		font-size: $val-fontSizeXBig;
	}

	&--small {
		width: $status-icon-small-size;
		height: $status-icon-small-size;
		min-width: $status-icon-small-size;
		min-height: $status-icon-small-size;
		font-size: $val-fontSizeXSmall;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;

		& [class^="icon-"]:before {
			top: 0;
		}
	}

	&--medium {
		width: $status-icon-small-size;
		height: $status-icon-small-size;
		min-width: $status-icon-small-size;
		min-height: $status-icon-small-size;
		font-size: $val-fontSizeBig;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		
		& [class^="icon-"]:before {
			top: 0;
		}
	}

	&--clickable {
		cursor: pointer;
	}

}