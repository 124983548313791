.text-page {
	padding-top: $val-spacing-l;
	display: flex;
	justify-content: center;

	&__container {
		padding: $val-spacing-l;
	}

	&__title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}