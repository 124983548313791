.work-order-card-view {
	&__container {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
		align-content: flex-start;
	}

	&__card {
		background: $col-white;
		border-radius: $val-borderRadius;
		border: 1px solid $col-greyConnection;
		margin-bottom: $val-spacing-l;
		width: 100%;
		position: relative;
		box-shadow: $val-boxShadow;
		align-self: stretch;

		@media (min-width: $bp-xs-high) {
			flex-basis: 49%;
			max-width: 49%;
		}

		@media (max-width: $bp-xxs-high) {
			flex-basis: 100%;
			max-width: 100%;
		}

		&:after {
			position: absolute;
			left: -1px;
			top: -1px;
			display: block;
			background-color: $col-transparent;
			content: '';
			height: calc(100% + 2px);
			width: calc(100% + 2px);
			transition: background-color 0.2s ease-in outline 0.1s linear, outline-color 0s;
			z-index: 2;
			pointer-events: none;
		}
	
		&--disabled {
			&:after {
				background-color: $col-shadow;
				background-image: url("/images/elements/ic_locked_white.svg");
				background-repeat: no-repeat;
				background-position: bottom $val-spacing-s right $val-spacing-s;
				pointer-events: all;
			}
		}
	}

	&__card-header {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding: $val-spacing-s $val-spacing-m;
		background-color: $col-greyBackground;
		transition: background-color 100ms ease-in;

		& > a, & > span {
			flex-grow: 1;
		}

		&--highlighted {
			background-color: $col-orangeHighlighted;
		}

		&--canceled {
			background-color: $col-canceledRed;

			.work-order-card-view__card-header-link {
				color: $col-white;
			}
		}
	}

	&__status-code-container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		line-height: $val-lineHeightRegular;
	}

	&__status-code {
		font-size: $val-fontSizeSmall;
		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	&__status-icon {
		margin-right: $val-spacing-s;
	}

	&__order-code {
		font-weight: bold;
		line-height: $val-lineHeightSmall;
	}

	&__order-code-placeholder {
		color: $col-greyFontLight;
		text-transform: uppercase;
	}

	&__settings {
		max-width: $val-spacing-l;
		display: flex;
		justify-content: flex-start;
		flex-flow: column nowrap;

		.btn {
			min-width: initial;
			height: initial;
			padding: 0;
			padding-left: $val-spacing-m;
			line-height: $val-lineHeightRegular;
		}
	}

	&__job {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		padding: $val-spacing-m $val-spacing-l;
		background-color: $col-greyLight;
		border-bottom: 1px solid $col-greyConnection;
	}

	&__job-segment {
		display: flex;
		flex-flow: column nowrap;
		line-height: $val-lineHeightBig;
		overflow: hidden;

		&--left {
			align-items: flex-start;
			justify-content: flex-start;
			flex: 7;
		}

		&--right {
			align-items: flex-end;
			justify-content: flex-end;
			flex: 3;
		}
	}

	&__job-details {
		font-size: $val-fontSizeSmall;

		&--ellipsised {
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&--title {
			font-size: $val-fontSizeBig;
			font-weight: bold;
		}
	}

	&__updated-segment {
		font-size: $val-fontSizeSmall;
		align-items: center;
	}

	&__order {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-between;
		padding: $val-spacing-s $val-spacing-l;
		background-color: $col-greyLight;
		font-size: $val-fontSizeSmall;
	}

	&__order-segment {
		display: flex;
		flex-flow: column nowrap;
		line-height: $val-lineHeightBig;

		&--left {
			align-items: flex-start;
			justify-content: flex-start;
		}

		&--right {
			align-items: flex-end;
			justify-content: flex-end;
		}
	}

	&__order-crew-type {
		span {
			font-weight: bold;
			padding-left: $val-spacing-xs;
			padding-right: $val-spacing-xs;
			padding-top: $val-spacing-xxs;
			padding-bottom: $val-spacing-xxs;
		}
	}
}