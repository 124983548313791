@import "./Colors.scss";
@import "./Constants.scss";

.sk-fading-circle {
	width: $val-spacing-l;
	height: $val-spacing-l;
	position: relative;
	
	& .sk-circle {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	& .sk-circle:before {
		content: '';
		display: block;
		margin: 0 auto;
		width: 15%;
		height: 15%;
		background-color: white;
		border-radius: $val-spacing-l;
		-webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
				animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
	}
	
	&.blue {
		& .sk-circle:before {
			background-color: $col-blue;
		}
	}

	&.orange {
		& .sk-circle:before {
			background-color: $col-orangeBorder;
		}
	}
}

@mixin rotation-deg($deg) {
	-webkit-transform: rotate($deg);
		-ms-transform: rotate($deg);
			transform: rotate($deg);
}

.sk-fading-circle {
	& .sk-circle2 {
		@include rotation-deg(30deg);
	}

	& .sk-circle3 {
		@include rotation-deg(60deg);
	}

	& .sk-circle4 {
		@include rotation-deg(90deg);
	}

	& .sk-circle5 {
		@include rotation-deg(120deg);
	}

	& .sk-circle6 {
		@include rotation-deg(150deg);
	}

	& .sk-circle7 {
		@include rotation-deg(180deg);
	}

	& .sk-circle8 {
		@include rotation-deg(210deg);
	}

	& .sk-circle9 {
		@include rotation-deg(240deg);
	}

	& .sk-circle10 {
		@include rotation-deg(270deg);
	}
	
	& .sk-circle11 {
		@include rotation-deg(300deg);
	}

	& .sk-circle12 {
		@include rotation-deg(330deg);
	}
}

@mixin animation-delay($time) {
	-webkit-animation-delay: $time;
			animation-delay: $time; 
}

.sk-fading-circle {
	& .sk-circle2:before {
		@include animation-delay(-1.1s);
	}

	& .sk-circle3:before {		
		@include animation-delay(-1s);
	}
	
	& .sk-circle4:before {
		@include animation-delay(-0.9s);
	}
	
	& .sk-circle5:before {
		@include animation-delay(-0.8s);
	}

	& .sk-circle6:before {
		@include animation-delay(-0.7s);
	}
	
	& .sk-circle7:before {
		@include animation-delay(-0.6s);
	}
	
	& .sk-circle8:before {
		@include animation-delay(-0.5s);
	}
	
	& .sk-circle9:before {
		@include animation-delay(-0.4s);
	}
	
	& .sk-circle10:before {
		@include animation-delay(-0.3s);
	}
	
	& .sk-circle11:before {
		@include animation-delay(-0.2s);
	}
	  
	& .sk-circle12:before {
		@include animation-delay(-0.1s);
	}
}


  
@-webkit-keyframes sk-circleFadeDelay {
	0%, 39%, 100% { opacity: 0; }
	40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
	0%, 39%, 100% { opacity: 0; }
	40% { opacity: 1; } 
}