$min-box-height: 354px;
$permission-header-height: 112px;

.member-permissions {

	border-bottom: 1px solid $col-greyConnection;

	&__controls-container {
		display: flex;
		justify-content: flex-end;
	}

	&__control {
		@extend .btn--control;
	}

	&__permissions-box {
		background: $col-greyLight;
		padding: $val-spacing-m;
		min-height: $min-box-height;
		margin-top: $val-spacing-m;

		& .checkbox-inline+.checkbox-inline {
			// Avoiding default behaviour
			margin-left: 0;
		}
	}

	&__permissions-box-header {
		font-size: $val-fontSizeSmall;
		margin-bottom: $val-spacing-m;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__permissions-box-title {
		@extend .segment-label;
		padding: 0;
	}

	&__single {
		padding: $val-spacing-s;
		border-bottom: 1px solid $col-greyConnection;
	}

	&__toggle {
		display: flex;
		justify-content: flex-end;
	}

	&__header {
		display: flex;
		align-items: center;
		height: $permission-header-height;
		padding: $val-spacing-m;

	}

	&__email {
		display: flex;

		&--disabled {
			color: $col-greyFontLight;
		}
	}

	&__name {
		font-size: $val-fontSizeRegular;
		font-weight: bold;
	}

	&__contact {
		font-size: $val-fontSizeSmall;
		padding-top: $val-spacing-xxs;
	}

	&__user-type {
		font-size: $val-fontSizeSmall;
		padding-top: $val-spacing-s;
		font-weight: bold;
	}

	&__all-permissions {
		padding: $val-spacing-m;
	}

	&__template-label {
		display: flex;
		align-items: center;

		.icon-info {
			margin-left: $val-spacing-xs;
			color: $col-greyFontLight;
			font-size: $val-fontSizeBig;

			&:hover {
				color: $col-greyDark;
			}
		}
	}

	&__description {
		&__title {
			font-weight: bold;
			color: $col-orange;
		}

		&__list {
			padding-left: $val-spacing-m;
			margin-bottom: 0;
		}

		&+& {
			margin-top: $val-spacing-s;
		}
	}
}