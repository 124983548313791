@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';

.add-billable-work-modal {
	padding: (-$val-spacing-xl) $val-spacing-m;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: $val-spacing-s $val-spacing-l;

	&__date {
		width: 50%;
	}

	&__comment {
		height: 100%;
	}

	&__billing-code-placeholder {
		font-weight: normal;
		font-style: italic;
		color: $col-greyMid;
	}

	&__billing-code-item {
		margin-top: $val-spacing-m;
	}

	&__billing-code-dropdown-item {
		& > b {
			display: flex;
		}
	}

	&__info-def-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > [class^="icon-delete"] {
			font-size: $val-fontSizeBig;
			margin-left: $val-spacing-m;
			color: $col-greyMid;
			cursor: pointer;

			&:hover {
				color: $col-greyDark;
			}
		}
	}

	&__add-field {
		color: $col-blue;
		cursor: pointer;
		margin-bottom: $val-spacing-s;

		& > [class^="icon-"] {
			font-size: $val-fontSizeBig;
			margin: 0 $val-spacing-s;
		}
	}
}

.dropdown_card_subtitle {
	font-size: $val-fontSizeSmall;
	color: $col-greyInactive;
	margin-bottom: 0;
}