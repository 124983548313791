@import "./Colors.scss";
@import "./Constants.scss";

$var-companyLogoBigSize: 36px;
$var-companyLogoSmallSize: 22px;
$var-borderBottom: 1px solid $col-greyConnection;
$var-zIndex: $val-sidenavZIndex + 1;
$var-shadow: 0 2px 16px 2px $col-shadow;
$var-navigationLinkHeight: 36px;

$var-connectionLineThickness: 1px;
$var-connectionLineWidth: calc(50% - 10px);
$var-connectionLineOffset: calc(50% + 10px);
$var-connectionLineBaseline: 22px;
$var-statusIconHeight: 20px;
$var-navigationPadding: 0 $val-spacing-m;

$var-addNewCompanyPadding: 6px;
$var-companyListOffsetTop: -2px;
$var-companyListPadding: 0;

$var-requestInfoWidth: 225px;
$var-requestInfoHeight: 38px;
$var-requestInfoStatusWidth: 42px;
$var-requestInfoTitleWidth: calc(#{$var-requestInfoWidth} - #{$var-requestInfoStatusWidth} - 8px);

$var-logoMargin: 2px;
$var-statusIconMargin: 2px;

$var-organizationLogoPadding: 1px 2px 3px 2px;

$var-datepicker-height: 34px;
$var-datepicker-width: 300px;

$var-sb-link-padding: 5px 10px;

.orders-navigation, .display-view-navigation {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding-left: $val-spacing-l;
	padding-right: $val-spacing-l;
	height: $val-headerHeight;
	width: 100%;
	user-select: none;

	& .orders-navigation-right {
		margin-right: -$val-spacing-l;
		display: flex;
			
		& .btn__rectangle-group {
			margin-right: $val-spacing-m;
		}

		& #table-and-display-view-dropdown-menu {
			min-width: unset;
			padding: 0px;
		}
	}
}

.logged-out-header {
	width: 100%;
	text-align: center;

	& .main-logo {
		height: $val-headerHeight;
		padding: $val-spacing-m;
	}
}

.navigation-title, .display-view-navigation-title {
	font-weight: bold;
	font-size: $val-fontSizeXBig;
}