.color-legend {
	&__color-row {
		display: flex;
	}

	&__color-square {
		@extend .color-square;
		display: inline-block;
		border: 1px solid $col-greyConnection;
		margin-right: $val-spacing-s;
	}
}