.addresses {
	&__headers {
		margin-bottom: $val-spacing-s;
	}

	&__add {
		display: flex;
	}
}

.address {
	&__map-toggle {
		border-left: none !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
}