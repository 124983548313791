.printout-segment-header {
	padding: $val-spacing-xs $val-spacing-s;
	border: 1px solid $col-transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&--segment-primary {
		background: $col-blue;
		color: $col-white;
		border-color: $col-blue;
	}

	&--segment-secondary {
		background: $col-greyConnection;
		border-color: $col-greyConnection;
	}

	&--block-primary {
		background: $col-blueBackground;
		color: $col-blue;
		border-color: $col-blue;
		font-size: $val-fontSizeSmall;
	}

	&--block-secondary {
		background: $col-greyBackground;
		border-color: $col-greyConnection;
		font-size: $val-fontSizeSmall;
	}

	&__title {
		font-weight: bold;
		text-transform: uppercase;
	}

	&__completion {
		font-size: $val-fontSizeSmall;
		line-height: $val-fontSizeSmall;
		display: flex;
		align-items: center;

		.icon-close, .icon-check {
			font-size: $val-fontSizeRegular;
			margin-right: $val-spacing-s;
		}
	}
}