@import "./Constants.scss";
@import "./Colors.scss";

$var-iconOffset: 30px;
$var-iconSize: 21px;

.notification-base {
	border-radius: $val-borderRadius;
	padding: $val-spacing-m;
	padding-right: $val-spacing-xl;
	color: $col-greyDark;
	border-width: 1px;
	border-style: solid;
	border-left-width: $val-spacing-xxl;
	position: relative;
	background: $col-white;

	& .icon-delete {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $val-spacing-xl;
		font-size: $val-fontSizeBig;
		cursor: pointer;
		color: $col-grey;
	}
	
	&:before {
		color: $col-white;
		font-family: 'icons';
		font-size: $var-iconSize;
		position: absolute;
		left: -$var-iconOffset;
		top: 0;
		bottom: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	& .notification-time {
		font-weight: bold;
		font-size: $val-fontSizeSmall;
		color: $col-grey;
	}

	& + [class^="notification-"] {
		margin-top: $val-spacing-m;
	}
}

.notification-warn {
	@extend .notification-base;
	border-color: $color-orange;

	&:before {
		content: '\e92c';
	}
}

.notification-info {
	@extend .notification-base;
	border-color: $color-babyblue;

	&:before {
		content: '\e917';
	}
}

.notification-success {
	@extend .notification-base;
	border-color: $color-green;

	&:before {
		content: '\e92d';
	}
}

.notification-error {
	@extend .notification-base;
	border-color: $color-red;

	&:before {
		content: '\e92e';
	}
}

.Toastify__toast {

	overflow: initial;

	& .Toastify__close-button {
		display: none;
	}

	&-body {
		background: $col-white;
	}

	&--error {
		@extend .notification-error;
	}

	&--warning {
		@extend .notification-warn;
	}

	&--info {
		@extend .notification-info;
	}

	&--success {
		@extend .notification-success;
	}

}