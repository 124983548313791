@import "./Colors.scss";
@import "./Constants.scss";
@import "./Shared.scss";

$var-maxNumberOfResourcesInColumn: 12;
$var-skillColorRectangleDimension: 20px;
$var-draggableItemHeight: 30px;
$var-skillPadding: 1px;
$var-datepickerSize: 34px;
$var-avatarSize: 210px;
$var-modalButtonHeight: 42px;
$var-labelBlockHeight: 30px;
$val-colorSquareSize: 20px;
$var-payItemContainerWidth: 366px;
$var-notificationBadgeSize: 6px;
$var-notificationBadgeOffset: 5px;
$var-badgeSize: 15px;
$var-badgeOffset: 8px;
$var-modalSegmentMargin: 20px;
$var-reservedDatepickerSpace-l: 370px;
$var-reservedDatepickerSpace-xl: 400px;
$var-rightModalHeaderWidth: 274px;
$var-cardsContainerMinHeight: 670px;
$var-noWorkOrdersMinWidth: 280px;
$var-weeklyViewToolbarHeaderHeight: 138px;
$var-scheduleBoardWeeklyViewBottomPadding: $val-spacing-xxxl * 2;
$var-lockedBannerActionDescriptionMaxWidth: 250px;
$var-highlightedActiveOutline: 3px;
$var-badgeBorderSize: 2px;

$var-removedResourceLookupBorderSize: 2px;

$var-cardAddressHeight: 67px;
$var-cardChildDefaultPadding: $val-spacing-xs;
$var-cardCrewContentMinHeight: $var-cardChildDefaultPadding * 2 + $val-lineHeightRegular + $val-spacing-xs;
$var-cardCrewPaddingTop: $val-spacing-m;
$var-cardCrewMinHeight: $var-cardCrewContentMinHeight + $var-cardCrewPaddingTop + $var-cardChildDefaultPadding;

$var-scrollBarHeight: 20px;
$var-scrollBarPadding: 9px;
$var-scrollBarAnchorHeight: $var-scrollBarHeight;

$var-arrowContainerHeight: $val-spacing-l;
$var-arrowHeight: #{$var-arrowContainerHeight - $val-spacing-s};
$var-arrowWidthHalf: calc(#{$var-arrowHeight} / 1.4);

$var-cardCheckboxSize: 22.5px;
$var-cardCheckboxTopPosition: -7px;
$var-cardCheckboxLeftPosition: -6px;

$var-toolbarCheckboxOffset: -16px;

$var-droppableDefaultHeight: 24px;
$var-placeholderInnerShadow: inset 0 0 $val-spacing-s $col-greyMid;

$val-tipsSectionHeight: 138px;
$val-collapsedTipsSectionHeight: 44px;

$var-noCDLBackground: repeating-linear-gradient(45deg, transparent, transparent 5px, $col-orange 5px, $col-orange 10px);

:root {
	--no-cdl-background-image-alternative: url("/images/elements/ic_orange_stripes.svg"); // used for html2canvas
}

@keyframes twinkle {
	0% {
		opacity: 0.6;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.6;
	}
}

@keyframes jump {
	0% {
		transform: translateY(-3px);
	}

	50% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(-3px);
	}
}

/*
 * Used this in mechanic view header
*/
.schedule-board-navigation {
	width: calc(100vw - #{$val-floatingSidebarWidthMax} - #{$val-sidebarWidthMin});
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 $val-spacing-l;
	margin-right: -$val-spacing-m;
	user-select: none;

	@media (max-width: $bp-xs-high) {
		justify-content: flex-end;
	}

	& .btn-datepicker {
		background: $col-greyBackground url("/images/inputs/ic_calendar_inactive.svg") center center no-repeat;
		margin-left: $val-spacing-s;
		display: block;
		height: $val-inputHeight;
		width: $val-inputHeight;
		background-size: $val-icon12;
		border: 0;
		padding: 6px;
		font-size: 1em;
		transition: all 0.1s ease;
		cursor: pointer;
		outline: none;
		border: 1px solid $col-greyMid;
	}

	& .schedule-board-navigation-left {
		display: flex;
		justify-content: flex-start;

		@media (min-width: $bp-m-high) {
			&>div {
				display: flex;
				justify-content: flex-start;
			}
		}

		@media (max-width: $bp-xs-high) {
			display: none;
		}

		@media (min-width: $bp-m-high) {
			.connection-counters {
				margin-left: $val-spacing-m;
			}
		}
	}

	& .schedule-board-navigation-center {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		flex: 1;
		justify-content: center;
		position: fixed;
		left: $val-sidebarWidthMin;
		right: 0;

		@media (max-width: $bp-xs-high) {
			right: auto;
		}

		& .sb-view-switcher {
			width: 50%;
			display: flex;
			justify-content: flex-end;
		}

		& .sb-date-picker {
			display: flex;
			width: 50%;
		}

		& .schedule-board-calculations-date {
			font-weight: bold;
			font-size: $val-fontSizeXXXBig;
		}

		& .schedule-board-datepicker {
			display: flex;
			margin-right: $val-spacing-s;

			& .schedule-board-date-range-picker {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;

				& .schedule-board-datepicker-input:last-child {
					margin-left: $val-spacing-m;
				}
			}

			& .schedule-board-yearpicker-input {
				margin-left: $val-spacing-m;
			}

			& .schedule-board-datepicker-input,
			& .schedule-board-monthpicker-input,
			& .schedule-board-yearpicker-input {
				width: 150px;
				line-height: initial;

				&.schedule-board-datepicker-input {
					width: auto;

					& .schedule-board-daily-date-picker,
					& .schedule-board-weekly-date-picker {
						display: flex;
						align-items: center;
						justify-content: space-between;

						& .schedule-board-date-format {
							font-weight: bold;
							font-size: $val-fontSizeXXXBig;
							display: flex;
							width: 100%;
							justify-content: center;
							align-items: center;
							padding: 0 $val-spacing-m;

							& .time-delimiter {
								margin: 0 $val-spacing-s;
								font-size: $val-fontSizeBig;
								font-weight: normal;
							}
						}

						& .btn--icon {
							border-right: none;
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}
					}

					& .schedule-board-weekly-date-picker {
						@media (max-width: $bp-s-high) {
							min-width: $var-reservedDatepickerSpace-l;
						}

						@media (min-width: $bp-m-low) {
							min-width: $var-reservedDatepickerSpace-xl;
						}
					}

					& .schedule-board-daily-date-picker {
						@media (max-width: $bp-s-high) {
							min-width: $var-reservedDatepickerSpace-l;
						}

						@media (min-width: $bp-m-low) {
							min-width: $var-reservedDatepickerSpace-xl;
						}
					}
				}

				& .react-datepicker-wrapper {
					width: 100%;

					& .react-datepicker__input-container {
						width: 100%;
					}

					& input {
						height: $val-inputHeight;
						font-size: $val-fontSizeRegular;
					}
				}
			}
		}
	}

	& .schedule-board-navigation-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 0;

		&>div+a,
		&>a+div {
			margin-left: $val-spacing-s;
		}

		& #table-and-display-view-dropdown-menu {
			min-width: unset;
			padding: 0px;
		}
	}
}

.icon-display_view_next_day {
	font-size: $val-fontSizeXBig !important;
}

@mixin scheduleBoardMixin($var-colPerRow) {
	$var-colInToolbar: 4;
	$var-containerWidth: calc(100vw - #{$val-sidebarWidthMin});
	$var-containerWidthNoPadding: calc(#{$var-containerWidth} - 2 * #{$val-spacing-m} - 2 * #{$val-spacing-s});

	$var-cardWidth: calc(#{$var-containerWidth} / #{$var-colPerRow + $var-colInToolbar});
	$var-cardMinWidth: calc(#{$var-cardWidth} - 20px / #{$var-colPerRow + $var-colInToolbar});
	$var-cardPadding: $val-spacing-s $val-spacing-s;

	$var-cardColumnWidthBase: calc(#{$var-cardWidth} - 2 * #{$val-spacing-s});
	$var-toolbarWrapperWidthBase: calc(#{$var-cardWidth} - 3 * #{$val-spacing-s} - 2 * #{$val-spacing-xs});
	$var-draggableItemWidthBase: calc(#{$var-cardWidth} - 2 * #{$val-spacing-s} - 2 * #{$val-spacing-xs});

	$var-shadow: 0 2px 2px 0 $col-shadow;
	$var-toolbarWidth: calc(#{$var-colInToolbar} * #{$var-toolbarWrapperWidthBase} + 4 * (#{$val-spacing-s} + #{$val-spacing-m}));
	$var-cardResourceItemMinHeight: calc(#{$val-spacing-xs} + #{$var-draggableItemHeight});
	$var-cardSectionPaddingHeight-xs: 1px+2 * $val-spacing-xs;
	$var-cardSectionPaddingHeight-s: 1px+2 * $val-spacing-s;
	$var-weeklyViewOfficeStatsMinWidth: 200px;

	height: 100%;
	width: 100%;
	background-color: $col-greyBackground;
	border: none;
	overflow: hidden;

	&.--filtered-results {

		& .schedule-board-card,
		& .sb-resource-item,
		& .schedule-board-month-card {
			transition: opacity 0.2s ease-in;

			&:not(.filtered) {
				opacity: 0.4;
			}
		}
	}

	& .--copy-placeholder {
		&+div[style] {
			display: none !important;
		}
	}

	& .input-group-append {
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		padding: 0;
		background: none;
		border: none;
	}

	&.metrics {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;

		& .schedule-board-card-wrapper {
			padding-bottom: $var-scrollBarAnchorHeight + $val-spacing-s;

			& .schedule-board-card {
				& a[role="button"] {
					cursor: default;
				}

				& .schedule-board-card-crew {
					cursor: default !important;
				}
			}
		}

		&.with-banner {
			& .banner-wrapper {
				width: 100%;
				height: $val-headerHeight;

				& .banner-container {
					height: 100%;
					padding: 0 $val-spacing-l;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: space-between;

					& .banner-description {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						color: $col-white;
						font-size: $val-fontSizeXBig;

						& .icon-lock {
							color: $col-white;
							font-size: $val-fontSizeXXBig;
						}
					}
				}
			}

			& .horizontal-scrollbar {
				&.top {
					top: #{$val-headerHeight * 2};
				}
			}
		}

		&.validation {
			& .banner-wrapper {
				background-color: $color-red;
			}
		}

		&.locked {
			& .banner-wrapper {
				background-color: $col-black;

				& .banner-container {
					& .banner-actions {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;

						& .btn {
							color: $col-white;
						}

						& .banner-action-description {
							font-size: $val-fontSizeRegular;
							max-width: $var-lockedBannerActionDescriptionMaxWidth;
							color: $col-white;
							font-weight: 100;
							display: inline-block;
							padding: $val-spacing-s;
						}
					}
				}
			}
		}

		& .horizontal-scrollbar {
			margin-left: $var-cardWidth;
			position: fixed;

			&.top {
				top: $val-headerHeight;
				background: linear-gradient(to bottom, $col-greyBackground 60%, transparent 0%);
			}

			&.bottom {
				margin-left: 0;
				padding-left: $var-cardWidth;
				background: linear-gradient(to bottom, transparent 50%, $col-greyBackground 0%);
			}

			& .scrollbar-container {
				width: calc(#{$var-containerWidth} - #{$var-cardWidth}) !important;
			}
		}

		& .loading {
			display: flex;

			@media (min-width: $bp-l-high) {
				padding-top: calc(#{$var-arrowContainerHeight} + #{$val-lineHeightRegular} + #{$var-cardSectionPaddingHeight-s} + 2 * #{$val-spacing-xs} + #{$val-spacing-m});
			}

			@media (max-width: $bp-m-high) {
				padding-top: calc(#{$var-arrowContainerHeight} + #{$val-lineHeightRegular} + #{$var-cardSectionPaddingHeight-xs} + #{$val-spacing-xs} + #{$val-spacing-m});
			}
		}

		& .cards-container {
			display: flex;
			position: absolute;
			padding-top: $val-spacing-m + $val-spacing-s;
			margin-top: 0 !important;
		}

		& .schedule-board-card-resources {
			background-color: $col-greyLight;

			@for $i from 1 through 12 {
				&.items-#{$i} {
					min-height: calc(2 * #{$val-spacing-xs} + #{$i} * #{$var-cardResourceItemMinHeight});
				}
			}
		}

		& .card-header {
			position: sticky;
			left: 0;
			background-color: $col-greyBackground;
			z-index: 3;

			& .title {
				display: flex;
				flex-flow: column wrap;
				justify-content: center;
				padding-right: 0;
				padding-left: 0;
			}

			& .schedule-board-card-resources {
				background-color: $col-greyBackground;
				justify-content: flex-start;
				padding-top: $val-spacing-s;

				&.items-0 {
					min-height: #{$var-draggableItemHeight + $val-spacing-s + $var-cardChildDefaultPadding};
				}
			}

			& .schedule-board-card-supervisor,
			& .schedule-board-card-crew,
			& .schedule-board-card-shop-work {
				border-bottom: 1px solid transparent;
			}

			& .schedule-board-card-shop-work {
				height: #{$val-lineHeightSmall + $var-cardChildDefaultPadding * 2};
			}

			& .input-fields-titles {
				&>div {
					min-height: calc(#{$val-inputHeightM} + 2 * #{$val-spacing-xs});
				}
			}
		}
	}

	.sb-resource-item {
		width: $var-draggableItemWidthBase;
	}

	.work-orders-droppable {
		display: flex;
		flex-wrap: nowrap;
		margin: $val-spacing-xxs;
	}

	.equipment-droppable,
	.employee-droppable,
	.resource-droppable {
		width: 100%;
		min-height: $var-draggableItemHeight;
	}

	.work-orders-droppable,
	.resource-droppable,
	.equipment-droppable,
	.employee-droppable {
		position: relative;

		&.empty-droppable {
			height: $var-droppableDefaultHeight;
			margin-bottom: $val-spacing-xs;
		}

		&.wrapped-columns {
			display: flex;
			flex-flow: column nowrap;
		}

		&.multiple-droppable-column {
			align-items: center;

			&.drag-over::before {
				@media (min-width: $bp-l-high) {
					margin-top: (2 * $val-spacing-s + $val-fontSizeRegular) !important;
				}

				@media (max-width: $bp-m-high) {
					margin-top: (2 * $val-spacing-s + $val-fontSizeSmall) !important;
				}
			}
		}
	}

	.schedule-board-cards-container-wrapper {
		width: calc(#{$var-containerWidth} - #{$var-toolbarWidth}) !important;
		background: $col-white;

		& .schedule-board-cards-scrollable-container {
			margin-bottom: $val-headerHeight;
		}

		& .schedule-board-cards-container {
			height: inherit;
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			padding: $val-spacing-xs $val-spacing-m;
		}
	}

	.weekly-view-toolbar-wrapper {
		position: fixed;
		left: $val-sidebarWidthMin;
		top: 2 * $val-headerHeight;
		width: $var-toolbarWidth;
		height: 100%;
		background-color: $col-white;
		z-index: 4;

		& .toolbar-header {
			position: relative;
			display: flex;
			align-items: center;
			height: $var-weeklyViewToolbarHeaderHeight;
			background-color: $col-greyBackground;
			color: $col-greyInactive;
			padding-left: $val-spacing-m;
			border-bottom: 1px solid $col-greyConnection;

			& .schedule-board-day-name {
				font-size: $val-fontSizeHuge;
				font-weight: bold;
				color: $col-blue;
			}

			& .schedule-board-date {
				font-size: $val-fontSizeXBig;
				font-weight: 100;
				color: $col-greyDark;
			}

			& .close-icon {
				position: absolute;
				top: $val-spacing-m;
				right: $val-spacing-m;
			}
		}
	}

	.schedule-board-weekly-view-wrapper {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
		position: absolute;
		background: $col-white;

		& .weekly-view-toolbar-wrapper {
			& .schedule-board-toolbar .resources {
				max-height: calc(100vh - 2 * #{$val-headerHeight} - #{$val-tipsSectionHeight} - #{$var-weeklyViewToolbarHeaderHeight});

				&--extended {
					max-height: calc(100vh - 2 * #{$val-headerHeight} - #{$val-collapsedTipsSectionHeight} - #{$var-weeklyViewToolbarHeaderHeight});
				}
			}
		}

		& .schedule-board-week-row {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			background-color: $col-white;
			margin-bottom: $val-spacing-s;

			& .day-curtain {
				position: absolute;
				width: calc(100% + #{$val-spacing-xs});
				height: calc(100% + 2 * #{$val-spacing-xs});
				background-color: $col-blackShadow;
				z-index: 2;
				top: -$val-spacing-xs;
				left: -$val-spacing-xs;
			}

			&.start-day {
				padding-top: $val-spacing-m + $val-spacing-s;
				margin-top: 0 !important;
			}

			&.end-day {
				padding-bottom: $var-scheduleBoardWeeklyViewBottomPadding;
			}

			&.horizontal-scrollbar {
				position: fixed;
				margin-left: calc(#{$var-toolbarWidth} + #{$val-spacing-xs});
				background-color: transparent;

				&.top {
					margin-top: 0;
				}

				&.bottom {
					margin-bottom: 0;
				}

				& .scrollbar-container {
					width: calc(#{$var-containerWidth} - #{$var-toolbarWidth} - #{$val-spacing-s}) !important;
				}
			}

			& .schedule-board-week-day-wrapper {
				display: flex;
				flex-flow: column nowrap;
				width: $var-toolbarWidth;
				background: $col-greyBackground;
				min-height: $var-cardsContainerMinHeight;

				& .schedule-board-week-day {
					width: $var-toolbarWidth;
					padding: $val-spacing-m;
					color: $col-grey;
					display: flex;
					justify-content: space-between;

					& .left-actions {
						display: flex;
						justify-content: space-between;
						position: relative;
						flex-direction: column;
					}

					& .right-actions {
						display: flex;
						position: relative;
						flex-direction: column;
						width: 100%;
						align-items: flex-end;

						& .schedule-board-toolbar-checkbox {
							position: absolute;
							right: $var-toolbarCheckboxOffset;
							top: $var-toolbarCheckboxOffset;
						}

						&>div {
							display: flex;
							justify-content: center;
						}

						& .btn {
							margin-top: $val-spacing-s;
							min-width: 0;
							max-width: 250px;

							@media (max-width: $bp-xxs-high) {
								width: calc(800px / #{$var-colPerRow});
							}

							@media (min-width: $bp-xs-low) and (max-width: $bp-xs-high) {
								width: calc(1000px / #{$var-colPerRow});
							}

							@media (min-width: $bp-s-low) and (max-width: $bp-s-high) {
								width: calc(1300px / #{$var-colPerRow});
							}

							@media (min-width: $bp-m-low) and (max-width: $bp-m-high) {
								width: calc(2000px / #{$var-colPerRow});
							}

							@media (min-width: $bp-l-low) and (max-width: $bp-l-high) {
								width: calc(4000px / #{$var-colPerRow});
							}

							@media (min-width: $bp-xl-low) {
								width: calc(5000px / #{$var-colPerRow});
							}

							&.btn--small {
								max-width: 121px;

								@media (max-width: $bp-xxs-high) {
									width: calc(calc(400px / #{$var-colPerRow}) - #{$val-spacing-xs});
								}

								@media (min-width: $bp-xs-low) and (max-width: $bp-xs-high) {
									width: calc(calc(500px / #{$var-colPerRow}) - #{$val-spacing-xs});
								}

								@media (min-width: $bp-s-low) and (max-width: $bp-s-high) {
									width: calc(calc(650px / #{$var-colPerRow}) - #{$val-spacing-xs});
								}

								@media (min-width: $bp-m-low) and (max-width: $bp-m-high) {
									width: calc(calc(1000px / #{$var-colPerRow}) - #{$val-spacing-xs});
								}

								@media (min-width: $bp-l-low) and (max-width: $bp-l-high) {
									width: calc(calc(2000px / #{$var-colPerRow}) - #{$val-spacing-xs});
								}

								@media (min-width: $bp-xl-low) {
									width: calc(calc(2500px / #{$var-colPerRow}) - #{$val-spacing-xs});
								}

								&.double {
									max-width: 250px;

									@media (max-width: $bp-xxs-high) {
										width: calc(calc(800px / #{$var-colPerRow}));
									}

									@media (min-width: $bp-xs-low) and (max-width: $bp-xs-high) {
										width: calc(calc(1000px / #{$var-colPerRow}));
									}

									@media (min-width: $bp-s-low) and (max-width: $bp-s-high) {
										width: calc(calc(1300px / #{$var-colPerRow}));
									}

									@media (min-width: $bp-m-low) and (max-width: $bp-m-high) {
										width: calc(calc(2000px / #{$var-colPerRow}));
									}

									@media (min-width: $bp-l-low) and (max-width: $bp-l-high) {
										width: calc(calc(4000px / #{$var-colPerRow}));
									}

									@media (min-width: $bp-xl-low) {
										width: calc(calc(5000px / #{$var-colPerRow}));
									}
								}
							}
						}
					}

					&>a {
						display: block;
						color: inherit;
						transition: background-color 0.1s ease-in;
					}

					& .schedule-board-day-name {
						font-size: $val-fontSizeHuge;
						font-weight: bold;
						color: $col-blue;
					}

					& .schedule-board-date {
						font-size: $val-fontSizeXBig;
						font-weight: 100;
						color: inherit;
					}
				}

				& .schedule-board-week-day-actions {
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					position: relative;
					flex: 1;
					padding: $val-spacing-m 0;
				}

				&>span {
					width: fit-content;
				}

				& .copy-button {
					display: flex;
					align-items: center;
					color: $col-greyFontLight;
					background: none;
					border: none;
					margin: $val-spacing-s 0;

					&:hover {
						background-color: $col-lightGrey;
					}
				}
			}

			& .schedule-board-week-no-work {
				padding: $val-spacing-l;
				color: rgba($color: $col-greyFontLight, $alpha: 0.7);
				font-size: $val-fontSizeBig;
				font-weight: 100;
				min-width: $var-noWorkOrdersMinWidth;
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: column;
				justify-content: center;
				min-height: $var-cardsContainerMinHeight;
			}

			& ::-webkit-scrollbar {
				display: none;
			}

			& .schedule-board-week-day-cards-wrapper {
				overflow: hidden;
				width: 100%;
				position: relative;

				&>div {
					position: relative !important;
					overflow: hidden !important;

					& .schedule-board-week-cards-container-wrapper {
						position: relative;

						@for $i from 1 through 200 {
							&.cards-#{$i} {
								width: calc(#{$i} * (#{$var-cardWidth}));
								min-width: calc(#{$i} * (#{$var-cardWidth}));
							}
						}

						& .schedule-board-week-cards-container {
							padding: $val-spacing-s $val-spacing-m;
							min-height: $var-cardsContainerMinHeight;
							white-space: nowrap;
							height: inherit;
							width: inherit;
							display: flex;
							flex-wrap: nowrap;
							align-items: stretch;

							&>div {
								height: inherit;
								display: block;
							}
						}
					}
				}
			}
		}
	}

	.schedule-board-card-wrapper {
		padding: $var-cardPadding;
		width: $var-cardWidth;
		min-width: $var-cardMinWidth;

		& .schedule-board-card-notes {
			cursor: pointer;
			padding: $val-spacing-s;
			font-size: $val-fontSizeSmall;
			border-top: 1px solid $col-greyConnection;
			background: $col-white;
			word-break: break-word;
			white-space: normal;

			& .schedule-board-card-add-note {
				color: $col-blue;
			}
		}

		@for $i from 1 through 40 {
			&.columns-#{$i} {
				width: calc(#{$i} * #{$var-cardWidth});
				min-width: calc(#{$i} * #{$var-cardMinWidth});

				& .schedule-board-card-crew {
					& .schedule-board-card-code {
						& span {
							max-width: calc(0.9 * (#{$i} * #{$var-cardColumnWidthBase}));
						}
					}
				}
			}
		}

		& .work-order-metrics {
			background-color: $col-greyLight;

			& .form-group {
				padding: $val-spacing-xs;
				width: 100%;
				margin-bottom: 0;
			}
		}

		& .blank-card {
			box-shadow: none;
			height: 100%;
			max-height: calc(100% - #{$var-arrowContainerHeight});
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& .placeholder-card {
			box-shadow: $var-shadow;
			background-color: $col-greyBackground;
			border-radius: $val-borderRadius;
			position: relative;
			height: 100%;
			max-height: calc(100% - #{$var-arrowContainerHeight});
			display: flex;
			justify-content: center;
			align-items: center;
		}

		// Default styles for metrics title and schedule-board-card elements
		& .schedule-board-card-supervisor,
		& .schedule-board-card-code,
		& .schedule-board-card-employees,
		& .schedule-board-card-equipment,
		& .schedule-board-card-resources,
		& .schedule-board-card-shop-work,
		& .schedule-board-card-metrics {
			padding: $var-cardChildDefaultPadding;
		}

		& .schedule-board-card-locked {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: calc(#{$val-spacing-l} + #{$val-spacing-xxs}) 0;
			background-color: $col-blue;
			color: $col-white;
			text-transform: uppercase;
			font-size: $val-fontSizeSmall;
			line-height: $val-lineHeightSmall;
			font-weight: normal;
			text-align: center;
		}

		& .schedule-board-card-on-hold {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: calc(#{$val-spacing-l} + #{$val-spacing-xxs}) 0;
			background-color: $col-greyInactive;
			color: $col-white;
			text-transform: uppercase;
			font-size: $val-fontSizeSmall;
			font-weight: normal;
			text-align: center;
		}

		& .schedule-board-card-crew {
			padding-top: $var-cardCrewPaddingTop;
			padding-right: $var-cardChildDefaultPadding;
			padding-bottom: $var-cardChildDefaultPadding;
			padding-left: $var-cardChildDefaultPadding;
			min-height: $var-cardCrewMinHeight;

			& .schedule-board-card-job-tooltip {
				width: 10px;
				height: 10px;
			}

			& .schedule-board-card-job-status {
				border-radius: $val-borderRadius;
				position: absolute;
				top: 0;
				left: 0;
				margin-left: $val-spacing-xs;
				margin-top: $val-spacing-xs;
				border: 1px solid $col-white
			}
		}

		& .schedule-board-card-address {
			padding: $val-spacing-s $var-cardChildDefaultPadding;
			height: $var-cardAddressHeight;
		}

		& .schedule-board-card-supervisor,
		& .schedule-board-card-address,
		& .schedule-board-card-canceled {
			line-height: $val-lineHeightSmall;
		}

		& .schedule-board-card {
			box-shadow: $var-shadow;
			background-color: $col-greyBackground;
			border-radius: $val-borderRadius;
			position: relative;

			&:focus {
				outline: none !important;
			}

			&::after {
				position: absolute;
				left: -1px;
				top: -1px;
				display: block;
				background-color: $col-transparent;
				content: "";
				height: calc(100% + 2px);
				width: calc(100% + 2px);
				transition: background-color 0.2s ease-in outline 0.1s linear, outline-color 0s;
				z-index: 2;
				pointer-events: none;
			}

			& .project {
				border: $val-borderSize-s solid $col-design-grey10;
				border-top: $val-borderSize-m solid $col-design-grey10;
				border-bottom: $val-borderSize-m solid $col-design-grey10;
			}

			&.disabled {
				&::after {
					background-color: $col-shadow;
					background-image: url("/images/elements/ic_locked_white.svg");
					background-repeat: no-repeat;
					background-position: bottom $val-spacing-s right $val-spacing-s;
					pointer-events: all;
				}

				& .sb-resource-item--disabled {
					position: relative !important;
					transform: none !important;
					box-shadow: none !important;
					z-index: initial !important;
					background: initial !important;

					& a {
						color: $col-greyFontLight;
					}
				}
			}

			&.highlighted {
				&::after {
					background-color: $col-orangeHighlighted;
					pointer-events: none;
					animation-name: twinkle;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					border-radius: $val-borderRadius;
					outline: 2px dashed $col-orange !important;
					outline-offset: 1px;
				}
			}

			&.highlighted-active {
				&::after {
					outline: $var-highlightedActiveOutline solid $col-orange !important;
				}
			}

			&.disabled.highlighted {
				&::after {
					background-color: $col-shadow;
					outline: 2px dashed $col-orange !important;
					outline-offset: 1px;
					animation-name: twinkle;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					border-radius: $val-borderRadius;
				}
			}

			&.selected {
				outline-color: $col-orange;
				outline-style: solid;
			}

			& .schedule-board-card-checkbox {
				position: absolute;
				top: $var-cardCheckboxTopPosition;
				left: $var-cardCheckboxLeftPosition;
				height: $val-fontSizeRegular;
				width: $val-fontSizeRegular;
				margin: 0;
				z-index: 1;

				&:before {
					display: flex;
					align-items: center;
					justify-content: center;
					height: $val-fontSizeRegular;
					width: $val-fontSizeRegular;
					border-radius: $val-borderRadius;
					background: $col-white;
				}
			}

			& .schedule-board-card-crew {
				font-weight: bold;
				text-align: center;
				background-color: $col-greyBackground;
				border-bottom: 1px solid $col-greyConnection;
				cursor: pointer;
				border-top-left-radius: $val-borderRadius;
				border-top-right-radius: $val-borderRadius;

				& .schedule-board-card-status {
					font-weight: bold;
					display: flex;
					justify-content: center;
					padding: 0;

					& .schedule-board-card-status-icon {
						position: absolute;
						top: -$val-spacing-m;
						border-radius: 50%;
						background-color: $col-greyBackground;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							height: $val-icon30;
							width: $val-icon30;
							border-radius: 50%;
							box-sizing: border-box;
							border: 2px solid $col-greyBackground;
						}

						font-size: $val-fontSizeRegular;
					}
				}

				& .schedule-board-card-code {
					font-weight: bold;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: center;
				}

				& .schedule-board-card-project-code {
					font-weight: normal;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: center;
					background-color: $col-white;
					border-radius: $val-borderRadius-l;
					font-size: $val-fontSizeXSmall;
					color: $col-black;
				}
			}

			& .schedule-board-card-supervisor,
			& .schedule-board-card-address,
			& .schedule-board-card-canceled {
				font-weight: normal;
				text-align: center;
				background-color: $col-greyBackground;
				border-bottom: 1px solid $col-greyConnection;
				text-transform: uppercase;
				font-size: $val-fontSizeSmall;
			}

			& .schedule-board-card-canceled {
				background-color: $col-canceledRed;
				color: $col-white;
				padding: calc(#{$val-spacing-l} + #{$val-spacing-xxs}) 0;
			}

			& .schedule-board-card-employees,
			& .schedule-board-card-equipment {
				background-color: $col-greyLight;

				& .droppable-title {
					text-transform: uppercase;
					color: $col-greyFontLight;
					font-weight: lighter;
					padding: $val-spacing-xs;
					margin-bottom: 0;

					@media (min-width: $bp-l-high) {
						font-size: $val-fontSizeRegular;
						line-height: $val-fontSizeRegular;
					}

					@media (max-width: $bp-m-high) {
						font-size: $val-fontSizeSmall;
						line-height: $val-fontSizeSmall;
					}
				}
			}

			& .schedule-board-card-address {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			& .schedule-board-card-metrics {
				background-color: $col-greyMid;
				border-bottom: 1px solid $col-greyConnection;
				color: $col-black;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: center;
				white-space: normal;
				font-size: $val-fontSizeSmall;
			}

			& .schedule-board-card-flags {
				background-color: $col-lighterGrey;
				border-bottom: 1px solid $col-greyConnection;
				color: $col-black;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: center;
				white-space: normal;
				font-size: $val-fontSizeSmall;
				margin-bottom: $val-spacing-xxs;

				& .schedule-board-card-flag {
					background-color: $col-white;
					font-size: $val-fontSizeSmall;
					font-weight: bold;
					margin: $val-spacing-xxs;
					padding-left: $val-spacing-xs;
					padding-right: $val-spacing-xs;
					border-radius: $val-borderRadius;
				}
			}

			& .schedule-board-card-shop-work {
				border-bottom: 1px solid $col-greyConnection;

				& .night-shift-icon-container {
					display: flex;
					flex-direction: row;
					justify-content: center;
					margin-bottom: $val-spacing-xs;

					.icon-night_shift {
						font-size: $val-fontSizeXXBig;
					}
				}

				& .shift-item {
					justify-content: space-between;
					font-size: $val-fontSizeSmall;
					line-height: $val-lineHeightSmall;

					&.centered {
						justify-content: center;
					}
				}

				& .schedule-board-card-shift {
					text-transform: uppercase;
				}

				& .schedule-board-card-night-shift {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				& div {
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;
					align-items: center;

					@media (min-width: $bp-l-high) {
						font-size: $val-fontSizeRegular;
					}

					@media (max-width: $bp-m-high) {
						font-size: $val-fontSizeSmall;
					}

					& label {
						text-transform: uppercase;
						color: $col-greyFontLight;
						font-weight: normal;
						font-size: inherit;
						margin: 0;
					}

					& span {
						font-size: inherit;
						white-space: pre-wrap;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			& .schedule-board-card-resources,
			& .schedule-board-card-employees,
			& .schedule-board-card-equipment {

				& .resource-droppable-columns-wrapper,
				& .employee-droppable-columns-wrapper,
				& .equipment-droppable-columns-wrapper {
					display: flex;
					flex-flow: row;

					& .resource-droppable,
					& .employee-droppable,
					& .equipment-droppable {
						padding-right: $val-spacing-xs;

						&:last-child {
							padding: 0;
						}
					}
				}
			}

			& .schedule-board-card-supervisor,
			& .schedule-board-card-address,
			& .schedule-board-card-canceled {
				font-weight: normal;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			& .schedule-board-card-address {
				& .schedule-board-card-address-content {
					overflow: inherit;
					text-overflow: inherit;
					white-space: inherit;
				}
			}
		}
	}

	.schedule-board-toolbar {
		height: 100%;
		min-width: $var-toolbarWidth !important;
		max-width: $var-toolbarWidth !important;
		background-color: $col-greyConnection;

		& .resources {
			display: flex;
			background-color: $col-greyBackground;
			height: 100%;
			max-height: calc(100vh - 2 * #{$val-headerHeight} - #{$val-tipsSectionHeight});
			-webkit-transition: max-height $val-toggleAnimation;
			transition: max-height $val-toggleAnimation;
			will-change: max-height, height;

			&--extended {
				max-height: calc(100vh - 2 * #{$val-headerHeight} - #{$val-collapsedTipsSectionHeight});
			}

			& .schedule-board-toolbar-column-wrapper {
				&.employees {
					box-shadow: -1px 0px 0px 0px $col-greyConnection inset !important;
				}

				&.employees,
				&.equipment {
					width: 50% !important;
				}

				&>div:first-child {
					overflow-x: hidden !important;
				}

				& .schedule-board-toolbar-column {
					display: flex;
					flex-flow: column nowrap;
					align-items: flex-start;
					height: 100%;

					&>div:first-child {
						width: 100%;
					}

					& .resource-placeholder {
						margin: $val-spacing-s;
					}
				}

				& .equipment-toolbar-multicolumn-wrapper,
				& .employee-toolbar-multicolumn-wrapper {
					display: flex;

					& .equipment-toolbar-multicolumn,
					& .employee-toolbar-multicolumn {
						margin: 0 $val-spacing-xs;
					}
				}
			}
		}

		& .tips {
			max-height: $val-tipsSectionHeight;
			height: $val-tipsSectionHeight;
			background-color: $col-white;
			border-top: 1px solid $col-orange;
			border-right: 1px solid $col-greyConnection;
			display: flex;
			position: relative;
			-webkit-transition: max-height $val-toggleAnimation;
			transition: max-height $val-toggleAnimation;
			will-change: max-height, height;

			&__toggle-icon {
				position: absolute;
				font-size: $val-fontSizeXBig;
				top: -$val-spacing-m;
				right: -$val-spacing-m;
				cursor: pointer;
				z-index: 1;
			}

			& .tip {
				padding: $val-spacing-m;
				flex: 1;

				&>div {
					max-width: calc((#{$var-toolbarWidth} - 4 * #{$val-spacing-m}) / 2) !important;
					word-wrap: break-word;
					font-size: $val-fontSizeSmall;
					color: $col-greyDark;
				}

				&__title {
					font-weight: bold;
					color: $col-orange !important;
					padding-bottom: $val-spacing-s;
				}
			}
		}
	}

	.schedule-board-toolbar-employees-row,
	.schedule-board-toolbar-equipment-row {
		padding: $val-spacing-xs;
		width: 100%;

		&>label {
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: $val-spacing-s;
			padding: $val-spacing-s;
			width: $var-toolbarWrapperWidthBase;

			@media (min-width: $bp-l-high) {
				font-size: $val-fontSizeBig;
			}

			@media (max-width: $bp-m-high) {
				font-size: $val-fontSizeRegular;
			}
		}
	}

	.schedule-board-toolbar-employees-wrapper {
		width: 100%;
	}

	.schedule-board-toolbar-equipment-wrapper {
		width: 100%;

		&.unavailable {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-end;
			width: 100%;
		}
	}

	.schedule-board-toolbar-equipment,
	.schedule-board-toolbar-employees {
		display: flex;
		flex-flow: row wrap;

		& .schedule-board-toolbar-equipment-group,
		& .schedule-board-toolbar-employees-group {
			flex-basis: 100%;
			flex-grow: 1;
		}

		&.unavailable {
			flex-flow: column nowrap;
			width: 100%;
		}
	}

	.schedule-board-toolbar-employees-group,
	.schedule-board-toolbar-equipment-group {
		padding: $val-spacing-m $val-spacing-xs;

		&>hr {
			@media (min-width: $bp-l-high) {
				margin: $val-spacing-m 0;
			}

			@media (max-width: $bp-m-high) {
				margin: $val-spacing-s 0;
			}
		}

		& .group-title {
			display: flex;
			align-items: center;
			margin-left: $val-spacing-s;

			& img {
				margin-bottom: $val-spacing-xxs;
			}

			& span {
				color: $col-greyFontLight;
				text-transform: uppercase;
				font-weight: lighter;
				margin-left: $val-spacing-s;

				@media (min-width: $bp-l-high) {
					font-size: $val-fontSizeRegular;
				}

				@media (max-width: $bp-m-high) {
					font-size: $val-fontSizeSmall;
				}
			}

			& .notification-status {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				padding-right: $val-spacing-s;
			}
		}

		.clickable-img {
			cursor: pointer;
		}
	}

	.schedule-board-arrow-index-container {
		display: flex;
		justify-content: center;
		min-height: $var-arrowContainerHeight;
		margin-bottom: $val-spacing-m;

		& .schedule-board-arrow {
			width: 100%;
			position: relative;
			will-change: transform, opacity;
			background: url("/images/elements/ic_search_arrow.svg") center center no-repeat;
			transition: opacity 0.35s ease-in-out;
			background-size: 30px;

			&.--invisible {
				opacity: 0;
			}

			&.--visible {
				animation: jump 1s infinite;
				opacity: 1;
			}

			@media (min-width: $bp-l-high) {
				height: $var-arrowContainerHeight + $val-fontSizeXBig - $val-fontSizeBig;
			}

			@media (max-width: $bp-m-high) {
				height: $var-arrowContainerHeight;
			}
		}

		& .schedule-board-card-index {
			text-align: center;
			font-weight: bold;
			color: $col-greyFontLight;

			@media (min-width: $bp-l-high) {
				font-size: $val-fontSizeXBig;
			}

			@media (max-width: $bp-m-high) {
				font-size: $val-fontSizeBig;
			}
		}
	}

	.schedule-board-toolbar-loading {
		display: flex !important;
		flex-flow: row wrap !important;

		& .toolbar-group {
			height: 50% !important;
			flex-basis: 50% !important;
			padding: $val-spacing-s;
		}
	}
}

.horizontal-scrollbar {
	height: $var-scrollBarAnchorHeight;
	background-color: transparent;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin: 0 $val-spacing-xs;
	z-index: 3;
	position: fixed;
	touch-action: none;

	& .curtain {
		position: absolute;
		width: 100%;
		height: 50%;
		background-color: $col-blackShadow;
	}

	&.top {
		top: 2 * $val-headerHeight;
		padding-top: $val-spacing-xs;
		margin-bottom: $val-spacing-xs;
		margin-top: 0;
		background: linear-gradient(to bottom, $col-white 60%, transparent 0%);

		& .curtain {
			top: 0;
		}
	}

	&.bottom {
		bottom: 0;
		padding-bottom: $val-spacing-xs;
		margin-top: $val-spacing-xs;
		margin-bottom: 0;
		background: linear-gradient(to bottom, transparent 50%, $col-white 0%);

		& .curtain {
			bottom: 0;
		}
	}

	& .scrollbar-container {
		height: $var-scrollBarHeight;
		background-color: $col-greyConnection;
		z-index: 1;

		& .scrollbar-anchor {
			display: flex;
			justify-content: center;
			align-items: center;
			height: $var-scrollBarHeight;
			border-radius: $val-borderRadius;
			background-color: $col-greyMid;
			will-change: transform;
			cursor: grab;
			border: 1px solid $col-greyConnection;
			background-color: $col-grey;
		}
	}
}

.modal-flex-row {
	display: flex;
	flex-direction: row;

	& .space-between {
		justify-content: space-between;
	}

	& .modal-col {
		flex: 1;
	}
}

.schedule-board-container {
	display: flex;
	flex-flow: row nowrap;
	height: 100%;
	width: 100%;
	background-color: $col-white;
}

.modal-header {
	background-color: $col-white;
}

.modal-text-group {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex: 1;
	margin-right: $val-spacing-s;

	&>div {
		margin-bottom: $val-spacing-m;
	}

	&>.modal-subtitle {
		font-weight: bold;
		font-size: $val-fontSizeBig;
		width: 100%;
		margin-bottom: $val-spacing-s;
	}

	&>.modal-text {
		font-weight: bold;
	}

	&>.modal-label-sm {
		font-size: $val-fontSizeSmall;
		width: 100%;
	}
}

.modal-group {
	&>label {
		font-weight: lighter;
		text-transform: uppercase;
		color: $col-greyFontLight;
	}
}

.modal-canceled {
	background-color: $col-canceledRed;
	color: $col-white;
	flex: 0 !important;

	.modal-text-group {
		justify-content: center;
	}

	.modal-subtitle {
		font-size: $val-fontSizeXBig;
		text-transform: uppercase;
		font-weight: bold;
	}
}

.modal-header-left {
	&.loading {
		align-items: center;
		flex: 1;

		& .modal-subtitle {
			margin: 0;
		}
	}
}

.work-order {

	& .modal-header-left,
	& .modal-header-right,
	& .crew-type {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	& .modal-header-left {
		justify-content: flex-start;
	}

	& .modal-header-right {
		justify-content: flex-end;
	}
}

.schedule-board-card-order-info {
	&.internal-card {
		& .schedule-board-card-crew {
			background: $col-black !important;
			border-color: $col-greyDark !important;
			color: $col-white !important;
		}

		& .schedule-board-card-address {
			background: $col-black !important;
			border-color: $col-greyDark !important;
			color: $col-greyFontLight !important;
		}

		& .schedule-board-card-metrics {
			background: $col-black !important;
			border-color: $col-greyDark !important;
			color: $col-greyFontLight !important;
		}
	}
}

.dropdown-menu.divider {
	margin: $val-spacing-s 0;
}

.dropdown-menu-item {
	cursor: pointer;
	display: flex;
	align-items: center;

	&.active>.icon-check {
		font-size: $val-fontSizeBig;
	}

	&.active>span {
		font-weight: bold;
	}
}

.schedule-board {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	@include scheduleBoardMixin(5);

	&.zoom-2 {
		@include scheduleBoardMixin(5);
	}

	&.zoom-1 {
		@include scheduleBoardMixin(10);
	}

	&.zoom-0 {
		@include scheduleBoardMixin(20);
	}
}

// NOTE: Do not add new styles below. Include them in mixin instead