@import "../../../src/styles/Colors.scss";
@import "../../../src/styles/Constants.scss";

$var-border: 1px solid $col-greyConnection;
$var-shadow: 0 2px 2px 0 $col-shadow;
$var-table-header-height: 64px;

.tree {
	width: 100%;
	padding: 0;
	border-radius: $val-borderRadius;
	border: $var-border;
	-webkit-box-shadow: $var-shadow;
	box-shadow: $var-shadow;

	&__loading-indicator {
		background: $col-white;
		align-items: center;
		border-radius: $val-borderRadius;
		display: flex;
		align-items: center;
		height: $var-table-header-height + 1px;
		padding: $val-spacing-m $val-spacing-l;
		border-bottom: $var-border;
		justify-content: center;
	}
}
