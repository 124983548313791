.preview-link {
	display: flex;
	padding: $val-spacing-s $val-spacing-m;
	background: $col-greyBackground;
	width: 100%;

	&:hover {
		background: rgba($col-greyDark, 0.1);
	}

	&__icon {
		min-width: auto;
		width: auto;
		height: auto;
	}

	&__text {
		display: flex;
		align-items: center;;
		color: rgba($col-greyDark, 0.7);
		font-size: $val-fontSizeSmall;
		line-height: $val-fontSizeSmall;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		flex: 1;
		cursor: pointer;
	}
}

.copy-to-clipboard {
	position: fixed;
	left: 9999px;
}
