$indicator-container-offset: 8px;
$dropdown-height: 300px;

.react-select-container {
	&--with-addon {
		display: flex;
		width: 100%;

		& .react-select-field {
			width: 100%;
		}

		& [class$="-control"] {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

.react-select-field {
	[class$="-singleValue"] {
		small {
			display: none;
		}
	}

	[class$="-control"] {
		height: $val-fieldSize;
		min-height: $val-fieldSize;
		border-color: $col-greyMid;

		& > div {
			height: auto;
			padding-top: 0;
			padding-bottom: 0;

			.icon-down {
				margin-right: $val-spacing-s;
				font-size: $val-fontSizeBig;
				cursor: pointer;
			}

			.icon-close {
				color: $col-greyMid;
				cursor: pointer;
				font-size: $val-fontSizeBig;

				&:hover {
					transition: color 1s;
					color: $col-greyDark;
				}
			}
		}
	}

	[class$="-menu"] {
		margin-top: $val-spacing-xxs;
		z-index: 11;
		
		& > div {
			border-radius: $val-borderRadius!important;
			padding: 0;
		}
	}

	[class$="-placeholder"] {
		width: max-content;
	}

	[class$="-IndicatorsContainer"] {
		min-height: $val-fieldSize;
	}

	&--error {
		& [class$="-control"] {
			border-color: $col-red !important;
		}
	}

	&--limited {
		& [class$="-menu"] > div {
			max-height: 150px;
		}
	}

	&--multi-tag {
		& [class$="-control"] {
			height: auto;
		}
	}

	&--fixed {
		[class$="-menu"] {
			position: fixed;
		}
	}
}

.react-select-input {
	&.error {
		& [class$="-control"] {
			border-color: $col-red !important;
		}
	}

	[class$="-singleValue"] {
		& small {
			display: none;
		}
	}
	
	[class$="-control"] {
		height: $val-fieldSize;
		min-height: $val-fieldSize;
		border-color: $col-greyMid;

		& > div {
			height: 100%;
			padding-top: 0;
			padding-bottom: 0;

			.icon-down {
				margin-right: $val-spacing-s;
				font-size: $val-fontSizeBig;
				cursor: pointer;
			}

			.icon-close {
				color: $col-greyMid;
				cursor: pointer;
				font-size: $val-fontSizeBig;

				&:hover {
					transition: color 1s;
					color: $col-greyDark;
				}
			}
		}
	}

	[class$="-menu"] {
		margin-top: $val-spacing-xxs;
		z-index: 11;

		& > div {
			border-radius: $val-borderRadius !important;
			padding: 0;
		}
	}

	[class$="-NoOptionsMessage"], [class$="-LoadingMessage"] {
		text-align: left;
	}

	[class$="-indicatorContainer"] {
		cursor: pointer;
		padding: 0 $indicator-container-offset 0 0;

		svg {
			display: none;
		}

		&:hover:after {
			color: $col-greyDark !important;
		}
		
		&:after {
			display: block;
			font-family: 'icons';
			font-size: $val-fontSizeBig;
		}
		
		&:first-child:after {
			content: '\e90b';
			color: $col-greyFontLight;
		}
		
		&:last-child:after {
			color: $col-greyDark;
			content: '\e914';
		}

	}

	&--fixed {
		[class$="-menu"] {
			position: fixed;
			top: auto;
		}
	}
}

.react-select-dropdown {

	&__create-label {
		color: $col-blue;
		margin-right: $val-spacing-xs;
	}

	&--scroll-container {
		@extend .custom-scrollbar;

		max-height: $dropdown-height;
		overflow-y: auto;
	}

	&--fixed {
		position: fixed !important;
		left: initial;
		right: initial;
		top: initial !important;
		bottom: initial;
		transform: translate(0, $val-spacing-xxs) !important;
		z-index: 100 !important;
	}

	&--full-width {
		width: 100%;
	}
}