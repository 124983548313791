.organization-suggestion {
	cursor: pointer;
	display: block;

	&__url {
		margin-bottom: $val-spacing-l !important;
	}

	&--admin {
		background: $col-blueBackground;
	}

	&:hover {
		background: $col-orangeBackground;
	}
}