.job-preview {

	&__toggle-info-button {
		text-transform: uppercase;
		font-size: $val-fontSizeSmall;
		cursor: pointer;
		color: $col-blue;
	}

	&__boolean-field-value {
		display: flex;
		align-items: center;

		.icon-dot {
			margin-right: $val-spacing-s;
			font-size: $val-fontSizeRegular;
		}
	}

	&__popover {
		max-width: none;
		width: initial;
	
		& .popover-content {
			padding: $val-spacing-s;
		}
	
	}
}