.map-container {
	display: flex;
	flex-flow: column-reverse nowrap;
	height: 100%;
	width: 100%;
	margin-bottom: $val-spacing-m;
	padding: 0 $val-spacing-l;

	& .map-element {
		width: 100%;
		height: 100%;
		min-height: 400px;
	}

	& .map-distance,
	& .map-travel-time {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		font-size: $val-fontSizeRegular;
		font-weight: normal;

		& .glyphicon {
			margin-right: $val-spacing-s;
		}
	}
}

.pac-container {
	z-index: 99999;
}