.connection-counters {
	display: flex;
	flex-flow: row nowrap;

	&__counter {
		align-items: center;
		font-size: $val-fontSizeSmall;
		display: flex;

		
		& + & {
			margin-left: $val-spacing-m;
		}
	}

	[class^="icon-"] {
		margin-right: $val-spacing-s;
		font-size: $val-fontSizeRegular;
	}
}