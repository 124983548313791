.accordion {
	display: flex;
	align-items: center;
	cursor: pointer;
	line-height: $val-lineHeightRegular;

	&__icon {
		margin-right: $val-spacing-xs;
		font-size: $val-fontSizeXBig;
	}

	&__text {
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightRegular;
		font-weight: 400;
	}
}
