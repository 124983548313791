.contact-method {
	margin: 0 calc(#{$val-spacing-l} * -1);
	padding: $val-spacing-l;
	padding-top: 0;

	&--bordered {
		border-bottom: 1px solid $col-greyConnection;
	}

	&--non-padded {
		padding-bottom: 0;
	}

	&__header {
		font-weight: normal;
		text-align: left;
		margin-bottom: $val-spacing-s;
		display: flex;
		align-items: center;
		padding-left: $val-spacing-l;
	}
}