@import "Constants.scss";
@import "Colors.scss";

$var-activeLineShadow: 5px 0px 0px -3px $col-greyConnection inset;
$var-replacementHeaderHeight: 215px;
$var-stickyHeaderTop: 87px;
$var-stickyPaddingBottom: 14px;
$var-borderCompensation: 2px;

.simple-tree-container {
	width: 100%;
	position: relative;
}

@mixin indent-line($indent) {
	position: relative;

	&::before {
		z-index: 2;
		content: ' ';
		display: block;
		top: 0;
		bottom: 0;
		left: 24px * $indent;
		border-left: 1px solid $col-greyConnection;
		height: 100%;
		position: absolute;
	}

	&.has-cost-code-children {
		&::before {
			border-left: 1px solid $col-blueHover;
		}
	}

	& .tree-element-content-info {
		padding-left: 24px * ($indent + 1) !important;
	}
}

.cost-codes-tree {
	padding: $val-spacing-l;
	width: 100%;
	height: calc(100vh - #{$val-headerHeight});
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	will-change: scroll-position;

	.tree-container {
		width: 100%;
		max-width: $val-maxBoxSize;

		& .tree-empty {
			height: 400px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $col-greyFontLight;
			font-weight: bold;
		}

		& .form-box {
			width: 100%;
			padding: 0;
	
			& .replacement-header {
				height: $var-replacementHeaderHeight;
				width: 100%;
			}

			& .sticky-header {
				max-width: calc(#{$val-maxBoxSize} - #{$var-borderCompensation});
				width: calc(100vw - #{$val-sidebarWidthMin} - 2 * #{$val-spacing-l} - #{$var-borderCompensation});
				border: none;
				padding: 0;

				& > .header-titles, .tree-header-container {
					margin: 0;
					padding: $val-spacing-l;
				}

				& .table-tabs, & .tree-header-container, & .header-titles {
					border: none;
				}

				& .tree-header-container {
					border-top: 1px solid $col-greyConnection;
				}

				& .header-titles.row {
					padding-left: 0;
					padding-right: 0;
					border-top: 1px solid $col-greyConnection;
				}
			}
			
			& .tree-element {
				padding: 0;
				margin: 0;
				transition: background-color .1s linear;

				& .tree-element-inner-wrapper {
					width: 100%;
					padding-top: $val-spacing-m;

					& .tree-element-content {
						width: 100%;
						margin-bottom: $val-spacing-m;
						display: flex;
						align-items: center;
						justify-content: space-between;
		
						& .tree-element-content-info {
							display: flex;
							align-items: center;
						}
						
						& > div {
							max-width: 50%;
						}
		
						& .metrics-container {
							display: inline-flex;
							align-items: center;
							justify-content: flex-end;
		
							& .metric {
								display: flex;
								flex-flow: column nowrap;
								align-items: flex-start;
							}
						}
					}
				}

				& mark {
					background: $col-orange;
				}
	
				&.nested {
					display: flex;
					flex-flow: row nowrap;

					&.indent-1 {
						@include indent-line(1);
					}
		
					&.indent-2 {
						@include indent-line(2);
					}

					&.indent-3 {
						@include indent-line(3);
					}
	
					&.indent-4 {
						@include indent-line(4);
					}
				
				}
	
				&.cost-code {
					background-color: $col-blueBackground;
				}

				&.open {
					background: $col-greyLight;
				}
					
				&.highlighted {
					background-color: $col-orangeBackground;
				}
	
				&.focused {
					border: 1px solid $col-orangeBorder;
				}

				&.with-bottom-border {
					border-bottom: 1px solid $col-greyConnection;
				}
			}
	
			& .tree-header {
				background: $col-greyLight;
				border: 0;
				-webkit-box-shadow: none;
				box-shadow: none;
				margin-bottom: 0;

				& > .header-titles, .tree-header-container {
					padding: $val-spacing-l;
				}
			}
		}
	}

	& .tree-options {
		& button {
			border: none;
			background: transparent;
			height: initial;
			line-height: initial;
			min-width: 0;

			& .caret {
				display: none;
			}
		}
	}

	& .locked-input-content, & .locked-input-header {
		font-size: $val-fontSizeSmall;
		padding: 0;
		line-height: $val-lineHeightRegular;
	}

	& .tree-header-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $col-greyConnection;

		& > .left-part {
			flex: 1;
		}

		& > .right-part {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-end;
			margin-left: $val-spacing-m;

			& > * {
				margin: 0 $val-spacing-s;
			}
		}

		& .match-filter {
			position: relative;
			width: 100%;

			& .input-filter {
				min-width: $val-textFilterMinWidth;
			}

			& .match {
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				line-height: $val-spacing-xl;
				right: 0;
				font-size: $val-fontSizeSmall;
				color: $col-greyFontLight;
				padding: 0 $val-spacing-s;
				z-index: 1;

				& span {
					padding: 0 $val-spacing-xs;
				}

				& img {
					margin: 0;
					padding: 0;
					cursor: pointer;
				}
			}
		}

		& > div {
			display: flex;
			align-items: center;
		}

		& .form-group {
			margin: 0;
		}
	}
}

.sticky-header {
	position: fixed;
	top: $val-headerHeight;
	right: $val-spacing-l;
	left: $val-sidebarWidthMin + $val-spacing-l;
	z-index: 3;
	margin: auto;
	margin-bottom: 0;
	max-width: $val-maxBoxSize;
	width: calc(100vw - #{$val-sidebarWidthMin} - 2 * #{$val-spacing-l});
	padding: 0 $val-spacing-l;

	& > div {
		background: $col-greyLight;
		border: 1px solid $col-greyConnection;
		border-radius: $val-borderRadius;
		padding: $val-spacing-m;
	}
}

.header-titles {
	font-size: $val-fontSizeSmall;
}
