@import "./Colors.scss";
@import "./Constants.scss";

$var-attachmentThumbnailWidth-default: 180px;
$var-attachmentDeleteIconSize-default: 26px;
$var-attachmentThumbnailWidth-inModal: 158px;
$var-attachmentDeleteIconSize-inModal: 26px;

$var-editModalMaxImageHeight: 600px;

@mixin attachment-preview($param-attachmentThumbnailWidth: $var-attachmentThumbnailWidth-default, $param-attachmentDeleteIconSize: $var-attachmentDeleteIconSize-default) {
	& .attachment-preview-thumbnail {
		$var-attachmentPreviewImage-size: $param-attachmentThumbnailWidth - $param-attachmentDeleteIconSize;

		height: $param-attachmentThumbnailWidth + $val-lineHeightBig;
		width: $param-attachmentThumbnailWidth;
		margin: 0 $val-spacing-m $val-spacing-s;
		position: relative;

		&:hover {
			& .attachment-delete-icon {
				height: $param-attachmentDeleteIconSize;
				width: $param-attachmentDeleteIconSize;
			}
		}

		& .attachment-preview-text {
			display: inline-block;
			overflow: hidden;
			text-align: center;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			font-size: $val-fontSizeRegular;
			height: $val-lineHeightBig;
		}

		& .attachment-preview-image-container {
			height: $var-attachmentPreviewImage-size;
			width: $var-attachmentPreviewImage-size;
			margin: calc(#{$param-attachmentDeleteIconSize} / 2);
			position: relative;

			&:hover {
				border: 1px $col-orangeBorder solid;
				transition: border 0.1s ease-out;

				&::after {
					position: absolute;
					left: 0;
					top: 0;
					display: block;
					background-color: $col-shadow;
					background-position: center;
					background-repeat: no-repeat;
					content: '';
					height: 100%;
					width: 100%;
					z-index: 1;
					pointer-events: none;
					transition: background-color 0.1s ease-in;
				}
			}

			&.preview:hover::after {
				background-size: 40%;
				background-image: url(/images/elements/ic_magnifier_simple_white.svg);
			}

			&.downloadable:hover::after {
				background-size: 30%;
				background-image: url(/images/elements/ic_download_white.svg);
			}

			& .attachment-preview-image {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: $val-borderRadius;
			}

			& .file-preview {
				height: 100%;
				width: 100%;
				background-image: url(/images/fileIcons/ic_file.svg);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 40%;

				&.csv {
					color: $col-green;
					background-image: url(/images/fileIcons/ic_csv.svg);
				}

				&.xls, &.xlsx {
					background-image: url(/images/fileIcons/ic_excel.svg);
				}

				&.image, &.svg {
					background-image: url(/images/fileIcons/ic_image.svg);
				}

				&.pdf {
					background-image: url(/images/fileIcons/ic_pdf.svg);
				}

				&.doc, &.docx {
					background-image: url(/images/fileIcons/ic_word.svg);
				}

				&.zip {
					background-image: url(/images/fileIcons/ic_zip.svg);
				}
			}
		}

		& .attachment-delete-icon {
			position: absolute;
			float: right;
			z-index: 2;
			top: 0;
			right: 0;
			height: 0;
			width: 0;
			background-image: url(/images/elements/ic_cancel_filled.svg);
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}

.attachment-form-padded-div {
	padding: $val-spacing-l;
}

.attachment-preview-container {
	padding: 0 $val-spacing-l $val-spacing-l $val-spacing-l;
	display: flex;
	flex-flow: row wrap;

	@include attachment-preview;
}

.modal-group.attachments {
	& .attachment-form-padded-div {
		padding: $val-spacing-s;
	}

	& .form-box-table {
		margin-left: (-$val-spacing-l) !important;
		margin-right: (-$val-spacing-l) !important;

		& table {
			margin-bottom: $val-spacing-s !important;
		}

		& th {
			height: $val-spacing-xxxl !important;
		}

		& td {
			font-size: $val-fontSizeSmall !important;
		}
	}

	& .attachment-preview-container {
		padding: 0 0 $val-spacing-s 0;

		@include attachment-preview($var-attachmentThumbnailWidth-inModal, $var-attachmentDeleteIconSize-inModal);
	}
}

#image-edit-modal {
	.modal-content {
		width: fit-content;
	}

	& .modal-body {
		padding: 0;
	}

	& .modal-edit-container {
		padding: $val-spacing-l;

		& .crop-container {
			min-height: $var-editModalMaxImageHeight;
			max-height: $var-editModalMaxImageHeight;

			& img {
				max-width: 100%;
			}
		}

		&.--loading {
			& .crop-container {
				position: relative;
				overflow: hidden;
			}

			& .crop-container::before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: 'Loading...';
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $val-fontSizeSmall;
				color: $col-white;
				background-color: rgba($color: $col-black, $alpha: 0.9);
			}
		}
	}

	& .modal-footer {
		justify-content: space-between;

		& .image-edit-actions {
			display: flex;
			justify-content: flex-start;
			flex-flow: row wrap;
			padding-top: $val-spacing-xs;
			width: 30%;

			& button {
				min-width: $val-icon20;
				margin-left: $val-spacing-xxs;
				margin-right: $val-spacing-s;
				padding: 0;
			}
		}

		& .image-title-with-options {
			text-align: center;
			width: 40%;

			& .image-title {
				width: 100%;

				& h4 {
					margin-top: #{$val-spacing-s - $val-spacing-xxxs};
					margin-bottom: $val-spacing-s;
				}
			}

			& .image-title-options {
				width: 100%;

				& a {
					cursor: pointer;
					color: $col-blue;

					&:hover {
						color: $col-blueHover;
					}
				}
			}
		}

		& .modal-actions {
			display: flex;
			justify-content: flex-end;
			padding-top: $val-spacing-xs;
			width: 30%;
		}
	}
}
