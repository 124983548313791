@import "./Colors.scss";
@import "./Constants.scss";

.error-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	align-content: center;
	width: 556px;
	position: absolute;
	top: 30%;

	& .error-text {
		flex: 2;
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		align-content: center;
		align-self: center;

		h1 {
			font-size: 32px;
			font-weight: normal;
			margin-bottom: $val-spacing-s;
		}

		p {
			font-size: $val-fontSizeBig;
		}
	}

	& .error-image-status {
		flex: 1;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;

		& .error-image {
			font-size: 72px;
		}

		& .error-status {
			font-size: 36px;
		}
	}
}
