$dropdown-height: 300px;
$loading-icon-size: 24px;
$dropdown-toggle-clearing: 20px;
$dropdown-sticky-section-top: 45px;

.dropdown-field {
	border: 1px solid $col-greyMid;
	border-radius: $val-borderRadius;
	width: 100%;
	height: $val-fieldSize;

	&:focus-within {
		border-color: $col-orange;
	}

	&--multiselect {
		.dropdown-menu {
			li,
			a {
				&:focus,
				&:active {
					outline: none;
				}

				width: 100%;
			}

			a {
				padding: $val-spacing-s;
			}

			.checkbox-inline {
				padding-top: 0;
			}
		}
	}

	&--error {
		background-color: $col-redBackground !important;
		border-color: $col-red !important;
	}

	&.disabled {
		background-color: $col-disabled !important;

		& button {
			background-color: $col-disabled !important;
		}
	}
}

.dropdown-toggle {
	@extend .btn;

	border-radius: 0;
	width: 100%;
	padding: 0 $val-inputPadding-lr; // Only adjust left and right paddings, flex will take care of top and bottom
	height: $val-fieldSize - 2px; // Taking border into account
	font-weight: normal !important;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&__container {
		width: 100%;
		width: calc(100% - #{$val-spacing-l}); // Give enough buffer for toggle icon
		height: inherit;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;

		& > span {
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-align: left;
		}
	}

	&--with-caret {
		padding-right: $val-spacing-l;
		
		&:after {
			font-family: 'icons';
			content: "\e914";
			position: absolute;
			right: $val-spacing-s;
			font-size: $val-fontSizeBig;
			color: $col-greyDark;
		}
	}

	&--fullwidth-button {
		& > div {
			width: 100%;
		}
	}

	&--action {
		color: $col-greyDark;
		min-width: unset;
		padding: 0;

		.icon-actions {
			float: left;
			font-size: $val-icon24;
			color: $col-greyFontLight;
			margin-right: 0;

			&:hover {
				color: $col-greyDark;
			}
		}
	}

	&__clear {
		position: absolute;
		right: $dropdown-toggle-clearing;
		color: $col-greyMid;
		margin: auto;
		cursor: pointer;

		&:hover {
			color: $col-black;
		}
	}

	&__placeholder {
		color: $col-greyFontLight;
		display: flex;
	}


	&--lazy-loading {
		position: relative;

		& > select {
			background: none !important;
		}
	}

	&--loading {
		&:after {
			@extend .twinkle-animation;
		}
	}

	&--bordered {
		border: 1px solid $col-greyMid !important;
		border-radius: $val-borderRadius;
	}

	&--cascade {
		&, &:active, &:hover, &:focus, &:active:focus {
			border: none;
			min-height: $val-fieldSize;
			color: $col-greyDark;
			padding: $val-spacing-s;
			padding-left:  $val-spacing-m;
		}

		.icon-right {
			margin: 0;
		}
	}
}

.dropdown-menu {
	padding: 0;
	border-radius: $val-borderRadius;
	border: 1px solid $col-greyConnection;
	z-index: 1002;

	& > :last-child {
		margin-bottom: $val-spacing-s;
	}

	& > :first-child {
		margin-top: $val-spacing-s;
	}

	& a {
		display: flex !important;
		align-items: center;
	}

	& button {
		justify-content: space-between;
	}

	& a, & button {
		padding: $val-spacing-s $val-spacing-m !important;

		&:hover {
			background-color: $col-orangeHover !important;
		}
		@media (max-width: $bp-xs-high) {
			height: $val-linkHeightSmall;
		}
		@media (min-width: $bp-s-low) and (max-width: $bp-m-high) {
			height: $val-linkHeightMedium;
		}
		@media (min-width: $bp-l-low) {
			height: $val-linkHeightBig;
		}
	}

	&__selected {
		width: 100%;
		display: flex;
		color: $col-greyDark;
	}

	&__divider, & .dropdown-divider {
		border-top: 1px solid $col-greyConnection;
		margin: $val-spacing-s 0;
	}

	&__unlimited-item {
		height: auto;
		& > a {
			height: auto !important;
		}
	}

	&__blank-item {
		min-height: $val-fieldSize;

		&__label {
			color: $col-greyFontLight;
		}
	}

	&__loading-item {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: $val-fieldSize;
	}

	&__search-input-container {
		margin: 0 !important;
		padding: $val-spacing-m $val-spacing-m;
		background-color: $col-greyBackground;
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: sticky;
		top: 0;
		z-index: 5;
	}

	&__search-input {
		@extend .form-control;
		width: 100%;
	}

	&__icon {
		right: $val-spacing-l; // Both outside border and input offset taken into account
		cursor: pointer;
		position: absolute;
		font-size: $val-fontSizeBig;
		color: $col-greyFontLight;
		display: flex;
		align-items: center;

		&:hover, &:active, &:focus {
			color: $col-greyDark;
		}
	}

	&__multiselect-item {
		& a label > span {
			font-size: $val-fontSizeRegular;
			display: flex;
			align-items: center;
			margin-left: $val-spacing-s;
		}

		& .checkbox-inline {
			margin-top: 0;
		}
	}

	&--scroll-container {
		@extend .custom-scrollbar;

		max-height: $dropdown-height;
		overflow-y: auto;

		& .presentation .menuitem:focused {
			background-color: $col-orange;
		}
	}

	&--fixed {
		position: fixed !important;
	}

	&--full-width {
		width: 100%;
	}

	&--cascade-right {
		left: 100% !important;
		transform: translate(0, -#{$val-spacing-s}) !important; // compensate for margin on first item
	}

	&--cascade-left {
		right: 100% !important;
		left: auto !important;
		transform: translate(0, -#{$val-spacing-s}) !important; // compensate for margin on first item
	}

	&--fit-content {
		width: max-content;
	}
}


.dropdown-item {
	height: 100% !important;

	&.disabled {
		color: $col-greyFontLight;
		cursor: not-allowed;
	}

	&__sticky {
		position: sticky;
		background-color: $col-white;
		top: 0;
		z-index: 1;

		&--filterable {
			top: $dropdown-sticky-section-top;
		}
	}
}

.dropdown-cascade {
	&:hover {
		background-color: $col-orangeHover !important;
	}
}

// Special overrides for colors. Needs specificity to trump the important markings from bootstrap
.dropdown, .dropup {
	button.dropdown-toggle {
		&:not([disabled]) {
			background-color: transparent !important;
		}
	
		&, &:hover, &:focus, &:active, &:active:hover, &:active:focus {
			background-color: transparent !important;
			border: none;
		}
	
		&--white:not([disabled]) {
			&, &:hover, &:focus, &:active, &:active:hover, &:active:focus {
				background-color: $col-white !important;
			}
		}

		&:disabled {
			background-color: $col-greyBackground !important;
		}
	}
}