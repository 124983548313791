// NOTE: All classes in this file are strictly dictated by react-bootstrap package

.input-group-append, .input-group-prepend {
	color: $col-greyMid;
	background-color: $col-greyBackground;
	border: 1px solid $col-greyMid;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: $val-fieldSize;
	width: initial;
	font-size: $val-fontSizeSmall;
	font-weight: bold;

	[class^="icon-"] {
		font-size: $val-fontSizeBig;
		font-weight: normal;
	}


	&.active {
		background: $col-blue;
		border-color: $col-blue;
	}

	&--clickable {
		cursor: pointer;
	}

	&--unstyled {
		border: none;
		padding: 0;
	}

	&--icon {
		width: $val-fieldSize;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--open-left {
		border-left: none !important;
	}
}

.input-group-append {
	border-top-right-radius: $val-borderRadius !important;
	border-bottom-right-radius: $val-borderRadius !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-left: none !important;
}

.input-group-prepend {
	border-top-left-radius: $val-borderRadius !important;
	border-bottom-left-radius: $val-borderRadius !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-right: none !important;
}