$time-picker-width: 98px;
$var-content-padding-bottom: 65px;
$created-at-width: 72px;
$duration-width: 48px;
$var-connector-line-width: 2px;
$var-connector-line-offset: 5px;
$var-strike-line-width: 1px;
$opaque-opacity: 0.3;

.time-sheet-edit-modal {
	&__title-opaque {
		opacity: $opaque-opacity;
	}

	&__footer-opaque {
		opacity: $opaque-opacity;
	}

	.modal-content {
		// this is equal to the .modal-content's max-height so it prevents height changes while changing tabs
		min-height: 65vh;
		overflow-y: hidden;
		padding-bottom: $var-content-padding-bottom;
	}

	.modal-footer {
		position: absolute;
		left: 0;
		right: 0;
	}

	&__body {
		flex: 1;
	}

	&__loading {
		text-align: center;
		margin-top: $val-spacing-l;
	}

	&__information {
		.row {
			padding-left: 0;
			padding-right: 0;
		}

		&__last-signature-date {
			margin-left: $val-spacing-s;
		}
	}

	&__timeline {
		// min-height set to allow dropdowns to render correctly. Container is 75vh, but that includes header and footer
		min-height: 30vh;
		display: flex;
		flex-direction: column;

		&__entries {
			margin-top: $val-spacing-m;
		}

		&__manual-title {
			font-size: $val-fontSizeBig;
			line-height: $val-lineHeightBig;
			font-weight: 700;
			margin-top: $val-spacing-l;
		}

		&__total-times {
			background-color: $col-white;
			color: $col-darkBlueBorder;
			font-size: $val-fontSizeRegular;
			font-weight: bold;
			padding: $val-spacing-s $val-spacing-m;
			margin: 0px (-$val-spacing-l) 0px (-$val-spacing-l);

			&__row {
				display: flex;
				justify-content: space-between;

				&__work-type {
					display: flex;
					align-items: center;
					margin-top: $val-spacing-s;
					padding-right: $val-spacing-s;
					font-weight: 400;

					> span {
						font-size: $val-fontSizeXBig;
						margin-right: $val-spacing-s;
					}
				}
			}

			&--opaque {
				opacity: $opaque-opacity;
			}
		}

		&__total-times-overlap {
			background-color: $col-canceledRed;
			color: $col-white;
			font-size: $val-fontSizeRegular;
			font-weight: bold;
			padding: $val-spacing-m $val-spacing-m;
			margin: 0px (-$val-spacing-l) 0px (-$val-spacing-l);

			&--opaque {
				opacity: $opaque-opacity;
			}
		}

		&__rejection-banner {
			background-color: $col-canceledRed;
			color: $col-white;
			margin: $val-spacing-l (-$val-spacing-l) 0px (-$val-spacing-l);
			padding: $val-spacing-s $val-spacing-m;
			font-size: $val-fontSizeSmall;

			&__user {
				font-weight: bold;
			}

			&__reason-title {
				font-weight: bold;
			}

			&__info {
				margin-left: $val-spacing-xs;
			}

			&--opaque {
				opacity: $opaque-opacity;
			}
		}

		&__new-entry {
			background-color: $col-orangeBackground;
			padding: $val-spacing-s;
			border-radius: $val-spacing-s;
			margin-top: $val-spacing-m;

			&__icon {
				font-size: $val-fontSizeXBig;
				color: $col-greyMid;
			}

			&__input-field {
				width: auto;
				background-color: $col-white;
			}

			&__input-wrapper {
				margin-bottom: 0;
			}

			&__minus-sign {
				font-size: $val-fontSizeXBig;
				display: flex;
				align-items: center;
			}

			&__close {
				cursor: pointer;
			}

			&__total {
				font-weight: bold;
			}
		}

		&__edit-entry {
			&__delete-container {
				display: flex;
				justify-content: left;
				align-items: center;
			}

			&__delete {
				cursor: pointer;
				color: $col-blue;

				&__text {
					margin-left: $val-spacing-m;
				}
			}
		}

		&--empty {
			display: flex;
			padding: $val-spacing-l;
			font-size: $val-fontSizeSmall;
			color: $col-greyMid;
		}
	}

	&__time-sheet-gap {
		background: $col-orange;
		color: $col-white;
		font-weight: bold;
		margin-top: $val-spacing-m;
		display: flex;
		align-items: center;
		padding: $val-spacing-s $val-spacing-l;
		font-size: $val-fontSizeSmall;
		line-height: $val-fontSizeSmall;
		border-radius: $val-borderRadius;

		.icon-info {
			margin-right: $val-spacing-s;
			font-size: $val-fontSizeRegular;
		}

		&--opaque {
			opacity: $opaque-opacity;
		}

		&--editable {
			cursor: pointer;
		}
	}

	&__time-sheet-occupied {
		background: $col-greyFontLight;
		color: $col-white;
		font-weight: bold;
		margin-top: $val-spacing-m;
		display: flex;
		padding: $val-spacing-s $val-spacing-l;
		font-size: $val-fontSizeSmall;
		line-height: $val-lineHeightSmall;
		border-radius: $val-borderRadius;

		&--opaque {
			opacity: $opaque-opacity;
		}
	}

	&__time-sheet-entry {
		margin-top: $val-spacing-s;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: $val-spacing-s $val-spacing-m;
		position: relative;

		&__connection {
			display: none;
		}

		& + & {
			.time-sheet-edit-modal__time-sheet-entry__connection {
				display: block;
				position: absolute;
				bottom: 40px;
				left: $val-spacing-xl - $var-connector-line-offset;
				width: $var-connector-line-width;
				height: $val-spacing-l;
				background-color: $col-greyConnection;

				&--overlap {
					background: $col-red;
				}
			}
		}

		&--opaque {
			opacity: $opaque-opacity;
		}

		&--newly-added {
			animation: newly-added 2000ms normal;
		}

		@keyframes newly-added {
			0% {
				background: $col-blueBackground;
			}

			100% {
				background: lighten($col-blueBackground, 100%);
			}
		}

		&__work-type-icon {
			font-size: $val-fontSizeXBig;
			color: $col-darkBlueBorder;
			margin-right: $val-spacing-l;

			&--inactive {
				color: $col-greyMid;
			}
		}

		&__start-date {
			display: flex;
			justify-content: center;
			white-space: nowrap;
		}

		&__work-type-label {
			font-size: $val-fontSizeRegular;
			color: $col-greyDark;
			font-weight: bold;
			margin-left: $val-spacing-s;

			&--inactive {
				color: $col-greyMid;
			}
		}

		&__time {
			font-weight: bold;

			&--inactive {
				color: $col-greyMid;
			}

			&--unsigned-manual {
				color: $col-greyDark;
			}

			&--overlap {
				color: $col-red;
			}
		}

		&__time-separator {
			margin: 0 $val-spacing-m;

			&--inactive {
				color: $col-greyMid;
			}
		}

		&__duration {
			font-size: $val-fontSizeRegular;
			margin-left: $val-spacing-xl;

			&--inactive {
				color: $col-greyMid;
			}
		}

		&__activity-toggle-icon {
			cursor: pointer;
			font-size: $val-fontSizeRegular;
			margin-left: $val-spacing-m;
		}

		.icon-edit {
			color: $col-black;
			cursor: pointer;
			position: relative;
		}

		.icon-delete {
			margin-left: $val-spacing-s;
			color: $col-black;
			cursor: pointer;
			position: relative;
		}

		&__fields {
			display: flex;
			flex-direction: row;
			align-items: center;

			.form-group {
				margin: 0;
			}
		}

		&__work-type-icon {
			color: $col-darkBlueBorder;
			font-size: $val-fontSizeBig;
			line-height: $val-fieldSize;
			margin-right: $val-spacing-m;

			&--unsigned-manual {
				color: $col-darkBlueBorder;
			}

			&--overlap {
				color: $col-red;
			}
		}

		&__delete-icon {
			font-size: $val-fontSizeBig;
			line-height: $val-fieldSize;
			margin-left: $val-spacing-m;
			cursor: pointer;
		}

		&__created-at {
			flex: 1;
			margin-left: $val-spacing-s;
			min-width: $created-at-width;
			justify-content: center;
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			font-size: $val-fontSizeSmall;
		}

		&__time-picker {
			width: $time-picker-width;
			min-width: $time-picker-width;
		}

		&__time,
		&__time-separator {
			font-size: $val-fontSizeRegular;
			line-height: $val-fieldSize;
			display: flex;
			justify-content: center;
		}

		&__time-separator {
			margin: 0 $val-spacing-s;
		}

		&__duration {
			font-size: $val-fontSizeRegular;
			line-height: $val-fieldSize;
			margin-left: $val-spacing-m;
			white-space: nowrap;
			width: $duration-width;
			min-width: $duration-width;
		}

		.help-block {
			justify-content: flex-end;
		}
	}

	&__time-splits {
		&__total-time {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: $val-spacing-m $val-spacing-l;
			background-color: $col-greyLight;
			font-weight: bold;
			font-size: $val-fontSizeRegular;
			margin-bottom: $val-spacing-l;
		}
	}

	&__add-button {
		display: flex;
		color: $col-darkBlueBorder;
		cursor: pointer;
		font-size: $val-fontSizeRegular;
		margin-bottom: $val-spacing-l;

		&__add-icon {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
			position: relative;
			top: 2px;
		}

		&--spacing-top {
			margin-top: $val-spacing-l;
		}
	}

	&__access_label {
		display: flex;
		align-items: center;

		.icon-check,
		.icon-close {
			margin-right: $val-spacing-s;
		}
	}
}
