@import "./Constants.scss";
@import "./Colors.scss";

$var-ccTreeMinHeight: 124px;
$var-inputPadding: 33px;
$var-backgroundPositionX: 10px;
$var-labelPaddingTop: 6px;
$var-maxIndent: 4;
$var-indentSize: 16px;
$var-stickyHeaderTop: 87px;
$var-stickyPaddingBottom: 14px;
$var-replacementHeaderHeight: 198px;

#cost-code-create-form {
	width: 100%;
	max-width: $val-maxBoxSize;
	height: calc(100vh - #{$val-headerHeight});

	& .cost-code-form {
		position: relative;
		
		& .replacement-header {
			height: $var-replacementHeaderHeight;
			width: 100%;
		}
		& .header {
			background: $col-blueBackground;

			& > div {
				background: $col-blueBackground;
			}

			&.sticky-header {
				position: fixed;
				top: $var-stickyHeaderTop;
				right: $val-spacing-l;
				left: $val-sidebarWidthMin + $val-spacing-l;
				max-width: calc(#{$val-maxBoxSize} - 2 * #{$val-spacing-l});
				margin: auto;
				border: 1px solid $col-greyConnection;
				padding-bottom: $var-stickyPaddingBottom;
				width: calc(100vw - #{$val-sidebarWidthMin} - 2 * #{$val-spacing-l});

				& > div {
					border: none;
					padding: 0;
				}

				& .cost-code-info {
					padding-top: $val-spacing-s!important;
				}

				& .cost-code-name, & .cost-code-info {
					padding: 0;
				}
			}
		}
	}

	& .cost-code-category {
		background: $col-blueBackground;
		margin-top: -$val-spacing-l;
		padding-top: $val-spacing-l;
		padding-bottom: $val-spacing-m;
		padding-left: $val-spacing-m;
		padding-right: $val-spacing-m;

		& input.rbt-input-main {
			padding-left: $var-inputPadding;
			background-size: $val-icon16!important;
			background-color: $col-white!important;
			background-repeat: no-repeat!important;
			background-position-x: $var-backgroundPositionX!important;
			background-position-y: center!important;
			background-image: url('/images/elements/ic_folder_full_open.svg')!important;
		}
	}

	& .cost-code-name {
		background: $col-blueBackground;
	}

	& .cost-code-info {
		background: $col-blueBackground;
		padding-top: $val-spacing-s;
		padding-bottom: $val-spacing-m;

		& .calculated-value {
			padding-top: $var-labelPaddingTop;
			font-weight: bold;
		}
	}

	& .cost-code-tree {
		background: $col-blueBackground;
		min-height: $var-ccTreeMinHeight;
		font-size: $val-fontSizeSmall;
		line-height: $val-lineHeightRegular;
		margin-top: -$val-spacing-l;
		padding-top: $val-spacing-l;
		margin-right: -$val-spacing-l;
		padding-right: $val-spacing-l;

		@mixin tree-row-x {
			$indentList: null;
			@for $i from 1 through $var-maxIndent {
				.tree-row-#{$i} {
					margin-left: $var-indentSize * $i;
				}
				$indentList: append($indentList, unquote(".tree-row-#{$i}"), comma);
			}
		}

		@include tree-row-x;

		& .category-row {
			padding-left: $val-spacing-l;
			background-color: $col-blueBackground!important;
			background-size: $val-icon14!important;
			background-repeat: no-repeat!important;
			background-position-x: $val-spacing-xs!important;
			background-position-y: center!important;
			background-image: url('/images/elements/ic_folder_full_open.svg')!important;
		}

		& .cost-code-row {
			padding-left: $val-spacing-l;
			font-weight: bold;
			background-color: $col-blueBackground!important;
			background-size: $val-icon12!important;
			background-repeat: no-repeat!important;
			background-position-x: $val-spacing-xs!important;
			background-position-y: center!important;
			background-image: url('/images/elements/ic_cost_code.svg')!important;
		}
	}

	& .use-column {
		text-align: center;
	}

	& .attribute-keys {

		& .column-title-label {
			padding-left: $val-spacing-m;
			padding-top: $var-labelPaddingTop;
		}
	}

	& .cost-code-variations {
		& .calculated-value {
			padding-left: $val-spacing-m;
			padding-top: $var-labelPaddingTop;
		}
	}

	& .cost-code-requirements-container {
		& .cost-code-requirement-section {
			background: $col-white;
			margin-left: -$val-spacing-l;
			margin-right: -$val-spacing-l;
			padding: $val-spacing-l;

			& .cost-code-requirement-section-header {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				padding-bottom: $val-spacing-m;

				& * {
					margin: 0;
				}

				& .requirement-section-title {
					display: flex;
					flex-direction: row;

					.cost-code-additional-counter {
						margin-left: $val-spacing-m;
						font-weight: bold;
						line-height: initial;
						font-size: $val-fontSizeRegular;

					}

					& .requirement-count {
						@extend .cost-code-additional-counter;
						color: $col-orange;
					}
					
					& .crew-count {
						@extend .cost-code-additional-counter;
						color: $col-grey;
					}

				}
			}

			& .cost-code-requirement-section-content .cost-code-requirement-row .remove-row {
				display: inline-block;
				margin-top: $val-spacing-m;
			}
		}

		& .cost-code-requirement-section:nth-child(2n) {
			background-color: $col-greyLight;
		}
	}

	& .cost-code-calculations-total {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		& div {
			margin-right: $val-spacing-s;
		}
	}

	& .cost-code-requirement-row .first {
		padding-left: $val-spacing-l;
	}
}
