.schedule-board-labor-modal {

	&__tabs {
		display: flex;
		justify-content: center;
		margin-top: $val-spacing-xxxl;
		margin-bottom: $val-spacing-l;
	}

	&__tab {
		font-size: $val-fontSizeRegular;
		background-color: $col-greyBackground;
		padding: $val-spacing-s $val-spacing-xl;
		font-weight: bold;
		cursor: pointer;
		border-radius: $val-spacing-xs 0px 0px $val-spacing-xs;

		&--active {
			color: $col-white;
			background-color: $col-darkBlueBorder;
		}
	}
}