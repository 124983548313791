@import "../../../../../../styles/Constants";
@import "../../../../../../styles/Colors.scss";

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-submit-action-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;

.invoice-form {

	&__status-cell {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&--draft {
			[class^="icon-draft"] {
				margin-right: $val-spacing-s;
				color: $col-design-gray6;
			}
		}
	}

	&__contact-dropdown-item {
		display: flex;
		flex-direction: column;

		&__main {
			font-size: $val-fontSizeRegular;
		}

		&__secondary {
			font-size: $val-fontSizeSmall;
			color: $col-greyFontLight;
		}
	}

	&__submit-section {
		display: flex;
		align-items: center;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 1px solid #C5C8CB;
		top: $var-submit-action-top-offset;
		position: sticky;

		&__invoice-code-and-reminder {
			font-size: $val-fontSizeBig;
			font-weight: bold;
			flex: 1;
			display: flex;

			&__code {
				padding-right: $val-spacing-l;
				align-content: center;
			}
		}
	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
		z-index: 3;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;

		&__form {
			background-color: #FAFAFA;			

			&__section {
				background-color: $col-white;
				box-shadow: $var-shadow;
				padding: $val-spacing-m $val-spacing-l;
				margin-top: $val-spacing-m;
				margin-bottom: $val-spacing-m;
				margin-left: $val-spacing-s;
				margin-right: $val-spacing-s;
	
				&__title {
					text-transform: uppercase;
					color: $col-design-gray5;
					font-size: $val-fontSizeSmall;
					margin-bottom: $val-spacing-m;
				}
	
				&__installment-notice {
					font-style: italic;
					color: $col-design-gray5;
					margin-bottom: $val-spacing-m;
				}
	
				&__row {
					display: grid;
					gap: 64px;
					grid-template-columns: repeat(24, 1fr);
	
					&__column-date {
						grid-column: span 6;
						margin-right: 128px;
					}
	
					&__column-note {
						grid-column: span 21;
					}
	
					&__column-payment-info {
						grid-column: span 4;
						margin-right: 64px;
					}
	
					&__column-id {
						grid-column: span 6;
						margin-right: 64px;
					}
	
					&__column-1 {
						grid-column: span 1;
					}
	
					&__column-2 {
						grid-column: span 2;
					}
	
					&__column-4 {
						grid-column: span 4;
					}
	
					&__column-6 {
						grid-column: span 6;
					}
	
					&__column-8 {
						grid-column: span 8;
					}
	
					&__centered-column {
						display: flex;
						align-items: center;
					}
				}
			}
		}

	}

	&__delete-button {
		margin-top: $val-spacing-m;

		& > [class^="icon-delete"] {
			color: $col-grey;
			font-size: $val-fontSizeBig;
			cursor: pointer;
			margin-top: calc($val-spacing-l + $val-spacing-s);

			&:hover {
				color: $col-black;
			}
		}
	}

	&__radio-group {
		padding-bottom: $val-spacing-m;
	}

	&__tab {
		display: flex;
		gap: $val-spacing-xs;
		align-items: center;

		& > [class^="icon-warning"] {
			color: $col-canceledRed;
			font-size: $val-fontSizeBig;

		}
	}

	&__add-field-button {
		width: fit-content;
		color: $col-blue;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-top: $val-spacing-m;

		& [class^="icon-plus"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
		&:hover {
			color: $col-darkBlueBorder;
		}
	}

}
