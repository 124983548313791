.night-shift-work-orders-modal {
	& .night-shift-board {
		@extend .display-view-board-extension;
	}

	&--date {
		display: block;
		flex: 1;
		text-align: center;
		text-transform: uppercase;
		width: unset;
	}
}
