@import "./Colors.scss";
@import "./Constants.scss";

$var-plusIconSize: 10px;
$var-toggleInputSize: 20px;
$var-floatingButtonSize: 48px;
$var-floatingButtonIconSize: 18px;
$var-floatingButtonPadding: 0 14px;
$var-floatingButtonExpandedSize: 222px;
$var-buttonIconBottom: 1px;
$var-errorIconSize: 13px;
$var-descriptionlessMargin: 26px;

$var-editIconWidth: 15px;
$var-editIconBottom: 2px;
$var-editIconLeft: 0px;

$var-buttonSize: 32px;
$var-buttonSizeSm: 30px;
$var-buttonSizeMd: 34px;

$var-zIndex: 1;

$val-colorSquareSize: 20px;

$var-animationLength: 300ms;

$var-colorPickerWidth: 250px;

$var-table-header-filter-min-width: 100px;

.background-color-animation {
	-webkit-transition: background-color $var-animationLength linear;
	-moz-transition: background-color $var-animationLength linear;
	-o-transition: background-color $var-animationLength linear;
	-ms-transition: background-color $var-animationLength linear;
	transition: background-color $var-animationLength linear;
}

.color-animation {
	-webkit-transition: color $var-animationLength linear;
	-moz-transition: color $var-animationLength linear;
	-o-transition: color $var-animationLength linear;
	-ms-transition: color $var-animationLength linear;
	transition: color $var-animationLength linear;
}

select.month-dropdown {
	& option {
		text-transform: capitalize !important;
	}
}

.loading-icon {
	display: flex;
	justify-content: center;
	align-items: center;

	& > div {
		border: 3px solid $col-orange;
		border-top: 3px solid $col-transparent;
		border-radius: 50%;
		width: 12px;
		height: 12px;
		animation: spin 1s linear infinite;
	}
}

input, textarea, select {
	padding: $val-spacing-s;
	outline: none;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;

	&:focus, &:active {
		border-color: $col-orangeBorder !important;
		outline: none !important;
	}

	&.dollar-value {
		background: $col-white url("/images/inputs/ic_money_inactive.svg") no-repeat left $val-spacing-s center;
		padding-left: $val-spacing-l;
	}

	&.percent-value {
		background: $col-white url("/images/inputs/ic_percent_inactive.svg") no-repeat left $val-spacing-s center;
		padding-left: $val-spacing-l;
	}

	&.centered {
		text-align: center;
	}

	&.with-error {
		background-color: $col-redBackground !important;
		border-color: $col-red !important;
	}
}

.with-error input {
	background-color: $col-redBackground !important;
	border-color: $col-red !important;
}

.input-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dropdown-menu.bootstrap-typeahead-menu.dropdown-menu-justify {
	border-radius: $val-borderRadius;
	padding: 0;
	margin: 0;

	& li {
		& a {
			height: 100%;
			width: 100%;
			padding: $val-spacing-l $val-spacing-m;

			&:hover {
				background-color: $col-orangeHover;
			}

			& .option {
				line-height: $val-lineHeightRegular;
			}
		}
	}
}

.with-new ul li:last-child[aria-label^="Add"],
.with-new ul li:last-child[aria-label^="New"] {
	background: $col-greyLight url("/images/inputs/ic_plus_blue.svg") no-repeat $val-spacing-m center;
	background-size: $var-plusIconSize;

	& a, & a:hover, & a:focus, & a:active {
		padding-left: calc(#{$val-spacing-m} * 2 + #{$var-plusIconSize}) !important;

		@extend .border-top;

		color: $col-blue;
	}
}

.input-description {
	font-size: $val-fontSizeSmall;
	line-height: $val-lineHeightSmall;
	color: $col-greyFontLight;
	margin-right: $val-spacing-s;
}

select {
	@extend .noappearance;

	padding-right: $val-spacing-l !important;

	&[disabled] {
		background-color: $col-disabled !important;
	}
}

.multiselect {
	.multiselect-option {
		padding: $val-spacing-xs $val-spacing-s;
		margin-left: 0 !important;
	}
}

#timeToReport {
	background: $col-white url("/images/inputs/ic_time_inactive.svg") no-repeat left $val-spacing-s center !important;
	padding-left: $val-spacing-xl;
}

// .form-group { // done in respective components: dropdown, select etc
// 	& .--lazy-loading {
// 		position: relative;

// 		& > select {
// 			background: none !important;
// 		}
// 	}
// }

.help-block {
	font-size: $val-fontSizeSmall;
	color: $col-red;
	display: flex;
	align-items: center;
	line-height: 1;

	& .icon-info {
		padding-right: $val-spacing-xs;
		font-size: $val-fontSizeRegular;
	}

	& img {
		padding-right: $val-spacing-xs;
		height: $var-errorIconSize;
	}
}

.additional-input-element {
	color: $col-blue;
	font-size: $val-fontSizeSmall;
	float: right;
}

.input-counter {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	& .input-counter-state,
	& button {
		height: $var-buttonSize;
		width: $var-buttonSize;
	}

	&.input-sm {
		& button,
		& .input-counter-state {
			height: $var-buttonSizeSm;
			width: $var-buttonSizeSm;
		}
	}

	& button {
		flex-grow: initial;
		background-color: $col-greyLight;
		border: 1px solid $col-greyMid;
		text-align: center;
		align-self: center;
	}

	& .input-counter-state {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

#tooltip-formatted .tooltip-inner {
	padding-top: $val-spacing-s;
	padding-bottom: $val-spacing-s;

	& > .tooltip-formatted-content {
		text-align: left;
	}

	& .color-square {
		width: $val-icon10;
		height: $val-icon10;
		display: block;
		margin-top: $val-spacing-s;
	}
}

.info-icon {
	margin-left: $val-spacing-s;

	&.checkbox-inline {
		height: 100%;
		vertical-align: bottom;
		margin-bottom: $val-spacing-xxs;
	}
}

.field-link {
	padding-top: 30px;
	text-align: center;
}

.suggestion-field-footer {
	font-size: $val-fontSizeSmall;
	color: $col-greyFontLight;
	padding-top: $val-spacing-xs;
}


.input-filter {
	position: relative;
	display: flex;
	align-items: center;

	& input {
		min-width: $val-textFilterMinWidth;
		background-image: url("/images/inputs/ic_search_disabled.svg");
		background-repeat: no-repeat;
		background-position: $val-spacing-s center;
		padding-left: $val-spacing-l;

		&::placeholder {
			color: $col-greyFontLight;
		}
	}

	// & .clear-search { // probably not used
	// 	position: absolute;
	// 	right: $val-spacing-s;
	// 	width: auto;
	// }

	& input.filtered-results::after {
		content: attr(data-filtered);
		position: absolute;
		right: $val-spacing-s;
		top: $val-spacing-s;
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
		z-index: 1000 !important;
	}
}

@mixin color-square($color) {
	&.selected .color-square {
		border: 2px solid $color;
	}

	& .color-square {
		width: $val-colorSquareSize !important;
		height: $val-colorSquareSize !important;
		border-radius: $val-borderRadius;
		border: 2px solid white;
		background-color: $color;
	}
}

.descriptionless {
	margin-top: $var-descriptionlessMargin;
}

.connected-input {
	background-color: $col-orangeBackground !important;
}

.after-input {
	@extend .noselect;

	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	display: block;
	color: $col-greyFontLight;
	margin: auto;
	pointer-events: none;
}

.open-left {
	border-left: none !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	margin-left: 0 !important;
}

.open-right {
	border-right: none !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	margin-right: 0 !important;
}

.flat-left {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.flat-right {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.text-field {
	border: none;
	border-radius: 0;
	background: initial;
	outline: initial;
	line-height: initial;
	color: initial;
	padding: initial;
	margin: initial;
	box-shadow: initial;
	font-size: $val-fontSizeRegular;
	line-height: $val-lineHeightRegular;
	display: flex;
	align-items: center;
}
