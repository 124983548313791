$var-scrollToLoadItemBorderLeft: 3px;

.scroll-to-load-table-container {
	& .btn {
		min-width: $val-spacing-l !important;
		padding: 0 $val-spacing-s;
	}

	& .table-container__header-regular {
		flex-flow: column;
		height: auto;
	}

	& .table-container__actions {
		width: 100%;

		&:not(:first-child) {
			padding-top: $val-spacing-m;
		}

		&--with-search-only {
			display: block;
		}
	}

	& .table-container__filter {
		width: 100%;
		& input {
			width: 100%;
		}
	}

	& .table-filter {
		justify-content: flex-start;
		flex-wrap: wrap-reverse;

		&__parameter {
			margin: $val-spacing-m 0 0;

			&--no-margin-top {
				margin-top: 0;
			}

			&--margin-right {
				margin-right: $val-spacing-s;
			}
		}
	}
}

.scroll-to-load {
	&::-webkit-scrollbar {
		display: none;
	}

	&__item {
		padding: $val-spacing-m $val-spacing-l;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		user-select: none;
		border-bottom: 1px solid $col-greyConnection;

		&:nth-child(2n) {
			border-left: $var-scrollToLoadItemBorderLeft solid $col-greyConnection;
		}
		&:nth-child(2n + 1) {
			border-left: $var-scrollToLoadItemBorderLeft solid $col-grey;
		}
	}
	&__item-values-container {
		width: 100%;
	}

	&__item-value {
		display: flex;
		justify-content: space-between;
		padding-right: $val-spacing-m;

		& > div {
			margin: $val-spacing-s 0;
		}
	}

	&__item-value-data {
		text-align: right;
	}

	& .setting-dropdown {
		display: flex !important;
		align-items: center;

		& .caret {
			display: none;
		}
	}
}

.infinite-scroll-container {
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	& .loading-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: $val-headerHeight;
		width: 100%;
	}

	.indent {
		position: relative;

		&,
		& > div,
		& .infinite-scroll-component {
			min-height: auto;
		}

		&--1 {
			background-color: $col-greyBackground !important;

			.scroll-to-load__item {
				margin-left: 32px;
				background-color: $col-greyBackground !important;

				&::before {
					z-index: 6;
					content: " ";
					display: block;
					top: 0;
					bottom: 0;
					left: $val-spacing-m;
					border-left: 1px solid $col-greyConnection;
					height: 100%;
					position: absolute;
				}
			}
		}

		&--2 {
			background-color: $col-blueBackground !important;

			.scroll-to-load__item {
				margin-left: 64px;
				background-color: $col-blueBackground !important;

				&::before {
					z-index: 6;
					content: " ";
					display: block;
					top: 0;
					bottom: 0;
					left: $val-spacing-xxxl;
					border-left: 1px solid $col-greyConnection;
					height: 100%;
					position: absolute;
				}
			}
		}
	}

	.indent-2 {
		padding-left: 32px;
		position: relative;

		&::before {
			z-index: 6;
			content: " ";
			display: block;
			top: 0;
			bottom: 0;
			left: $val-spacing-m;
			border-left: 1px solid $col-greyConnection;
			height: 100%;
			position: absolute;
		}
	}
}
