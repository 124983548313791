.submit-success-modal {
	$var-checkIconSize: $val-icon64;

	.modal-body, &__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: $val-spacing-xxxl;
		gap: $val-spacing-xl;
	}
	
	&__content-title {
		color: $col-greyMid;
		font-size: $val-fontSizeXXBig;
		font-weight: bold;
	}

	&__check-icon {
		font-size: $var-checkIconSize;
	}
	&__user-message {
		font-size: $val-fontSizeBig;
		line-height: $val-lineHeightBig;
		text-align: center;
	}
}
