.priority-label {
	display: flex;
	align-items: center;

	&__icon {
		margin-right: $val-spacing-s;

		&--high {
			color: $col-red;
		}

		&--medium {
			color: $col-orange;
		}

		&--low {
			color: $col-green;
		}
	}
}