@import "../../../../styles/Colors.scss";
@import "../../../../styles/Constants.scss";


.associated-subjob-data-container {
	display: flex;
	flex-direction: row;
	background-color: $col-blueBackground;
	padding-top: $val-spacing-s;
	padding-bottom: $val-spacing-xxs;
	padding-left: $val-spacing-s;
	padding-right: $val-spacing-s;
	margin-bottom: $val-spacing-xs;
	margin-top: $val-spacing-xs;
	border: 1px solid $col-blueBorder;
	width: fit-content;
}

.associated-subjob-data-container-label {
	margin-left: $val-spacing-s;
}

.associated-subjob-data-container-job {
	text-transform: uppercase;
}