@import "./mixins/Environment.scss";

@import "./Constants.scss";
@import "./Colors.scss";

$var-iconSize: 18px;
$var-iconCaretSize: 30px;
$var-iconMargin: 15px;
$var-iconCaretMargin: 9px;
$var-logoHeight: 22px;
$var-logoMargin: 20px;
$var-logoBackground: 64px;

.sidenav {
	@extend .noselect;

	flex: 1 0 auto;
	z-index: $val-sidenavZIndex + 1;
	height: 100%;
	position: fixed;
	align-items: flex-start;
	justify-content: flex-start;
	flex-flow: column nowrap;
	display: flex;
	padding: 0;
	padding-bottom: $var-logoBackground;
	overflow-x: hidden;
	width: $val-sidebarWidthMax;
	-webkit-transition: width $val-toggleAnimation;
	transition: width $val-toggleAnimation;
	will-change: width;
	background-color: $col-black;
	color: $col-grey;

	& a {
		min-width: $val-sidebarWidthMax;
	}

	& .sidenav-close {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		min-height: $val-headerHeight;
		cursor: pointer;

		&:hover {
			color: $col-orange;
			background-color: $col-blackDark;
		}

		& a {
			min-width: 0;
		}

		& .sidenav-collapse {
			width: calc(#{$val-sidebarWidthMax} - #{$val-sidebarWidthMin});
			overflow: hidden;
			-webkit-transition: width $val-toggleAnimation;
			transition: width $val-toggleAnimation;

			& span {
				padding-left: $val-sidebarWidthMin;
			}
		}

		& [class^="icon-"] {
			font-size: $var-iconCaretSize;
			margin: 0 $var-iconCaretMargin;
		}
	}

	& .custom-scroll-container>div {
		width: $val-sidebarWidthMax;
		overflow-y: auto !important;
		overflow-x: hidden !important;
		margin: 0 !important;
		-webkit-transition: width $val-toggleAnimation;
		transition: width $val-toggleAnimation;
	}

	& .sidenav-menu {
		padding: 0;
		position: absolute;
		left: 0;

		& hr {
			width: $val-sidebarWidthMax - $val-spacing-m;
			-webkit-transition: width $val-toggleAnimation;
			transition: width $val-toggleAnimation;
			border-color: $col-blackDark;
			margin-left: $val-spacing-s;
		}

		&>li {
			padding: 0;
			cursor: pointer;
			list-style: none;
			text-align: left;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			height: $val-sidebarWidthMin;

			& a {
				line-height: $val-sidebarWidthMin;
				color: $col-grey;
				display: flex;
				align-items: center;
			}

			& img {
				height: $var-iconSize;
				width: $var-iconSize;
				margin: 0 $var-iconMargin;
			}

			& .sidenav-icon {
				font-size: $var-iconSize;
				margin: 0 $var-iconMargin;
			}

			&:hover,
			&.active {
				background-color: $col-blackDark;

				& a {
					color: $col-orange;
				}
			}
		}
	}

	& .sidenav-logo {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		& div {
			width: 100%;
			display: flex;
			justify-content: center;

			& .logo-small {
				opacity: 0;
			}

			& .logo-big {
				opacity: 1;
			}

			& img {
				height: $var-logoHeight;
				margin-bottom: $var-logoMargin;
				-webkit-transition: opacity $val-toggleAnimation;
				transition: opacity $val-toggleAnimation;
				position: absolute;
				bottom: 0;
			}
		}
	}

	// Overrides for minimized version
	&.minimized {
		width: $val-sidebarWidthMin;

		& .custom-scroll-container>div {
			width: $val-sidebarWidthMin;
		}

		& .sidenav-close .sidenav-collapse {
			width: 0;
		}

		& hr {
			width: $val-sidebarWidthMin - $val-spacing-m;
		}

		& .sidenav-logo {
			left: 0;

			& .logo-small {
				opacity: 1;
			}

			& .logo-big {
				opacity: 0;
			}
		}
	}
}

@include envTesting {
	.sidenav {
		background-color: $col-env-testing-bg;
		color: $col-white;

		& .sidenav-close {
			&:hover {
				color: $col-blackDark;
				background-color: $col-white;
			}
		}

		& .sidenav-menu {
			& hr {
				border-color: $col-white;
			}

			&>li {
				& a {
					color: $col-white;
				}

				&:hover,
				&.active {
					background-color: $col-white;

					& a {
						color: $col-blackDark;
					}
				}
			}
		}
	}
}

@include envStaging {
	.sidenav {
		background-color: $col-env-staging-bg;
		color: $col-white;

		& .sidenav-close {
			&:hover {
				color: $col-blackDark;
				background-color: $col-white;
			}
		}

		& .sidenav-menu {
			& hr {
				border-color: $col-white;
			}

			&>li {
				& a {
					color: $col-white;
				}

				&:hover,
				&.active {
					background-color: $col-white;

					& a {
						color: $col-blackDark;
					}
				}
			}
		}
	}
}