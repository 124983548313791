@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

$min-modal-height: 15vh;
$max-modal-height: 75vh;
$header-footer-z-index: 10;

.employee-wo-history-modal {
	width: $val-modal-xl !important;
	display: flex !important;
	justify-content: center !important;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&__big-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		overflow: initial !important;

		&::before,
		&::after {
			display: none;
		}
	}

	&__big-header-left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	&__big-header-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.modal-content {
		width: $val-modal-sm !important;
	}

	.header-close-icon {
		cursor: pointer;
	}

	.modal-body {
		padding: 0px !important;
	}
}
