@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

.section {
	background-color: $col-white;
	margin-bottom: $val-spacing-m;
	padding: $val-spacing-m;
	border-bottom: 1px solid $col-greyConnection;
}

.container {
	background-color: $col-greyLight;
	padding: $val-spacing-s;
}


.timestamp {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-items: flex-start;
	margin-bottom: $val-spacing-s;


	&__label {
		font-weight: 700;
	}
}

.field-value {
	margin: $val-spacing-s;
	padding-bottom: $val-spacing-l;
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.title {
	font-size: $val-fontSizeXBig;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	gap: $val-spacing-s;
}

.tool-type {
	color: $col-design-grey15;
}

.section-title {
	color: $col-grey;
	text-transform: uppercase;
	font-size: $val-fontSizeBig;
	display: flex;
	flex-direction: column;
	margin-bottom: $val-spacing-m;
}
.valid-cell-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 5px;
}

.grey-text {
	color: $col-greyFontLight;
}

.date-value {
	font-size: $val-fontSizeRegular;
	color: $col-design-grey15;
}

.total-cost-container {
	background-color: $col-greyBackground;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
	font-weight: bold;
	padding-right: $val-spacing-m;
	padding-top: $val-spacing-l;
	padding-bottom: -$val-spacing-xl;
	height: 55px;
}

.buttons {
	display: flex;
	flex-direction: row;
	gap: $val-spacing-s;
	justify-content: flex-end;
}

.field-row {
	margin-bottom: $val-spacing-l;
}

.table-text {
	font-size: $val-fontSizeRegular;
}