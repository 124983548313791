$var-rowHeight: 70px;
$var-highlightedRowBorderSize: 2px;

$var-arrowContainerHeight: 30px;

$var-draggableTableBlankSpaceWidth: 5%;
$var-draggableTableColumnWidth: 30%;

$var-filterInputWidth: 300px;

// Mixin

@mixin mechanicViewMixin($var-colPerRow, $var-colInToolbar) {
	// colPerRow = How many "unavailable card" do we show in one row
	// colInToolbar = How many "unavailable card" widths do we give to toolbar

	$var-containerWidth: calc(100vw - #{$val-sidebarWidthMin});
	$var-unavailableCardWidth: calc(#{$var-containerWidth} / #{$var-colPerRow + $var-colInToolbar});
	$var-toolbarWidth: calc(#{$var-unavailableCardWidth} * #{$var-colInToolbar});

	
	$var-cardPadding: $val-spacing-s $val-spacing-s;

	.mechanic-view-body {
		&__available {
			width: $var-toolbarWidth !important;
			max-width: $var-toolbarWidth !important;
			min-width: $var-toolbarWidth !important;
		}

		&__unavailable {
			width: calc(#{$var-containerWidth} - #{$var-toolbarWidth}) !important;
		}

		&__unavailable-wrapper {
			&--empty {
				height: 100%;
			}
		}
	}

	.unavailable-equipment-card-wrapper {
		padding: $var-cardPadding;
		width: $var-unavailableCardWidth;
	}
}

// Regular rules

.mechanic-view-navigation {
	&__date {
		font-weight: bold;
		font-size: $val-fontSizeXXXBig;
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		padding: 0 $val-spacing-m;
	}
}

.mechanic-view {
	height: 100%;
	width: 100%;
	background-color: $col-greyBackground;
	border: none;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	.mechanic-view-header {
		height: $val-headerHeight;
		width: 100%;
		color: $col-black;
		background: $col-white;
		border-bottom: 1px solid $col-greyConnection;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 $val-spacing-l;
		z-index: 4;
		user-select: none;

		&__left {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		&__center {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 0 $val-spacing-s;
		}

		&__right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.mechanic-view-search {
			position: relative;
			display: flex;
		
			.form-group {
				margin-bottom: 0;
			}
		
			& .input-filter {
				min-width: $var-filterInputWidth;
				margin-right: $val-spacing-s;
			}
		
			& .input-group-append {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				color: $col-greyFontLight;
				position: absolute;
				background-color: transparent;
				border: none;
				right: 0;
		
		
				& .match {
					margin-right: $val-spacing-xs;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 0;
					height: 100%;
					display: flex;
					align-items: center;
					z-index: 2;
		
					& .icon-left, & .icon-right {
						cursor: pointer;
						font-size: $val-fontSizeSmall;
					}
		
					span {
						font-size: $val-fontSizeSmall;
						padding: 0 $val-spacing-xs;
						line-height: $val-lineHeightSmall;
					}
				}
		
				& .icon-close {
					cursor: pointer;
					font-size: $val-fontSizeXBig;
				}
			}
		}
	}

	.mechanic-view-body {
		display: flex;
		flex-flow: row nowrap;
		height: calc(100% - #{$val-headerHeight} - #{$val-spacing-xxxs}); // 100% - header height - header border
		width: 100%;
		background-color: $col-white;

		&__available {
			height: 100%;
			background-color: $col-greyConnection;
		}

		&__unavailable {
			height: 100%;
		}

		&__unavailable {
			background: $col-white;
			margin: $val-spacing-xxs;

			&--dragged-over {
				outline: 1px dashed $col-greyMid;
			}

			&::-webkit-scrollbar {
				display: none;
			}

			&__list {
				display: flex;
				flex-wrap: wrap;
			}
		}

	}

	.arrow-container {
		display: flex;
		justify-content: center;
		min-height: $var-arrowContainerHeight;
		pointer-events: none;
		cursor: default;

		.arrow {
			height: $var-arrowContainerHeight;
			width: 100%;
			position: relative;
			will-change: transform, opacity;
			background: url("/images/elements/ic_search_arrow.svg") center center no-repeat;
			transition: opacity 0.35s ease-in-out;
			background-size: $var-arrowContainerHeight;
			animation: jump 1s infinite;
			opacity: 0.2;

			&--active {
				opacity: 1;
			}
		}
	}

	.unavailable-equipment-card {
		box-shadow: $var-shadow;
		background-color: $col-greyBackground;
		border-radius: $val-borderRadius;
		position: relative;

		&:focus {
			outline: none !important;
		}

		&::after {
			position: absolute;
			left: -1px;
			top: -1px;
			display: block;
			background-color: $col-transparent;
			content: '';
			height: calc(100% + 2px);
			width: calc(100% + 2px);
			transition: background-color 0.2s ease-in outline 0.1s linear, outline-color 0s;
			z-index: 2;
			pointer-events: none;
		}

		&--highlighted {
			&::after {
				background-color: $col-orangeHighlighted;
				pointer-events: none;
				animation-name: twinkle;
				animation-duration: 2s;
				animation-iteration-count: infinite;
				border-radius: $val-borderRadius;
				outline-offset: 1px;
				outline: 2px dashed $col-orange !important;
			}
		}

		&--highlighted-active {
			&::after {
				background-color: $col-orangeHighlighted;
				pointer-events: none;
				animation-name: twinkle;
				animation-duration: 2s;
				animation-iteration-count: infinite;
				border-radius: $val-borderRadius;
				outline-offset: 1px;
				outline: $var-highlightedActiveOutline solid $col-orange !important;
			}
		}

		&__title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $val-spacing-xs $val-spacing-s;
			font-weight: bold;
			border-radius: $val-borderRadius;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			text-transform: uppercase;

			.actions {
				position: relative;
				right: 0;

				.dropdown-item {
					font-weight: normal;
				}
				
				.dropdown-menu {
					text-transform: initial;
					top: calc(50% + #{$val-spacing-s});
					right: 0;
					left: auto;
				}

				.caret {
					display: none;
				}

				.icon {
					position: absolute;
					right: 0;
				}
			}
		}

		&__title-left {
			display: flex;
			align-items: center;
		}

		&__title-full {
			font-size: $val-fontSizeBig;

			&--additional {
				font-size: $val-fontSizeSmall;
				margin-left: $val-spacing-s;
			}
		}

		&__body {
			padding: $val-spacing-s;
			display: flex;
			flex-flow: column nowrap;
		}

		&__entry {
			display: flex;
			flex-wrap: wrap;

			&__label {
				width: 40%;
			}

			&__value {
				width: 60%;
				font-weight: bold;
				align-self: flex-end;
			}
		}

		&__failure-details-wrapper {
			padding: $val-spacing-s;
			font-size: $val-fontSizeSmall;
			border-top: 1px solid $col-greyConnection;
			background: $col-white;
			word-break: break-word;
			white-space: normal;
			cursor: pointer;
		}

		&__toggle-notes-button {
			font-size: $val-fontSizeSmall;
			cursor: pointer;
			color: $col-blue;
		}
		
		&__failure-details {
			margin-top: $val-spacing-s;
		}

		&__details-content {
			border-left: 2px solid $col-greyConnection;
			margin-bottom: $val-spacing-s;
			padding-left: $val-spacing-s;
			margin-top: $val-spacing-xs;
		}
	}

	.draggable-table {
		$var-tableHeight: calc(100vh - 2 * #{$val-headerHeight});

		background-color: $col-greyBackground;
		height: 100%;
		max-height: $var-tableHeight;
		
		&__content {
			height: calc(#{$var-tableHeight} - #{$var-rowHeight});
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&__row {
			display: flex;
			align-items: center;
			background-color: $col-white;
			height: $var-rowHeight;
			width: calc(100% - #{2 * $var-highlightedRowBorderSize});
			margin: auto;
			border-bottom: 1px solid $col-greyConnection;

			&--highlighted {
				position: relative;
				outline: $var-highlightedRowBorderSize dashed $color-orange;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $col-orangeHighlighted;
					pointer-events: none;
					animation-name: twinkle;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					border-radius: $val-borderRadius;
				}
			}

			&--highlighted-active {
				outline: $var-highlightedRowBorderSize solid $color-orange;
				z-index: 1;
			}

			&--header {
				background-color: $col-greyLight;
				font-size: $val-fontSizeSmall;
			}
		}

		&__cell {
			white-space: nowrap;
			width: $var-draggableTableColumnWidth;
			padding: $val-spacing-xs $val-spacing-s;
			overflow: hidden;

			&--blank-space {
				width: $var-draggableTableBlankSpaceWidth;
			}

			&--with-background {
				border-radius: $val-borderRadius;
				font-weight: bold;

				.draggable-table__cell-content {
					justify-content: space-between;
					width: 100%;
				}

			}

			&--uppercase {
				text-transform: uppercase;
			}
		}

		&__cell-content {
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			align-items: center;

			& .color-square {
				margin-right: $val-spacing-s;
			}

			&--additional {
			font-size: $val-fontSizeXSmall;
				font-weight: bold;
				text-transform: uppercase;
				line-height: $val-lineHeightSmall;
			}

		}
	}
}

// Zoom-specific rules

.mechanic-view {
	@include mechanicViewMixin(1, 1);

	&.zoom-2 {
		@media (min-width: $bp-xs-low) and (max-width: $bp-xs-high) {
			@include mechanicViewMixin(1, 1);
		}
		@media (min-width: $bp-s-low) {
			@include mechanicViewMixin(2, 1.5);
		}
	}

	&.zoom-1 {
		@media (min-width: $bp-xs-low) and (max-width: $bp-xs-high) {
			@include mechanicViewMixin(2, 2);
		}
		@media (min-width: $bp-s-low) and (max-width: $bp-s-high) {
			@include mechanicViewMixin(3, 2);
		}
		@media (min-width: $bp-m-low) {
			@include mechanicViewMixin(4, 1.5);
		}
	}

	&.zoom-0 {
		@media (min-width: $bp-xs-low) and (max-width: $bp-xs-high) {
			@include mechanicViewMixin(3, 2);
		}
		@media (min-width: $bp-s-low) and (max-width: $bp-s-high) {
			@include mechanicViewMixin(4, 2);
		}
		@media (min-width: $bp-m-low) {
			@include mechanicViewMixin(6, 2);
		}
	}
}