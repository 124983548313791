@import "../../styles/Constants.scss";
@import "../../styles/Colors.scss";

@mixin label {
	background-color: $col-blue;
	padding-left: $val-spacing-xs;
	padding-right: $val-spacing-xs;
	color: $col-white;
	font-weight: bold;
	margin-right: $val-spacing-s;
	margin-bottom: $val-spacing-s;
	text-transform: uppercase;
	white-space: nowrap;
}

@mixin subjob {
	background-color: $col-design-blue7;
	color: $col-blue;
	padding-left: $val-spacing-xs;
	padding-right: $val-spacing-xs;
	font-weight: bold;
}

@mixin completed {
	background-color: $col-greyConnection;
	color: $col-green;
	font-weight: bold;
	text-transform: none;
	padding: $val-spacing-xs;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: $val-spacing-s;
	font-size: $val-fontSizeXSmall;
}

@mixin opened {
	background-color: $col-greyConnection;
	color: $col-black;
	font-weight: bold;
	text-transform: none;
	padding: $val-spacing-xs;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: $val-spacing-s;
	font-size: $val-fontSizeXSmall;
}

@mixin unfinished {
	background-color: $col-greyConnection;
	color: $col-red;
	font-weight: bold;
	text-transform: none;
	padding: $val-spacing-xs;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $val-fontSizeXSmall;
	margin-top: $val-spacing-s;
}

@mixin pending {
	background-color: $col-greyConnection;
	color: $color-apricot;
	font-weight: bold;
	text-transform: none;
	padding: $val-spacing-xs;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $val-fontSizeXSmall;
	margin-top: $val-spacing-s;
}

@mixin inactive {
	background-color: $col-greyConnection;
	color: $col-grey;
	font-weight: bold;
	text-transform: none;
	padding: $val-spacing-xs;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $val-fontSizeXSmall;
	margin-top: $val-spacing-s;
}

.label {
	@include label;

	&__subjob {
		@include label;
		@include subjob;

		&__margin {
			@include label;
			@include subjob;
			margin-right: $val-spacing-s;
		}
	}

	&__opened {
		@include label;
		@include opened
	}

	&__completed {
		@include label;
		@include completed;

		&__light {
			@include label;
			@include completed;
			background-color: $col-design-grey13;
		}
	}

	&__inactive {
		@include label;
		@include inactive;

		&__light {
			@include label;
			@include inactive;
			background-color: $col-design-grey13;
		}
	}

	&__unfinished {
		@include label;
		@include unfinished;
	}

	&__pending {
		@include label;
		@include pending;

		&__light {
			@include label;
			@include pending;
			background-color: $col-design-grey13;
		}
	}
}