.toast {
	display: grid;
	color: $col-white;
	margin-top: 50px;
	user-select: none;
	
	&__message {
		background-color: $col-design-gray6;
		justify-self: center;
		padding: $val-spacing-m;
		border-radius: 100px;
	}
}