@import "./Colors.scss";
@import "./Constants.scss";

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-border: 1px solid $col-greyConnection;
$var-formControlMargin: 15px;
$var-fieldSize: 34px;
$var-contactHeaderIconSize: 19px;
$var-wr-table-header-height: 64px;
$var-wr-table-header-filter-width: 350px;
$var-formBoxMiniMobileWidth: 304px;
$var-formBoxMediumMobileWidth: 404px;
$var-formBoxMiniWidth: 504px;
$var-formBoxMediumWidth: 850px;
$val-workOrderInfoMinWidth: 100px;
$var-sideBorderSize: 4px;
$var-pagination-button-size: 34px;
$var-maxHeaderTextPortion: 60%;
$var-prodAndBillingRowHeight: 50px;
$var-verticalPercentageIndicator: 1px;
$var-percentageIndicatorWidth: 20px;
$val-colorSquareSize: 20px;
$var-colorSquareSize: 16px;
$var-droppableDefaultHeight: 24px;
$var-dragOverOffset: 4px;
$var-smileyIconSize: 80px;
$var-wo-danger-text-width: 61%;
$var-date-picker-width: 130px;

.drag-over::before {
	content: '';
	position: absolute;
	left: $var-dragOverOffset;
	top: $var-dragOverOffset;
	bottom: $var-dragOverOffset;
	right: $var-dragOverOffset;
	z-index: 2;
	border: 1px dashed $col-greyMid;
	border-radius: $val-borderRadius;
}

#tasks, #private-tasks, #contacts, .tasks-container {
	& .task-description {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		background-color: $col-greyLight;
		padding-bottom: $val-spacing-l;
		margin-bottom: $val-spacing-l;

		& .form-group {
			margin: 0;
		}

		& .task-index {
			align-self: center;
			padding-top: $val-spacing-s;
			font-weight: bold;
		}
	}

	& .task-preview {
		& .task-preview-header {
			padding-top: $val-spacing-l;
			font-weight: bold;
			font-size: $val-fontSizeRegular;
		}

		& .task-preview-content {
			font-size: $val-fontSizeRegular;
			padding-top: $val-spacing-s;
		}
	}
}

#work-request-form, #work-estimate-form, #work-review-form {
	height: 100%;
	width: 100%;
	padding: $val-spacing-l;
	max-width: $val-maxBoxSize !important;

	& .contact-method-picker {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;

		& div:first-child {
			text-align: center;
		}

		& div {
			align-self: center;
		}

		&.not-selected {
			color: $col-greyFontLight;
		}
	}
}

.estimate-section {
	&:nth-child(2n) {
		border-left: $var-sideBorderSize solid $col-blueLine;
	}

	&:nth-child(2n + 1) {
		border-left: $var-sideBorderSize solid $col-blue;
	}

	& + .estimate-section {
		border-top: 1px solid $col-blueLine;
	}

	& .estimate-cost-code-header {
		background-color: $col-blueBackground;
		padding: $val-spacing-l;
		height: $val-headerHeight;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $col-blueLine;

		& h3 {
			margin: 0;
			text-transform: none;
			background-size: $var-contactHeaderIconSize;
			background-position: left center;
			background-repeat: no-repeat;
			padding-left: $val-spacing-m + $var-contactHeaderIconSize;
			background-image: url("/images/elements/ic_cost_code.svg");
		}

		& .btn + .btn {
			margin-left: $val-spacing-s;
		}
	}

	& .estimate-cost-code-attributes {
		background-color: $col-blueBackground;
		padding-top: $val-spacing-m;
		padding-bottom: $val-spacing-m;
	}

	& .estimate-cost-code-requirements {
		background-color: $col-white;
		padding: 0;
	}

	& .cost-code-requirement {
		padding: $val-spacing-m $val-spacing-l;

		& .cost-code-requirement-row {
			display: flex;
			align-items: center;
		}

		a {
			display: inline-block;
		}
	}

	& .fixed-values {
		padding-bottom: $val-spacing-l;
	}

	.task-cost-code-requirements-container {
		max-width: $val-maxBoxSize;

		& > div {
			&:nth-child(2n) > div > div {
				background: $col-greyLight;
			}

			&:nth-child(2n+1) > div > div {
				background: $col-white;
			}
		}
	}
}

.confirm-dialog {
	position: absolute !important;
	top: 30%;
	left: 45%;
	width: 30vw;
}

.mock-image-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.company-dashboard {
	height: 100%;
	width: 100%;
	padding: $val-spacing-l;
}

.scroll-container {
	max-height: calc(100vh - #{$val-headerHeight});
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
}

.document-add-form {
	& .document-add-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-top: $val-spacing-m;

		& div {
			& .form-group {
				margin-bottom: 0;
			}
		}
	}
}

.icon-info {
	&.--error {
		color: $col-red;
	}
}

.color-label {
	width: 100%;
	height: $val-spacing-m + $val-spacing-s;
	display: flex;
	justify-content: center;
	align-items: center;

	& > span {
		text-align: center;
		font-weight: bold;
		width: 100%;
		padding: 0 $val-spacing-m;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
}

.app-scrollable {
	& > div:first-child {
		padding: $val-spacing-m !important;
	}
}