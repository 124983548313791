@import "./Colors.scss";
@import "./ColorApplications.scss";
@import "./Constants.scss";

$company-image-button-width: 130px;
$company-info-basic-width: 400px;
$var-userGroupInputMinWidth: 200px;
$var-iconSize: 16px;
$var-infoLineHeight: 30px;

.image-upload-button {
	width: $company-image-button-width;
}

.image-delete-button {
	width: $company-image-button-width;
}

.bg-light-grey {
	background-color: $col-greyLight;
}

.change-password {
	font-size: $val-fontSizeSmall;
}

.status-availability-radio {
	display: flex;
	align-items: center;

	& label {
		margin-left: $val-spacing-s !important;
		margin-bottom: $val-spacing-s;

		& input::before {
			left: -$val-spacing-s;
		}
	}
}

.connected-device {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:not(:first-child) {
		padding-top: $val-spacing-l;
	}

	& .connected-device-info {
		display: flex;
		align-items: center;

		& > [class^='icon'] {
			font-size: $val-fontSizeHuge;
			margin-right: $val-spacing-m;
		}
	}
}