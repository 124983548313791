@import '../../../styles/Constants.scss';
@import '../../../styles/Colors.scss';

.fixed-cell {
	position: sticky;
	left: 0;
}

.empty-cell {
	display: inline-block;
	background-color: $col-white;

	&--header {
		background-color: $col-greyBackground;
	}
}

.text-cell {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.link-cell {
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&>[class^="icon-external"] {
		color: $col-blue;
		font-weight: bold;
		margin-right: $val-spacing-s;
		font-size: $val-fontSizeXBig;
	}

	&>b {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:hover {
		cursor: pointer;
	}
}

.dollar-cell {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.percentage-cell {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.updated-by-cell {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__time {
		font-size: $val-fontSizeSmall;
	}

	&__name {
		display: flex;
		font-size: $val-fontSizeSmall;
		color: $col-greyMid;
	}
}