@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

$avatar-size: 210px;
$no-cdl-background: repeating-linear-gradient(45deg, transparent, transparent 5px, $col-orange 5px, $col-orange 10px);
$max-header-title-width: 450px;

.schedule-board-modal {
	&__big-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: $val-fontSizeXXXBig;
		font-weight: bold;
		overflow: initial !important;

		&::before,
		&::after {
			display: none;
		}
	}

	&__big-header-left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	&__big-header-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	&__resource {
		// For employees / equipment / placeholders
		&__header-actions {
			max-width: $val-spacing-xl;
			font-weight: normal;

			.dropdown {
				transform: translate(0px, 28px) !important;
			}

			.options-icon {
				color: $col-darkGrey;
				font-size: $val-fontSizeXXXBig;
			}
		}
	}
}
