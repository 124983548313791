@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$var-bid-status-width: 100px;
$var-bid-status-filter-width: 150px;

.work-request-table {

	&__filters {
		display: flex;
		width: 100%;

		&__bid-status-container {
			display: flex;
			margin-left: $val-spacing-s;
		}

		&__bid-status {
			background-color: $col-white;
			border: 1px solid $col-greyConnection;
			// width: $var-bid-status-filter-width;
		}
	}


	&__double-cell {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > span {
			font-size: $val-fontSizeSmall;
			color: $col-greyMid;
		}
	}
	&__bid-status {
		color: $col-white;
		text-align: center;
		padding: 0 $val-spacing-m;
		border-radius: $val-borderRadius;
		font-weight: bold;
		font-size: $val-fontSizeSmall;
		min-width: $var-bid-status-width;
		text-overflow: hidden;

		&--in-progress {
			background-color: $col-yellow;
		}
		&--won {
			background-color: $col-green;
		}
		&--lost {
			background-color: $col-canceledRed;
		}
		&--locked-in {
			color: $col-blue;
			cursor: pointer;

			&:hover {
				color: $col-darkBlueBorder;
			}
		}
	}
}