.user-groups-upsert {

	&__accounts-container {
		display: flex;
		padding-top: $val-spacing-m;
	}

	&__account-item {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: $val-spacing-xs $val-spacing-s;
		margin-right: $val-spacing-xs;
		margin-bottom: $val-spacing-xs;
		background-color: $col-greyBackground;
		border: 1px solid $col-greyConnection;
		border-radius: $val-borderRadius;

		&--unnoticed {
			opacity: .5;
		}
	}

	&__account-icon {
		color: $col-greyDark;
		cursor: pointer;
		margin-left: $val-spacing-s;
	}
}