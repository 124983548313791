@import "./Colors.scss";
@import "./Constants.scss";

$var-textAndColorResourceFormWidth: 200px;
$var-textAndColorResourceOrgListMinHeight: 50px;
$var-textAndColorResourcePreviewHeight: 34px;
$val-colorSquareSize: 20px;
$var-image-border-weight: 1px;
$var-imageSize: 200px;
$var-photo-upload-button-width: 100px;
$var-skillColorRectangleDimension: 20px;

.equipment-form {
	& .equipment-info {
		display: flex;

		&__details {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: inherit;

			& .form-group {
				margin-bottom: 0;
			}
		}
	}
}

@mixin text-and-color-resource-color-list {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: $val-spacing-s 0;

	&.column {
		flex-direction: column;

		& .text-and-color-form {
			margin-bottom: $val-spacing-s;
		}
	}

	& .text-and-color-resource-preview {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: $val-spacing-s $val-spacing-m;
		border-radius: $val-borderRadius;
		margin-right: $val-spacing-s;
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightSmall;
		color: $col-greyDark;
		margin-bottom: $val-spacing-s;
		height: $val-fieldSize;

		& [class^="icon-"] {
			margin-left: $val-spacing-m;
			margin-right: -$val-spacing-s;
		}
	}
}

.employee-skills {
	@include text-and-color-resource-color-list;
}

// #skills, #divisions {
// 	& .no-text-and-color-resources {
// 		color: $col-greyFontLight;
// 		font-size: $val-fontSizeBig;
// 		margin-bottom: $val-spacing-m;
// 	}

// 	& .text-and-color-resource-list {
// 		display: flex;
// 		flex-flow: column nowrap;
// 		justify-content: flex-start;

// 		&.organization {
// 			min-height: $var-textAndColorResourceOrgListMinHeight;
// 			margin: $val-spacing-m 0;
// 		}

// 		& .text-and-color-resource-color-list {
// 			@include text-and-color-resource-color-list;
// 		}
// 	}

// 	.text-info {
// 		& img {
// 			margin-right: $val-spacing-s;
// 		}

// 		& span {
// 			color: $col-blue;
// 		}

// 		& span:hover {
// 			color: $col-blueHover;
// 		}
// 	}
// }

.employee-and-equipment-skill-item {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	& .color-square {
		width: $val-colorSquareSize;
		height: $val-colorSquareSize;
		border-radius: $val-borderRadius;
	}
}

.employee-and-equipment-status-form {
	& > div {
		display: flex;
		align-items: center;
	}
}

.image-buttons-container {
	display: flex;
}

.equipment-form {
	&__image {
		& img {
			width: $var-imageSize;
			height: $var-imageSize;
		}

		&__action-buttons {
			width: $var-imageSize;
			display: flex;
			flex-direction: column !important;
		}
	}

	// & .input-checkbox {
	// 	padding-top: $val-spacing-s;
	// }

	& > hr {
		margin: $val-spacing-xl;
		margin-left: -$val-spacing-l;
		margin-right: -$val-spacing-l;
	}
}