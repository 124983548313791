.companyCompliance {
	:global(.notion-header) {
		position: static;
		z-index: unset;

		:global(.notion-nav-header) {
			position: static;
		}
	}

	:global(.notion-asset-wrapper) {
		max-width: 100%;
	}
}