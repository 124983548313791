$filter-button-widht: 200px;
$filter-date-min-width: 150px;

.field-report-orders-table {
	&__filters {
		display: flex;
		align-items: center;

		.table-filter__date {
			min-width: $filter-date-min-width;
		}
	}

	&__row {
		&--canceled {
			background-color: $col-redBackground !important;
		}
	}

	&__canceled-code {
		color: $col-red;
		font-size: $val-fontSizeSmall;
	}

	&__filter-button {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		display: flex;
		align-items: center;
		background: $col-white;
		width: $filter-button-widht;
	}

	&__send-multiple-reports-modal {
		.modal-body > div {
			margin-top: $val-spacing-s;
			margin-bottom: $val-spacing-l;
		}

		&__job-details-container {
			.row, div[class^="col-"] {
				padding-left: 0;
				padding-right: 0;
			}
		}
	
		&__description-container {
			font-size: $val-fontSizeRegular;
		}

		&__picker-radio {
			padding-bottom: $val-spacing-s;
		}

		&__date-picker {
			width: 50%;
		}

		&__reports-list {
			display: flex;
			flex-direction: column;
			align-items: center;

			.row, div[class^="col-"] {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__reports-list-item {
			width: 100%;
			display: flex;
			align-items: center;
		}
	}

	&__popover {
		max-width: none;
		width: initial;
	}
}