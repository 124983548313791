@import '../../../../styles/Constants.scss';
@import '../../../../styles/Colors.scss';

$var-shadow: 0 1px 4px 0 $col-shadow;
$var-tabs-navigation-top-offset: 48px;
$var-actions-top-offset: -16px;

.job-preview {

	&__back-to-list {
		background-color: $col-white !important;
		margin-bottom: $val-spacing-m;
	}

	&__submit-section {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $val-spacing-m;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 2px solid $col-greyBackground;

		.title-container {
			flex: 1;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: $val-spacing-m;

			.title {
				font-size: $val-fontSizeXBig;
				text-transform: uppercase;
			}

			.main-job-title {
				font-size: $val-fontSizeBig;
				line-height: $val-fontSizeBig;
			}
		}

		.actions {
			display: flex;
			align-items: center;
			gap: $val-spacing-m;
		}
	}

	&__submit-section-sticky {
		top: $var-actions-top-offset;
		position: sticky;
	}

	&__additional-info {

		background-color: $col-white;
		box-shadow: $var-shadow;
		padding: $val-spacing-m $val-spacing-l;

		&__accordion {
			// padding: 0 $val-spacing-l;
			margin-bottom: $val-spacing-m;
		}
	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;


		&__section {
			background-color: $col-white;
			box-shadow: $var-shadow;
			padding: $val-spacing-m $val-spacing-l
		}
	}

}
