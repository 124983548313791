$var-shortUrlMinHeight: $val-spacing-s + 20px + 4 * $val-spacing-m; // set min-height so parent container doesn't expand and shrink when we show short url

.submit-button {
	display: flex;
	justify-content: center;
}

.short-url-container {
	min-height: $var-shortUrlMinHeight;
	padding: $val-spacing-m 0;
	
	&__expiration-message {
		padding-top: $val-spacing-s;
	}
}