@import "./Colors.scss";
@import "./Constants.scss";
@import "./Shared.scss";

$var-numberOfColumnsInDisplayView: 12;
$var-minNumberOfColumnsResourceTitleTakes: 2;
$var-maxNumberOfColumnsResourceTitleCanTake: 2;
$var-numberOfColumnsTakenByUnavailableResourceItem: 2;
$var-badgeSize: 15px;
$var-badgeOffset: 8px;
$var-separatorWidth: 12px;
$var-badgeBorderSize: 2px;

$var-displayViewHeaderNavigationMarginRight: 24px;
$var-columnWrapperWidth: calc((100vw - 4 * #{$val-spacing-s} - #{$var-separatorWidth}) / #{$var-numberOfColumnsInDisplayView});
$var-resourceItemHeight: 30px;
$var-minCardResourcesHeight: 42px;
$var-minWorkOrderHeight: 240px;

$var-headerHeight: 64px;
$var-displayViewGroupHeight: 200px;

$var-displayBoardViewPadding: $val-spacing-m;
$var-displayBoardViewColumnSize: calc((100vw - 2 * #{$var-displayBoardViewPadding}) / #{$var-numberOfColumnsInDisplayView});


$var-placeholderInnerShadow: inset 0 0 $val-spacing-s $col-greyMid;

.display-view {
	@extend .fullscreen;

	& .display-view-header {
		display: flex;

		& .display-view-header-title {
			font-size: $val-fontSizeHuge;
			font-weight: bold;
			padding: $val-spacing-s;
		}

		& .display-view-statistics-wrapper {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1 1 0;
			padding: 0 $val-spacing-s;
		}

		& .display-view-header-navigation {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-right: $var-displayViewHeaderNavigationMarginRight;

			& #table-and-display-view-dropdown-menu {
				min-width: unset;
				padding: 0px;
			}
		}
	}

	& .display-view-toolbar {
		display: flex;
		border-top: $var-separatorWidth solid $col-white;

		& .display-view-labor-section-wrapper {
			width: calc((100vw - 4 * #{$val-spacing-s} - #{$var-separatorWidth}) / 3 + 2 * #{$val-spacing-s} + #{$var-separatorWidth});
	
			& .display-view-labor-section {
				height: 100vh;
				border-right: $var-separatorWidth solid $col-white;
				padding: $val-spacing-s;
			}
		}
	
		& .display-view-equipment-section-wrapper {
			width: calc(2 * (100vw - 4 * #{$val-spacing-s} - #{$var-separatorWidth}) / 3 + 2 * #{$val-spacing-s});
			
			& .display-view-equipment-section {
				padding: $val-spacing-s;
			}
		}
	}

	& .display-view-section-title {
		color: $col-blue;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		text-transform: uppercase;
		display: inline-block;
		margin: $val-spacing-m 0;
	}

	& .display-view-groups-wrapper {
		display: flex;
		flex-flow: row wrap;

		& .display-view-group {
			display: flex;
			flex-flow: column;
			min-height: $var-displayViewGroupHeight;

			& .display-view-group-title {
				display: flex;
				align-items: center;
				width: calc(#{$var-maxNumberOfColumnsResourceTitleCanTake} * #{$var-columnWrapperWidth} - #{$val-spacing-s});
				margin-right: $val-spacing-s;
				
				& .display-view-group-title-text {
					margin-left: $val-spacing-xs;
					font-size: $val-fontSizeBig;
					color: $col-grey;
					font-weight: bold;
					text-transform: uppercase;
				}
			}

			& .display-view-columns-wrapper {
				display: flex;
				flex-flow: row wrap;

				& .display-view-available-column-wrapper {
					width: $var-columnWrapperWidth;
					padding: $val-spacing-xs;
				}

				& .display-view-unavailable-column-wrapper {
					width: calc(2 * #{$var-columnWrapperWidth});
					padding: $val-spacing-xs;
				}

				& .display-view-column {
					display: flex;
					flex-flow: column;
				}
			}
		}
	}

	&__unavailable-item {
		display: flex;
		flex-flow: column;
	}

	&__card-and-date {
		display: flex;
		flex-flow: row;

		&__date {
			font-weight: bold;
			margin-left: $val-spacing-s;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	
		&__reason {
			font-weight: bold;
			margin-bottom: $val-spacing-s;
			color: $col-grey;
		}
	}

	& .ellipsed {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	& .display-view-board {
		border-top: 1px solid $col-greyConnection;
		@extend .display-view-board-extension;
	}

	& .display-view-next-rotation-time {
		position: fixed;
		z-index: 6;
		font-size: $val-fontSizeHuge;
		font-weight: bold;
		right: $val-spacing-xxl;
		bottom: $val-spacing-l;
	}

	&__timer {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: $val-spacing-m;

		&--progress {
			background-color: $col-orange;
			height: 100%;
			transition: width ease-out;
		}
	}
}