@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600,700");

@import "./Constants.scss";
@import "./Colors.scss";

body {
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	height: 100vh;
	font-size: $val-fontSizeRegular;
	background: $col-greyBackground;
	color: $col-greyDark;
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
	color: $col-greyDark;
	outline: none;

	&.disabled {
		cursor: not-allowed;
	}
}

textarea {
	resize: none;
}

.form-group {
	text-align: left;
}

.clickable-item {
	cursor: pointer !important;

	& a {
		cursor: pointer !important;
	}
}

.maximize-width {
	width: 100%;
}

.borderless {
	border: none !important;
}

// Utilities
.pointer {
	cursor: pointer;
}

.disabled {
	cursor: not-allowed;
	pointer-events: none;
}

.cursor-disabled {
	cursor: not-allowed;
}

.bold {
	font-weight: bold !important;
}

.regular {
	font-weight: normal !important;
}

.flex {
	display: flex !important;
}

// border radius
.b-r {
	border-radius: $val-borderRadius !important;
}

// Generic paddings
.p-0 {
	padding: 0 !important;
}

.p-s {
	padding: $val-spacing-s !important;
}

.p-xs {
	padding: $val-spacing-xs !important;
}

.p-xxs {
	padding: $val-spacing-xxs !important;
}

.p-l-xs {
	padding-left: $val-spacing-xs !important;
}

.p-l-xxs {
	padding-left: $val-spacing-xxs !important;
}

.p-l-xl {
	padding: $val-spacing-xl !important;
}

.p-r-s {
	padding-right: $val-spacing-s !important;
}

.p-r-m {
	padding-right: $val-spacing-m !important;
}

.p-r-0 {
	padding-right: 0 !important;
}

.p-l-s {
	padding-left: $val-spacing-s !important;
}

.p-l-m {
	padding-left: $val-spacing-m !important;
}

.p-m {
	padding: $val-spacing-m !important;
}

.p-l {
	padding: $val-spacing-l !important;
}

.p-l-0 {
	padding-left: 0 !important;
}

.p-t-0 {
	padding-top: 0 !important;
}

.p-t-s {
	padding-top: $val-spacing-s !important;
}

.p-t-m {
	padding-top: $val-spacing-m !important;
}

.p-t-l {
	padding-top: $val-spacing-l !important;
}

.p-t-xl {
	padding-top: $val-spacing-xl !important;
}

.p-b-m {
	padding-bottom: $val-spacing-m !important;
}

.p-b-l {
	padding-bottom: $val-spacing-l !important;
}

.p-r-l {
	padding-right: $val-spacing-l !important;
}

.p-l-l {
	padding-left: $val-spacing-l !important;
}

.p-b-0 {
	padding-bottom: 0 !important;
}

.p-xl {
	padding: $val-spacing-xl !important;
}

// Generic margins
.m-0 {
	margin: 0 !important;
}

.m-s {
	margin: $val-spacing-s !important;
}

.m-r-s {
	margin-right: $val-spacing-s !important;
}

.m-r-m {
	margin-right: $val-spacing-m !important;
}

.m-l-0 {
	margin-left: 0 !important;
}

.m-l-s {
	margin-left: $val-spacing-s !important;
}

.m-l-m {
	margin-left: $val-spacing-m !important;
}

.m-m {
	margin: $val-spacing-m !important;
}

.m-l {
	margin: $val-spacing-l !important;
}

.m-r-l {
	margin-right: $val-spacing-l !important;
}

.m-r-0 {
	margin-right: 0 !important;
}

.m-l-l {
	margin-left: $val-spacing-l !important;
}

.m-l-xl {
	margin-left: $val-spacing-xl !important;
}

.m-t-m {
	margin-top: $val-spacing-m !important;
}

.m-t-s {
	margin-top: $val-spacing-s !important;
}

.m-t-l {
	margin-top: $val-spacing-l !important;
}

.m-t-xl {
	margin-top: $val-spacing-xl !important;
}

.m-b-0 {
	margin-bottom: 0 !important;
}

.m-b-xs {
	margin-bottom: $val-spacing-xs !important;
}

.m-b-s {
	margin-bottom: $val-spacing-s !important;
}

.m-b-m {
	margin-bottom: $val-spacing-m !important;
}

.m-l-xs {
	margin-left: $val-spacing-xs !important;
}

.m-r-xs {
	margin-right: $val-spacing-xs !important;
}

.m-t-xs {
	margin-top: $val-spacing-xs !important;
}

.m-b-l {
	margin-bottom: $val-spacing-l !important;
}

.justify-end {
	display: flex;
	justify-content: flex-end;
}

.justify-center {
	display: flex;
	justify-content: center;
}

.vertical-center {
	display: flex;
	align-items: center;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.f-s-xs {
	font-size: $val-fontSizeXSmall !important;
}

.f-s-s {
	font-size: $val-fontSizeSmall !important;
}

.f-s-m {
	font-size: $val-fontSizeRegular !important;
}

.f-s-l {
	font-size: $val-fontSizeBig !important;
}

.f-s-xl {
	font-size: $val-fontSizeXBig !important;
}

.f-s-xxl {
	font-size: $val-fontSizeXXBig !important;
}

.l-h-s {
	line-height: $val-lineHeightSmall !important;
}

.l-h-r {
	line-height: $val-lineHeightRegular !important;
}