$indicator-size: 4px;
$indicator-highlight-size: 20px;
$employee-width: 160px;

.per-diem-modal {
	&__codes-list {
		@extend .schedule-board-modal__codes-list;
	}

	&__codes-group {
		@extend .schedule-board-modal__codes-group;
	}

	&__example {
		display: flex;
		justify-content: center;
		margin-top: $val-spacing-m;
	}

	&__employee {
		padding: $val-spacing-xs $val-spacing-s;
		font-weight: bold;
		position: relative;
		background-color: $col-white;
		user-select: none;
		margin-bottom: $val-spacing-m;
		width: $employee-width;
		box-shadow: $val-boxShadow;
		-webkit-box-shadow: $val-boxShadow;
	}

	&__indicator {
		position: absolute;
		bottom: $val-spacing-xs;
		left: $val-spacing-xs;
		width: $indicator-size;
		height: $indicator-size;
		background: $col-green;

		&::after {
			content: '';
			position: absolute;
			width: $indicator-highlight-size;
			height: $indicator-highlight-size;
			border: 1px dashed $col-red;
			border-radius: 50%;
			left: calc(-#{$indicator-highlight-size} / 2 - #{$val-spacing-xxs});
			top: calc(-#{$indicator-highlight-size} / 2 - #{$val-spacing-xxs});
			background: rgba($color: $col-red, $alpha: 0.1);
		}
	}
}