.timeline-history-modal {
	
	&__container {
		display: flex;
		flex-flow: column;
	}

	&__section {
		display: flex;

		&--active {
			flex-flow: column;
		}

		&--deleted{
			flex-flow: row;
			justify-content: space-between;
		}
	}

	&__section-title {
		font-weight: bold;
	}
	
	&__entry {
		font-weight: bold;
		font-size: $val-fontSizeRegular;
		padding-left: 0;
	}

	&__entry-item {
		display: flex;

		&--start {
			justify-content: flex-start;
			padding-left: $val-spacing-l;
		}
		
		&--center {
			justify-content: center;
		}

		&--end {
			justify-content: flex-end;
		}

		&--blue {
			color: $col-blue;
		}
	}

	&__type-icon {
		font-size: $val-fontSizeXBig;
	}

	&__type {
		text-transform: uppercase;
	}

	&__expander-icon {
		font-size: $val-fontSizeXBig;
		cursor: pointer;
	}

	&__information {
		background: $col-greyLight;
		font-size: $val-fontSizeSmall;
		padding-left: $val-spacing-xxl;
		padding-right: $val-spacing-s;
		padding-top: $val-spacing-s;
	}

	&__information-row {
		padding: 0;
		padding-bottom: $val-spacing-s;
	}

	&__deleted-row {
		margin-bottom: $val-spacing-m;
	}

	&__information-item {
		padding: 0;
		
		&--end {
			display: flex;
			justify-content: flex-end;
		}

		&--bold {
			font-weight: bold;
		}

		&--deleted {
			color: $col-orange;
		}

		&--plain {
			color: $col-blue;
		}
	}

	&__information-icon {
		font-size: $val-fontSizeBig;
	}
}