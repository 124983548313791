.display-view-board-extension {
	$var-displayViewCardAddressHeight: 67px;

	background-color: $col-white;
	padding: $var-displayBoardViewPadding;
	width: 100%;
	height: 100%;

	& .display-view-card-index {
		min-height: $val-spacing-l;
		display: flex;
		justify-content: center;
		margin-bottom: $val-spacing-m;
		font-weight: bold;
		color: $col-greyFontLight;
	}

	& .display-view-work-orders-wrapper {
		background-color: $col-white;
		display: flex;
		flex-flow: row wrap;
		min-height: $var-minWorkOrderHeight;

		@for $i from 1 through 12 {
			& .display-view-work-order-wrapper-width-#{$i} {
				padding: $val-spacing-xs;
				width: calc(#{$i} * #{$var-displayBoardViewColumnSize});

				& .resource-column {
					display: flex;
					flex-flow: column nowrap;
					min-height: $var-resourceItemHeight;
					padding: $val-spacing-xs;
					position: relative;
					width: calc(100% / #{$i});
				}
			}
		}

		& .display-view-card {
			position: relative;
			box-shadow: 0 2px 2px 0 $col-shadow;

			& .project {
				border: $val-borderSize-s solid $col-design-grey10;
				border-top: $val-borderSize-m solid $col-design-grey10;
				border-bottom: $val-borderSize-m solid $col-design-grey10;
			}

			& .display-view-card-order-info {


				& .display-view-card-flags {
					background-color: $col-lighterGrey;
					border-bottom: 1px solid $col-greyConnection;
					color: $col-black;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: center;
					white-space: normal;
					font-size: $val-fontSizeSmall;
					margin-bottom: $val-spacing-xxs;

					& .display-view-card-flag {
						background-color: $col-white;
						font-size: $val-fontSizeSmall;
						font-weight: bold;
						margin: $val-spacing-xxs;
						padding-left: $val-spacing-xs;
						padding-right: $val-spacing-xs;
						border-radius: $val-borderRadius;
					}
				}

				&.internal-card {
					& .display-view-card-crew {
						background: $col-black !important;
						border-color: $col-greyDark !important;
						color: $col-white !important;
					}

					& .display-view-card-address {
						background: $col-black !important;
						border-color: $col-greyDark !important;
						color: $col-greyFontLight !important;
					}
				}

				& .display-view-card-crew {
					padding: $val-spacing-xs;
					padding-top: $val-spacing-m;
					font-weight: bold;
					text-align: center;
					border-bottom: 1px solid $col-greyConnection;

					& .display-view-card-project-code {
						font-weight: normal;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						justify-content: center;
						background-color: $col-white;
						border-radius: $val-borderRadius-l;
						font-size: $val-fontSizeXSmall;
						color: $col-black;
					}

					& .display-view-card-job-tooltip {
						width: 10px;
						height: 10px;
					}

					& .display-view-card-job-status {
						border-radius: $val-borderRadius;
						position: absolute;
						top: 0;
						left: 0;
						margin-left: $val-spacing-xs;
						margin-top: $val-spacing-xs;
						border: 1px solid $col-grey
					}

					& .display-view-card-status {
						font-weight: bold;
						display: flex;
						justify-content: center;
						padding: 0;

						& .display-board-card-status-icon {
							position: absolute;
							top: -$val-spacing-m;
							border-radius: 50%;
							background-color: $col-greyBackground;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: $val-fontSizeRegular;
						}
					}

					& .display-view-card-code {
						font-weight: bold;
						text-align: center;
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						justify-content: center;
					}
				}

				& .display-board-card-canceled {
					font-weight: normal;
					text-align: center;
					border-bottom: 1px solid $col-greyConnection;
					text-transform: uppercase;
					font-size: $val-fontSizeSmall;
					line-height: $val-lineHeightSmall;
					background-color: $col-canceledRed;
					color: $col-white;
					padding: 26px 0;
				}

				& .display-view-card-address {
					font-weight: normal;
					padding: $val-spacing-s $val-spacing-xs;
					height: $var-displayViewCardAddressHeight;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background-color: $col-greyBackground;
					border-bottom: 1px solid $col-greyConnection;
					text-transform: uppercase;
					font-size: $val-fontSizeSmall;
					line-height: $val-lineHeightSmall;

					& .display-view-card-address-content {
						overflow: inherit;
						text-overflow: inherit;
						white-space: inherit;
					}
				}

				& .display-view-card-supervisor {
					text-align: center;
					background-color: $col-greyBackground;
					border-bottom: 1px solid $col-greyConnection;
					text-transform: uppercase;
					font-size: $val-fontSizeSmall;
					line-height: $val-lineHeightSmall;
					padding: $val-spacing-xs;
					font-weight: normal;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				& .display-view-card-shop-work {
					background-color: $col-greyBackground;
					border-bottom: 1px solid $col-greyConnection;
					padding: $val-spacing-xs;

					& .shift-item {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-flow: column nowrap;
						font-size: $val-fontSizeSmall;

						& .icon-night_shift {
							font-size: $val-fontSizeXXBig;
							margin-bottom: $val-spacing-xs;
						}

						& .display-view-card-shift {
							font-size: inherit;
						}
					}
				}
			}

			& .display-view-card-resources {
				background-color: $col-greyBackground;
				min-height: $var-minCardResourcesHeight;

				& .resource-columns-wrapper {
					display: flex;
					flex-flow: row;
				}
			}

			& .display-view-card-notes {
				padding: $val-spacing-s;
				font-size: $val-fontSizeSmall;
				border-top: 1px solid $col-greyConnection;
				word-break: break-word;
			}
		}
	}
}