$rt-thumbnail-size: 50px;
$fixed-settings-dropdown-offset: 80px;

.paginationButtonContainer {
	flex-grow: initial;
	margin-left: $val-spacing-s;
}

.paginationButton {
	font-family: 'icons' !important;
	border: 1px solid $col-greyMid;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	height: $val-fieldSize;
	width: $val-fieldSize;
	position: relative;
	background: $col-greyBackground;
	border-radius: $val-borderRadius;
	color: $col-greyDark;
	font-size: $val-fontSizeBig;
}

.ReactTable {
	border: none !important;
	z-index: 1;

	&.checkbox-table {
		& input[type="checkbox"] {
			&::before {
				cursor: pointer;
				content: '';
				height: 20px;
				width: 20px;
				display: block;
				position: absolute;
				background: url("/images/inputs/ic_checkbox_inactive.svg") no-repeat center center;
			}

			&:checked::before {
				background: url("/images/inputs/ic_checkbox_active.svg") no-repeat center center;
			}
		}	
		
		& .table-settings__list {
			& input[type="checkbox"] {
				&::before {
					content: none;
					background: none;
				}
	
				&:checked::before {
					background: none;
				}
			}
		}
	}

	.-cursor-pointer .rt-resizable-header-content:after {
		background: none !important;
		content: '\e95a' !important;
		font-family: 'icons';
		right: auto !important;
		padding-left: $val-spacing-s;
		color: $col-greyFontLight;
		font-size: $val-fontSizeRegular;
	}

	.-sort-asc .rt-resizable-header-content:after {
		content: '\e958' !important;
		color: $col-greyDark;
	}
	
	.-sort-desc .rt-resizable-header-content:after {
		content: '\e956' !important;
		color: $col-greyDark;
	}

	& .pagination-bottom {
		background-color: $col-greyLight;
		border-top: 1px solid $col-greyConnection;
	}

	& .rt-tr {
		// Works for both, head and body
		& .rt-th, & .rt-td {
			&:last-child {
				flex: auto!important;
			}
		}
	}

	& .rt-thead {
		box-shadow: none !important;

		&.-header {
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 7;
		}

		& .rt-tr {
			position: relative;
			
			& > div:last-child {
				display: flex;
				justify-content: center;
			}

			& .rt-th {
				background-color: $col-greyLight;
				height: $val-headerHeight;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				font-size: $val-fontSizeSmall;
				color: $col-greyDark;
				padding: $val-spacing-m $val-spacing-l;
				border-right: none;
				position: relative;
				border-bottom: 1px solid $col-greyConnection;

				&:focus {
					outline: none !important;
				}

				&.-sort-asc, &.-sort-desc {
					box-shadow: none;
				}

				&.-cursor-pointer {
					& > div:first-child::after {
						content: "";
						position: absolute;
						height: $val-icon14;
						width: $val-icon14;
						background: url(/images/elements/ic_sort.svg) center right no-repeat;
						right: $val-spacing-s;
						top: calc(50% - #{$val-icon14} / 2);
					}
				}
			}
		}
	}

	& .rt-noData {
		background: $col-transparent;
	}

	& .rt-tbody {
		cursor: pointer;
		height: calc(100vh - 5 * #{$val-headerHeight} - #{$val-spacing-s} - #{$val-scrollBarHeight} - var(--extra-table-offset, 0px));
		overflow: initial;
		background-color: $col-greyBackground;

		& .rt-tr-group {
			user-select: none;
			flex: 0 0 auto;

			& .rt-tr {
				background-color: $col-white;

				&--darker {
					background-color: $col-greyLight !important;
				}

				&.inactive {
					background-color: $col-greyLight;

					& .rt-td {
						color: $col-greyInactive;

						& .rt-badge {
							opacity: .75;

							&.rt-badge-negative {
								color: $col-grey;
							}
						}
					}
				}

				& .rt-td {
					padding: $val-spacing-m $val-spacing-l;
					border-right: none;
					display: flex;
					align-items: center;
					height: 100%;

					& .large {
						width: $val-icon16;
						height: $val-icon16;
					}

					&.settings-cell {
						display: flex;
						flex-flow: column nowrap;
						justify-content: center;
						align-items: center;
						overflow: visible;
						padding: 0;

						.icon-actions {
							color: $col-greyDark;
						}

						.dropdown-toggle {
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	& .rt-thead .rt-tr .rt-th:first-child [type="checkbox"],
	& .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child [type="checkbox"] {
		display: flex;
		align-items: center;
		position: relative;
	}

	& .rt-tbody .rt-tr {
		background-color: $col-white;
		align-items: center;

		&:hover {
			background-color: $col-orangeHover !important;
		}

		&--non-highlighted {
			cursor: default;

			&:hover {
				background-color: $col-white !important;
			}
		}

		@media (min-width: $bp-s-high) {
			& .rt-td {
				background: none !important;
			}
		}
	}

	& .align-right {
		text-align: right !important;
		justify-content: flex-end !important;

		& * {
			text-align: right !important;
			justify-content: flex-end !important;
		}
	}
	
	& .rt-td {
		&--inverted {
			padding: 0 !important;

			div:not(.tooltip__trigger-wrapper) {
				padding: $val-spacing-m $val-spacing-l !important;
			}
		}
	}

	& .rt-resizer {
		z-index: 5;
	}
}

.rt-thumbnail {
	& > img {
		border-radius: 50%;
		min-height: $rt-thumbnail-size;
		max-height: $rt-thumbnail-size;
		max-width: $rt-thumbnail-size;
		object-fit: contain;
	}
}

.rt-expander {
	display: none !important;
}

.custom-expander {
	flex: 0 !important
}