@import "../../styles/Constants.scss";
@import "../../styles/Colors.scss";

$var-sticky-top-offset: -16px;

.form {
	display: grid;
	grid-template-columns: $val-spacing-s auto $val-spacing-s;
	background-color: $col-design-grey12;


	&__header {
		grid-column: 1 / span 3;

		background-color: $col-white;
		border-bottom: 1px solid $col-design-grey14;
		padding: $val-spacing-s $val-spacing-l;

		display: grid;
		grid-auto-flow: column;
		align-items: center;
		justify-content: space-between;

		&--sticky {
			position: sticky;
			top: $var-sticky-top-offset;
			z-index: 5;
		}
	}

	&__section {
		grid-column: 2 / span 1;

		background-color: $col-white;
		padding: $val-spacing-m;
		margin: calc($val-spacing-xs + $val-spacing-s) 0;
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

		&+& {
			margin-top: 0;
		}

	}

	&__section-title {
		font-weight: 400;
		line-height: $val-lineHeightRegular;
		color: $col-design-grey10;
		padding: $val-spacing-s calc($val-spacing-xs + $val-spacing-s);
		text-transform: uppercase;
	}
}
