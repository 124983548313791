@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$var-dropdown-width: 360px;

.tool-option {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: $val-spacing-s;

	&__type {
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
	}
}

.equipment-option {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: $val-spacing-s;
}

.item-table-footer {
	&__action, &__dropdown {
		display: flex;
		min-height: $val-spacing-xl;
		align-items: center;
		padding-left: $val-spacing-s;
		flex-direction: row;
	}

	&__action {
		color: $col-blue;
		cursor: pointer;
		width: fit-content;
	}

	&__dropdown {
		gap: $val-spacing-s;
	}
}

.item-dropdown {
	min-width: $var-dropdown-width;
	background-color: white;
}

.item-option {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $val-spacing-s;
	align-items: flex-start;
	margin-left: $val-spacing-s;

	&__details {
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
	}
}

.section {
	background-color: $col-white;
	margin-bottom: $val-spacing-s;
	padding: $val-spacing-m;
	border-bottom: 1px solid $col-greyConnection;
}

.section-title {
	color: $col-grey;
	text-transform: uppercase;
	font-size: $val-fontSizeBig;
	display: flex;
	flex-direction: column;
	margin-bottom: $val-spacing-m;
}

.container {
	background-color: $col-greyLight;
	padding: $val-spacing-s;
}

.title {
	display: flex;
	flex-direction: row;
	gap: $val-spacing-s;
	font-size: $val-fontSizeXBig;
	font-weight: bold;
}

.buttons-container {
	display: flex;
	flex-direction: row;
	gap: $val-spacing-s;
	justify-content: end;
}

@mixin label {
	background-color: $col-greyBackground;
	font-weight: bold;
	margin-right: $val-spacing-s;
	margin-bottom: $val-spacing-s;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: $val-spacing-xxs;
	padding-bottom: $val-spacing-xxs;
	min-width: 130px;
	margin-top: $val-spacing-xs;
}

@mixin forTable {
	background-color: transparent;
	border: 2px solid $col-greyConnection;
	margin-top: $val-spacing-xxs;
}

.label {
	&__new {
		@include label;
		color: $col-grey
	}

	&__open {
		@include label;
		color: black;
	}

	&__completed {
		@include label;
		color: $col-green;
	}

	&__pending {
		@include label;
		color: $col-orange;
	}

	&__new_t {
		@include label;
		@include forTable;
		color: $col-grey;
	}

	&__open_t {
		@include label;
		@include forTable;
		color: black;
	}

	&__completed_t {
		@include label;
		@include forTable;
		color: $col-green;
	}

	&__pending_t {
		@include label;
		@include forTable;
		color: $col-orange;
	}
}

.total-cost-container {
	background-color: $col-greyBackground;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
	font-weight: bold;
	padding-right: $val-spacing-m;
	padding-top: $val-spacing-s;
	padding-bottom: $val-spacing-m;
	height: 50px;
}

.field-row {
	margin-bottom: $val-spacing-l;
}

.tool-type {
	color: $col-design-grey15;
}
