@import "../mixins/Environment.scss";

$header-z-index: 7;
$main-logo-size: 36px;
$list-logo-size: 22px;

.main-header {
	position: fixed;
	background: $col-white;
	width: 100%;
	height: $val-headerHeight;
	z-index: $header-z-index;
	border-bottom: 1px solid $col-greyConnection;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px 8px 16px;

	&__companies-dropdown {
		width: $val-floatingSidebarWidthMax;
		height: $val-headerHeight;
		z-index: 2;

		&__toggle {
			background-color: $col-white !important;
			border: none;
			border-bottom: 1px solid $col-greyConnection !important;
			width: $val-floatingSidebarWidthMax;
			height: $val-headerHeight;
			padding: 0;

			&:hover,
			&:focus,
			&:active {
				background-color: $col-greyLight !important;
				border: none !important;
				border-bottom: 1px solid $col-greyConnection !important;
			}

			&__logo {
				@extend .background-circle;

				height: $main-logo-size;
				width: $main-logo-size;
				min-height: $main-logo-size;
				min-width: $main-logo-size;
				margin-left: $val-spacing-m;
				margin-right: $val-spacing-m;
				background-color: transparent !important;

				&--letter {
					background-color: $col-greyLight !important;
					color: $col-greyFontLight;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid $col-greyConnection
				}
			}

			&__data {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				overflow: hidden;
				padding-right: $val-spacing-s;
				text-align: start;

				&__user {
					color: $col-greyFontLight;
					font-size: $val-fontSizeSmall;
					line-height: $val-lineHeightSmall;
				}

				&__company {
					color: $col-greyDark;
					font-weight: bold;
					font-size: $val-fontSizeBig;
					line-height: $val-lineHeightBig;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					width: 100%;
				}
			}
		}

		&__menu {
			top: -2px !important;
			border-top: none;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			width: $val-floatingSidebarWidthMax;

			hr {
				margin: 0;
			}

			& &__companies {
				// double & required for higher specificity
				padding: 0 !important;


				&:hover {
					background: none !important;
				}
			}

			& &__item {
				cursor: pointer;
				display: flex;
				width: $val-floatingSidebarWidthMax - 2px; // remove 2px for potential horizontal scrolling issues
				height: $val-linkHeightMedium !important;
				align-items: center;
				padding: 0 !important;
				margin: 0 !important;

				[class^="icon-"] {
					font-size: $val-fontSizeBig;
					width: $list-logo-size;
					margin-left: $val-spacing-m;
					margin-right: $val-spacing-s;
				}

				&:hover {
					background: $col-orangeHover !important;
				}

				&__logo {
					@extend .background-circle;

					margin-left: $val-spacing-m;
					margin-right: $val-spacing-s;
					height: $list-logo-size;
					width: $list-logo-size;
					display: flex;
					align-items: center;
					justify-content: center;
					color: $col-greyFontLight;
					border: 1px solid $col-greyMid;
					background-color: $col-greyLight;
					font-size: 10px;
					font-weight: bold;
					line-height: 1px;
				}
			}

			& &__organization {
				margin-top: 0;
				background: $col-blueBackground !important;
				height: $val-linkHeightBig !important;


				&:hover {
					background: $col-blueBackground !important;
				}

				&__name {
					font-weight: bold;
				}
			}
		}

	}

	&--with-sidebar {
		padding-left: $val-sidebarWidthMin;
	}
}

@include envTesting {
	.main-header {
		background-color: $col-env-testing-bg-light;
	}
}

@include envStaging {
	.main-header {
		background-color: $col-env-staging-bg-light;
	}
}