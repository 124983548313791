@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';

.modal {
	&__container {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-l;
		padding: $val-spacing-m $val-spacing-s;
	}

	&__col {
		padding-left: 0px;
	}

	&__approved-warning {
		padding: $val-spacing-m $val-spacing-s;
		background-color: $col-canceledRed;
		color: $col-white;
		text-align: center;
	}

	&__search-container {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: sticky;
		top: 0;
		z-index: 5;

		&__search-input {
			width: 100%;
		}

		&__search-icon {
			right: $val-spacing-l; // Both outside border and input offset taken into account
			position: absolute;
			font-size: $val-fontSizeBig;
			color: $col-greyFontLight;
		}
	}

	&__legend {
		display: flex;
		padding: $val-spacing-m $val-spacing-s;

		&__single {
			display: flex;
			align-items: center;
			margin-right: $val-spacing-m;

			&__empty-cell {
				display: inline-block;
				height: $val-spacing-m;
				width: $val-spacing-m;
				border: 1px solid $col-greyMid;
				margin-right: $val-spacing-s;
				border-radius: $val-borderRadius;

				&--selected {
					background-color: $col-design-red4;
				}

			}

		}
	}
}

.job-report-list {

	&__container {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
	}

	&__list-container {
		gap: $val-spacing-xxs;
	}
	
	&__selected {
		color: $col-greyInactive;
		font-style: italic;
	}

	&__action-container {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-l;
	}

	&__action {
		cursor: pointer;
		color: $col-darkBlueBorder;
	}
}

.job-report-item {
	&__container {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-m;

		&--not-approved {
			background-color: $col-design-red4;
		}
	}

	&__checkbox {
		background-color: $col-white;
	}

	&__code {
		text-transform: uppercase;
		font-weight: bold;
	}

	&__info-container {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-s;
		font-size: $val-fontSizeSmall;
	}
}