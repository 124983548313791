
$per-diem-indicator-size: 4px;

.sb-resource-item {
	background-color: $col-white;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: $val-spacing-xs;
	height: $var-draggableItemHeight;
	border-radius: $val-borderRadius;
	will-change: transform;
	transition: transform 150ms ease !important;
	backface-visibility: hidden;
	perspective: 1000px;

	&::after {
		position: absolute;
		left: 0px;
		top: 0px;
		display: block;
		background-color: $col-transparent;
		content: '';
		height: 100%;
		width: 100%;
		transition: background-color 0.2s ease-in, outline 0.1s linear, outline-color 0s;
		z-index: 2;
		pointer-events: none;
	}

	&:focus {
		outline: none !important;
	}

	.loading-box-row::after {
		height: $var-draggableItemHeight;
	}

	& > a {
		// Generated automatically
		color: inherit;
	}

	&__content {
		// anchor when used on SB, content when used on display views (does not need draggable anchor)
		padding: $val-spacing-xs $val-spacing-s;
		color: inherit !important;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		height: 100%;

		&--justify-start {
			justify-content: flex-start;
		}
	}

	&__per-diem-indicator {
		position: absolute;
		bottom: 0;
		left: 0;
		height: $per-diem-indicator-size;
		width: $per-diem-indicator-size;
		background-color: $col-green;
		border-radius: 50%;
		margin: $val-spacing-xxs;
	}

	&__last-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-transform: capitalize;
	}

	&__temp-labor-icon {
		font-size: $val-fontSizeBig;
	}

	&__unique-id {
		font-size: $val-fontSizeXSmall;
		margin: 0 $val-spacing-xs;
	}

	&__full-name {
		padding: 0 $val-spacing-xxs;
		background: $col-white;
		border-radius: $val-borderRadius;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&--transparent-bg {
			background: none;
		}
	}

	&__specification {
		font-size: $val-fontSizeXSmall;
		margin: 0 $val-spacing-xs;
	}

	&__category-color-preview {
		height: $val-lineHeightRegular;
		width: $val-lineHeightRegular;
		min-width: $val-lineHeightRegular;
	}

	&__expected-return-date-picker {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: $val-inputHeight;

		&__date {
			font-size: $val-fontSizeSmall;
			font-weight: bold;
		}

		& > div {
			height: $var-draggableItemHeight;
			display: flex;
			align-items: center;
			color: $col-greyFontLight;
		}

		.icon-close {
			margin: 0;
			margin-left: $val-spacing-xs;
			font-size: $val-fontSizeBig;
			cursor: pointer;

			&:hover {
				color: $col-greyDark;
			}
		}
		
		.btn-datepicker {
			margin: 0 $val-spacing-xs;
			display: block;
			height: $val-inputHeight;
			width: calc(#{$val-inputHeight} / 2);
			border: 0;
			cursor: pointer;
			outline: none;
			display: flex;
			align-items: center;

			&:after {
				font-family: 'icons';
				content: '\e916';
				font-size: $val-fontSizeBig;
			}

			&:hover:after {
				color: $col-greyDark;
			}
		}
	}

	&__unavailability-reason {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: relative;

		.input-group-append {
			width: $var-draggableItemHeight !important;
			height: $var-draggableItemHeight !important;

			& > span {
				display: flex;
				align-items: center;
				cursor: pointer;
				width: $var-draggableItemHeight !important;
				height: $var-draggableItemHeight !important;
				font-size: $val-fontSizeXBig;
			}
		}

		.async-select-input {
			flex: 1;
		}
	}

	&--employee {
		position: relative;
		text-transform: capitalize;

		.icon-helmet_s, .icon-helmet_pm, .icon-helmet_si_pm {
			font-size: $val-fontSizeXXBig;
		}

		.icon-notification_status {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 3;
			width: $val-spacing-m;
			height: $val-spacing-m;

			&.opened {
				color: $col-green;
			}

			&.received {
				color: $col-orange;
			}

			&.error {
				color: $col-canceledRed;
			}

			&.outdated {
				color: $col-greyMid;
			}

			&.queued {
				display: none;
			}
		}

		.icon-night_shift {
			font-size: 2 * $var-badgeBorderSize + $var-badgeSize;
		}
	}

	&--placeholder {
		@extend .transparent-bg-pattern;

		border: 1px solid $col-greyBackground;
		box-shadow: $var-placeholderInnerShadow;
	}

	&--equipment {
		&.sb-resource-item--highlighted,
		&.sb-resource-item--active {
			&::after {
				outline-offset: 2px !important;
			}
		}
	}

	&--disabled {
		position: relative;
		transform: translate(2px, 2px) scale(1.1);
		box-shadow: $var-shadow;
		z-index: 1;
		background: $col-white;

		& a {
			color: $col-greyFontLight;
		}
	}

	&--highlighted {
		&::after {
			background-color: $col-orangeHighlighted;
			pointer-events: none;
			animation-name: twinkle;
			animation-duration: 2s;
			animation-iteration-count: infinite;
			border-radius: $val-borderRadius;
			outline: 2px dashed $col-orange;
			outline-offset: 1px;
		}
	}

	&--hidden {
		visibility: hidden !important;
	}

	&--no-cdl {
		background-image: $var-noCDLBackground;
		background-size: cover;
	}

	&--with-reason {
		margin-bottom: $val-spacing-s;

		&:focus {
			outline: none;
		}

		.react-datepicker-wrapper {
			margin-bottom: $val-spacing-xs;
		}

		.react-datepicker__triangle {
			left: initial;
			right: $val-spacing-s;
		}

		.form-group {
			margin: 0 !important;
			width: 100%;
			opacity: 1;
			transition: opacity .1s ease-out;

			.rbt {
				width: 100%;
				height: 100%;
			}

			& > div {
				display: flex;
				align-items: center;
			}

			.form-control {
				height: $var-draggableItemHeight !important;
				margin-bottom: $val-spacing-xs !important;
				padding-right: $var-draggableItemHeight;
			}
		}

	}

	&--with-datepicker {
		display: flex;
		align-items: center;
		justify-content: space-between;
		opacity: 1;
		transition: opacity .1s ease-out;

		.sb-resource-item--opaque {
			justify-content: flex-start;
		}
	}

	&--opaque {
		opacity: 0 !important;
	}
}