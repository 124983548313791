$max-header-title-width: 450px;
$resource-card-width: 300px;

%work-order-info-modal-section-container {
	position: relative;
	display: flex;
	flex-flow: column;
	padding-bottom: $val-spacing-l;
}

%resource-lookup-item {
	padding: $val-spacing-xs $val-spacing-s;
	font-weight: bold;
	width: $resource-card-width;
	height: $val-fieldSize;
	box-shadow: $val-boxShadow;
	-webkit-box-shadow: $val-boxShadow;
}

.work-order-info-modal {
	&__header {
		@extend .schedule-board-modal__big-header;
	}

	&__header-left {
		@extend .schedule-board-modal__big-header-left;
	}

	&__header-right {
		@extend .schedule-board-modal__big-header-right;
	}

	&__header-title {
		@extend .schedule-board-modal__big-header-title;
		max-width: $max-header-title-width;
	}

	&__header-read-only {
		font-size: $val-fontSizeXXXBig;
		color: $col-greyFontLight;
	}

	&__header-details {
		@extend .schedule-board-modal__big-header-details;
	}

	&__header-actions {
		max-width: $val-spacing-l;
		display: flex;
		justify-content: flex-start;
		flex-flow: column nowrap;

		.btn {
			min-width: initial;
			height: initial;
			padding: 0;
			margin: 0;

			&,
			&:active,
			&:hover,
			&:focus {
				color: $col-greyDark;
			}
		}

		.icon-actions {
			font-size: $val-fontSizeXXXBig;
			margin: 0;
		}
	}

	&__header-edit {
		margin-right: $val-spacing-s;
		margin-left: $val-spacing-m;
	}

	&__revisions {
		padding-bottom: $val-spacing-m;
	}

	&__revisions-dropdown-container {
		display: flex;
		justify-content: center;

		& > div {
			// Generated by the dropdown component
			width: 75%;
		}
	}

	&__revision-item {
		display: flex;
		align-items: center;

		[class^="icon-"] {
			margin-right: $val-spacing-m;
			font-size: $val-fontSizeBig;
		}
	}

	&__revision-item-details {
		color: $col-greyFontLight;
		margin-left: $val-spacing-s;
		font-size: $val-fontSizeSmall;
	}

	&__changes {
		position: absolute;
		right: $val-spacing-s;
		top: $val-spacing-s;
		display: flex;
		justify-content: flex-end;
		z-index: 1;
		color: $col-greyFontLight;
		font-size: $val-fontSizeBig;

		.color-square {
			margin-right: $val-spacing-s;
			cursor: pointer;
		}
	}

	&__general-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: $val-spacing-m;

		&--canceled {
			background: $col-canceledRed;
			color: $col-white;
			margin: -$val-spacing-l;
			margin-bottom: $val-spacing-m;
			padding: $val-spacing-l;
		}

		&--paused {
			background: $col-greyInactive;
			color: $col-white;
			margin: -$val-spacing-l;
			margin-bottom: $val-spacing-m;
			padding: $val-spacing-l;
		}

		hr + & {
			padding-top: $val-spacing-m;
		}
	}

	&__general-info-left {
		text-align: left;
	}

	&__general-info-right {
		text-align: right;
	}

	&__important-info {
		font-size: $val-fontSizeBig;
		font-weight: bold;
	}

	&__info {
		strong {
			margin-left: $val-spacing-xs;
		}
	}

	&__shift-container {
		position: relative;
	}

	&__shift {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		padding: $val-spacing-m 0;
	}

	&__work-locations {
		@extend %work-order-info-modal-section-container;
	}

	&__work-location {
		display: flex;
		justify-content: flex-start;
		font-weight: bold;
		margin-top: $val-spacing-m;

		& > div {
			// There are 7 elements in work location
			width: calc(100% / 7);
		}

		&--removed {
			color: $col-canceledRed;
		}

		&--header {
			font-weight: normal;
			margin-top: 0;
		}
	}

	&__site-contact {
		@extend %work-order-info-modal-section-container;
	}

	&__site-contact-name {
		font-size: $val-fontSizeBig;
		font-weight: bold;
	}

	&__site-contact-description {
		font-size: $val-fontSizeRegular;
		color: $col-greyFontLight;
		margin-left: $val-spacing-m;
	}

	&__site-contact-location {
		@extend .work-order-info-modal__work-location;

		& > div {
			// There are 6 elements in work location
			width: calc(100% / 6);
		}

		&--removed {
			@extend .work-order-info-modal__work-location--removed;
		}

		&--header {
			@extend .work-order-info-modal__work-location--header;
		}
	}

	&__site-contact-info-container {
		margin-top: $val-spacing-m;
	}

	&__site-contact-info {
		& + & {
			margin-top: $val-spacing-s;
		}

		&--removed {
			@extend .work-order-info-modal__work-location--removed;
		}

		.label {
			margin-right: $val-spacing-s;
		}
	}

	&__deliverables-container {
		@extend %work-order-info-modal-section-container;
	}

	&__deliverables {
		display: flex;

		& > div {
			width: calc(100% / 3);
		}
	}

	&__notes-container {
		@extend %work-order-info-modal-section-container;
	}

	&__notes {
		display: flex;

		& > div {
			width: 50%;
		}
	}

	&__report-link {
		@extend .btn--link;
		margin-top: $val-spacing-m;
		margin-bottom: $val-spacing-m;
	}

	&__status-label {
		width: fit-content;
		margin-bottom: $val-spacing-m;
	}

	&__toggle {
		@extend .btn--link;
		margin-top: $val-spacing-m;

		&--open {
			margin-bottom: $val-spacing-m;
		}

		[class^="icon-"] {
			margin-left: $val-spacing-s;
			font-size: $val-fontSizeBig;
		}
	}

	&__resources-container {
		@extend %work-order-info-modal-section-container;
	}

	&__resource-item {
		&__icon {
			font-size: $val-fontSizeBig;
		}

		&__temporary-employee {
			display: flex;
			gap: $val-spacing-s;
		}

		&--employee {
			@extend %resource-lookup-item;
		}

		&--details {
			display: flex;
			justify-content: space-between;
		}

		&--equipment {
			@extend %resource-lookup-item;
		}

		&--placeholder {
			@extend %resource-lookup-item;
			@extend .bg-transparent-pattern;

			padding: 0px $val-spacing-s;
			border: 1px solid $col-greyBackground;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&--removed {
			border: $var-removedResourceLookupBorderSize solid $col-canceledRed;
		}
	}

	&__resource-lookup-group {
		display: flex;
		flex-flow: column;

		.tooltip__trigger-wrapper {
			width: fit-content;
			// padding-right: $val-spacing-m;
		}

		.work-order-info-modal__resource-item + .work-order-info-modal__resource-item,
		.tooltip__trigger-wrapper + .tooltip__trigger-wrapper,
		.work-order-info-modal__resource-item + .tooltip__trigger-wrapper,
		.tooltip__trigger-wrapper + .work-order-info-modal__resource-item {
			// In cases where we have something wrapped with tooltip, standalone and any combination
			margin-top: $val-spacing-m;
		}
	}

	&__attachments-container {
		@extend %work-order-info-modal-section-container;
	}

	&__attachments {
		margin-left: -$val-spacing-l;
		margin-right: -$val-spacing-l;
	}

	&__production-data-container {
		@extend %work-order-info-modal-section-container;
	}

	&__production-data {
		display: flex;
		justify-content: flex-start;

		& > div {
			width: calc(100% / 3);
		}

		& + & {
			margin-top: $val-spacing-m;
		}
	}

	&__icon-text {
		margin-right: 4px;
	}

	.segment-label {
		padding: $val-spacing-l 0;
	}
}
