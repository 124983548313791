@import '../../../styles/Colors.scss';
@import '../../../styles/Constants.scss';

$var-inputWidth: 60px;
$var-inputHeight: 32px;


.pagination-footer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	background-color: $col-greyLight;
	padding: $val-spacing-m;

	&__dropdown {
		background-color: $col-white;
		margin: 0 $val-spacing-s;
		border-radius: $val-borderRadius;
	}

	& > input {
		max-width: $var-inputWidth;
		max-height: $var-inputHeight;
		border: 1px solid $col-greyMid;
		margin: 0 $val-spacing-s;
		border-radius: $val-borderRadius;
	}

	& > button {
		border: 1px solid $col-greyMid;
		background-color: $col-white;
		height: $var-inputHeight;
		width: $var-inputHeight;
		margin: 0 $val-spacing-s;
		border-radius: $val-borderRadius;
		color: $col-greyFontLight;
		display: flex;
		align-items: center;
		font-size: $val-fontSizeBig;
	}
}

.disabled {
	pointer-events: none;
}