@import "../../../../../../../src/styles/Colors.scss";
@import "../../../../../../../src/styles/Constants.scss";

$var-border: 1px solid $col-greyConnection;
$var-table-header-height: 64px;

.modal {
	width: $val-modal-xl !important;
	display: flex !important;
	justify-content: center !important;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&__big-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		overflow: initial !important;

		&::before,
		&::after {
			display: none;
		}

		&__thin {
			font-size: $val-fontSizeXBig;
			font-weight: normal;
		}
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

}

.document-preview {
	height: 500px;
	max-width: 600px;
}

.settings-actions {
	font-size: $val-fontSizeXXXBig;

	&__dropdown-toggle {
		color: $col-black;
		justify-content: center;
		cursor: pointer;

		&:focus,
		&:hover,
		&:active,
		&:active:hover,
		&:active:focus {
			color: $col-black;
		}
	}
}

input {
	&.input-text-field {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		background-color: $col-white;
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightRegular;
		outline: none;
		height: $val-fieldSize;
		width: -webkit-fill-available;
	}
}

.date-field {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	text-align: center;
	align-items: baseline;

	&__date-time {
		margin-right: 2px;
	}

	&__updated-by {
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
	}
}

.drag-drop-style {
	background-color: $col-design-orange5;
	border-color: $col-orangeBorder;
	border-style: dashed;
}

.child-color {
	background-color: $col-orange-100;
}