@import "../../../../src/styles/Colors.scss";
@import "../../../../src/styles/Constants.scss";

.icon {
	font-size: $val-icon64;
	color: $col-greyInactive;
}

.containerPadded {
	padding: $val-spacing-s;
}

.centeredRow {
	display: flex;
	justify-content: center;
}

.marginBottom {
	&__m {
		margin-bottom: $val-spacing-xl;
	}

	&__l {
		margin-bottom: $val-spacing-xxxl;
	}
}

.titleText {
	color: $col-greyInactive !important;
	font-weight: 400;
	font-size: $val-fontSizeXXBig !important;
}

.subtitleText {
	color: $col-design-grey900 !important;
	margin-bottom: 0 !important;
}