@import "../../../src/styles/Constants.scss";

$var-dropzone-height: 80px;

.dropzone-element {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: $var-dropzone-height;
	border: 2px dashed $col-greyConnection;
	color: $col-greyFontLight;
	
	&__select-files-button {
		cursor: pointer;
		margin-left: $val-spacing-m;
	}
}