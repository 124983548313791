@import '../../../../../../../styles/Constants.scss';
@import '../../../../../../../styles/Colors.scss';

.contact-label-asterisk {
	color: $col-orange;
}

.contact-menu-item {
	display: flex;
	flex-direction: column;
	gap: $val-spacing-xs;

	&__email {
		color: $col-design-grey15;
	}
}

.contact-menu-selected {
	display: flex;
	gap: $val-spacing-s;

	& > span {
		gap: $val-spacing-m;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.contact-info {
	display: flex;
	flex-direction: column;
	gap: $val-spacing-xs;
	margin-left: $val-spacing-s;
}

.contact-container {
	& + & {
		padding-bottom: $val-spacing-m;
	}


	flex: 1;
}

.contact-emails-error {
	font-size: $val-fontSizeSmall;

	& > [class^="icon-info"] {
		margin-right: $val-spacing-s;
	}
	& > span {
		color: $col-red;
	}
}
