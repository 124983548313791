// Pilled Resources are all resources pages that have a pilled format: Crew Type, Skills, Division

.resources-pilled {
	& &__list {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
	}

	&__color-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0;

		& + & {
			margin-top: $val-spacing-m;
		}
	}

	&__empty {
		color: $col-greyFontLight;
		font-weight: bold;
	}

	&__addition {
		margin-top: $val-spacing-m;
		display: flex;
	}
}