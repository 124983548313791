@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-borderRadius: 2px;
$var-imageHeight: 40px;
$var-imageMaxWidth: 200px;

.virtual-row-container {
	display: flex;
	flex-direction: column;
	margin-bottom: $val-spacing-s;
	box-shadow: $var-shadow;
	background-color: white;
}

.virtual-row-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.virtual-row-header-links {
	display: flex;
	flex-direction: row;
}

.virtual-row-header-link {
	margin-right: $val-spacing-m;
	color: $col-blue;
	margin-top: $val-spacing-m;
	cursor: pointer;
}

.virtual-row-header-name {
	font-weight: bold;
	text-transform: uppercase;
	font-size: $val-fontSizeXBig;
	padding-top: $val-spacing-xl;
	padding-left: $val-spacing-m;
}

.virtual-row-content-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.virtual-row-signatures-container {
	display: flex;
	flex-direction: column;
	background-color: $col-greyLight;
	padding: $val-spacing-m;
	margin-left: $val-spacing-m;
	flex: 2;
}

.virtual-row-signatures-header {
	text-transform: uppercase;
	font-size: $val-fontSizeRegular;
}

.original-time-container {
	display: flex;
	flex-direction: column;
	background-color: $col-greyLight;
	padding: $val-spacing-s;
	margin-left: $val-spacing-m;
	flex: 3;
}

.original-time-header {
	text-transform: uppercase;
	font-size: $val-fontSizeRegular;
	color: white;
	background-color: $col-design-blue8;
	padding: $val-spacing-s;
	margin-bottom: $val-spacing-s;
}

.time-card-container {
	display: flex;
	flex-direction: column;
	background-color: $col-greyLight;
	padding: $val-spacing-s;
	margin-left: $val-spacing-m;
	margin-right: $val-spacing-m;
	flex: 3;
}

.time-card-header {
	text-transform: uppercase;
	font-size: $val-fontSizeRegular;
	color: $col-design-gray6;
}

.time-sheet-signature-status-label{
	color: $col-greyDark;
	font-size: $val-fontSizeSmall;
	font-weight: normal;
	display: flex;
	flex-direction: row;
}

.image {
	margin-top: $val-spacing-s;
	height: $var-imageHeight;
	max-width: $var-imageMaxWidth;
	background-color: white;
	margin-right: $val-spacing-s;
	border-left: 1px solid $col-greyMid;
	border-right: 1px solid $col-greyMid;
}

.signature-name {
	margin-left: $val-spacing-xs;
}

.signature-superintendent {
	color: $col-red;
	margin: $val-spacing-s 0;
}

.temp-labor-icon {
	margin-right: $val-spacing-xs;
	font-size: $val-fontSizeXXBig;
}