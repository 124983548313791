@import "./Constants.scss";
@import "./Colors.scss";

$var-copyContainerHeight: 32px;

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.icon-html-entity {
	font-family: 'icons' !important;

	// CSS properties unsupported by html2canvas override:
	-webkit-font-variant-ligatures: normal !important;
	font-variant-ligatures: normal !important;
}

.display-none {
	display: none !important;
}

.display-inline {
	display: inline !important;
	flex-shrink: 0;
}

.text-highlight {
	background-color: $col-orange;
	color: $col-white;

	&.invert {
		color: $col-orange;
		background-color: $col-white;
	}
}

.background-circle {
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	border-radius: 50%;
}

.back-navigation {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding: $val-spacing-l 0;

	& .back-link {
		& a {
			display: flex;
			align-items: center;
			font-weight: bold;
			font-size: $val-fontSizeBig;
			line-height: $val-lineHeightBig;

			& span {
				font-weight: bold;
				font-size: $val-fontSizeBig;
				line-height: $val-lineHeightBig;
			}

			& [class^="icon-"] {
				font-size: $val-fontSizeXXBig;
			}
		}
	}
}

.press-and-hold-anchor {
	position: relative;
	height: 100%;
	width: 100%;
	display: block;
	color: inherit;

	& > * {
		width: inherit;
	}

	& .copy-container {
		overflow: hidden;
		padding: $val-spacing-xs $val-spacing-s;
		position: absolute;
		left: 0;
		right: 0;
		top: -#{$var-copyContainerHeight * 0.9};
		z-index: 1000;
		height: $var-copyContainerHeight;
		background-color: $col-white;
		display: flex;
		align-items: center;
		justify-content: center;
		will-change: opacity;
		transition: opacity 100ms linear;
		pointer-events: none;

		& .copy-progress-bar {
			will-change: transform;
			background-color: $col-green;
			transition: transform 650ms linear;
			position: absolute;
			top: 0;
			left: -100%;
			height: 100%;
			width: 100%;
			z-index: 9;
		}

		& .copy-label {
			z-index: 10;
			text-transform: uppercase !important;
			font-weight: bold !important;
			color: $col-black !important;

			& .copy-label::after {
				height: 100%;
				width: 100%;
			}
		}

		&.--hidden {
			opacity: 0;
			transition: opacity 100ms linear;

			& .copy-label::after {
				content: '';
			}
		}

		&.--visible {
			opacity: 1;
			transition: opacity 0ms linear;

			&.--in-progress {
				& .copy-progress-bar {
					transform: translateX(100%);
				}

				& .copy-label::after {
					content: 'Copying...';
				}
			}

			&.--finished {
				& .copy-progress-bar {
					transform: translateX(100%);
				}

				& .copy-label::after {
					content: 'Copied';
				}
			}
		}
	}
}

.flex-centered {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.--space-around {
		justify-content: space-around;
	}
	&.--left {
		justify-content: flex-start;
	}
	&.--right {
		justify-content: flex-end;
	}
}

.has-feedback .form-control {
	padding-right: $val-spacing-s;
}

.noappearance {
	appearance: none;
	-moz-appearance: none;
	-ms-progress-appearance: none;
	-webkit-appearance: none;
}

.label.label-default {
	background: $col-greyBackground;
	border: 1px solid $col-greyMid;
	height: 20px;
	width: 42px;
	color: $col-greyDark;
	font-size: $val-fontSizeXSmall;
	line-height: 14px;
	font-weight: 100;
	vertical-align: middle;
	border-radius: $val-borderRadius;

	&[disabled] {
		background: $col-greyLight;
		color: $col-greyFontLight;
	}
}

.horizontal-dash {
	display: inline-block;
	vertical-align: middle;
	height: 2px;
}

.checkbox label,
.radio label {
	vertical-align: middle !important;
}

.border-top {
	border-top: 1px solid $col-greyConnection;
}

.border-bottom {
	border-bottom: 1px solid $col-greyConnection;
}

.overflow-y-scroll {
	overflow: hidden;
	overflow-y: auto;
}

.link-with-icon {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-size: $val-fontSizeSmall;
	text-transform: capitalize;
	line-height: $val-fontSizeSmall;

	& [class^="a-icon"] {
		margin-right: $val-spacing-s;
	}
}

.input-filter {
	position: relative;
	display: flex;
	align-items: center;

	& input {
		background-image: url(/images/inputs/ic_search_disabled.svg);
		background-repeat: no-repeat;
		background-position: $val-spacing-s center;
		padding-left: $val-spacing-l;

		&::placeholder {
			color: $col-greyFontLight;
		}
	}

	& .clear-search {
		position: absolute;
		right: $val-spacing-s;
		width: auto;
	}
}

.font-big {
	font-size: $val-fontSizeBig;
}

.font-xBig {
	font-size: $val-fontSizeXBig;
}

.text-strong {
	font-weight: bold;
}

input::placeholder {
	color: $col-greyFontLight !important;
}

.saving-changes-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	& .saving-changes-text {
		margin-left: $val-spacing-s;
		color: $col-greyFontLight;
		font-size: $val-fontSizeSmall;
	}
}

.transparent-bg-pattern {
	background-image: linear-gradient(45deg, rgba(128, 128, 128, 0.082) 25%, transparent 25%), linear-gradient(-45deg, rgba(128, 128, 128, 0.082) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(128, 128, 128, 0.082) 75%), linear-gradient(-45deg, transparent 75%, rgba(128, 128, 128, 0.082) 75%) !important;
	background-size: 16px 16px !important;
	background-position: 0 0, 0 8px, 8px -8px, -8px 0px !important;
}

.a-icon10 {
	height: $val-icon10;
	width: $val-icon10;
	font-size: $val-icon10;
}

.a-icon12 {
	height: $val-icon12;
	width: $val-icon12;
	font-size: $val-icon12;
}

.a-icon14 {
	height: $val-icon14;
	width: $val-icon14;
	font-size: $val-icon14;
}

.a-icon16 {
	height: $val-icon16;
	width: $val-icon16;
	font-size: $val-icon16;
}

.a-icon20 {
	height: $val-icon20;
	width: $val-icon20;
	font-size: $val-icon20;
}

.a-icon24 {
	height: $val-icon24;
	width: $val-icon24;
	font-size: $val-icon24;
}

.a-icon56 {
	height: $val-icon56;
	width: $val-icon56;
	font-size: $val-icon56;
}

.square {
	display: inline-block;

	height: $val-icon16;
	width: $val-icon16;

	&--small {
		height: $val-icon12;
		width: $val-icon12;
	}
}

.rotate {
	color: #afafaf;
	-moz-animation: spin 2s 1 linear;
	-o-animation: spin 2s 1 linear;
	-webkit-animation: spin 2s 1 linear;
	animation: spin 2s 1 linear;
	animation-iteration-count: infinite;
	transform-origin: 50% 50%;
	display: block;
}

.clearfix {
	overflow: auto;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
	}
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(359deg);
	}
}

@-o-keyframes spin {
	0% {
		-o-transform: rotate(0deg);
	}

	100% {
		-o-transform: rotate(359deg);
	}
}

@-ms-keyframes spin {
	0% {
		-ms-transform: rotate(0deg);
	}

	100% {
		-ms-transform: rotate(359deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

.icon-drag_indicator {
	color: $col-greyFontLight;
}