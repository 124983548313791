@import "./Constants.scss";

.equipment-preview {
	width: 100%;
	max-width: $val-maxBoxSize;
	padding: $val-spacing-l;

	& &__label {
		text-transform: uppercase;
		color: $col-greyFontLight;
		font-size: $val-fontSizeSmall;

		margin-bottom: $val-spacing-m;
	}

}
