.document-selection-draggable-list {
	position: relative;
	display: flex;
	flex-flow: column nowrap;

	& .draggable-list-item {
		height: 30px;
		padding: $val-spacing-s $val-spacing-m $val-spacing-s $val-spacing-s;
		margin: $val-spacing-m 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 0px 2px 1px $col-greyBackground;

		& .item-name {
			display: flex;
			align-items: center;
			
			& img {
				margin-right: $val-spacing-s;
			}
		}

		&.generated {
			background-color: $col-orangeHover;		
		}
	}
}