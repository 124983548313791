$var-indent-size: 64px;
$var-indent-decorative-line-z-index: 6;

@mixin indent-line($indent) {
	position: relative;

	& .rt-table .rt-tr > div:first-child {
		padding-left: $var-indent-size * $indent !important;
	}

	&::before { // indent decorative line
		z-index: $var-indent-decorative-line-z-index;
		content: ' ';
		display: block;
		top: $val-spacing-s;
		bottom: $val-spacing-s;
		left: $val-spacing-xl * $indent;
		border-left: 1px solid $col-greyConnection;
		position: absolute;
	}
}

.indent-table {
	&__body {
		height: auto !important;
	}
	
	&__row-group {
		&---blue {
			border: 1px solid $col-darkBlueBorder;
		}
	}

	&--indent-1 {
		@include indent-line(1);
	}

	&--indent-2 {
		@include indent-line(2);
	}

	&--bold {
		font-weight: bold;
	}

	&--grey {
		background-color: $col-greyBackground !important;
	}
	
	&--blue {
		background-color: $col-blueBackground !important;
	}

	&--expanded {
		background-color: $col-blueBackgroundDark !important;
	}
}
