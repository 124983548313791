.condensed-table {
	$var-inputHeight: 20px;
	$var-actionWidth: 20px;
	$val-colorBoxSize: 12px;
	$var-inputWidth: 64px;
	$var-inputWidth-l: 88px;
	
	&__filter {
		display: flex;
		margin: $val-spacing-s 0;
		background: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		border-bottom: 1px solid $col-greyConnection;

		.icon-clear, .icon-search {
			position: absolute;
			right: $val-spacing-s;
			font-size: $val-fontSizeBig;
			color: $col-greyFontLight;
			line-height: $val-spacing-l;
		}
	}

	&__signature {
		&--signed {
			color: $col-darkBlueBorder;
		}
		&--unsigned {
			color: $col-greyMid;
		}
		&--outdated {
			color: $col-orangeBorder;
		}
	}

	&__approve-description {
		white-space: pre-wrap;
	}

	&__header {
		display: flex;
		gap: $val-spacing-m;

		background-color: $col-greyLight;
		border-top: 1px solid $col-greyConnection;
		padding: $val-spacing-l $val-spacing-m;
		color: $col-greyDark;
		font-size: $val-fontSizeSmall;
	}

	&__header-element {
		cursor: pointer;

		.icon-table_sort {
			color: $col-greyFontLight;
		}
	}

	&__card {
		user-select: none;
		display: grid;
		grid:
			"orderDetails . reviewDetails"
			"employees employees employees"
			"details . actions"
			/ auto 1fr auto
		;
		background-color: $col-white;
		margin-top: $val-spacing-s;

		&--disabled {
			.condensed-table__employee-list {
				color: rgba($col-greyDark, 0.5);
			}
			.condensed-table__employee-list-employee__review-status--approved, .condensed-table__signature--signed {
				color: rgba($col-darkBlueBorder, 0.5);
			}
			.condensed-table__signature--outdated {
				color: rgba($col-orangeBorder, 0.5);
			}
		}

		&--warning {
			grid:
				"indicator orderDetails . reviewDetails"
				"employees employees employees employees"
				"details . . actions"
				/ $var-actionWidth auto 1fr auto
			;
		}
	}

	&__work-order-details {
		grid-area: orderDetails;
		display: flex;
		flex-direction: row;
		gap: $val-spacing-l;
		padding: $val-spacing-s;

		&__code {
			font-weight: bold;
			font-size: $val-fontSizeBig;
		}
	
		&__state {
			font-weight: bold;
			margin-right: $val-spacing-m;
		}
	
		&__address {
			color: $col-greyFontLight;
		}

	}

	&__work-order-refresh-indicator {
		grid-area: indicator;
		color: $col-orangeBorder;
		align-self: start;
		font-size: $val-fontSizeXBig;
		padding: $val-spacing-s $val-spacing-xs;
		cursor: pointer;
	}

	&__review-details {
		grid-area: reviewDetails;
		display: flex;
		flex-direction: row;
		gap: $val-spacing-l;
		justify-self: end;
		padding: $val-spacing-s;
		font-size: $val-fontSizeSmall;

		.report-display-review-status-label {
			flex-flow: row;
			gap: $val-spacing-xs;
		}

		&__approved-by {
			line-height: $val-spacing-l;
		}
	}

	&__details {
		grid-area: details;
		padding: $val-spacing-s;
	}

	&__actions {
		grid-area: actions;
		display: flex;
		flex-direction: row;
		justify-self: end;
		padding: $val-spacing-s;
	}

	&__employee-list {
		grid-area: employees;
		display: flex;
		flex-direction: column;
		font-size: $val-fontSizeSmall;
	}

	&__employee-list-header, &__employee-list-item {
		display: grid;
		grid: "employee sheet equipment" / 1fr 1fr 1fr;
		padding: $val-spacing-s;

		@media (max-width: $var-screen-s-max) {
			grid: "employee" "sheet" "equipment" / 1fr;
		}
	}

	&__employee-list-header {
		& [class^="icon-"] {
			color: $col-greyFontLight;
			font-size: $val-fontSizeBig;
		}
	}

	&__employee-list-item {
		border-top: 1px solid $col-greyMid;
	}

	&__employee-list-employee {
		grid-area: employee;
		display: grid;
		grid: "message employeeName employeeAction signature status" / 24px 1fr 1fr 1fr 1fr;

		&__error-indicator {
			.icon-warning {
				font-size: $val-fontSizeRegular;
				cursor: pointer;
				color: $col-canceledRed;
			}
		}

		&__error-list {
			display: flex;
			flex-direction: column;
		}

		&__reject {
			justify-self: start;
			height: $val-spacing-l;
			font-size: $val-fontSizeXSmall;
			padding: 0 $val-spacing-s;
		}

		&__review-status {
			cursor: pointer;
			justify-self: start;

			&--pending {
				color: $col-greyMid;
			}

			&--approved {
				color: $col-darkBlueBorder;
			}

			&--rejected {
				color: $col-canceledRed;
			}
		}	
	}

	&__temp-labor-icon {
		font-size: $val-fontSizeSmall;
	}

	&__employee-list-sheet {
		grid-area: sheet;
		display: grid;
		grid: "action total job shop travel break" / $var-actionWidth 1fr 1fr 1fr 1fr 1fr;
		align-items: start;
		
		&--expanded {
			grid-template-areas:
				"action total job shop travel break"
				"entries entries entries entries entries entries";

			grid-template-columns: $var-actionWidth 1fr 1fr 1fr 1fr 1fr;
			grid-template-rows: auto 1fr;
		}

		&__total {
			grid-area: total;
		}

		&__job {
			grid-area: job;
		}

		&__shop {
			grid-area: shop;
		}

		&__travel {
			grid-area: travel;
		}

		&__break {
			grid-area: break;
		}

		&__action {
			grid-area: break;
		}

		&__entries {
			grid-area: entries;
			display: grid;
			grid: "action entry" / $var-actionWidth 1fr;

			&--readonly {
				row-gap: $val-spacing-xs;
			}
		}

		&__entry {
			display: grid;
			gap: $val-spacing-xs;
			grid-template-areas: "type date start separator end duration delete .";
			grid-template-columns: $var-inputWidth $var-inputWidth $var-inputWidth auto $var-inputWidth $var-inputWidth auto 1fr;
			
			
			&--edit {
				grid-template-columns: auto $var-inputWidth-l $var-inputWidth auto $var-inputWidth $var-inputWidth auto 1fr;
			}

			.icon-equipment,
			.icon-travel,
			.icon-home,
			.icon-break {
				color: $col-darkBlueBorder;
				cursor: default;

				&:hover {
					color: $col-darkBlueBorder;
				}
			}

			&__work-type {
				display: grid;
				gap: $val-spacing-xs;
				grid: "icon dropdown" / $val-spacing-m 1fr;
			}

			&__start-time {
				justify-self: end;
			}
		}

		& [class^="icon-"] {
			font-size: $val-fontSizeBig;
			cursor: pointer;
		}

		.icon-collapse {
			margin-bottom: $val-spacing-xs;
		}
	}

	&__employee-list-equipment {
		grid-area: equipment;
		display: flex;
		flex-flow: column;
		gap: $val-spacing-xs;

		& [class^="icon-"] {
			font-size: $val-fontSizeBig;
			cursor: pointer;
		}
	}

	&__employee-list-sheet, &__employee-list-equipment {
		.icon-delete, .icon-expand, .icon-collapse {
			color: $col-greyFontLight;
			cursor: pointer;

			&:hover {
				color: $col-greyDark;
			}
		}

		.icon-edit {
			color: $col-greyFontLight;
			cursor: pointer;

			&:hover {
				color: $col-greyDark;
			}

			&--active {
				color: $col-orangeBorder;

				&:hover {
					color: $col-orangeBorder;
				}
			}
		}
	}

	&__input {
		height: $var-inputHeight !important;
		font-size: $val-fontSizeXSmall !important;
		color: $col-greyDark !important;
	}

	&__input-wrapper {
		height: $var-inputHeight !important;

		&--error {
			border-color: $col-red;
		}
	}

	&__time-picker {
		margin-bottom: $val-spacing-xs !important;
	}

	&__time-picker-toggle {
		min-width: 0;
	}

	&__employee-list-equipment-row {
		display: grid;
		grid: "action equipmentName time classificationCode delete ." / $var-actionWidth 2fr $var-inputWidth-l 2fr auto 1fr;
		gap: $val-spacing-xs;

		&__equipmentName {
			grid-area: equipmentName;
		}

		&__time {
			grid-area: time;
		}

		&__classificationCode {
			grid-area: classificationCode;
		}

		&__equipment-color {
			display: inline-block;
			width: $val-colorBoxSize;
			height: $val-colorBoxSize;
			margin-right: $val-spacing-s;
		}

		&__specification {
			margin-left: $val-spacing-xxs;
		}
	}

	&__add-row {
		display: grid;
		grid: ". action ." / $val-spacing-m auto 1fr;
	}

	&__add-row-button {
		grid-area: action;
		display: flex;
		color: $col-blue;
		cursor: pointer;
	}
}


.review-status-tooltip {
	$var-imageHeight: 36px;
	$var-imageMaxWidth: 200px;

	display: flex;

	&__image {
		height: $var-imageHeight;
		max-width: $var-imageMaxWidth;
		background-color: $col-white;
		margin-right: $val-spacing-s;
	}

	&__info {
		display: flex;
		flex-direction: column;
	}

	&__date {
		color: $col-greyConnection;
	}

	&__name {
		color: $col-greyFontLight;
	}
}
