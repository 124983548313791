@import "./Colors.scss";
@import "./Constants.scss";

$var-autosaveHeight: 80px;
$var-connectionLineLeft: 30px;
$var-connectionLineTop: -7px;
$var-connectionLineHeight: 15px;
$var-connectionLineWidth: 1px;
$var-statusIconWidth: 21px;
$var-statusIconPadding: 6px;
$var-infoHeight: 48px;
$var-counterBubbleSize: 21px;
$var-activeUnderlineShadow: 0px -6px 0px -3px $col-orange inset;

.floating-sidenav {
	@extend .noselect;

	overflow-x: hidden;
	overflow-y: auto;
	flex: 1 0 auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	position: fixed;
	height: calc(100vh - #{$val-headerHeight});
	top: $val-headerHeight;
	left: $val-sidebarWidthMin;
	background: $col-white;
	width: $val-floatingSidebarWidthMax;
	z-index: $val-sidenavZIndex;
	align-items: left;

	& .link.info .collapse-icon {
		display: none;
	}

	& .floating-sidenav-menu {
		padding: 0;
		margin: 0;
		width: 100%;

		& > .link {
			cursor: pointer;
			list-style: none;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			position: relative;

			&:hover, &.active {
				background-color: $col-orangeHover;
			}

			& .fsn-link-sublabel {
				font-size: $val-fontSizeXSmall;
				color: $col-greyFontLight;
				font-weight: bold;
				padding-left: $val-spacing-m;
			}

			& .fsn-link-with-status {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				padding: $val-spacing-s $val-spacing-l;
				height: auto;
			}

			& .fsn-link-multirow {
				overflow: hidden;
				width: calc(100% - #{$var-statusIconWidth} - #{$val-spacing-s});
				text-overflow: ellipsis;
			}

			& a {
				display: flex!important;
				align-items: center;

				@media (max-width: $bp-xs-high) {
					height: $val-linkHeightSmall;
				}
			
				@media (min-width: $bp-s-low) and (max-width: $bp-m-high) {
					height: $val-linkHeightMedium;
				}
			
				@media (min-width: $bp-l-low) {
					height: $val-linkHeightBig;
				}
				width: 100%;

				& .counter-bubble {
					color: $col-white;
					font-size: $val-fontSizeXSmall;
					position: absolute;
					right: $val-spacing-m;
					background-color: $col-orange;
					text-align: center;
					height: $var-counterBubbleSize;
					width: $var-counterBubbleSize;
					border-radius: 50%;
					line-height: $var-counterBubbleSize;
				}

				& span {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			&.standalone a, &.action a, &.connected a {
				padding-left: $val-sidebarLeftPad;
			}

			&.action a {
				height: $val-linkHeightBig;
			}

			&.with-icon {
				& a {
					padding-left: 0;
				}

				& [class^="icon-"] {
					padding: 0 $val-spacing-m;
				}
			}

			&.action.tabbed {
				padding-bottom: $val-spacing-m;

				&:hover {
					background: none;
				}

				& a {
					padding: 0;
					justify-content: center;
					font-weight: bold;

					&.active {
						box-shadow: $var-activeUnderlineShadow;
					}
				}
			}

			// Connections between connected list items
			&.connected + .connected::before {
				content: '';
				position: absolute;
				display: block;
				height: $var-connectionLineHeight;
				width: $var-connectionLineWidth;
				background: $col-greyConnection;
				left: $var-connectionLineLeft;
				top: $var-connectionLineTop;
			}

			& .floating-sidenav-icon {
				width: $var-statusIconWidth;
				padding: $var-statusIconPadding;
				margin-right: $val-sidebarLeftPad;
				transition: all 80ms ease-in;
				background-repeat: no-repeat;
				background-position: center center;
			}

			&.floating-sidenav-search {
				border-top: 1px solid $col-greyConnection;
				border-bottom: 1px solid $col-greyConnection;
				position: relative;

				& input {
					width: 100%;
					height: $val-linkHeightSmall;
					border: none;
					outline: none;
					box-shadow: none;
					padding-left: $val-spacing-xxl * 1.1;
					background: url("/images/inputs/ic_search_disabled.svg") $val-spacing-l center no-repeat;
					font-weight: normal;
				}

				& .clear-search {
					position: absolute;
					right: $val-spacing-l;
					width: auto;
				}

				&:hover {
					background-color: initial;
				}
			}

			&.secondary-links-title {
				border-bottom: 1px solid $col-greyConnection;
			}

			& .private-link {
				color: $col-greyFontLight;

				& .collapsible-link-arrow {
					position: absolute;
					right: $val-spacing-m;
				}
			}

			&.floating-sidenav-collapser {
				align-items: center;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-around;
				height: $val-linkHeightBig;

				& div {
					color: $col-greyFontLight;
					font-size: $val-fontSizeSmall;
					font-weight: normal;
					text-transform: uppercase;
				}

				&:hover {
					background-color: initial;
				}
			}

		}

		& > .info {
			list-style: none;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			position: relative;
			height: $var-infoHeight;

			& .info-text {
				height: $val-linkHeightSmall;
				display: flex;
				align-items: center;
				padding-left: $val-sidebarLeftPad;
				font-size: $val-fontSizeSmall;
				text-transform: uppercase;
				color: $col-greyFontLight;
			}

			&:hover {
				background: none !important;
				cursor: initial;
			}
		}
	}

	& .floating-sidenav-bottom {
		position: absolute;
		display: flex;
		flex: 1 0 auto;
		justify-content: center;
		align-items: center;
		bottom: 0;
		width: 100%;
		height: $var-autosaveHeight;
		background: $col-greyLight;

		& .autosave {
			text-align: center;
			width: 100%;
			padding: 0 $val-spacing-l;

			& .btn-primary {
				width: 100%;
			}
		}
	}

	@media (max-width: $bp-xxs-high) {
		position: fixed;
		-webkit-transition: width $val-toggleAnimation;
		transition: width $val-toggleAnimation;
		will-change: width;
		border-right: 1px solid $col-greyConnection;

		&.minimized {
			width: $val-sidebarWidthMin;
			
			& .link.info {
				justify-content: center !important;

				& .info-text {
					display: none !important;
				}

				& .collapse-icon {
					margin-right: 0;
				}
			}
		}
		
		& .link > a {
			-webkit-transition: padding $val-toggleAnimation;
			transition: padding $val-toggleAnimation;
			will-change: padding;

			& span {
				text-overflow: inherit !important;
			}
		}

		& .link.info {
			justify-content: space-between !important;

			& .collapse-icon {
				display: block;
				font-size: $val-fontSizeBig;
				margin-right: $val-spacing-l;
			}
		}
	}
}

.floating-sidenav-placeholder + .app__main {
	padding-left: $val-floatingSidebarWidthMax;

	@media (max-width: $bp-xxs-high) {
		padding-left: $val-floatingSidebarWidthMin;
	}
}
