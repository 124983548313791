@import "./Constants.scss";
@import "./Colors.scss";

$var-closeModalSize: $val-fontSizeSmall;
$var-minPerDiemEmployeeWidth: 80px;
$var-accessRowHeight: 64px;

.loading-modal {
	& .modal-content {
		overflow: hidden;
		overflow-y: auto;
	}
}

.notification-preview-modal {
	& .modal-body {
		margin: $val-spacing-l;
		background: $col-greyBackground;
		font-family: 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
		border: 1px solid $col-greyConnection;
		border-left: 4px solid $col-grey;
		border-radius: $val-borderRadius;
	}
}

.daily-tip-modal {
	flex: 1;

	& .content-form {
		padding: $val-spacing-l;
		background-color: $col-greyLight;

		.input-checkbox {
			margin-top: 0;
			margin-bottom: $val-spacing-m;
		}
	}

	& .content-footer {
		padding: $val-spacing-l;
	}

	& .content-list {
		padding: $val-spacing-l;

		.blue-underline {
			color: $col-blue;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.schedule-board-template-modal {
	& .modal-body {
		padding: $val-spacing-l 0;
	}

	& .user-groups-selected-employees {
		padding: 0 $val-spacing-l;
	}
}

.work-order-note-edit {
	& .modal-header {
		border-bottom: 0;
		background-color: $col-white;
	}

	& .modal-footer {
		padding-top: 0;

		& > button {
			flex: 1;
		}
	}
}


.revision-highlight { // temporary solution, later this should be modifier of inside modal
	color: $col-orange;
	font-weight: bold;
}

.field-report-access-modal {
	&__row {
		align-items: center;
		padding: $val-spacing-m 0 !important;
		
		&--header {
			background: $col-greyBackground;
		}
		
		& + & {
			height: $var-accessRowHeight;
			border-top: 1px solid $col-greyConnection;
		}
	}

	&__name-cell {
		font-weight: bold;

	}
	
	&__dropdown-cell {
		.form-group {
			margin-bottom: 0;
		}

		.dropdown-item {
			overflow: hidden;
			white-space: break-spaces;
		}

		.dropdown-toggle {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__role-cell {
		color: $col-greyFontLight;
		font-weight: bold;
	}

	&__access-cell {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: $val-spacing-s;

		&--added {
			justify-content: space-between;
		}

		.date-input {
			width: auto;
		}
	}
}


