@import "./Constants.scss";
@import "./Colors.scss";

$containerSize: 400px;
$modalPadding: $val-spacing-l;

#image-upload {
	& .modal-dialog {
		max-width: calc(#{$containerSize} + 2 * #{$modalPadding});

		& .modal-content {
			max-width: inherit;
			margin: inherit;
		}
	}

	& .crop-container,
	& .dropzone-element {
		width: $containerSize;
		height: $containerSize;
	}

	& .modal-upload-container {
		&.--loading {
			& .crop-container {
				position: relative;
			}

			& .crop-container::before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: 'Loading...';
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $val-fontSizeSmall;
				color: $col-white;
				background-color: rgba($color: $col-black, $alpha: 0.9);
			}
		}
	}

	& .modal-upload-container {
		padding: $val-spacing-l;

		& .crop-container {
			& img {
				max-width: 100%;
			}
		}

		& .modal-upload-action {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-end;
		}

		& .cropper-actions {
			display: flex;
			flex-flow: row nowrap;
			margin-top: $val-spacing-s;

			& .cropper-action-group {
				margin-right: $val-spacing-m;
			}
		}
	}
}
