@import "../../../../../styles/Colors.scss";
@import "../../../../../styles/Constants.scss";

$var-displayStatusBorderRadius: 4px;

.label {
	display: flex;
	flex-flow: column;
	align-items: center;
	background-color: $col-greyBackground;
	border-radius: $var-displayStatusBorderRadius;
	padding: $val-spacing-xs $val-spacing-xs;
	user-select: none;
	text-transform: capitalize;
	font-size: $val-fontSizeSmall;
	font-weight: bold;
	color: $col-white;
	width: 120px;

	&__active {

		&--to-do {
			color: $col-greyFontLight;
		}

		&--in-progress {
			color: $col-blackDark;
		}

		&--pending-superintendent {
			background-color: $col-orange;
		}

		&--pending-management {
			background-color: $color-blue;
		}

		&--pending-accounting {
			background-color: $color-eggplant;
		}

		&--changes-requested {
			background-color: $col-canceledRed;
		}

		&--finalizing {
			background-color: $color-green;
		}

		&--done {
			color: $color-green;
		}

		&--submitted {
			color: $color-green;
		}
	}

	&__inactive {

		&--to-do {
			color: $col-greyFontLight;
		}

		&--in-progress {
			color: $col-blackDark;
		}

		&--pending-superintendent {
				color: $col-orange;
		}

		&--pending-management {
				color: $color-blue;
			}

		&--pending-accounting {
			color: $color-eggplant;
		}
		
		&--changes-requested {
			color: $col-canceledRed;
		}

		&--finalizing {
			color: $color-green;
		}

		&--done {
			color: $color-green;
		}

		&--submitted {
			color: $color-green;
		}
	}
	
}

.label-condensed-view {
	background-color: $col-greyBackground;
	display: flex;
	align-items: center;
	border-radius: $var-displayStatusBorderRadius;
	padding: $val-spacing-xs $val-spacing-s;
	user-select: none;
	text-transform: capitalize;
	font-size: $val-fontSizeSmall;
	font-weight: bold;
	color: $col-white;
	flex-flow: column;

	&__active {

		&--to-do {
			color: $col-greyFontLight;
		}

		&--in-progress {
			color: $col-blackDark;
		}

		&--pending-superintendent {
			background-color: $col-orange;
		}

		&--pending-management {
			background-color: $color-blue;
		}

		&--pending-accounting {
			background-color: $color-eggplant;
		}

		&--changes-requested {
			background-color: $col-canceledRed;
		}

		&--finalizing {
			background-color: $color-green;
		}

		&--done {
			color: $color-green;
		}

		&--submitted {
			color: $color-green;
		}
	}

	&__inactive {

		&--to-do {
			color: $col-greyFontLight;
		}

		&--in-progress {
			color: $col-blackDark;
		}

		&--pending-superintendent {
			color: $col-orange;
		}

		&--pending-management {
			color: $color-blue;
		}

		&--pending-accounting {
			color: $color-eggplant;
		}
		
		&--changes-requested {
			color: $col-canceledRed;
		}

		&--finalizing {
			color: $color-green;
		}

		&--done {
			color: $color-green;
		}

		&--submitted {
			color: $color-green;
		}
	}
	
}
