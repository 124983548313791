.loading-overlay {
	$var-min-width: 332px;

	.modal-content {
		min-height: unset;
	}
	.modal-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: $var-min-width;
		.loading-spinner {
			width: 48px;
			padding: $val-spacing-m 0;
			i.spinner__dot {
				width: 9px;
				height: 9px;
			}
		}
	}
	&__label {
		font-size: $val-fontSizeBig;
		text-align: center;
		margin-top: $val-spacing-m;
	}
}
