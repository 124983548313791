// NOTE: All classes in this file are strictly dictated by react-datepicker package

$datepicker-width: 258px;

.react-datepicker {

	&-wrapper {
		flex: 1;
	
		& input {
			height: $val-fieldSize;
		}
	}

	&__input-container {
		z-index: 0;
		height: $val-fieldSize;
		width: 100%;

		& .form-control {
			border-top-left-radius: $val-borderRadius !important;
			border-bottom-left-radius: $val-borderRadius !important;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;

			&.form-control--open-left {
				@extend .form-control--open-left;
			}
		}
	}

	&__day {
		&--disabled {
			color: $col-greyFontLight !important;
			cursor: not-allowed;
		}
	}

	&__close-icon {
		background: transparent url("/images/inputs/ic_close_inactive.svg") center center no-repeat;
		width: $val-inputHeight;
	}
	&__close-icon::after{
		content: none;
	}
}

.react-datepicker-popper {
	font-family: inherit;
	z-index: 11 !important;
	min-width: $datepicker-width;

	& .react-datepicker {
		padding: $val-spacing-m;
		border: 1px solid $col-greyMid;

		& .react-datepicker__month-container {
			& .react-datepicker__header {
				background: $col-white;
				margin-top: -8px;
				padding-top: 0;
				border: none;

				& .react-datepicker__day-names {
					& .react-datepicker__day-name {
						margin: 0;
						width: $val-fieldSize;
						height: $val-fieldSize;
						vertical-align: middle;
						display: table-cell;
						color: $col-greyFontLight;
						font-size: $val-fontSizeSmall;
					}
				}

				& .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
					font-size: $val-fontSizeRegular;
					font-weight: bold;
					margin-bottom: $val-spacing-m;
					line-height: $val-lineHeightRegular;
				}

				& .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;

					& .react-datepicker__month-select,
					& .react-datepicker__year-select {
						height: $val-fieldSize;
						font-size: $val-fontSizeRegular;
						outline: none !important;
						background: $col-white;
						border: 1px solid $col-greyMid;
						border-radius: 0;
						width: 100%;
						line-height: initial;
						padding: 0 $val-spacing-s;
					}

					& .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select,
					& .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
						margin: 0;
					}

					& .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
						width: 100%;
						padding-right: $val-spacing-m;
					}

					& .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
						width: 45%;
					}
				}
			}

			& .react-datepicker__month {
				display: table;
				border-collapse: collapse;
				margin: 0;

				& .react-datepicker__week {
					display: table-row;

					& .react-datepicker__day {
						display: table-cell;
						border: 1px solid $col-greyMid;
						border-radius: 0;
						width: $val-fieldSize;
						height: $val-fieldSize;
						vertical-align: middle;
						font-size: $val-fontSizeSmall;
						color: $col-greyDark;
					}

					& .react-datepicker__day--selected,
					& .react-datepicker__day--keyboard-selected,
					& .react-datepicker__day--in-range {
						background-color: $col-orange !important;
						color: $col-white;
						font-weight: normal;
					}

					& .react-datepicker__day.react-datepicker__day--outside-month {
						color: $col-greyFontLight;
					}
	
					& .react-datepicker__day.react-datepicker__day--mon.react-datepicker__day--today {
						font-weight: normal;
					}
				}
			}
		}

		& .react-datepicker__navigation {
			top: 12px;
		}

		& .react-datepicker__navigation.react-datepicker__navigation--previous {
			border-right-color: $col-black;
		}

		& .react-datepicker__navigation.react-datepicker__navigation--next {
			border-left-color: $col-black;
		}

		& .react-datepicker__today-button {
			border: 1px solid $col-greyMid;
			border-radius: $val-borderRadius;
			border-collapse: collapse;
			background-color: $col-greyLight;
			height: $val-fieldSize;
			font-size: $val-fontSizeSmall;
			border-top: none;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			font-weight: 400;
		}

		& .react-datepicker__triangle {
			border-bottom-color: $col-white !important;
		}
	}
}

.week-picker {
	& .react-datepicker__week {
		&:hover {
			background: $col-orangeHover;
		}

	}

	& .react-datepicker__day--highlighted {
		color: $col-greyFontLight;
		background: $col-orangeBackground;
	}
}

.period-picker {
	& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
		background-color: $col-orangeHover !important;
	}

	& .react-datepicker__day--highlighted {
		color: $col-greyFontLight;
		background: $col-orangeBackground;
	}
}
