@import "./Colors.scss";
@import "./Constants.scss";

$var-wrappedPaddingTop: 2px;
$var-wrappedPaddingBottom: 7px;
$var-wrappedContentPadding: 8px;
$var-contactRemoveWrapperHeight: 30px;
$var-progressBarHeight: 3px;
$var-progressBarWidth: 100px;

.contact-remove {
	height: $var-contactRemoveWrapperHeight;
}

.locked-input {
	padding-top: $val-spacing-l;

	& .row + .row {
		padding-top: $val-spacing-xl;
	}
}

.wrapped {
	padding: $val-spacing-s;
	margin-top: calc(-#{$val-spacing-s} / 2);
	border-radius: $val-borderRadius;
}

.wrapped-blue {
	@extend .wrapped;

	background: $col-blueHighlight;
	border: 1px solid $col-blueBorder;

	& .locked-input-content {
		padding-top: $var-wrappedContentPadding;
	}
}

.wrapped-black {
	@extend .wrapped;

	background: $col-greyDark;
	border: 1px solid $col-blackDark;
	color: $col-white;

	& .locked-input-content {
		padding-top: $var-wrappedContentPadding;
		color: $col-orange;
	}
}

.wrapped-grey {
	@extend .wrapped;

	background: $col-greyBackground;
	border: 1px solid $col-greyMid;

	& .locked-input-content {
		padding-top: $var-wrappedContentPadding;
	}
}

.wrapped-orange {
	@extend .wrapped;

	background: $col-orangeBackground;
	border: 1px solid $col-greyMid;

	& .locked-input-content {
		padding-top: $var-wrappedContentPadding;
	}
}

.validation-header {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.validation-header * {
	margin-left: $val-spacing-m;
}

.cancel-upload {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	margin: 0px $val-spacing-l;
	margin-bottom: $val-spacing-s;
}

.bulk-download-delete {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding: 0 $val-spacing-l;
}

.progress {
	margin: 0;
	width: $var-progressBarWidth;
	height: $var-progressBarHeight;
	
	& .progress-bar {
		background-color: $col-green;
	}
}

.checkbox-list {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	& div:first-child {
		text-align: center;
	}

	& div {
		align-self: center;
	}

	& .checkbox {
		display: inline-block;
		margin-top: 10px !important;
		margin-bottom: 10px !important;
	}

	&.not-selected {
		color: $col-greyFontLight;
	}
}