@import "./Constants.scss";
@import "./Colors.scss";

$var-headerFormMargin: 0px;
$var-importantBottom: 9px;
$var-bigIconLeeway: 80px;
$var-multipurposeSpacing: 11px;
$var-sideShadowWidth: 4px;
$var-breakdownToggleEditPadding: 11px 32px 27px 24px;
$var-breakdownTogglePreviewPadding: 16px 32px 16px 24px;

.estimate-box {
	padding: 0!important;

	& .estimate-content {
		padding: $val-spacing-l $val-spacing-s;
		margin: 0;
	}

	& .estimate-header {
		padding: $val-spacing-l $val-spacing-s;
		background: $col-greyLight;
		display: flex;
		justify-content: space-between;

		&.requirement {
			padding: $val-spacing-l $val-spacing-l $val-spacing-l $val-spacing-m;
		}

		&.blue {
			background: $col-blueBackgroundDark;
			padding-left: $val-spacing-s - $var-sideShadowWidth;
		}

		& .form-group {
			margin-bottom: $var-headerFormMargin;
		}

		& .btn {
			margin-top: $val-spacing-m;
		}
		
		& .locked-input-header {
			font-size: $val-fontSizeRegular;
			display: flex;

			& img {
				margin-left: $val-spacing-s;
			}
		}

		& .locked-input-content {
			font-size: $val-fontSizeRegular;
			font-weight: bold;
		}

		& .locked-input-important {
			font-size: $val-fontSizeBig;
			font-weight: bold;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 100%;
			overflow: hidden;
			padding-top: $val-spacing-s;
			padding-bottom: $var-importantBottom;
		}

		& .private-task-icon {
			font-size: $val-fontSizeSmall;
			color: $col-greyFontLight;
			margin-bottom: $val-spacing-s;
			margin-top: $var-headerFormMargin;
			display: flex;

			img {
				margin-right: $val-spacing-s;
				height: $var-multipurposeSpacing;
			}
		}

		& .validation-header {
			& h3 {
				margin: 0;
			}
		}

	}

	& .estimate-cost-code-attributes {
		& .locked-input-content {
			padding-bottom: $var-multipurposeSpacing;
		}

		& .wrapped-blue, & .wrapped-orange, & .wrapped-grey, & .wrapped-black {
			& .locked-input-content {
				padding-bottom: 0;
			}
		}

		& .second-row {
			margin: 0;
			padding-right: $val-spacing-s;
			margin-top: 11px;

			&.edit {
				margin-top: 0;
			}
		}

		& .last-row {
			margin: 0;
			margin-top: $var-multipurposeSpacing;
			padding-bottom: $var-multipurposeSpacing;
			padding-right: $val-spacing-s;

			& .breakdown-toggle {
				padding: $var-breakdownTogglePreviewPadding
			}

			&.edit {
				margin: 0;
				padding-bottom: 0;

				& .breakdown-toggle {
					padding: $var-breakdownToggleEditPadding;
				}
			}
		}


	}

	& .estimate-cost-code-requirements {
		& .cost-code-requirement {
			padding: $val-spacing-m;
		}
	}

	& .requirement-error {
		background: $col-greyBackground;
		padding: $val-spacing-l;
		margin: 0;
		border-top: 1px solid $col-greyConnection;
		border-bottom: 1px solid $col-greyConnection;
	}

	& .task-cost-code-select {
		margin: 0;
	}

}

.requirement-count {
	color: $col-orange;
	font-weight: bold;
	font-size: $val-fontSizeRegular;
}

.bottom-anchor {
	padding-bottom: $var-bigIconLeeway;
}

.private-task {
	color: $col-greyFontLight;
	font-size: $val-fontSizeSmall;
}