$indentation-width: 30px;
$indentation-offset: 14px;
$drag-handle-offset: 8px;

@mixin indent-line($indent) {
	position: relative;

	& .tree-table__item-content-info {
		padding-left: $indentation-width * $indent !important;
	}

	&::before {
		z-index: 2;
		content: ' ';
		display: block;
		top: 0;
		bottom: 0;
		left: ($indentation-width * ($indent - 1)) + $indentation-offset; // Magic
		border-left: 1px solid $col-greyConnection;
		height: 100%;
		position: absolute;
	}
}

.tree-table {
	padding: $val-spacing-l;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	will-change: scroll-position;
	flex-flow: column;

	&__expander {
		@extend .table-container__expander;
	}

	&__header {
		background: $col-greyLight;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		margin-bottom: 0;
		position: sticky;
		top: -$val-spacing-m;
		z-index: 3;
		border-bottom: 1px solid $col-greyConnection;
	}

	&__header-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $col-greyConnection;
		padding: $val-spacing-m $val-spacing-l;
	}

	&__header-right {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
		margin-left: $val-spacing-m;

		& > * {
			margin: 0 $val-spacing-s;
		}
	}

	&__header-left {
		flex: 1
	}

	&__match-filter {
		position: relative;
		width: 100%;

		@media (min-width: $bp-s-low) {
			max-width: $val-serachBoxMaxWidth;
		}
	}

	&__input-filter {
		min-width: $val-textFilterMinWidth;
	}

	&__match {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		line-height: $val-fieldSize;
		right: $val-spacing-m;
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
		padding: 0 $val-spacing-s;
		z-index: 1;

		& [class^="icon-"] {
			cursor: pointer;
			font-size: $val-fontSizeRegular;

			&:hover {
				color: $col-greyDark;
			}
		}
	}

	&__filter-icon {
		position: absolute;
		right: $val-spacing-s;
		top: 0;
		z-index: 1;
		font-size: $val-fontSizeBig;
		height: $val-fieldSize;
		display: flex;
		align-items: center;
		color: $col-greyFontLight;

		&--clear {
			cursor: pointer;
			&:hover {
				color: $col-greyDark;
			}
		}
	}

	&__header-titles {
		margin: 0;
		padding: 0 $val-spacing-l !important;
		border: none;
		display: flex;
		font-size: $val-fontSizeSmall;
		height: $val-headerHeight;

		& > div {
			display: flex;
			align-items: center;
		}
	}

	&__node-counter {
		color: $col-greyFontLight;
		margin-left: $val-spacing-l;
	}

	&__item {
		padding: 0 0 0 $val-spacing-l !important;
		margin: 0;
		transition: background-color .1s linear;

		&--nested {
			display: flex;
			flex-flow: row nowrap;
		}

		&--open {
			background: $col-greyLight;
		}
			
		&--highlighted {
			background-color: $col-orangeBackground;
		}

		&--focused {
			border: 1px solid $col-orangeBorder;
		}

		&--with-bottom-border {
			border-bottom: 1px solid $col-greyConnection;
		}

		&--hidden {
			display: none;
		}

		&--indent-1 {
			@include indent-line(1);
		}

		&--indent-2 {
			@include indent-line(2);
		}

		&--indent-3 {
			@include indent-line(3);
		}

		&--indent-4 {
			@include indent-line(4);
		}
	}

	&__item-inner-wrapper {
		width: 100%;
		padding-top: $val-spacing-m;
	}

	&__item-content {
		width: 100%;
		margin-bottom: $val-spacing-m;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		& > div {
			max-width: 50%;
		}
	}

	&__item-content-info {
		display: flex;
		align-items: center;
	}

	&__scrollable-icon {
		margin-left: $val-spacing-s;
	}

	&__skills-cell {
		display: flex;
		align-items: center;
	}

	&__value-cell {
		white-space: nowrap;

		&--small {
			font-size: $val-fontSizeSmall;
		}
	}

	&__drag-handle {
		margin-left: $drag-handle-offset;
		margin-right: $val-spacing-s;
		color: $col-greyFontLight;
	}

	& .scroll-to-load {
		& .tree-table__item--indent-3 {
			& .tree-table__item-inner-wrapper {
				padding: 0;
			}
	
			.tree-table__item-content{
				margin: 0;
	
				div {
					max-width: none;
				}
			}
		}
	
		&__item {
			flex: 1;
			margin-left: ($indentation-width * 2) + $indentation-offset;
			border: 0 !important;
		}
	}
}
