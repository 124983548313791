.multi-assignment {
	background: $col-orange;
	height: $var-badgeBorderSize * 2 + $var-badgeSize;
	width: $var-badgeBorderSize * 2 + $var-badgeSize;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $val-fontSizeXSmall;
	color: $col-white;
	border-radius: 50%;
	border: $var-badgeBorderSize solid $col-white;
	z-index: 3;

	&--auto-position {
		position: absolute;
		left: calc(100% - #{$var-badgeOffset});
		top: $var-badgeOffset - $var-badgeBorderSize;
	}

	&--opaque {
		opacity: 0.5;
	}
}