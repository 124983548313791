$treebeard-max-width: 400px;
$treebeard-min-width: 200px;

.treeview {
	width: fit-content;

	&__active-border {
		border: 1px solid $col-greyMid;
		border-top: none;
		border-bottom-left-radius: $val-borderRadius;
		border-bottom-right-radius: $val-borderRadius;
		position: absolute;
		z-index: 3;
		width: 100%;
		left: 0;
		background: $col-white;
	}

	&__toggle {
		width: $val-fieldSize;
		height: $val-fieldSize;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: $val-borderRadius;
		border-bottom-right-radius: $val-borderRadius;
		border: 1px solid $col-greyMid;
		border-left: none;
		background: $col-greyBackground;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $val-fontSizeBig;

		&--active {
			background: $col-blue;
			border-color: $col-blue;
		}
	}

	&__header {
		background-color: $col-greyLight;
		padding: $val-spacing-l;
		margin-bottom: 0;
		height: $val-headerHeight;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		@extend .segment-label;
	}

	&__treebeard {
		min-height: $treebeard-min-width;
		max-height: $treebeard-max-width;
		overflow-y: auto;

		// Rest is defined by react-treebeard package
		& > ul > li > .tree-node {
			& > span {
				display: flex;
				align-items: center;
			}
			
			&.opened {
				background-color: $col-greyLight !important;
			}
		}
		
		& ul {
			list-style-type: none;
			padding-left: $val-spacing-m;
			font-family: 'Roboto', sans-serif !important;
			background-color: white !important;
	
			& .treebeard-form {
				display: flex;
				margin-left: $val-spacing-m;
	
				& .form-group {
					margin-bottom: 0;
				}
			}
	
			& .tree-node {
				color: black;
				font-size: $val-fontSizeRegular;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				padding: 10px 0;
				min-height: $val-treeNodeHeight;
				
			
				& + span {
					& > ul {
						box-shadow: $var-activeLineShadow;
						background-color: $col-greyLight !important;
					}
				}
	
				& .tree-node-icon {
					display:flex;
					flex-flow: column nowrap;
					justify-content: center;
					height: 100%;
				}
	
				& .tree-node-header {
					display:flex;
					flex-flow: column nowrap;
					justify-content: center;
					height: 100%;
					width: calc(100% - #{$var-iconWidth});
	
					& .cost-code {
						display: flex;
						flex-flow: row wrap;
						justify-content: flex-start;
						align-items: center;
	
						& .metric {
							font-size: $val-fontSizeSmall;
						}
	
						& > span {
							flex: 1;
							display: flex;
							flex-flow: row nowrap;
							justify-content: flex-end;
							width: 50%;
	
						}
						& strong {
							white-space: nowrap;
							display: block;
							width: 50%;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
	
				& img {
					margin-right: $val-spacing-m;
					height: $val-spacing-m;
					width: $val-spacing-m;
				}
	
				&.active-cost-code {
					background-color: $col-orangeHighlighted;
				}
	
				.cc-category-form {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					width: 100%;
					margin-left: $val-spacing-m;
	
					& .parent-code-label {
						min-width: $val-spacing-s;
						min-height: 100%;
						font-size: $val-fontSizeBig;
						color: $col-greyInactive;
					}
	
					& img {
						width: $val-ccIconWidth;
						height: $val-ccIconHeight;
						margin: 0;
					}
				}
			}
	
			& .active-leaf {
				background-color: $col-orangeHover;
			}
	
			& .active-parent {
				font-weight: bold;
			}
		}
	}
}