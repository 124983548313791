$var-scrollbarBorderRadius: 3px;
$var-scrollbarWidth: 6px;

.custom-scrollbar {
	border-right: none;

	&::-webkit-scrollbar {
		width: $var-scrollbarWidth;
		cursor: auto !important;
	}

	&::-webkit-scrollbar-track {
		background-color: $col-greyBackground;
	}
	
	&::-webkit-scrollbar-thumb {
		border-radius: $var-scrollbarBorderRadius;
		background-color: rgba(0, 0, 0, 0.2);
	}
}