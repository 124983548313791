@import '../../../styles/Constants.scss';
@import '../../../styles/Colors.scss';

$var-resizer-width: 16px;
$var-gap-size: $val-spacing-m;

@mixin row {
	display: grid;
	align-items: start;
	gap: $var-gap-size;
	padding: 0 $val-spacing-l;
	border-bottom: 1px solid $col-design-grey14;

	&:last-child {
		border: 0;
	}
}

@mixin cell ($type) {
	font-weight: normal;
	display: flex;
	align-items: center;
	height: 100%;
	font-size: $val-fontSizeSmall;
	line-height: $val-fontSizeRegular;
	padding: $val-spacing-s 0;

	@if $type ==header {
		color: $col-greyInactive;
		font-size: $val-fontSizeSmall;
		cursor: pointer;
	}

	@if $type ==regular {
		color: $col-blackDark;
	}

	&:has(div > :global(.show.dropdown)) {
		z-index: 2;
	}

	&:has(:global(.form-group)) {
		& > div {
			margin-bottom: 0px; // form-group has margin-bottom for some reason
		}

		&:has(:global(.help-block)) {
			margin-top: $val-spacing-m;
			padding: 0;
		}
	}

	&:has(div > :global(.show.dropup)) {
		z-index: 2;
	}
}




.simple-table {
	background-color: $col-design-grey12;
	overflow-x: auto;

	&__body {
		display: grid;
		background-color: $col-design-grey12;
		border: 1px solid $col-design-grey14;
		overflow-x: auto;
	}

	&__title-row {
		padding: $val-spacing-s $val-spacing-l;
		border: 1px solid $col-design-grey14;
		border-bottom: none;
		display: flex;
		justify-content: flex-end;

		&__label {
			flex: 1;
			color: $col-design-grey10;
			text-transform: uppercase;
		}

		&__buttons {
			display: flex;
			gap: $val-spacing-s;
		}
	}

	&__empty-table-message {
		display: flex;
		color: $col-greyMid;
		font-style: italic;
		padding: $val-spacing-xs $val-spacing-l;
	}

	&__header-row {
		@include row;
	}

	&__row {
		@include row;
		background-color: $col-design-grey13;
	}

	&__cell {
		@include cell(regular);
	}

	&__header-cell {
		@include cell(header);

		&__name-with-icon {
			flex: 1;
		}
	}

	&__resizer {
		color: $col-greyInactive;
		cursor: col-resize;
		min-width: $var-resizer-width;
		max-width: $var-resizer-width;
		// placing resizer to the right most part of column cell, due to existence of gap property
		margin-right: (-$var-gap-size);
		height: 100%;
	}

	&__footer-component {
		border: 1px solid $col-design-grey14;
		border-top: 0;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: $val-spacing-xs $val-spacing-l;
		background-color: $col-design-grey12;
		border: 1px solid $col-design-grey14;
		border-top: 0;

	}

	&__footer-buttons {
		display: flex;
		gap: $var-gap-size;
	}

	&__button {
		color: $col-blue;
		cursor: pointer;

		&:hover {
			color: $col-darkBlueBorder;
		}

		& > [class^="icon-"] {
			color: $col-blue;
			margin-right: $val-spacing-s;

			&:hover {
				color: $col-darkBlueBorder;
			}
		}
	}

	&__sort-disabled {
		color: $col-disabled;
		cursor: default;
	}
}
