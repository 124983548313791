$color-picker-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.175);

.color-picker {
	width: $val-fieldSize;
	height: $val-fieldSize;
	border-radius: $val-borderRadius;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $col-greyMid;
	position: relative;

	&__label {
		@extend .input-header;
	}

	&__selection-container {
		width: $val-colorSquareSize;
		height: $val-colorSquareSize;
		cursor: pointer;
	}
	
	&__no-selection {
		width: $val-colorSquareSize;
		height: $val-colorSquareSize;
		cursor: pointer;
		position: relative;
		color: $col-greyFontLight;

		&:after {
			font-family: 'icons';
			content: "\e430";
			position: absolute;
			font-size: $val-fontSizeXBig;
			width: 100%;
			height: 100%;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:hover {
			color: $col-greyDark;
		}
	}

	&__wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 0 $val-spacing-s;
		height: $val-fieldSize;
	
		&--focused {
			& .color-picker {
				border-color: $col-orangeBorder;
			}
		}

		&--open {
			border-left: none;
			border-right: none;
			padding: 0;

			& .color-picker {
				border-left: none;
				border-radius: 0;
			}
		}
	}

	&__palette-container {
		height: auto;
		width: auto;
		-webkit-box-shadow: $color-picker-shadow;
		box-shadow: $color-picker-shadow;
		background-color: $col-white;
		position: absolute;
		z-index: 5;
		border-radius: $val-borderRadius;

		&--top {
			bottom: $val-fieldSize + $val-spacing-xs;
			left: -$val-spacing-s;
		}
	
		&--bottom {
			top: $val-fieldSize + $val-spacing-xs;
			left: -$val-spacing-s;
		}

		&--right {
			left: $val-fieldSize + $val-spacing-xs;
		}

		&--left {
			right: $val-fieldSize + $val-spacing-xs;
		}

		&--start {
			top: 0;
		}

		&--end {
			bottom: 0;
		}
	}

	&__reset {
		cursor: pointer;
		color: $col-greyFontLight;
		padding: $val-spacing-s;
		display: flex;
		align-items: center;

		& [class^="icon-"] {
			margin-right: $val-spacing-s;
			font-size: $val-fontSizeBig;
		}

		&:hover {
			color: $col-greyDark;
		}
	}

	&__palette {
		padding: $val-spacing-s;
		width: 100%;
		display: grid;
		grid-gap: $val-spacing-xs;
		grid-template-columns: repeat(8, auto);
		grid-template-rows: repeat(3, auto);
		grid-template: "brown maroon rust ocher forrestgreen ultramarine eggplant crimson" auto
						"bronze red orange gold green blue purple ruby" auto
						"tan peach apricot lemon palegreen babyblue violet cerise" auto;
	}

	&__form {
		display: flex;
		align-items: center;
		margin-right: $val-spacing-s;
		margin-bottom: $val-spacing-xs;
		height: $val-fieldSize;
	}

	&--error {
		border-color: $col-red !important;
		background: $col-redBackground;
	}
}

.selectable-color-square {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: $val-borderRadius;
	cursor: pointer;

	&__inner {
		width: $val-colorSquareSize;
		height: $val-colorSquareSize;
		border-radius: $val-borderRadius;
		border: 1px solid transparent;
	}

	&.red { grid-area: red; }

	&.brown { grid-area: brown; }

	&.bronze { grid-area: bronze; }

	&.tan { grid-area: tan; }

	&.maroon { grid-area: maroon; }

	&.red { grid-area: red; }

	&.peach { grid-area: peach; }

	&.rust { grid-area: rust; }

	&.orange { grid-area: orange; }

	&.apricot { grid-area: apricot; }

	&.ocher { grid-area: ocher; }

	&.gold { grid-area: gold; }

	&.lemon { grid-area: lemon; }

	&.forrestgreen { grid-area: forrestgreen; }

	&.green { grid-area: green; }

	&.palegreen { grid-area: palegreen; }

	&.ultramarine { grid-area: ultramarine; }

	&.blue { grid-area: blue; }

	&.babyblue { grid-area: babyblue; }

	&.eggplant { grid-area: eggplant; }

	&.purple { grid-area: purple; }

	&.violet { grid-area: violet; }

	&.crimson { grid-area: crimson; }

	&.ruby { grid-area: ruby; }

	&.cerise { grid-area: cerise; }

	&.white &__inner{
		border-color: $col-greyDark;
	}
}
