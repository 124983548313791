
.setting-dropdown {
	$var-setting-dropdown-icon-size: $val-fieldSize;

	&__toggle {
		@extend .btn--icon;

		&, &:hover, &:focus, &:hover, &:active {
			background: transparent !important;
			background-color: transparent !important;
			color: $col-greyDark !important;
		}

		&:after {
			display: none;
		}
	}

	&__menu {
		width: max-content;
		height: fit-content;
		// override inline inset:
		top: calc(50% + #{$var-setting-dropdown-icon-size} / 2) !important;
		right: calc(100% - #{$var-setting-dropdown-icon-size}) !important;
		left: auto !important;
		// override inline transform:
		transform: translate(0, 0) !important
	}
}