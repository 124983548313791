@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';

.label {
	color: $col-greyFontLight;
	padding: $val-spacing-s;
	padding-left: 0;
}

.value {
	padding-left: $val-spacing-s;
}

.status-value {
	padding-left: $val-spacing-s;
	padding-top: $val-spacing-s;
	padding-bottom: $val-spacing-l;
}
