.work-order-status-summary {
	font-size: $val-fontSizeSmall;

	&__section:nth-child(odd) {
		margin-bottom: $val-spacing-m;
	}

	&__section-title {
		margin-bottom: $val-spacing-s;
		font-weight: bold;
	}

	&__review-label-container {
		width: fit-content;
		padding-bottom: $val-spacing-s;
	}

	&__review-info {
		font-size: $val-fontSizeXSmall;
	}

	&__time-sheet {
		display: flex;
		flex-flow: row;
		gap: $val-spacing-xs;
		margin-bottom: $val-spacing-s;
	}

	&__time-sheet-label {
		font-size: $val-fontSizeXSmall;
	}
}