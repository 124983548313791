@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

.job-table{

	&__row {
		&--finished {
			background-color: $col-greyBackground !important;
			color: $col-greyFontLight;
		}
	}
}