@import "../../styles/Colors.scss";
@import "../../styles/Constants.scss";

.project-sub-job-indicator {
	font-size: $val-fontSizeRegular;
	padding: $val-spacing-xxs $val-spacing-xs;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 1px;

	&.project {
		background-color: $col-darkBlueBorder;
		color: $col-white;
	}

	&.subjob {
		background-color: $col-design-blue200;
		color: $col-design-blue500;
	}
}
