@import '../../../../../styles/Colors.scss';
@import '../../../../../styles/Constants.scss';
@import '../../../../../components/Table/styles.module.scss';

.job-work-summary {

	&__table-header {
		font-size: $val-fontSizeSmall;

		&>span {
			text-transform: uppercase;
		}

		&>b {
			margin-left: $val-spacing-s;
		}
	}

	&__sub-row-cell {
		color: $col-greyFontLight;
	}

	&__no-billing-code {
		color: $col-greyFontLight;
		font-style: italic;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__empty-cell {
		color: $col-greyFontLight;

		&--right-aligned {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}

	&__review-status-cell {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&--draft {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-greyFontLight;
			}
		}

		&--in-progress {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-orange;
				font-weight: bold;
			}
		}

		&--outdated {
			[class^="icon-dot_outline"] {
				margin-right: $val-spacing-s;
				color: $col-orange;
				font-weight: bold;
			}
		}

		&--reviewed {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-darkBlueBorder;
				font-weight: bold;
			}
		}

		&--completed {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-green;
				font-weight: bold;
			}
		}

		&--sub-row-cell {
			color: $col-greyFontLight;
		}

		&>b {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.table-container__table__body {
		height: calc(100vh - $var-company-header-height - $var-breadcrumbs-header-height - $var-tabs-navigation-height - $var-table-header-height - $var-table-action-header-height - $var-table-footer-height - 4 * $val-spacing-xl - 6 * $val-spacing-m);
	}
}

.work-summary-table {
	height: calc(100vh - $var-company-header-height - $var-breadcrumbs-header-height - $var-tabs-navigation-height - $var-table-header-height - $var-table-action-header-height - $var-table-footer-height - 4 * $val-spacing-xl - 6 * $val-spacing-m) !important;
}