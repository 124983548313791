.report-type {
	padding-bottom: 0;
	display: flex;

	& >.tooltip-wrapper {
		width: 100%;
	}

	.report-block {
		width: 100%;
	}

	&__toggle {
		padding: $val-spacing-m;
		display: flex;
		align-items: center;
		border-right: 1px solid $col-greyConnection;
		border-bottom: 1px solid $col-greyConnection;

		&--primary {
			background: $col-blueBackgroundDark;
		}

		&--secondary {
			background: $col-greyLight;
		}

		&--locked {
			background: $col-orangeHover;
		}

		&--locked-by-other {
			background: $col-greyConnection;
		}

		&--rejected {
			background: $col-redBackground;
		}
	}

	&__primary {
		.report-block {
			border-left: $val-spacing-xs solid $col-darkBlueBorder;
			border-bottom: 1px solid $col-greyConnection;
		}

		&--margin-top {
			margin-top: $val-spacing-m;
		}

		&--margin-bottom {
			margin-bottom: $val-spacing-m;
		}
	}

	&__secondary {
		margin-left: $val-spacing-m;

		.report-block {
			border-top: none;
		}
	}

	&__comment {
		border: none;
		font-size: $val-fontSizeBig;
		color: $col-greyFontLight;
		cursor: pointer;

		&--locked {
			color: $col-grey;
		}

		&--self-locked {
			color: $col-orange;
		}

		&--rejected {
			color: $col-canceledRed;
		}
	}
}