$val-column-break-width: 767px;

.locked-input {
	&__header {
		font-weight: normal;
		display: flex;
	}

	&__content {
		white-space: pre-wrap;
		font-weight: bold;

		&--redText {
			color: $col-red
		}

		&--normalText {
			font-weight: normal;
		}
	}

	&__header, &__content {
		.tooltip__trigger-wrapper {
			// allow proper positioning of content wrapped in tooltips (such as tooltip icon-help_fill)
			align-items: inherit;
		}
	}

	&__inline-content {
		display: flex;

		div {
			margin-right: $val-spacing-s;
		}
	}

	&__header + &__content {
		@media (max-width: $val-column-break-width) {
			padding-bottom: $val-spacing-s;
		}
		@media (min-width: $val-column-break-width + 1px) {
			padding-top: $val-spacing-s;
		}
	}

	&__addition {
		color: $col-greyFontLight;
		margin-left: $val-spacing-s;
	}

	&__color {
		display: flex;
		align-items: center;
	}

	&__pills {
		padding-top: $val-spacing-s;
		& .pills {
			padding: 0;
		}
	}

	&__marginated {
		margin-bottom: $val-spacing-xs;
	}

	&__label {
		&--boldText {
			font-weight: bold;
		}

		&__required {
			color: $col-orange;
		}
	}

}

.locked-input-one-row {
	display: flex;
	flex-flow: row;
	gap: $val-spacing-s !important;

	&__header {
		color: $col-greyFontLight;
	}

	&__content {
		font-weight: normal;
	}

	&__header + &__content {
		padding: 0 !important;
	}
}