.work-order-copy-modal {
	&__codes-list {
		@extend .schedule-board-modal__codes-list;
	}

	&__codes-group {
		@extend .schedule-board-modal__codes-group;
	}

	&__date-selection {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-left: $val-spacing-l;

		.react-datepicker-wrapper {
			.react-datepicker__input-container {
				width: 100%;
			}
		}

		.checkbox-inline {
			padding-top: $val-spacing-s;
			padding-left: 0;
		}
	}

	&__single-date {
		padding-left: 0;
	}

	&__work-ordering {
		display: flex;

		.icon-info {
			display: flex;
			align-items: center;
			margin-left: $val-spacing-s;
			padding-top: $val-spacing-s;
			font-size: $val-fontSizeXBig;
		}
	}

	&__work-days {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		& > label {
			margin-right: $val-spacing-l;

			& + label {
				margin-left: 0 !important;
			}
		}

		.text-danger {
			margin-left: $val-spacing-l;
			margin-top: $val-spacing-m;
		}
	}
}
