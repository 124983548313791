$preview-card-width: 200px;
$no-CDL-background: repeating-linear-gradient(45deg, $col-white, $col-white 5px, $col-orange 5px, $col-orange 10px);
$hovered-partial-z-index: 3;
$curtain-z-index: 2;
$per-diem-dimensions: 4px;
$crew-type-height: 58px;
$work-order-information-height: 26px;
$customer-info-height: 69px;

.legend-modal {
	&__body {
		overflow-anchor: none;
	}

	&__segment {
		display: flex;
		flex-flow: column;
		padding-bottom: $val-spacing-m;

		.segment-label {
			padding-top: 0;
		}
	}

	&__segment-content {
		display: flex;
	}

	&__resource-card-container {
		background: $col-greyBackground;
		padding: $val-spacing-m;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__resource-card {
		height: $val-fieldSize;
		width: $preview-card-width;
		display: flex;
		justify-content: space-between;
		background: $col-white;
		align-items: center;
		border-radius: $val-borderRadius;
		position: relative;
		padding: 0 $val-spacing-s;

		&--employee {
			background: $no-CDL-background;
		}

		&--with-cdl {
			background: $col-white;
		}

		&--equipment {
			background: $color-lemon;
		}

		&--placeholder {
			@extend .bg-transparent-pattern;
		}

		.icon-night_shift {
			font-size: 2 * $var-badgeBorderSize + $var-badgeSize;
		}

		.icon-notification_status {
			position: absolute;
			left: 0;
			top: 0;

			&--border {
				color: $col-white;
				font-size: $val-fontSizeXBig;
			}
		}

		&+& {
			margin-top: $val-spacing-m;
		}

	}

	&__resource-card-curtain {
		background: $col-whiteShadow;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: $curtain-z-index;
	}

	&__resource-card-label {
		padding: 0 $val-spacing-xs;
		font-weight: bold;

		&--employee {
			color: $color-purple;
			background: $col-white;
		}

		&--equipment {
			color: $col-greyDark;
		}

		&--specification {
			color: $col-greyDark;
			font-size: $val-fontSizeXSmall;
		}
	}

	&__description-container {
		display: flex;
		flex-flow: column;
		padding-left: $val-spacing-l;
	}

	&__description {
		font-weight: bold;
		padding: $val-spacing-s 0;
		display: flex;
		align-items: baseline;
		cursor: pointer;

		.btn--control {
			margin-left: $val-spacing-s;
		}

		&--details {
			font-weight: normal;
			padding: 0 0 0 $val-spacing-m;
			color: $col-greyFontLight;
			font-size: $val-fontSizeSmall;
		}

		&--additional {
			font-weight: normal;
			padding-left: $val-spacing-m;
			padding-top: 0;
			display: flex;
			align-items: center;

			b {
				margin: 0 $val-spacing-xs;
			}

			.status-icon--small,
			.icon-helmet_pm,
			.icon-helmet_s,
			.icon-helmet_si_pm,
			.icon-night_shift {
				margin-right: $val-spacing-s;
			}

			.icon-night_shift {
				font-size: $val-fontSizeXBig;
			}
		}
	}

	&__hovered-partial {
		z-index: $hovered-partial-z-index;

		.icon-helmet_pm,
		.icon-helmet_si_pm,
		.icon-helmet_s {
			font-size: $val-fontSizeXXXBig;
		}
	}

	&__opaque-partial {
		opacity: 0.5;
	}

	&__per-diem {
		height: $per-diem-dimensions;
		width: $per-diem-dimensions;
		position: absolute;
		left: $val-spacing-xs;
		bottom: $val-spacing-xs;
		background: $col-green;
	}

	&__work-order-card-container {
		background: $col-greyBackground;
		padding: $val-spacing-m;
		display: flex;
		align-items: center;
	}

	&__work-order-card {
		width: $preview-card-width;
		display: flex;
		flex-flow: column;
		background: $col-white;
		border-radius: $val-borderRadius;
		position: relative;
		border-top-right-radius: $val-borderRadius;
		border-top-left-radius: $val-borderRadius;
		box-shadow: $val-boxShadow;
		-webkit-box-shadow: $val-boxShadow;

		&--project {
			border: $val-borderSize-s solid $col-design-grey10;
			border-top: $val-borderSize-m solid $col-design-grey10;
			border-bottom: $val-borderSize-m solid $col-design-grey10;
		}
	}

	&__work-order-card-curtain {
		background: $col-whiteShadow;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -1px;
		right: 0;
		z-index: $curtain-z-index;
	}

	&__crew-type {
		height: $crew-type-height;
		background: $col-orange;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: $val-spacing-m;
		padding-bottom: $val-spacing-xs;
		border-top-right-radius: $val-borderRadius;
		border-top-left-radius: $val-borderRadius;

		&--internal {
			background: $col-black;
			color: $col-white;
			border-color: $col-greyDark;
		}
	}

	&__project-code {
		font-weight: normal;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background-color: $col-white;
		border-radius: $val-borderRadius-l;
		font-size: $val-fontSizeXSmall;
		color: $col-black;
		padding: 0 $val-spacing-s;
	}

	&__production-data {
		background: $col-greyMid;
		border-top: 1px solid $col-greyConnection;
		border-bottom: 0.5px solid $col-greyConnection;
		height: $work-order-information-height;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $val-fontSizeSmall;

		&--internal {
			background: $col-black;
			color: $col-white;
			border-color: $col-greyDark;
		}
	}

	&__customer-info {
		background: $col-greyBackground;
		border-top: 0.5px solid $col-greyConnection;
		border-bottom: 0.5px solid $col-greyConnection;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $val-fontSizeSmall;
		padding: $val-spacing-s 0;
		text-transform: uppercase;
		text-align: center;
		height: $customer-info-height;

		&--canceled {
			background: $col-canceledRed;
			color: $col-white;
		}

		&--locked {
			background: $col-blue;
			color: $col-white;
		}

		&--paused {
			background: $col-greyInactive;
			color: $col-white;
		}

		&--internal {
			background: $col-black;
			color: $col-white;
			border-color: $col-greyDark;
		}
	}

	&__sipm {
		background: $col-greyBackground;
		border-top: 0.5px solid $col-greyConnection;
		border-bottom: 0.5px solid $col-greyConnection;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $val-fontSizeSmall;
		padding: $val-spacing-xs;
	}

	&__shift {
		background: $col-greyBackground;
		border-top: 0.5px solid $col-greyConnection;
		border-bottom: 0.5px solid $col-greyConnection;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: $val-spacing-xs;

		.night-shift-badge {
			font-size: $val-fontSizeXXBig;
			margin-bottom: $val-spacing-xs;
		}

		&__time {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: $val-fontSizeSmall;
		}
	}

	&__work-order-status {
		position: absolute;
		top: -16px;
		left: 0;
		right: 0;
		margin: auto;
		display: flex;
		justify-content: center;
		z-index: $hovered-partial-z-index;
	}

	&__flags {
		background-color: $col-lighterGrey;
		border-bottom: 1px solid $col-greyConnection;
		color: $col-black;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		white-space: normal;
		font-size: $val-fontSizeSmall;
		margin-bottom: $val-spacing-xxs;
	}

	&__flag {
		background-color: $col-white;
		font-size: $val-fontSizeSmall;
		font-weight: bold;
		margin: $val-spacing-xxs;
		padding-left: $val-spacing-xs;
		padding-right: $val-spacing-xs;
		border-radius: $val-borderRadius;
	}

	&__job-status-box {
		width: 10px;
		height: 10px;
		border-radius: $val-borderRadius;
		position: absolute;
		top: 0;
		left: 0;
		margin-left: $val-spacing-xs;
		margin-top: $val-spacing-xs;
		border: 1px solid $col-white;
		background-color: $col-grey;
	}

}