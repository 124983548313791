@import "../../../styles/Colors.scss";
@import "../../../styles/Constants.scss";

.table-settings {
	&__item {
		display: flex;
		margin: $val-spacing-s 0;
		display: flex;
		align-items: center;

		&__label {
			color: $col-greyFontLight;
			margin-left: $val-spacing-m;
			font-style: italic;
		}
	}

	.icon-settings {
		cursor: pointer;
		color: $col-greyFontLight;

		&:focus,
		&:active,
		&:hover {
			color: $col-greyDark;
		}
	}
}