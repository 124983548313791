$min-draggable-location-width: 120px;
$var-minDraggableLocationHeight: 36px;

.locations-table {
	&__droppable-container {
		display: inline-flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&__draggable-container {
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		margin-bottom: $val-spacing-s;
		flex: 0 0 auto;
		align-items: center;

		& [class^="icon-"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
	}
}