$image-button-size: 92px; // Half of 200px image width and margin between them (16px)

.image-upload {

	&__buttons {
		display: flex;
		padding-top: $val-spacing-s;
	}

	&__button {
		width: $image-button-size;

		& + & {
			margin-left: $val-spacing-m;
		}

		& [class^="icon-"] {
			margin: 0;
		}

		&--with-wrapper {
			margin-left: $val-spacing-m;
		}
	}

	&__row {
		display: inline-block;
	}
}