.segment-label-container {
	display: flex;
	align-items: center;

	& [class^="icon-"] {
		font-size: $val-fontSizeRegular;
		margin-left: $val-spacing-s;
	}
}

.segment-label {
	font-size: $val-fontSizeSmall;
	text-transform: uppercase;
	color: $col-design-grey11;
	padding: $val-spacing-l 0;
	user-select: none;

	&--reduced-top {
		padding-top: $val-spacing-s;
	}

	&--reduced-all {
		padding-top: 0;
		padding-bottom: $val-spacing-s;
	}

	&--link {
		color: $col-blue;

		&:hover {
			color: $col-blueHover;
		}
	}

	&--stretched {
		width: 100%;
	}
}

.label-with-color {
	display: flex;
	align-items: center;

	.color-square {
		margin-right: $val-spacing-s !important;
	}
}

.label-with-boolean {
	display: flex;
	align-items: center;

	.icon-check,
	.icon-close {
		margin-right: $val-spacing-s;
		font-size: $val-fontSizeBig;
	}

	.icon-check {
		color: $col-green;
	}

	.icon-close {
		color: $col-red;
	}

	.icon-lock {
		color: $col-blue;
	}

	.icon-pause {
		color: $col-grey;
	}
}

.control-label {
	margin-bottom: $val-spacing-s;
	line-height: $val-lineHeightRegular;
	font-size: $val-fontSizeRegular;
	font-weight: normal;
	display: block;
}

.small-label {
	font-size: $val-fontSizeSmall;
	color: $col-greyFontLight;
	vertical-align: middle;
}

.status-label {
	display: flex;
	align-items: center;

	&__icon {
		font-size: $val-fontSizeSmall !important;
		margin-right: $val-spacing-s;

		&--available {
			color: $col-green;
		}

		&--unavailable {
			color: $col-orange;
		}

		&--down {
			color: $col-grey;
		}
	}

	&__bold {
		font-weight: bold;
	}
}

.no-data-label {
	color: $col-greyFontLight;
	font-weight: bold;
}

.label-with-icon {
	display: flex;
	gap: $val-spacing-xxs;
}