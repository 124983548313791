@import "../../../../src/styles/Colors.scss";
@import "../../../../src/styles/Constants.scss";

$var-border: 1px solid $col-greyConnection;
$var-table-header-height: 64px;

.tree-table {
	background: $col-white;
	border-radius: $val-borderRadius;

	.tree-table-row {
		padding: $val-spacing-m $val-spacing-l;
		display: flex;
		align-items: center;
		border-bottom: $var-border;
	}

	.category-name{
		display: flex;
		align-items: center;
	}

	.directory-attachment-icon {
		user-select: text;
		font-size: $val-fontSizeXXBig;
	} 

	.directory-attachment-name {
		margin-left: $val-inputPadding-tb
	}

	.expand-icon-cell {
		font-size: large;
	}

	.cell-flex-05 {
		flex: 0.5;
		justify-content: center;
		display: flex;
	}

	.cell-flex-1 {
		flex: 1;
		justify-content: center;
		display: flex;
	}

	.cell-flex-2 {
		flex: 2;
		justify-content: center;
		display: flex;
	}

	.cell-flex-3 {
		flex: 3;
		justify-content: center;
		display: flex;
	}

	.upload-icon,
	.download-icon,
	.preview-icon {
		width: 15px;
		font-size: 20px;
		transition: color 0.1s;
		cursor: pointer;

		&:hover {
			color: $col-blue;
		}
	}


	.linked-wo-list-container {
		color: $col-blue;
		justify-content: left;

		.expander {
			cursor: pointer;

			.expander-icon {
				margin-right: $val-spacing-s;
			}

			.wo-counter {
				margin-left: $val-spacing-s;
			}
		}

		.wo-list {
			margin-top: $val-spacing-s;

			.wo-link {
				color: $col-blue;
				display: block;

				.external-icon {
					margin-right: $val-spacing-s;
				}
			}
		}
	}
}

.modal {
	width: $val-modal-xl !important;
	display: flex !important;
	justify-content: center !important;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&__big-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		overflow: initial !important;

		&::before,
		&::after {
			display: none;
		}

		&__thin {
			font-size: $val-fontSizeXBig;
			font-weight: normal;
		}
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.img-preview {
		max-height: 500px;
		max-width: 600px;
	}
}

.settings-actions {
	font-size: $val-fontSizeXXXBig;

	&__dropdown-toggle {
		color: $col-black;
		justify-content: center;
		cursor: pointer;

		&:focus,
		&:hover,
		&:active,
		&:active:hover,
		&:active:focus {
			color: $col-black;
		}
	}
}

input {
	&.input-text-field {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		background-color: $col-white;
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightRegular;
		outline: none;
		height: $val-fieldSize;
		width: -webkit-fill-available;
	}
}

.drag-drop-style {
	background-color: $col-design-orange5;
	outline-color: $col-orangeBorder;
	outline-style: dashed;
}

.child-color {
	background-color: $col-orange-100;
}