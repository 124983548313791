$var-shadow: 1px 1px 2px $col-shadow;
$main-block-height: 86px;
$main-block-height-empty: 54px;
$var-calculated-block-header-height: 20px;
$var-block-list-title-height: 5%;

.report-block-form-container {
	display: flex;
	flex-direction: row;
}

.report-block-form {
	padding: $val-spacing-l;

	&__sticky-sidebar {
		position: sticky;
		top: 0;
		height: calc(100vh - $val-headerHeight - 2 * $val-spacing-m);
		background-color: $col-white;
		z-index: 2;
		box-shadow: $var-shadow;

		&__content {
			height: calc(90% - $val-spacing-l);
			margin-top: $val-spacing-l;
			overflow: auto;
		}
	}

	&__block-list {

		&__title {
			height: $var-block-list-title-height;
			padding: $val-spacing-m;
		}

		&__list {
			height: calc(80% - $val-spacing-l);
			margin-top: $val-spacing-l;
			overflow: auto;
		}

		&__filter {
			padding: $val-spacing-m;
			height: 15%;

			&__search-filter {
				position: relative;
				display: flex;

				.input-group-append {
					color: $col-greyFontLight;
					background: transparent;
					border: none;
					position: absolute;
					right: 0;
					margin-right: $val-spacing-m;

					& .icon-close {
						cursor: pointer;
						font-size: $val-fontSizeXBig;
					}
				}
			}
		}
	}

	&__block_option {
		display: flex;
		flex-direction: row;
		align-items: center;

		&__uniqueId {
			font-size: $val-fontSizeSmall;
			margin-left: $val-spacing-xs;
			color: $col-greyFontLight;
		}
	}

	.report-type {
		padding-bottom: $val-spacing-l;
		display: initial;
	}

	&+& {
		padding-top: 0;
	}

	&--single-block {
		.report-block {
			background-color: $col-greyLight;
		}
	}

	&--calculated-draggable-block {
		padding: $val-spacing-m;
	}

	&__legend {
		padding-bottom: $val-spacing-l;
	}

	&__title {
		@extend .segment-label;
		padding: $val-spacing-m;
		font-size: $val-fontSizeRegular;
		height: $var-block-list-title-height;
		display: flex;
		align-items: center;

		&--padding-top {
			padding-top: $val-spacing-l;
		}

		.icon-help_fill {
			margin-left: $val-spacing-xs;
			font-size: $val-fontSizeBig !important;
			color: $col-greyFontLight;

			&:hover {
				cursor: pointer;
				color: $col-greyDark;
			}
		}
	}

	&__fields-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $col-greyLight;
		padding: $val-fontSizeBig $val-fontSizeXXBig;
		min-height: $val-headerHeightXL;
		border-bottom: 1px solid $col-greyConnection;
		text-transform: uppercase;

		&--subtitle {
			color: $col-greyFontLight;
			font-size: $val-fontSizeSmall;
			margin-top: $val-spacing-xs;
		}

		&--regular {
			height: $val-headerHeight;
		}

		&--primary {
			background-color: $col-blueBackground;
			color: $col-darkBlueBorder
		}
	}

	&__header-buttons {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-end;
		margin-left: $val-spacing-s;
	}

	&__header-title {
		font-weight: bold;
		font-size: $val-fontSizeBig;
	}

	&__header-icon {
		cursor: pointer;
	}

	&__draggable-field {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-weight: bold;

		&+& {
			margin-top: $val-spacing-m;
		}

		.icon-drag_indicator {
			cursor: grab;
		}

		.report-block-form__draggable-label,
		.icon-delete {
			cursor: pointer;
		}

		&--reorder-disabled {
			& .report-block-form__draggable-label {
				cursor: unset;
			}
		}
	}

	&__draggable-label {
		user-select: none;
		background-color: $col-greyBackground;
		padding-left: $val-spacing-m;
		padding-right: $val-spacing-s;
		-webkit-box-shadow: $var-shadow;
		box-shadow: $var-shadow;
		flex: 1;
		display: flex;
		min-height: $val-fieldSize;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		position: relative;

		&--stretched {
			justify-content: space-between;
			flex-direction: row;
			align-items: center;
			width: 100%;
		}

		&--danger {
			background-color: $col-redBackground;
		}

		&--expandable {
			display: block;
			height: auto;
		}

		&--main {
			background-color: $col-blueBackground;
		}

		&--calculated-block {
			background-color: $col-white;
			border: 2px solid $col-greyConnection;
		}

		&--non-padded {
			padding: 0;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&__name {
			&--main {
				font-weight: bold;
				color: $col-darkBlueBorder;
			}
		}

		&__uniqueId {
			font-size: $val-fontSizeSmall;
			color: $col-greyFontLight;

			&--main {
				font-weight: bold;
				color: $col-blue;
			}
		}

		&__icon {
			font-size: $val-icon20;
			cursor: pointer;
		}

		&__expanded-segment {
			padding: $val-spacing-s $val-spacing-m;
			line-height: $val-lineHeightBig;
			background-color: $col-white;
			width: 100%;
			z-index: 2;
			color: $col-greyDark;
			font-weight: normal;
		}

		&__type-info {
			margin-left: $val-spacing-s;
			color: $col-greyFontLight;
			text-transform: uppercase;
			font-weight: normal;
		}

		&__field_info {
			display: flex;
			align-content: center;
		}

		.icon-help_fill {
			font-size: $val-fontSizeBig !important;
			color: $col-greyFontLight;
			margin-right: $val-spacing-m;
			margin-top: $val-spacing-xxs;

			&:hover {
				cursor: pointer;
				color: $col-greyDark;
			}
		}
	}

	&__expander {
		display: flex;
		align-items: center;


		&__label {
			color: $col-greyFontLight;
			font-weight: bold;
			font-size: $val-fontSizeSmall;
		}

		& [class^="icon-"] {
			font-size: $val-fontSizeBig;
			margin-left: $val-spacing-xs;
			color: $col-greyDark;
		}
	}

	&__add-button-container {
		display: flex;
		justify-content: flex-start;
		padding: 0 $val-spacing-l;

		&--padded {
			padding-bottom: $val-spacing-l;
		}
	}

	&__droppable-container {
		margin-top: (-$val-spacing-m);
		margin-bottom: (-$val-spacing-m);
		margin-right: (-$val-spacing-m);
		margin-left: (-$val-spacing-m);

		padding-top: ($val-spacing-m);
		padding-bottom: ($val-spacing-m);
		padding-right: ($val-spacing-m);
		padding-left: ($val-spacing-m);

		&--empty {
			margin: 0;
			margin-right: (-$val-spacing-m - 1px);
			margin-left: (-$val-spacing-m - 1px);
			border: 1px dashed $col-greyConnection;
			min-height: 120px;
			align-items: center;
			justify-content: center;
			display: flex;
			flex-direction: column;
			cursor: pointer;
			user-select: none;
		}

		&--hover {
			margin-top: (-$val-spacing-m - 1px);
			margin-bottom: (-$val-spacing-m - 1px);
			margin-right: (-$val-spacing-m - 1px);
			margin-left: (-$val-spacing-m - 1px);
			border: 1px dashed $col-greyConnection;
		}

		&--main {
			border-color: $col-darkBlueBorder;
			height: $main-block-height;
			min-height: $main-block-height-empty;

			&.report-block-form__droppable-container--empty {
				height: $main-block-height-empty;
			}

			.report-block-form__droppable-container__action-description {
				color: $col-blue;
			}
		}

		&--main-expanded {
			margin-bottom: 2 * $val-fieldSize;
		}

		&__action-link {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color: $col-blue;
			cursor: pointer;
			margin: 0 $val-spacing-l $val-spacing-l 0;
		}

		&__action-description {
			display: flex;
			align-items: center;
			color: $col-greyConnection;
			cursor: inherit;

			& [class^="icon-"] {
				font-size: $val-fontSizeBig;
				margin-right: $val-spacing-xs;
			}
		}
	}

	&__icon {
		font-size: $val-fontSizeBig !important;
		margin-right: 0 !important;
		margin-left: $val-spacing-s;
		color: $col-greyFontLight;
		cursor: pointer;

		&:hover {
			color: $col-greyDark;
		}

		&--hidden {
			display: none;
		}
	}

	&__draggable-indicator {
		margin-right: $val-spacing-s !important;
		margin-left: -$val-spacing-xs;
	}

	&__submit-container {
		padding: $val-spacing-l;
		padding-top: 0;
		display: flex;
		justify-content: flex-end;
	}

	&__block_group {
		&+& {
			margin-top: $val-spacing-m;
		}

		&--empty {
			color: $col-greyFontLight;
			font-style: italic;
			text-align: center;
			font-size: $val-fontSizeBig;
			padding: $val-spacing-m 0;
			user-select: none;
			-webkit-user-select: none;
		}
	}

	&--open-bottom {
		padding-bottom: 0;
	}

	&__billable-work {
		padding: 0 $val-spacing-l;

		&__title-container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__title {
				font-size: $val-fontSizeBig;
				padding: $val-spacing-l 0;
				font-weight: bold;
			}

			&>[class^='icon'] {
				cursor: pointer;
				font-size: $val-fontSizeXBig;
			}
		}

		&__actions {
			display: flex;
			justify-content: flex-start;

			&__add {
				display: flex;
				align-items: center;
				color: $col-darkBlueBorder;
				padding: $val-spacing-m 0;

				&>[class^='icon'] {
					font-size: $val-fontSizeBig;
					margin-right: $val-spacing-s;
				}
			}

		}

		&__item {
			display: flex;
			align-items: center;
			font-weight: bold;
			padding: $val-spacing-s 0;

			&__work-info {
				align-items: center;
				text-transform: uppercase;
				flex: 1;

				&__row {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					white-space: nowrap;

					& [class^="icon-"] {
						cursor: pointer;
						margin-left: $val-spacing-s;
						font-size: $val-fontSizeBig;
					}

					&__definition-field {
						font-weight: normal;
						text-transform: none;
						margin-right: $val-spacing-s;
					}
				}

			}

			&__highlight-button {
				cursor: pointer;

				&--highlighted {
					color: $col-blue;
				}
			}
		}

		&__list {
			&__item {
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;

				&__name {
					flex: 1;
				}

				&__delete-action {
					font-size: $val-fontSizeBig;
					padding: $val-spacing-m;
				}
			}
		}
	}

	&__warning {
		color: $col-red;
	}
}

.report-block-form-field-modal {
	&__grouped {
		padding: 0 $val-spacing-s;
	}

	&__dimension-dropdown {
		top: auto;
		bottom: $val-fieldSize;
	}

	&__option-title {
		padding-bottom: $val-spacing-s;
	}

	&__options {
		padding: $val-spacing-m;
		background-color: $col-greyLight;
	}

	&__option-actions {
		display: flex;
		justify-content: space-between;
	}
	
	&__actions {
		display: flex;
		gap: $val-spacing-m;
	}
	
	&__option-item {
		display: flex;
		align-items: center;
	}

	&__calculation-dropdown {
		background-color: $col-white;
	}

	&__dropdown-option {
		text-align: left;
		height: 100%;
		width: 100%;
		padding: $val-spacing-s $val-spacing-xxs;

		&--primary {
			background-color: $col-blueBackground;
			color: $col-darkBlueBorder;
		}

		&--secondary {
			background-color: $col-greyLight;
		}

		&__item {
			display: inline-block;

			&--meta {
				padding-left: $val-spacing-s;
				font-size: $val-fontSizeSmall;
				color: $col-greyFontLight;
			}

			&--block {
				text-transform: uppercase;
				font-weight: bold;
				padding-right: $val-spacing-s;
			}
		}
	}

	&__image-upload-container {
		margin-top: $val-spacing-m;
		margin-bottom: $val-spacing-m;
		display: flex;
		flex-flow: column;
		gap: $val-spacing-m;
		align-items: center;
	}
}

.report-block-modal {
	& .modal-dialog .modal-content {
		overflow-y: visible !important;
	}
}

.report-settings-tabs {
	margin: 0 $val-spacing-s;
	background-color: $col-greyLight !important;
	border: 1px solid $col-greyConnection !important;
	border-bottom: 0 !important;
}

.report-table {

	&__block-row {
		&--main {
			border-left: $val-spacing-xs solid $col-darkBlueBorder;
		}

		&--not-main {
			border-left: $val-spacing-xs solid $col-greyConnection;
		}
	}

	&__field-count {
		display: inline-block;

		&--left {
			padding-right: $val-spacing-xs;
		}

		&--right {
			padding-left: $val-spacing-xs;
		}
	}

	&__tooltip {

		&--title {
			margin-bottom: $val-spacing-s;
		}

		&--field-type {
			color: $col-greyFontLight;
		}
	}
}

.billable-work-modal {

	&__definition-fields-section,
	&__information-fields-section {
		&__title {
			margin-top: $val-spacing-xxl;
			margin-bottom: $val-spacing-l;
			font-size: $val-fontSizeRegular;
			line-height: $val-lineHeightRegular;
			color: $col-greyFontLight;
		}

		&__field-container {
			display: flex;
			flex-direction: row;
			align-items: flex-start;

			&__dropdown {
				flex: 1;

				&__dropdown-option {
					text-align: left;
					height: 100%;
					width: 100%;
					padding: $val-spacing-s $val-spacing-xxs;
					display: flex;
					flex-flow: column;

					&--primary {
						background-color: $col-blueBackground;
						color: $col-darkBlueBorder;
					}

					&--secondary {
						background-color: $col-greyLight;
					}

					&__item {
						display: inline-block;

						&--meta {
							font-size: $val-fontSizeSmall;
							color: $col-greyFontLight;
						}

						&--block {
							text-transform: uppercase;
							font-weight: bold;
							padding-right: $val-spacing-s;
						}
					}
				}
			}

			&__delete-option {
				margin-left: $val-spacing-s;
				padding: $val-spacing-s;
				font-size: $val-fontSizeBig;
				color: $col-greyFontLight;
				cursor: pointer;
			}
		}

		&__add-action {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: $val-fontSizeRegular;
			color: $col-darkBlueBorder;
			cursor: pointer;

			.icon-plus {
				margin-right: $val-spacing-s;
			}
		}
	}
}