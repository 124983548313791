@import "../../../../src/styles/Colors.scss";
@import "../../../../src/styles/Constants.scss";

$var-border: 1px solid $col-greyConnection;
$var-table-header-height: 64px;

.tree-table-header {
	background: $col-white;
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
	height: $var-table-header-height + 1px; // Accomodate input field
	padding: $val-spacing-m $val-spacing-l;
	border-bottom: $var-border;
	background-color: $col-greyLight;
}

.expand-icon {
	flex: 0.5;
	font-size: large;
	text-align: center;
}

.cell-flex-1 {
	flex: 1;
	font-size: small;
	text-align: center;
}

.cell-flex-2 {
	flex: 2;
	font-size: small;
	align-items: center;
	text-align: center;
}

.cell-flex-3 {
	flex: 3;
	font-size: small;
	align-items: center;
	text-align: center;
}

.cell-flex-4 {
	flex: 4;
	font-size: small;
	align-items: center;
	text-align: center;
}

.cell-flex-5 {
	flex: 5;
	font-size: small;
	align-items: center;
	text-align: center;
}

.cell-flex-6 {
	flex: 6;
	font-size: small;
	align-items: center;
}

.row-actions {
	flex: 5;
	font-size: large;
	align-items: center;
	margin: 10px;
}
