.deliverable-data {
	&__container {
		@include text-and-color-resource-color-list;

		& .text-and-color-resource-preview {
			margin-right: 0;
			.tooltip__trigger-wrapper {
				justify-content: space-between;
				width: 100%;
			}
		}

		& .text-and-color-form {
			display: flex;
			align-items: flex-start;

			& .form-group {
				margin-bottom: $val-spacing-s;
			}
		}

		& .create-tag {
			margin-bottom: $val-spacing-l;
			& .form-group {
				margin-bottom: 0;
			}
		}
	}

	&__type-list {
		flex: 1;
		padding: 0 $val-spacing-m;
	}

	&__type-label {
		@extend .segment-label;
		padding-top: 0 !important;
		padding-bottom: $val-spacing-s !important;
	}
}
