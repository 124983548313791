$title-width: 160px;
$inline-title-padding: 40px;
$image-field-size: 50px;
$image-modal-min-height: 500px;
$completion-offset: 124px;
$var-search-outline-offset: 1px;
$var-animation-duration: 2s;
$var-outline-border: 2px;
$var-screen-l-min: 1600px;
$var-screen-m-max: $var-screen-l-min - 1px;
$var-screen-m-min: 1230px;
$var-screen-s-max: $var-screen-m-min - 1px;
$var-image-field-height: 36px;

@mixin blockMixin($var-columnCount) {
	grid-template-columns: repeat($var-columnCount, calc(100% / #{$var-columnCount}));

	.report-block__array-elements__element {
		padding: 0 $val-spacing-s;
		grid-column-start: auto;

		&--largest {
			// Setting on RB/RT preview to same as 'large' since both take the whole row
			grid-column-end: span 4;
		}

		&--large {
			grid-column-end: span 4;
		}

		&--medium {
			grid-column-end: span 3;
		}

		&--small {
			grid-column-end: span 2;
		}

		&--very-small {
			grid-column-end: span 1;
		}

		&--adjustable {
			grid-column-end: span 2;
		}

		&--with-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: $val-spacing-m;

		}

		.report-block_with_icon {
			width: $val-icon64;
			height: $val-icon64;
			font-size: $val-icon64;
		}
	}
}

.report-block {
	margin: 0;
	border: 1px solid $col-greyConnection;

	&+& {
		border-top: none;
	}

	&--collapsed {
		border-bottom: none !important;

		&.report-block--main {
			border-bottom: 1px solid $col-greyConnection !important;
		}
	}

	&--merged {
		border-bottom: none !important;

		&:not(:nth-of-type(2)) {
			.report-block__fields {
				padding-top: 0;
			}
		}

		&:not(:last-of-type) {
			.report-block__fields {
				padding-bottom: 0;
			}
		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-transform: uppercase;
		font-size: $val-fontSizeBig;
		font-weight: bold;
		padding: $val-spacing-m;
		padding-left: $val-spacing-l;
		width: $title-width;

		&__count {
			margin-left: $val-spacing-s;
		}

		&__hide-row {
			cursor: pointer;
		}

		&__name {
			display: flex;
			align-items: center;
		}

		&__toggle {
			font-size: $val-fontSizeRegular;
			cursor: pointer;
			text-transform: none;
		}

		[class^="icon-"] {
			font-size: $val-fontSizeBig;
			margin-left: $val-spacing-s;
			cursor: pointer;
		}

		.btn--icon [class^="icon-"] {
			margin: 0;
		}

		.icon-delete {
			color: $col-darkBlueBorder !important;
		}

		&--collapsed {
			padding-bottom: $val-spacing-m !important;
		}

		&--main {
			color: $col-darkBlueBorder;
			padding-bottom: 0;
			padding-top: $val-spacing-l;
			width: auto;

			.icon-delete {
				color: $col-greyInactive;

				&:hover {
					color: $col-greyDark;
				}
			}
		}

		&--rejected {
			background-color: $col-redBackground;
		}

		&__counter {
			color: $col-greyDark;
			font-size: $val-fontSizeRegular;

			[class^="icon-"] {
				border: none;
				font-size: $val-fontSizeRegular;
			}
		}
	}

	&__title-name {
		display: flex;
		align-items: center;

		.icon-delete,
		.icon-lock {
			position: relative;
			bottom: $val-spacing-xxs;
		}
	}

	&__empty-preview {
		font-size: $val-fontSizeBig;
		color: $col-greyFontLight;
		font-style: italic;
		text-align: center;
		padding: $val-spacing-m;

	}

	&__comment-container {
		position: relative;
		display: flex;
		align-items: center;
	}

	&__field-group {
		display: flex;
		align-items: center;
		flex-flow: column;
		width: 100%;

		.input-group-append {
			width: $val-fieldSize;
			padding: 0;
			text-align: center;
		}

		&--border-bottom {
			border-bottom: 1px solid $col-greyConnection;
		}
	}

	&__hidden-fields {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
		cursor: pointer;
	}

	&__fields {
		flex: 1;
		padding: $val-spacing-m;
		padding-bottom: $val-spacing-s;
		width: 100%;

		&--preview {
			padding-top: $val-spacing-l !important;
			padding-bottom: $val-spacing-m;
		}
	}

	&__array {
		display: flex;
		align-items: center;

		&--padded {
			padding-bottom: $val-spacing-l;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	&__array-elements-container {
		width: 100%;
		display: flex;
	}

	&__array-image-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		img {
			height: $var-image-field-height;
			width: $var-image-field-height;
		}
	}

	&__array-elements {
		display: grid;
		flex: 1;

		&--settings-preview {
			@include blockMixin(4);
		}

		&--field-report {
			@media (min-width: $var-screen-l-min) {
				@include blockMixin(12);
			}

			@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
				@include blockMixin(8);
			}

			@media (max-width: $var-screen-s-max) {
				@include blockMixin(4);
			}
		}

		&--padded-right {
			padding-right: $val-spacing-l; // matches the width of .report-block__array-icon
		}
	}

	&__icon {
		width: $val-icon24;
		height: $val-icon24;
		font-size: $val-icon24;

		&--primary {
			color: $col-orange;
		}
	}

	&__array-icon {
		cursor: pointer;
		font-size: $val-fontSizeBig !important;
		color: $col-greyInactive;
		width: $val-spacing-l;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		left: $val-spacing-xs;

		&:hover {
			color: $col-greyDark;
		}
	}

	&__field-title {
		display: flex;
		align-items: center;

		.icon-help_fill {
			margin-left: $val-spacing-xs;
			font-size: $val-fontSizeBig !important;
			color: $col-greyFontLight;

			&:hover {
				cursor: pointer;
				color: $col-greyDark;
			}
		}
	}

	&__image-field {
		margin-bottom: $val-spacing-m;

		&__title {
			margin-bottom: $val-spacing-s;
		}

		&__upload {
			border: 2px dashed $col-greyConnection;
			padding: $val-spacing-l;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $col-greyFontLight;
			user-select: none;
			cursor: pointer;

			&--highlighted-red {
				background-color: $col-redBackground;
			}
		}

		[class^="icon-"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
	}

	&__input-field {
		&--highlighted-red {
			background-color: $col-redBackground !important;
		}

		&--highlighted-blue {
			border: 2px solid $col-blue !important;
			border-radius: $val-borderRadius !important;
			scroll-margin-top: $val-spacing-l;
			scroll-margin-bottom: $val-spacing-s;
		}

		&--no-label {
			margin-top: $val-fontSizeRegular;
		}

		&--no-label+span {
			margin-top: $val-fontSizeRegular;
		}

		&__icon-location_pin {
			font-size: $val-icon20;
		}
	}


	&__completion-status {
		justify-content: flex-end;
		margin-right: $val-spacing-s !important;
	}

	&__boolean-field {
		width: fit-content;
		margin-bottom: $val-spacing-s;

		&--highlighted-blue {
			border: 2px solid $col-blue !important;
			border-radius: $val-borderRadius !important;
			scroll-margin-top: $val-spacing-l;
			scroll-margin-bottom: $val-spacing-s;
		}
	}

	&__textarea-field {
		min-height: $val-fieldSize !important;

		&--highlighted-red {
			background-color: $col-redBackground !important;
		}

		&--highlighted-blue {
			border: 2px solid $col-blue !important;
			border-radius: $val-borderRadius !important;
			scroll-margin-top: $val-spacing-l;
			scroll-margin-bottom: $val-spacing-s;
		}
	}

	&__time-picker-field {
		background-color: $col-white;

		&--highlighted-red {
			background-color: $col-redBackground !important;
		}
	}

	&__calculated-field {
		margin-bottom: $val-spacing-s;

		&--highlighted-blue {
			border: 2px solid $col-blue !important;
			border-radius: $val-borderRadius !important;
			padding: $val-spacing-xs;
			scroll-margin-top: $val-spacing-l;
			scroll-margin-bottom: $val-spacing-s;
		}
	}

	&__descriptive-text {
		color: $col-red;
		white-space: pre-wrap;

		&--black-text {
			color: $col-black;
		}

		&--bold-text {
			font-weight: bold;
		}
	}

	&__signature-field {
		display: flex;
		align-items: center;
		gap: $val-spacing-s;

		&__signature-image {
			height: $var-image-field-height;
		}
	}

	&__add-new {
		width: fit-content;

		&,
		&:active {
			@extend .btn--link;
			margin-left: $val-spacing-s;
			padding-bottom: $val-spacing-s;
		}

		&--disabled {

			&,
			&:hover {
				@extend .btn--link-disabled;
			}
		}
	}

	&__preview-block {
		margin-bottom: $val-spacing-s;
	}

	&__preview-value {
		text-transform: uppercase;
	}

	&__icon-field {
		display: flex;
		align-items: center;

		[class^="icon-"] {
			font-size: $val-icon64;
			margin-bottom: $val-spacing-m;
			margin-right: $val-spacing-m;
		}
	}

	&__completion-box {
		display: flex;
		justify-content: flex-end;
		padding-top: 0;
		padding-right: $val-spacing-l;
		padding-bottom: $val-spacing-m;
		width: 100%;

		&--offset {
			margin-right: $completion-offset;
		}
	}

	&__completion-preview {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&--primary {
		background: $col-blueBackground;

		&.report-block--main {
			border-right: none;
		}
	}

	&--secondary {
		background: $col-white;

		&.report-block--main {
			border-right: none;
			background: $col-greyLight;
		}
	}

	&--inline {
		display: flex;
		padding-top: $val-spacing-m;

		.report-block__field-group {
			flex: 1;
		}

		.report-block__fields {
			padding-top: $val-spacing-l;
		}

		.report-block__title {
			align-items: flex-start;
			padding-top: $inline-title-padding;
		}

		&.report-block--collapsed {
			display: none;
		}
	}

	&--hidden {
		.report-block__title {
			align-items: center;
			padding-top: $val-spacing-m; // Matches the initial padding, just needs to be explicitly defined to override --inline's override
		}
	}

	&--small {
		padding-top: 0;
		display: flex;
		flex-flow: column;

		.report-block__title {
			padding-bottom: 0;
			padding-top: $val-spacing-m;
		}

		.report-block__title--main.report-block__title--collapsed {
			border-bottom: 1px solid $col-greyConnection;
		}
	}

	&--rejected {

		&,
		.report-block__title,
		.report-block__title--main,
		.report-block__field-group {
			background-color: $col-redBackground;
		}
	}

	&--locked {

		&,
		.report-block__title,
		.report-block__title--main,
		.report-block__field-group {
			background-color: $col-greyConnection;
		}

		.report-block__title-name {
			color: $col-greyDark;
		}


		.icon-lock {
			color: $col-greyDark !important;
		}
	}

	&--self-locked {

		&,
		.report-block__title,
		.report-block__title--main,
		.report-block__field-group {
			background-color: $col-orangeHover;
		}

		.report-block__title-name,
		.icon-lock {
			color: $col-orange;
		}
	}

	&--full-row-click {
		cursor: pointer;
	}

	&--standalone {
		border-bottom: none !important;

		.report-block__title {
			padding-top: $val-spacing-m;
		}

		&+.report-block {
			border-top: none;
		}
	}

	&--highlighted {
		animation-name: twinkle;
		animation-duration: $var-animation-duration;
		animation-iteration-count: infinite;
		border-radius: $val-borderRadius;
		outline: $var-outline-border dashed $col-orange !important;
		outline-offset: $var-search-outline-offset;
	}

	&--search-active {
		animation-name: twinkle;
		animation-duration: $var-animation-duration;
		animation-iteration-count: infinite;
		border-radius: $val-borderRadius;
		outline-offset: $var-search-outline-offset;
		outline: $var-highlightedActiveOutline solid $col-orange !important;

		&,
		.report-block__title,
		.report-block__title--main,
		.report-block__field-group {
			background-color: $col-orangeHighlighted;
		}
	}
}

.image-field {
	&__container {
		width: $image-field-size;
		height: $image-field-size;
		margin-bottom: $val-spacing-m;
	}

	&__image {
		cursor: pointer;
	}

	&__empty {
		width: 100%;
		height: 100%;
		border: $val-spacing-xxs dashed $col-greyConnection;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $col-greyFontLight;
		-webkit-user-select: none;
		user-select: none;
		cursor: pointer;

		&--disabled {
			cursor: none;
			border: $val-spacing-xxs dashed $col-grey;
		}
	}
}

.image-field-modal {

	&__header {
		display: flex;
		justify-content: flex-end;
	}

	&__body {
		min-height: $image-modal-min-height;
	}

	&__footer {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__field-name {
		font-size: $val-fontSizeBig;
	}

	&__delete {
		cursor: pointer;
	}

	&__image {
		width: 100%;
		height: 100%;
	}
}