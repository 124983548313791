@import '../../../styles/Constants.scss';
@import '../../../styles/Colors.scss';

$val-action-header-height: 2 * $val-spacing-m;

@mixin icon {
	position: absolute;
	right: $val-spacing-s;
	font-size: $val-fontSizeBig;
	color: $col-greyFontLight;
}

.action-header {
	display: flex;
	padding: $val-spacing-m $val-spacing-l;
	border-bottom: 1px solid $col-greyConnection;
	background-color: $col-greyLight;

	&__filters {
		display: flex;
		width: 50%;
		height: $val-action-header-height;

		&__search-input {
			flex: 1;
			position: relative;
			display: flex;
			align-items: center;
			margin-right: $val-spacing-m;

			& > input {
				height: 100%;
				width: 100%;
				min-width: $val-textFilterMinWidth;
				border: 1px solid $col-greyMid;
				border-radius: $val-borderRadius;

				&::placeholder {
					color: $col-greyFontLight;
				}

				&[placeholder*="by"] {
					min-width: 160px;
				}
			}

			& > div {
				& > [class^="icon-search"] {
					@include icon;
				}

				& > a {
					& > [class^="icon-close"] {
						@include icon;
						top: 8px;
					}
				}
			}

			&--stretch {
				flex: 1;
			}
		}


		&__buttons {
			width: 50%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: $val-spacing-s;
		}

	}

	&__extended-filters {
		width: 100%;
	}

}