$field-title-offset: 23px;
$clickable-area-extension: $val-spacing-l;

input[type=checkbox] {
	@extend .noappearance;

	height: 0;
	padding: 0;
	margin: 0;
	color: $col-greyMid;

	&:checked {
		color: $col-orange;
	}

	font-size: $val-fontSizeRegular;
	display: flex;
	align-items: center;
	margin: 0;
}

// Our class
.input-checkbox {
	font-size: $val-fontSizeXBig;
	display: flex;
	align-items: center;
	margin: 0;
	height: $val-fieldSize;

	&:before {
		// This is the actual checkbox
		color: $col-greyMid;
		margin-left: 0 !important;
	}

	&__label {
		font-family: "Roboto", sans-serif !important;
		color: $col-greyDark;
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightRegular;
		font-weight: bold;
		display: flex;
		align-items: center;

		&--capitalize {
			font-weight: normal;
			text-transform: capitalize;
		}

		&--small {
			font-size: $val-fontSizeSmall;
		}

		&--disabled {
			color: $col-greyFontLight;
		}

	}

	&--checked:before {
		color: $col-orange;
	}

	&--standalone {
		padding: 0;
		margin-top: 0 !important;
	}

	&--standalone-row {
		padding: 0;
		margin-top: $val-spacing-s !important;
	}

	&--condensed {
		margin-left: 0 !important;
	}

	&--desaturated {
		filter: grayscale(100%);
	}

	&--no-label {
		width: $val-fontSizeXBig;
		height: $val-fontSizeXBig;
		padding: 0;
		margin-top: 0 !important;

		& > label {
			height: $val-fontSizeXBig;
			width: $val-fontSizeXBig;
		}
	}

	&--disabled {
		cursor: not-allowed;
	}
}

// Bootstrap-defined class
.form-check-label {
	margin: 0;
	margin-left: -$val-spacing-m;
	padding-left: $val-spacing-l;
	cursor: pointer;
}

.checkbox-inline {
	font-weight: normal;
	padding-left: 0;
	margin: 0;
	margin-top: $field-title-offset;
	cursor: initial;

	&::before {
		margin-left: -$val-spacing-xxs;
	}

	& + & {
		margin-left: $val-spacing-s;
	}
}