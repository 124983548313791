$image-size: 42px;
$main-popover-top: -60px;
$first-header-info-offset: 46px;
$link-column-margin-top: 30px;
$var-input-filter-width: 300px;
$var-animation-duration: 2s;
$var-searchHiglightedOutline: 2px;
$var-screen-l-min: 1600px;
$var-screen-m-max: $var-screen-l-min - 1px;
$var-screen-m-min: 1230px;
$var-screen-s-max: $var-screen-m-min - 1px;
$var-main-border-width: 4px;
$var-expander-width: 65px;
$var-secondary-header-width: 160px;
$var-minimum-col-width: 10px; // can be any value smaller that 100% % 12
$var-work-summary-col-width: 100px;
$var-work-summary-actions-col-width: calc($var-work-summary-col-width / 2);
$var-work-summary-short-col-width: calc($var-work-summary-col-width / 1.5);
$var-work-summary-long-col-width: calc($var-work-summary-col-width * 2);
$var-work-summary-table-resizer-width: 8px;
$var-work-summary-three-dot-actions-menu: 20px;

.field-report {
	padding-bottom: $val-headerHeight;

	.tabs-navigation__wrapper {
		border-bottom: none;
	}

	&__order-info-title {
		font-size: $val-fontSizeXBig;
		display: flex;
		align-items: center;

		.status-icon {
			margin-right: $val-spacing-xs;
		}

		&__label {
			&--deleted {
				color: $col-greyFontLight;
				text-decoration: line-through;
			}
		}
	}

	&__first-header-info {
		padding-left: $first-header-info-offset;
	}

	&__submit-button-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.btn {
			// Some buttons are wrapped with tooltips so regular btn + btn margin doesn't get applied
			margin-left: $val-spacing-s;
		}
	}

	&__saving-status {
		color: $col-greyMid;
		font-size: $val-fontSizeSmall;
		font-weight: normal;
		display: flex;
		align-items: center;
		margin-left: $val-spacing-m;

		[class^="icon-"] {
			margin-right: $val-spacing-xs;
			font-size: $val-fontSizeRegular;
		}
	}

	&__confirmation-status {
		display: flex;
	}

	&__status_tooltip {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&__status {
			font-weight: bold;
		}
	}

	&__confirmation-status-type {
		color: $col-white;
		margin-right: $val-spacing-s;
		border-radius: $val-borderRadius;
		font-size: $val-fontSizeSmall;
		height: fit-content;
		width: $val-icon56;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;

		&--sent {
			background-color: $col-darkBlueBorder;
		}

		&--signed {
			background-color: $col-green;
		}

		&--revised {
			background-color: $col-orange;
		}
	}

	&__tab-navigation {
		&__work-summary-icon {
			[class^="icon-"] {
				margin-right: $val-spacing-s;
			}

			&--draft {
				[class^="icon-dot"] {
					color: $col-greyFontLight;
				}
			}

			&--in-progress {
				[class^="icon-dot"] {
					color: $col-orange;
					font-weight: bold;
				}
			}

			&--outdated {
				[class^="icon-dot_outline"] {
					color: $col-orange;
					font-weight: bold;
				}
			}

			&--reviewed {
				[class^="icon-dot"] {
					color: $col-darkBlueBorder;
					font-weight: bold;
				}
			}

			&--completed {
				[class^="icon-dot"] {
					color: $col-green;
					font-weight: bold;
				}
			}
		}

		&__required-field-completion-icon {
			[class^="icon-"] {
				margin-left: $val-spacing-s;
			}

			&--draft {
				[class^="icon-dot"] {
					color: $col-greyFontLight;
				}
			}

			&--in-progress {
				[class^="icon-dot"] {
					color: $col-orange;
					font-weight: bold;
				}
			}

			&--outdated {
				[class^="icon-dot_outline"] {
					color: $col-orange;
					font-weight: bold;
				}
			}

			&--reviewed {
				[class^="icon-dot"] {
					color: $col-darkBlueBorder;
					font-weight: bold;
				}
			}

			&--completed {
				[class^="icon-dot"] {
					color: $col-green;
					font-weight: bold;
				}
			}
		}
	}

	&__search-bar {
		display: flex;
		justify-content: space-between;
		padding-top: $val-spacing-m;
		padding-right: $val-spacing-m;
		background-color: $col-white;
		box-shadow: $val-boxShadow;

		&__filter-container {
			width: 100%;
			display: flex;

			.form-group {
				width: auto;
			}

			.input-filter {
				width: $var-input_filter_width;
				margin-right: $val-spacing-s;
			}

			&__search-form {
				background-image: url("/images/inputs/ic_search_disabled.svg");
				background-repeat: no-repeat;
				background-position: $val-spacing-s center;

				@media (min-width: $bp-l-high) {
					padding-left: $val-spacing-xl;
					background-size: $val-icon16;
				}

				@media (max-width: $bp-m-high) {
					padding-left: $val-spacing-l;
					background-size: $val-icon12;
				}
			}

			&__input-group-addon {
				height: 100%;
				min-width: 2 * $val-spacing-xl;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-right: $val-spacing-s;
				color: $col-greyFontLight;
				background: none;
				border: none;
				position: absolute;
				top: 0;
				right: 0;

				&__result-count-container {
					margin-right: $val-spacing-xs;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 0;
					height: 100%;
					display: flex;
					align-items: center;
					z-index: 2;

					& .icon-left,
					& .icon-right {
						cursor: pointer;
						font-size: $val-fontSizeSmall;

						&--disabled {
							pointer-events: none;
						}
					}

					span {
						font-size: $val-fontSizeSmall;
						padding: 0 $val-spacing-xs;
						line-height: $val-lineHeightSmall;
					}
				}

				& .icon-close {
					cursor: pointer;
					font-size: $val-fontSizeXBig;
				}
			}
		}

		&__comment-checkbox {
			margin-top: 0;
		}
	}

	&__header {
		width: inherit;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: $val-spacing-l;
	}

	.tab-active {
		background-color: $col-orangeBackground;
	}

	&__work-summary {
		&--empty {
			background-color: $col-white;
			padding: $val-spacing-m;
			color: $col-grey;
			font-style: italic;
		}

		&__status-container {
			display: flex;
			padding: $val-spacing-m $val-spacing-s;
			background-color: $col-white;
		}

		&__status {
			margin: 0 $val-spacing-m 0 $val-spacing-xs;

			&--draft {
				color: $col-grey;
			}

			&--in-progress {
				color: $col-orange;
			}

			&--outdated {
				color: $col-orange;
			}

			&--reviewed {
				color: $col-darkBlueBorder;
			}

			&--completed {
				color: $col-green;
			}
		}

		&__billable-work {
			background-color: $col-white;
			padding: $val-spacing-s;

			&--non-billable {
				border-top: 1px solid $col-greyMid;
			}

			&__legend {
				display: flex;
				padding: $val-spacing-m $val-spacing-s;

				&__single {
					display: flex;
					align-items: center;
					margin-right: $val-spacing-m;

					&__billable {
						& > [class^="icon-dollar"] {
							background-color: $col-green;
							color: $col-white;
							margin-right: $val-spacing-s;
							border: 1px solid transparent;
							border-radius: $val-borderRadius;
						}
					}

					&__non-billable {
						& > [class^="icon-dollar"] {
							font-size: $val-fontSizeRegular;
							height: $val-fontSizeRegular;
							color: $col-greyMid;
							background-color: $col-white;
							border-radius: $val-borderRadius;
							margin-right: $val-spacing-s;
							border: 1px solid $col-greyMid;
							border-radius: $val-borderRadius;
						}
					}

					&__empty-cell {
						display: inline-block;
						height: $val-spacing-m;
						width: $val-spacing-m;
						border: 1px solid $col-greyMid;
						margin-right: $val-spacing-s;
						border-radius: $val-borderRadius;

						&--selected {
							background-color: $col-greyConnection;
						}

						&--suggested {
							background-color: $col-blueBackgroundDark;
						}
					}

					&__recently-used {
						& > [class^="icon-star"] {
							font-size: $val-fontSizeXBig;
							color: $col-orange;
							margin-right: $val-spacing-s;
						}
					}
				}

				&__user-guide {
					flex: 1;
					display: flex;
					justify-content: flex-end;
					margin-right: $val-spacing-s;
				}
			}

			&__user-guide-modal {
				&__subtitle {
					margin: $val-spacing-m 0;
					font-weight: bold;
				}

				&__row {
					display: flex;
					align-items: center;
					padding-bottom: $val-spacing-s;
				}

				&__billable {
					& > [class^="icon-dollar"] {
						background-color: $col-green;
						color: $col-white;
						border: 1px solid transparent;
						border-radius: $val-borderRadius;
					}

					&--standalone {
						& > * {
							margin-right: $val-spacing-m;
						}
					}

					&--inline {
						& > * {
							margin: 0 $val-spacing-xs;
						}
					}
				}

				&__non-billable {
					& > [class^="icon-dollar"] {
						font-size: $val-fontSizeRegular;
						height: $val-fontSizeRegular;
						color: $col-greyMid;
						background-color: $col-white;
						border-radius: $val-borderRadius;
						margin-right: $val-spacing-m;
						border: 1px solid $col-greyMid;
						border-radius: $val-borderRadius;
					}
				}

				&__empty-cell {
					display: inline-block;
					height: $val-spacing-m;
					min-width: $val-spacing-m;
					border: 1px solid $col-greyMid;
					margin-right: $val-spacing-m;
					border-radius: $val-borderRadius;

					&--selected {
						background-color: $col-greyConnection;
					}

					&--alternative {
						background-color: $col-blueBackgroundDark;
					}
				}

				&__recently-used {
					& > [class^="icon-star"] {
						font-size: $val-fontSizeXBig;
						color: $col-orange;
						margin-right: $val-spacing-m;
					}
				}

				&__examples {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-template-rows: 1fr min-;
					margin: $val-spacing-l 0;
				}

				&__work-summary-status {
					display: flex;
					flex-direction: column;

					&__draft {
						color: $col-greyMid;
						padding: $val-spacing-s 0;

						& > [class^="icon-dot"] {
							margin-right: $val-spacing-s;
						}

						&__description {
							color: $col-black;
							margin-left: $val-spacing-m;
						}
					}

					&__in-progress {
						color: $col-orange;

						& > [class^="icon-dot"] {
							margin-right: $val-spacing-s;
						}

						&__description {
							color: $col-black;
							margin-left: $val-spacing-m;
						}
					}

					&__reviewed {
						color: $col-darkBlueBorder;
						padding: $val-spacing-s 0;

						& > [class^="icon-dot"] {
							margin-right: $val-spacing-s;
						}

						&__description {
							display: inline-flex;
							align-items: center;
							color: $col-black;
							margin-left: $val-spacing-m;

							& > button {
								margin-left: $val-spacing-s;
								cursor: default;
								font-size: $val-fontSizeSmall;
							}
						}
					}

					&__outdated {
						color: $col-orange;

						& > [class^="icon-dot_outline"] {
							margin-right: $val-spacing-s;
						}

						&__description {
							margin-left: $val-spacing-m;
							color: $col-black;

							& > b:first-child {
								color: $col-darkBlueBorder;
							}

							& > b:last-child {
								color: $col-green;
							}
						}
					}

					&__completed {
						color: $col-green;
						padding: $val-spacing-s 0;

						& > [class^="icon-dot"] {
							margin-right: $val-spacing-s;
						}

						&__description {
							display: inline-flex;
							align-items: center;
							color: $col-black;
							margin-left: $val-spacing-m;

							& > button {
								margin-left: $val-spacing-s;
								cursor: default;
								font-size: $val-fontSizeSmall;
							}
						}
					}
				}
			}

			&__header {
				display: flex;
				align-items: center;
				padding: 0 $val-spacing-s;

				&__title {
					font-size: $val-fontSizeBig;
					font-weight: bold;
					text-transform: uppercase;
					padding: $val-spacing-s 0;
				}

				&__export {
					margin-left: $val-spacing-m;

					& > [class^="icon-download"] {
						font-size: $val-fontSizeRegular;
					}
				}
			}

			&__total {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding: $val-spacing-s $val-spacing-m;
				font-weight: bold;
				font-size: $val-fontSizeBig;

				&__hint {
					color: $col-greyMid;
					font-style: italic;
					font-weight: normal;
					font-size: $val-fontSizeRegular;
					margin-right: $val-spacing-m;
				}
			}

			&__review-actions {
				display: flex;
				justify-content: flex-end;
				padding: $val-spacing-s $val-spacing-m;
			}
		}

		&__details {
			margin-top: $val-spacing-s;
			background-color: $col-white;
			padding: $val-spacing-s;

			&__header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 0 $val-spacing-s $val-spacing-s $val-spacing-s;

				&__title {
					font-size: $val-fontSizeBig;
					font-weight: bold;
					text-transform: uppercase;

					&__hint {
						color: $col-greyMid;
						font-style: italic;
						font-weight: normal;
						font-size: $val-fontSizeRegular;
						margin-left: $val-spacing-m;
					}
				}

				&__sort-by {
					font-size: $val-fontSizeRegular;
					display: flex;
					margin-left: $val-spacing-l;
					text-transform: capitalize;
					color: $col-greyFontLight;

					.input-header {
						display: flex;
						align-items: center;
						margin-right: -$val-spacing-xs;

						& > div {
							margin-bottom: 0;
						}
					}

					.dropdown {
						& > button {
							.dropdown-menu__selected {
								color: $col-greyFontLight;
							}
						}

						& > div {
							.dropdown-menu--fixed {
								transform: 0 !important;
							}
						}
					}
				}

				&__export {
					display: flex;
					align-items: center;
					margin-left: $val-spacing-m;

					& > [class^="icon-download"] {
						color: $col-blue;
						font-size: $val-fontSizeRegular;
						margin-right: $val-spacing-s;
					}
				}
			}

			&__actions {
				margin-top: $val-spacing-m;
				display: flex;
				justify-content: flex-start;
			}
		}

		&__table-container {
			overflow-x: auto;
			padding-bottom: $val-spacing-m;
		}

		&__table {
			display: inline-flex;
			flex-direction: column;
			width: 100%;

			&__headers {
				display: flex;
				padding-bottom: $val-spacing-s;

				& > span:nth-child(2) {
					border-right: 0;
				}

				& > span:last-child {
					border-right: 0;
				}

				&__cell {
					flex: auto;
					width: calc($var-work-summary-col-width - $var-work-summary-table-resizer-width);
					min-width: calc($var-work-summary-col-width - $var-work-summary-table-resizer-width);
					background-color: $col-greyLight;
					border-top: 1px solid $col-greyMid;
					border-bottom: 1px solid $col-greyMid;
					padding: $val-spacing-s $val-spacing-xs;
					font-size: $val-fontSizeSmall;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow-x: hidden;

					&:first-child {
						min-width: calc($var-work-summary-col-width - $var-work-summary-table-resizer-width);
					}

					&--short {
						max-width: calc($var-work-summary-short-col-width - $var-work-summary-table-resizer-width);
						min-width: calc($var-work-summary-short-col-width - $var-work-summary-table-resizer-width);
					}

					&--long {
						min-width: calc($var-work-summary-long-col-width - $var-work-summary-table-resizer-width);
						max-width: calc($var-work-summary-long-col-width - $var-work-summary-table-resizer-width);
					}

					&--recently-used {
						max-width: calc(
							$var-work-summary-actions-col-width - $var-work-summary-table-resizer-width +
								$var-work-summary-three-dot-actions-menu
						) !important;
						min-width: calc(
							$var-work-summary-actions-col-width - $var-work-summary-table-resizer-width +
								$var-work-summary-three-dot-actions-menu
						) !important;
					}

					&--additional-action {
						display: flex;
						justify-content: space-between;
						flex-direction: row;
						align-items: center;
					}

					&__additional-action {
						cursor: pointer;
						color: $col-darkBlueBorder;

						&__icon {
							font-size: $val-icon16;
							margin-right: $val-spacing-xs;
						}
					}
				}

				&__resizer {
					background-color: $col-greyLight;
					cursor: col-resize;
					min-width: $var-work-summary-table-resizer-width;
					max-width: $var-work-summary-table-resizer-width;
					border-right: 1px solid $col-greyMid;
					border-top: 1px solid $col-greyMid;
					border-bottom: 1px solid $col-greyMid;
				}
			}

			&__row {
				display: flex;
				flex-direction: row;

				&__actions {
					display: inline-block;
					min-width: $var-work-summary-three-dot-actions-menu;
					height: $val-fontSizeRegular;

					& > div > .dropdown {
						& > button {
							& > [class^="icon-actions"] {
								font-size: $val-fontSizeRegular;
							}
						}
					}

					& > div > .dropup {
						& > div {
							position: fixed !important;
							width: $var-work-summary-col-width;
						}

						& > button {
							& > [class^="icon-actions"] {
								font-size: $val-fontSizeRegular;
							}
						}
					}

					&__menu-item {
						font-size: $val-fontSizeSmall;
					}
				}

				$row-border-radius: $val-spacing-xs;

				&:first-child > div {
					& > span:first-child {
						border-top-left-radius: $row-border-radius;
					}

					& > span:last-child {
						border-top-right-radius: $row-border-radius;
					}
				}

				&:last-child > div {
					& > span:first-child {
						border-bottom-left-radius: $row-border-radius;
					}

					& > span:last-child {
						border-bottom-right-radius: $row-border-radius;
					}
				}

				&__data {
					display: flex;
					flex: 1;
					width: 100%;
					cursor: pointer;

					&--billable-table {
						& > span:first-child {
							margin-left: $var-work-summary-three-dot-actions-menu;
							border-top-left-radius: $val-borderRadius;
							border-bottom-left-radius: $val-borderRadius;
							border-right: 0;
						}

						& > span:last-child {
							border-top-right-radius: $val-borderRadius;
							border-bottom-right-radius: $val-borderRadius;
						}
					}

					&__cell {
						flex: auto;
						min-width: calc($var-work-summary-col-width);
						width: calc($var-work-summary-col-width);
						border-right: 1px solid $col-greyMid;
						border-bottom: 1px solid $col-greyMid;
						border-top: 1px solid $col-greyMid;
						font-size: $val-fontSizeSmall;
						padding: $val-spacing-xs;
						display: flex;
						align-items: center;

						&__text {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow-x: hidden;

							& > a {
								text-decoration: underline;
								color: $color-blue;
							}

							&--align-right {
								display: block;
								width: 100%;
								text-align: right;
							}
						}

						&--dropdown {
							overflow-x: hidden;
						}

						& > div {
							width: 100%;
							background-color: $col-white;
						}

						&__checkbox {
							margin-right: $val-spacing-xs;
						}

						&__billing-action {
							margin-top: $val-spacing-xs;

							& > [class^="icon-dollar"] {
								font-size: $val-fontSizeRegular;
								height: $val-fontSizeRegular;
								color: $col-greyMid;
								background-color: $col-white;
								border-radius: $val-borderRadius;
								margin-right: $val-spacing-s;
								border: 1px solid $col-greyMid;
							}

							&--billed {
								& > [class^="icon-dollar"] {
									cursor: pointer;
									font-size: $val-fontSizeRegular;
									background-color: $col-green;
									color: $col-white;
									border: 1px solid transparent;
								}
							}

							&--readonly {
								cursor: not-allowed;

								& > [class^="icon-dollar"] {
									pointer-events: none;
								}
							}
						}

						&--actions {
							max-width: $var-work-summary-actions-col-width !important;
							min-width: $var-work-summary-actions-col-width !important;
						}

						&--short {
							max-width: $var-work-summary-short-col-width;
							min-width: $var-work-summary-short-col-width;
						}

						&--long {
							min-width: $var-work-summary-long-col-width;
							max-width: $var-work-summary-long-col-width;

							> .form-group {
								margin-bottom: 0;
							}
						}

						&--align-right {
							display: block;
							text-align: right;
						}

						&__dropdown {
							display: flex;
							flex-direction: row;
							width: 100%;

							> button {
								font-size: $val-fontSizeSmall;
								margin: 0;
							}

							> div {
								position: fixed !important;
								width: calc($var-work-summary-long-col-width);
							}
						}

						&__dropdown-menu-item {
							display: flex;
							flex-direction: column;

							> p {
								width: $var-work-summary-long-col-width - 2 * $val-spacing-m;
								overflow-x: hidden;
								white-space: break-spaces;
								font-size: $val-fontSizeSmall;
							}
						}
					}

					& > span:nth-child(1) {
						border-left: 1px solid $col-greyMid;
					}

					& > span:nth-child(2) {
						border-left: 0;
					}

					& > span:first-child {
						position: sticky;
						left: 0;
						background-color: $col-white;
						padding: 0;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						width: calc($var-work-summary-col-width - $var-work-summary-three-dot-actions-menu);
						min-width: calc($var-work-summary-col-width - $var-work-summary-three-dot-actions-menu);

						& > [class^="icon-dollar"] {
							cursor: pointer;
							font-size: $val-fontSizeRegular;
							height: $val-fontSizeRegular;
							color: $col-white;
							background-color: $col-green;
							border-radius: $val-borderRadius;
							margin-right: $val-spacing-s;
						}

						& > [class^="icon-star"] {
							font-size: $val-fontSizeXBig;
							color: $col-orange;
							margin-right: $val-spacing-s;
						}

						& > a {
							display: flex;
							margin-right: $val-spacing-s;

							& > [class^="icon-external"] {
								cursor: pointer;
								font-size: $val-fontSizeBig;
								color: $col-blue;
								font-weight: bold;
							}
						}
					}

					&--highlighted {
						& > span:first-child {
							background-color: $col-greyConnection;
						}

						& > span {
							background-color: $col-greyConnection;
						}
					}

					&--alternative {
						& > span:first-child {
							background-color: $col-blueBackgroundDark;
						}

						& > span {
							background-color: $col-blueBackgroundDark;
						}
					}

					&--split {
						& > span:first-child {
							border-left: 2px solid $col-greyFontLight !important;
							border-top-left-radius: 0 !important;
							border-bottom-left-radius: 0 !important;
						}
					}

					&--last-seperated {
						& > span:first-child {
							border-bottom-left-radius: $row-border-radius;
						}

						& > span:last-child {
							border-bottom-right-radius: $row-border-radius;
						}
					}

					&--first-seperated {
						& > span {
							border-top: 1px solid $col-greyMid !important;
						}

						& > span:first-child {
							border-top-left-radius: $row-border-radius;
						}

						& > span:last-child {
							border-top-right-radius: $row-border-radius;
						}
					}
				}

				&__seperator {
					padding: $val-spacing-xs;
					flex-basis: 100%;
					margin-left: $var-work-summary-three-dot-actions-menu;

					&--connect {
						border-left: 2px solid $col-greyFontLight;
					}
				}

				&--custom-sort {
					&:first-child > div > span:first-child {
						border-top-left-radius: $row-border-radius;
					}

					& > div > span {
						border-bottom: 0;
					}

					&:last-child > div > span {
						border-bottom: 1px solid $col-greyMid;
					}
				}
			}

			&--grouped {
				margin-bottom: $val-spacing-s;

				& > div:nth-child(n + 2) {
					& > div {
						margin-bottom: 0;

						& > span {
							border-top: 0;
						}
					}
				}
			}
		}
	}
}

.report-type-modal {
	&__draggable-item {
		margin: 0 $val-spacing-l $val-spacing-m;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		& .form-group {
			margin-bottom: 0;
		}
	}

	&__draggable-label {
		display: flex;
		align-items: center;
		user-select: none;
		background-color: $col-greyLight;
		padding: $val-spacing-s;
		-webkit-box-shadow: $val-boxShadow;
		box-shadow: $val-boxShadow;
		flex: 1;

		[class^="icon-"] {
			margin-right: $val-spacing-s;
		}

		& + .tooltip__trigger-wrapper {
			// Margin on the wrapper so that tooltip is directly above the trash icon
			margin-left: $val-spacing-s;
		}
	}

	&__remove-icon {
		font-size: $val-fontSizeBig !important;
		margin-right: 0 !important;
		color: $col-greyInactive;
		cursor: pointer;

		&:hover {
			color: $col-greyDark;
		}
	}

	&__draggable-indicator {
		margin-right: $val-spacing-s !important;
		margin-left: -$val-spacing-xs;
	}

	&__action-link {
		display: flex;
		padding-left: $val-spacing-xxl;
	}

	&__report-type-dropdown {
		& > li > a {
			height: auto;
			min-height: $val-fieldSize;
		}

		small {
			color: $col-greyFontLight;
			white-space: normal;
		}
	}
}

.field-report-type {
	&__add-instance-button-container {
		display: flex;
		justify-content: flex-end;
		margin-top: $val-spacing-l;
	}

	&__instance {
		display: flex;
		flex-flow: column;
		background: $col-white;
		margin-bottom: $val-spacing-m;
		border-top: 1px solid $col-greyConnection;

		&__add-secondary {
			padding: $val-spacing-m;
			border: 1px solid $col-greyConnection;
			border-top: 0;
			background: $col-white;
			display: flex;
			justify-content: flex-end;
		}
	}

	&__segment {
		display: flex;

		&__blocks-container {
			width: 100%;

			.report-block {
				border-top: none;
				padding-top: 0;
			}
		}
	}

	&__status-information {
		padding: $val-spacing-l;
		background: $col-greyInactive;
		color: $col-white;
		font-weight: bold;

		& a {
			color: $col-white;
			text-decoration: underline;
		}
	}

	.popover {
		left: $val-spacing-s !important;
		top: $main-popover-top !important;
	}

	.lock-info {
		& [class^="icon-"] {
			margin-left: $val-spacing-s;
		}

		&__title {
			color: $col-greyConnection;
		}

		&__user {
			display: flex;
			align-items: center;
			margin-top: $val-spacing-xs;

			&__image {
				border-radius: 50%;
				margin-right: $val-spacing-m;
				width: $image-size !important;
				height: $image-size !important;
			}

			&__role {
				color: $col-greyConnection;
			}

			&__name {
				font-weight: bold;
			}
		}
	}
}

.modal-timeout {
	&__header {
		background-color: $col-orange;
	}

	&__title,
	&__close {
		color: $col-white;
	}
}

.fr-table {
	.rt-th {
		font-weight: bold;
	}

	.fr-approval-status-icon {
		color: $col-greyFontLight;
		font-size: $val-fontSizeBig;
		margin-right: $val-spacing-s;
	}

	.fr-table-column__field-report-types {
		.types {
			font-size: $val-fontSizeRegular;
			line-height: $val-lineHeightRegular;
		}

		.rest-count {
			color: $col-greyFontLight;
			font-size: $val-fontSizeRegular;
			line-height: $val-lineHeightRegular;
			margin-top: $val-spacing-xs;
		}
	}

	.status {
		display: flex;
		gap: $val-spacing-s;
	}

	.fr-table-column__updated {
		justify-content: flex-end !important;
	}

	.fr-table-cell-data__customer-approval-date {
		margin-top: $val-spacing-s;
	}
}

.field-report-type-new {
	&__add-segment {
		padding: $val-spacing-m;
		border: 1px solid $col-greyConnection;
		border-top: none;
		display: flex;
		justify-content: flex-end;
		text-transform: capitalize;
	}
}

.field-report-block {
	display: grid;
	border: 1px solid $col-greyConnection;
	border-top: none;

	gap: $val-spacing-l $val-spacing-m;

	grid:
		". . . ."
		". header body ."
		". . . ."
		/ $val-spacing-s $var-secondary-header-width auto $val-spacing-s;

	@media (max-width: $var-screen-s-max) {
		grid:
			". . ."
			". header ."
			". body ."
			". . ."
			/ $val-spacing-s auto $val-spacing-s;
	}

	&__header {
		grid-area: header;
		font-size: $val-fontSizeBig;
		font-weight: bold;
		text-transform: uppercase;

		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;

		&__clickable {
			cursor: pointer;
		}

		&__comment {
			margin-left: -$val-spacing-s;
		}
	}

	&__header-left {
		font-size: $val-fontSizeBig;
		display: flex;
		align-items: baseline;

		&--main {
			color: $col-darkBlueBorder;
		}

		&--highlighted {
			color: $col-orange;
		}

		&--locked {
			color: $col-greyDark;
		}

		span {
			margin-right: $val-spacing-s;
		}
	}

	&__header-right {
		font-size: $val-fontSizeRegular;

		@media (max-width: $var-screen-s-max) {
			display: none;
		}

		&--link {
			color: $col-darkBlueBorder;
			font-weight: normal;
			text-transform: capitalize;
		}

		span {
			margin-left: $val-spacing-s;
		}
	}

	&__footer {
		grid-area: footer;
		justify-self: end;
	}

	&__expander {
		grid-area: expander;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $val-fontSizeBig;

		&--primary {
			background: $col-blueBackgroundDark;

			@media (max-width: $var-screen-s-max) {
				background: initial;
			}
		}

		&--primary#{&}--rejected {
			background-color: $col-redBackground;
		}

		&--primary#{&}--active {
			background-color: $col-orangeHighlighted;
		}

		&--primary#{&}--highlighted {
			background-color: $col-orangeHover;
		}

		&--primary#{&}--locked {
			background-color: $col-greyConnection;
		}
	}

	&__body {
		grid-area: body;
		display: grid;

		&--hidden {
			color: $col-greyFontLight;
			text-transform: uppercase;
			font-size: $val-fontSizeSmall;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__block {
		display: grid;
		grid-template-columns: auto $val-spacing-l;
		column-gap: $val-spacing-s;
	}

	&__add {
		cursor: pointer;
		color: $col-darkBlueBorder;
		justify-self: start;
		display: flex;
		align-items: center;
	}

	&__field-array {
		display: grid;
		grid-auto-flow: initial;
		column-gap: $val-spacing-m;
		row-gap: $val-spacing-s;
		grid-template-columns: repeat(12, 1fr);

		&--preview {
			row-gap: $val-spacing-m;
		}

		@media (min-width: $var-screen-l-min) {
			&--justifiable {
				grid-template-columns: repeat(auto-fit, minmax($var-minimum-col-width, 1fr));
			}
		}

		@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
			&--justifiable {
				grid-template-columns: repeat(auto-fit, minmax($var-minimum-col-width, 1fr));
			}
		}

		@media (max-width: $var-screen-s-max) {
			&--justifiable {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		&:has(.field-report-block__field--adjustable) {
			@media (min-width: $var-screen-l-min) {
				grid-template-columns: repeat(auto-fit, minmax($var-minimum-col-width, 1fr));
			}

			@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
				grid-template-columns: repeat(auto-fit, minmax($var-minimum-col-width, 1fr));
			}

			@media (max-width: $var-screen-s-max) {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}

	&__remove {
		align-self: center;

		color: $col-greyFontLight;
		cursor: pointer;

		&:hover {
			color: $col-greyDark;
		}
	}

	&__field {
		grid-column-start: auto;

		&--with-icon {
			display: grid;
			grid-template-rows: auto auto;
			row-gap: $val-spacing-m;

			@media (max-width: $var-screen-s-max) {
				grid-template-columns: $val-icon64 auto;
				column-gap: $val-spacing-m;
				row-gap: unset;
			}
		}

		&--focused {
			border: 2px solid $col-blue;
			border-radius: $val-spacing-xs;
			padding: 0 $val-spacing-xs;
		}

		&--largest {
			grid-column-end: span 12;
		}

		&--large,
		&--adjustable {
			@media (min-width: $var-screen-l-min) {
				grid-column-end: span 4;
			}

			@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
				grid-column-end: span 6;
			}

			@media (max-width: $var-screen-s-max) {
				grid-column-end: span 12;
			}
		}

		&--medium {
			@media (min-width: $var-screen-l-min) {
				grid-column-end: span 3;
			}

			@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
				grid-column-end: span 4;
			}

			@media (max-width: $var-screen-s-max) {
				grid-column-end: span 12;
			}
		}

		&--small {
			@media (min-width: $var-screen-l-min) {
				grid-column-end: span 2;
			}

			@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
				grid-column-end: span 3;
			}

			@media (max-width: $var-screen-s-max) {
				grid-column-end: span 6;
			}
		}

		&--very-small {
			@media (min-width: $var-screen-l-min) {
				grid-column-end: span 1;
			}

			@media (min-width: $var-screen-m-min) and (max-width: $var-screen-m-max) {
				grid-column-end: span 2;
			}

			@media (max-width: $var-screen-s-max) {
				grid-column-end: span 3;
			}
		}

		&--other-dropdown {
			.dropdown-menu__selected {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				width: auto;
			}
		}

		&__boolean {
			@media (max-width: $var-screen-s-max) {
				margin: 0;
			}
		}

		&__address {
			font-size: $val-icon20;
		}

		&__icon {
			justify-self: center;
			font-size: $val-icon64;
		}

		&__title {
			display: flex;
			align-items: center;

			.icon-help_fill {
				margin-left: $val-spacing-xs;
				font-size: $val-fontSizeBig !important;
				color: $col-greyFontLight;

				&:hover {
					cursor: pointer;
					color: $col-greyDark;
				}
			}
		}

		.input-group-append {
			width: $val-inputHeight;
			padding: 0;
			text-align: center;
		}

		.dropdown-field {
			background-color: $col-white;
		}
	}

	&--main {
		grid:
			". . expander"
			". header expander"
			". body expander"
			". . expander"
			/ $val-spacing-s auto $var-expander-width;

		@media (max-width: $var-screen-s-max) {
			grid:
				". . . ."
				". header expander ."
				". body body ."
				". . . ."
				/ $val-spacing-s auto $var-expander-width $val-spacing-s;
		}
	}

	&--primary {
		background: $col-blueBackground;
		border-left: $var-main-border-width solid $col-darkBlueBorder;
	}

	&--secondary {
		background: $col-white;
	}

	&--main#{&}--primary {
		background: $col-blueBackgroundDark;
	}

	&--main#{&}--secondary {
		background: $col-greyLight;
	}

	&--completable {
		grid:
			". . . ."
			". header body ."
			". header footer ."
			". . . ."
			/ $val-spacing-s $var-secondary-header-width auto $val-spacing-s;
	}

	&--main#{&}--completable {
		grid:
			". . expander"
			". header expander"
			". body expander"
			". footer expander"
			". . expander"
			/ $val-spacing-s auto $var-expander-width;

		@media (max-width: $var-screen-s-max) {
			grid:
				". . . ."
				". header expander ."
				". body body ."
				". footer footer ."
				". . . ."
				/ $val-spacing-s auto $var-expander-width $val-spacing-s;
		}
	}

	&--rejected {
		background-color: $col-redBackground;
	}

	&--highlighted {
		background-color: $col-orangeHover;
	}

	&--primary#{&}--rejected {
		border-left-color: $col-red;
	}

	&--primary#{&}--highlighted {
		border-left-color: $col-orange;
	}

	&--main#{&}--secondary#{&}--rejected {
		background-color: $col-redBackground;
	}

	&--main#{&}--primary#{&}--highlighted,
	&--main#{&}--secondary#{&}--highlighted {
		background-color: $col-orangeHover;
	}

	&--locked {
		background-color: $col-greyConnection !important;
		color: $col-greyDark !important;

		.field-report-block__add,
		.field-report-block__header-right--link {
			color: $col-greyDark;
		}
	}

	&--active,
	&--match {
		animation-name: twinkle;
		animation-duration: $var-animation-duration;
		animation-iteration-count: infinite;
		border-radius: $val-borderRadius;
		outline-offset: $var-search-outline-offset;
	}

	&--match {
		outline: $var-outline-border dashed $col-orange !important;
	}

	&--active {
		outline: $var-highlightedActiveOutline solid $col-orange !important;
		background-color: $col-orangeHighlighted;
	}

	&--primary#{&}--locked {
		border-left-color: $col-greyDark;
	}

	&--main#{&}--secondary#{&}--locked {
		background-color: $col-greyConnection;
		color: $col-greyDark;
	}

	&--primary-total-block {
		margin-bottom: $val-spacing-m;
	}

	&--secondary-total-block {
		margin-top: $val-spacing-l;
	}
}

.lock-info-popover {
	left: $val-spacing-s !important;
}

.lock-info {
	& [class^="icon-"] {
		margin-left: $val-spacing-s;
	}

	&__title {
		color: $col-greyConnection;
	}

	&__user {
		display: flex;
		align-items: center;
		margin-top: $val-spacing-xs;

		&__image {
			border-radius: 50%;
			margin-right: $val-spacing-m;
			width: $image-size !important;
			height: $image-size !important;
		}

		&__role {
			color: $col-greyConnection;
		}

		&__name {
			font-weight: bold;
		}
	}
}
