$dot-size: 6px;
$loading-indicator-width: 32px;

.loading-indicator {
	opacity: .7;
	width: $loading-indicator-width;
	height: 100%;
	vertical-align: middle;
	display: inline-flex;
	justify-content: space-around;
	align-items: center;
	position: relative;
	text-align: center;

	&__dots {
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		align-self: center;

		&__dot {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $val-fontSizeSmall;
			animation: pulse .85s infinite ease-in-out;
			z-index: 10;

			&--small {
				font-size: $val-fontSizeXSmall !important;
			}

			&--medium {
				font-size: $val-fontSizeRegular !important;
			}

			&--big {
				font-size: $val-fontSizeBig !important;
			}

			&--white {
				color: $col-white;
			}

			&--blue {
				color: $col-blue;
			}

			&--orange {
				color: $col-orangeBorder;
			}

			&--black {
				color: $col-black;
			}

			&--one {
				opacity: 0;
				animation-delay: -.2s;
			}

			&--three {
				animation-delay: .2s;
			}
		}
	}
}

@keyframes pulse {
	0%,
	100%,
	80% {
		opacity: 0;
		transform: scale(0.8);
	}

	40% {
		opacity: 1;
		transform: scale(1);
	}
}
