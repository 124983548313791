$resource-lookup-skill-picker-width: 70px;
$employee-skill-offset: 30px;
$resource-per-diem-width: 120px;
$sm-col-break-point: 767px;

.resource-lookup {
	width: 100%;

	&__list {
		@media (max-width: $bp-xs-high) {
			width: 100%;
		}
	
		@media (min-width: $bp-s-low) {
			width: 70%;
		}
	}

	&__per-diem-amount {
		display: flex;
		align-items: center;
	}
	
	&__per-diem-amount-label {
		margin-left: $val-spacing-s;
		white-space: nowrap;
	}

	&__draggable {
		display: flex;
		width: 100%;
		align-items: center;
		padding: $val-spacing-s 0;

		.row {
			width: 100%;

			&.resource-lookup__item {
				flex: 1;
			}
		}

		&--details {
			display: flex;
			align-items: center;
		}
	}

	&__actions {
		display: flex;
	}

	&__add-new {
		padding: 0;

		& + & {
			margin-left: $val-spacing-m;
		}
	}

	&__item {
		width: 100%;
		margin: 0 $val-spacing-s;
		display: flex;

		& [class^="icon-"] {
			font-size: $val-fontSizeBig;
		}

		& .form-group--standalone + .form-group--standalone {
			margin-left: $val-spacing-s;
		}

		&--placeholder {
			& button, & button:hover {
				@extend .bg-transparent-pattern;
			}
		}

		&--non-padded {
			margin: 0;
		}
	}

	&__item-skill {
		width: $resource-lookup-skill-picker-width;

		& .dropdown {
			width: $resource-lookup-skill-picker-width;
		}

		& .dropdown-toggle {
			min-width: unset;
		}
	}

	&__skill-option {
		font-family: 'Roboto', sans-serif;
		display: flex;
		align-items: center;

		& .color-square {
			margin-right: $val-spacing-s;
		}
	}

	&__resource-details-employee {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		width: 100%;
	}

	&__resource-details-equipment {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		width: 100%;
	}

	&__resource-name {
		font-weight: bold;
	}

	&__resource-info {
		margin-left: $val-spacing-xs;
	}

	&__resource-status {
		font-size: $val-fontSizeXSmall;
		color: $col-grey;
	}

	&__resource-additional-info {
		font-weight: bold;
		font-size: $val-fontSizeSmall;
		text-transform: uppercase;
		margin-left: $val-spacing-xs;

		&--large {
			@media (max-width: $bp-xxs-high) {
				display: none;
			}
		
			@media (min-width: $bp-xs-low) {
				display: block;
			}
		}

		&--small {
			@media (max-width: $bp-xxs-high) {
				display: block;
			}
		
			@media (min-width: $bp-xs-low) {
				display: none;
			}
		}

	}

	&__resource {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding-right: $employee-skill-offset;
		width: 100%;

		&--equipment {
			justify-content: flex-start;
		}

		& .color-square {
			margin-right: $val-spacing-s;
		}
	}

	&__per-diem {
		width: $resource-per-diem-width;

		&--hidden {
			visibility: hidden !important;
		}

		@media (max-width: $sm-col-break-point) {
			margin-left: $val-spacing-l;
		}
	}
}