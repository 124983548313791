.field-report-confirmation-modal {

	&__title {
		font-size: $val-fontSizeRegular;
	}

	&__form-box {
		padding: $val-spacing-m 0;
		background: $col-greyLight;
	}
}
