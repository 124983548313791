
h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
	margin-bottom: $val-spacing-l;
}

// Used mostly for form box headings
h2 {
	$margin-top: $val-spacing-l;
	font-size: $val-fontSizeXBig;
	text-align: center;
}

// Used mostly for segment titles
h3 {
	font-size: $val-fontSizeBig;
	text-transform: uppercase;
	margin-top: $val-spacing-s;
	margin-bottom: $val-spacing-l;
	margin-left: $val-spacing-s;

	& span {
		text-transform: none;
		font-weight: normal;
		color: $col-greyFontLight;
	}
}

// Used mostly for form box sub-headings
h4 {
	font-size: $val-fontSizeRegular;
	color: $col-greyFontLight;
	text-align: center;
	margin-bottom: $val-spacing-l;
	font-weight: normal;

	& + h4 {
		margin-top: 0;
	}
}