$category-picker-height: 120px;

.equipment-cost-upsert {
	&__header {
		background: $col-blueBackground;
	}

	&__category-picker {
		flex-grow: 1;
	}

	&__tree {
		user-select: none;

		& > div {
			display: flex;
			align-items: center;
			margin-top: $val-spacing-xs;
		}

		[class^="icon-"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
			color: $col-greyFontLight;
		}

		.color-square {
			margin-right: $val-spacing-s;
		}
	}

	&__tree-level {
		&--1 {
			margin-left: $val-spacing-m;
		}
		
		&--2 {
			margin-left: 2 * $val-spacing-m;
		}
		
		&--3 {
			margin-left: 3 * $val-spacing-m;
		}
	}

	&__selected-category {
		display: flex;
		align-items: center;
		font-weight: bold;

		.color-square {
			margin-right: $val-spacing-s;
		}
	}

	& .avatar {
		margin-right: $val-spacing-m;
	}
}