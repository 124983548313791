$signature-height: 100px;
$signature-width: 200px;
$mobile-width: 600px;
$tablet-width: 1024px;
$custom-header-height: 200px;

.public-report {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	padding: $val-spacing-xl 0;
	overflow: auto;
	scroll-behavior: smooth;

	&__loading {
		.form-segment {
			display: flex;
			justify-content: center;
			width: 60%;
		}
	}

	&__header {
		box-shadow: $val-boxShadow;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background-color: $col-white;
		text-align: center;
		display: grid;
		grid-template-columns: 1fr 5fr 1f;
		grid-template-areas: "mobile-sidebar logo actions";
		justify-items: center;
		align-items: center;

		&__mobile-sidebar {
			grid-area: mobile-sidebar;

			>span {
				color: $col-black;
				font-size: $val-fontSizeXBig;
			}

			&__reports-list {
				display: flex;
				flex-direction: column;

				& .modal-dialog .modal-content {
					min-height: 100vh;
					min-width: 100vw;
					animation: open-sidebar 500ms normal;
				}

				&__title-container {
					display: flex;
					justify-content: space-between;
					padding: $val-spacing-m;

					&__report-count {
						text-transform: uppercase;
						color: $col-greyFontLight;
					}

					>span {
						font-size: $val-fontSizeXXBig;
						color: $col-black;
					}
				}
			}
		}

		&__logo {
			grid-area: logo;
		}

		&__actions {
			grid-area: actions;
		}

		&>img {
			height: $val-fontSizeXXBig;
			margin: $val-spacing-s;
		}
	}

	&__footer {
		display: grid;
		grid-template-areas: "close previous-next print-sign";
		grid-template-columns: 1fr 2fr 1fr;
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		background-color: $col-white;
		padding: $val-spacing-m;
		border-top: 1px solid $col-greyConnection;
		z-index: 1;

		&__close {
			grid-area: close;
			display: flex;
			justify-content: flex-end;
		}

		&__previous-next {
			display: flex;
			justify-content: space-evenly;
			grid-area: previous-next;

			&__reports-status {
				padding: 0 $val-spacing-s;

				&__signed {
					display: flex;
					justify-content: center;
					font-size: $val-fontSizeBig;
					color: $col-orange;

					>b {
						padding: 0 $val-spacing-xs;
					}
				}

				&__position {
					display: flex;
					justify-content: center;

					>b {
						padding: 0 $val-spacing-xs;
					}
				}
			}

			&>button {
				width: 150px;
			}
		}

		&__print-sign {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			width: 100%;
			grid-area: print-sign;

			&--web-view {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);

				&>a {
					margin-top: $val-spacing-s;
					grid-area: 1;
				}

				&>div {
					grid-area: 2/1/3/3;
				}

				&__print-button {
					padding: $val-spacing-s;
					width: 40vw;
				}
			}

			&__signings {
				&__sign-all {
					margin-left: $val-spacing-s;
				}
			}

			&__label {
				display: flex;
				align-items: center;
				height: 100%;
				padding-left: $val-spacing-s;

				& [class^="icon-"] {
					font-size: $val-fontSizeBig;
					padding-left: $val-spacing-xs;
					padding-right: $val-spacing-xs;
				}
			}
		}

		&__button-container {
			display: flex;
			justify-content: space-between;
			width: 100%;

			&>div {
				display: flex;
			}
		}
	}

	&__mobile-signature-modal {
		height: 100%;

		&__full-name {
			padding: $val-spacing-xs (
				-$val-spacing-xs
			);
	}

	&__success-step {
		margin-top: 5vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		&__title {
			font-size: $val-fontSizeXXBig;
			color: $col-greyFontLight;
			font-weight: bold;
			padding: $val-spacing-m;
		}

		&__icon {
			font-size: 10vh;
		}

		&__description {
			font-size: $val-fontSizeBig;
			padding: $val-spacing-l;
			text-align: center;
		}

		&__close-button {
			width: 70vw;
			padding: $val-spacing-m;
		}
	}

	.modal-dialog {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.modal-body {
		height: 85vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.modal-content {
		height: auto;
		min-height: 100%;
		min-width: 100%;
		border-radius: 0;
	}

	&__canvas-container {
		padding: $val-spacing-xs (
			-$val-spacing-xs
		);
	flex: 1;
}

&__canvas {
	height: 100%;
	width: 100%;
	border: 1px dashed $col-greyMid;
	border-radius: $val-borderRadius;
}

&__footer {
	display: flex;
	justify-content: space-between;
	width: 100%;

	&__button {
		width: 40vw;
	}
}
}

&__sidebar {
	height: calc(100% - $val-headerHeight);
	width: 20%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: $col-white;
	overflow-x: hidden;
	padding-top: $val-headerHeight;

	&__signed-number {
		display: flex;
		align-items: center;
		color: $col-orange;
		text-transform: uppercase;
		padding: $val-spacing-s $val-spacing-m;

		>span {
			margin-left: $val-spacing-s;
		}
	}

	&__title {
		color: $col-greyFontLight;
		text-transform: uppercase;
		padding: $val-spacing-m;
	}

	&__element {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		padding: $val-spacing-m;
		cursor: pointer;

		&__name {
			width: 90%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		>span {
			color: $col-green;
			font-size: $val-fontSizeBig;
		}

		&--active {
			background-color: $col-orangeHover;
		}
	}
}

&__content-container {
	display: flex;
	flex-flow: column nowrap;
	padding: $val-spacing-xl;
}

&__content {
	background-color: $col-white;
	padding: $val-spacing-xl;
	width: 60%;
	margin: 0 auto;
}

&__company-header {
	display: flex;
	flex-flow: row nowrap;
	height: $var-company-info-height;
	margin-bottom: $val-spacing-s;

	&__logo {
		width: $var-company-info-height;

		@extend .background-circle;

		display: flex;
		align-items: center;
		justify-content: center;
		color: $col-greyFontLight;
		background-color: $col-greyLight;
		font-size: 10px;
		font-weight: bold;
		line-height: 1px;
	}

	&__divider {
		height: auto;
		width: $val-spacing-xxxs;
		background: $col-greyFontLight;
		margin-left: $val-spacing-l;
		margin-right: $val-spacing-m;
	}

	&__details {
		display: flex;
		flex-flow: column nowrap;
		gap: $val-spacing-s;
		font-size: $val-fontSizeSmall;
		justify-content: center;
		color: $col-greyFontLight;
	}

	&__contacts,
	&__contact {
		display: flex;
		flex-flow: row nowrap;
	}

	&__contacts {
		gap: $val-spacing-xxl;
	}

	&__contact {
		gap: $val-spacing-s;

		&__icon {
			padding-top: $val-spacing-xxs;
		}
	}
}

&__company-header-custom {
	margin: $val-spacing-s 0px;
	height: $custom-header-height;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

&__report-info {
	background-color: $col-greyLight;
	border: 1px solid $col-greyConnection;
	padding: $val-spacing-m;

	&__title-row {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		text-transform: uppercase;

		&__title {
			display: flex;
			align-items: center;
			gap: $val-spacing-s;
			font-size: $val-fontSizeXBig;
			font-weight: bold;
		}

		&__status {
			color: $col-white;
			background: $col-blackDark;
			padding: $val-spacing-xs $val-spacing-s;
			border-radius: $val-borderRadius;
			font-size: $val-fontSizeSmall;
			font-weight: bold;
			margin-left: $val-spacing-m;
		}

		&__date {
			flex: 1;
			justify-content: flex-end;
			display: flex;
			font-size: $val-fontSizeBig;
			font-weight: bold;
			color: $col-grey;
		}
	}
}

&__segment {
	display: flex;
	flex-direction: column;

	&__row {
		display: flex;
		padding: $val-spacing-s 0;
		flex-wrap: wrap;
	}

	&__inline-table {
		display: flex;
		flex-wrap: nowrap;

		&__header {
			background-color: $col-greyBackground;
		}

		&__item {
			padding: $val-spacing-xs;
			word-break: break-word;
			border: 1px solid $col-black;
			margin-left: -1px;

			&--1 {
				flex-grow: 1;
				flex-basis: calc(100% * 1 / 8);
			}

			&--2 {
				flex-grow: 1;
				flex-basis: calc(100% * 1 / 8);
			}

			&--3 {
				flex-grow: 2;
				flex-basis: calc(100% * 2 / 8);
			}

			&--4 {
				flex-grow: 3;
				flex-basis: calc(100% * 3 / 8);
			}

			&--5 {
				flex-grow: 8;
				flex-basis: calc(100% * 8 / 8);
			}
		}
	}

	&__appendix-table {
		display: flex;
		flex-wrap: nowrap;

		&__header {
			background-color: $col-greyBackground;
		}

		&__item {
			padding: $val-spacing-xs;
			word-break: break-word;
			border: 1px solid $col-black;
			margin-left: -1px;

			&--1 {
				flex-grow: 1;
				flex-basis: calc(100% / 16);
			}

			&--2 {
				flex-grow: 1;
				flex-basis: calc(100% / 16);
			}

			&--3 {
				flex-grow: 2;
				flex-basis: calc(100% / 16 * 2);
			}

			&--4 {
				flex-grow: 3;
				flex-basis: calc(100% / 16 * 3);
			}

			&--5 {
				flex-grow: 16;
				flex-basis: calc(100%);
			}
		}
	}

	&__item {
		margin: $val-spacing-s 0;
		padding: $val-spacing-xs;
		word-break: break-word;

		&__image {
			margin-right: $val-spacing-m;
			width: 72px;
			max-height: 72px;
		}

		&--inline {
			display: flex;

			.locked-input__content {
				padding: 0 $val-spacing-s 0 0;
			}
		}

		&--weather {
			width: calc(100% / 8);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			>div {
				text-align: center;
			}

			[class^="icon-"] {
				font-size: $val-fontSizeMax;
			}
		}

		&--1 {
			width: calc(100% / 6);
		}

		&--2 {
			width: calc(100% / 3);
		}

		&--3 {
			width: calc(100% / 2);
		}

		&--4 {
			width: calc(100% / 1.5);
		}

		&--5 {
			width: calc(100% * 5 / 6);
		}

		&--6 {
			width: 100%;
		}
	}

	&__title {
		font-weight: bold;
		font-size: $val-fontSizeBig;
		text-transform: uppercase;
		padding: $val-spacing-s 0;
	}
}

&__time-sheet {
	display: flex;
	padding: $val-spacing-s 0;

	&+& {
		border-top: 1px solid $col-greyConnection;
	}

	&__user-and-signature-container {
		display: flex;
		flex-direction: column;
		width: 40%;
	}

	&__user-and-signature {
		display: flex;
		flex-direction: column;

		&__note {
			display: flex;
			margin-top: $val-spacing-s;
		}

		&__user {
			font-size: $val-fontSizeBig;
			font-weight: bold;
			padding: $val-spacing-s $val-spacing-xxs;
			word-break: break-word;
		}

		&__signature-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__signature-statuses {
			display: flex;
			flex-direction: column;
			font-size: $val-fontSizeSmall;

			&__si {
				color: $col-red;
			}

			.time-sheet-signature-status-label__pill {
				margin-right: $val-spacing-xs;
			}
		}

		&__signature-image {
			width: $signature-width;
			background-size: cover !important;
			background-repeat: no-repeat;
			padding: 0 $val-spacing-m;
		}
	}

	&__time-and-equipment {
		display: flex;
		flex-direction: column;
		flex: 1;

		&__totals {
			display: flex;
			justify-content: space-between;

			&__start-finish {
				display: flex;
				padding-bottom: $val-spacing-s;

				&>div {
					padding-right: $val-spacing-m;
				}

				.locked-input__content {
					padding-top: 0;
				}
			}

			&__total {
				font-size: $val-fontSizeXBig;
				font-weight: bold;
			}
		}

		&__totals-breakdown {
			display: flex;

			&__item {
				width: 25%;
				display: flex;
				justify-content: flex-start;
				padding: $val-spacing-xs $val-spacing-s;
				border-bottom: 1px solid $col-greyConnection;

				b {
					padding-left: $val-spacing-xs;
				}

				&:first-of-type {
					background-color: $col-greyBackground;
					border: 1px solid $col-greyConnection;
					border-bottom: 1px solid $col-transparent;
				}
			}

			&--empty {
				// Overriding borders and job highlight in case there are no time splits to connect it to
				background: $col-transparent !important;

				.public-report__time-sheet__time-and-equipment__totals-breakdown__item {
					border: none !important;
					background-color: $col-transparent;
				}
			}
		}

		&__equipment {
			display: flex;
			flex-direction: column;
			background-color: $col-greyBackground;
			border: 1px solid $col-greyConnection;
			border-top: none;
			padding: $val-spacing-s;

			&__item {
				display: flex;
				font-size: $val-fontSizeSmall;

				&+& {
					margin-top: $val-spacing-xs;
				}

				&__time {
					width: 20%;
				}

				&__classification-code {
					width: 40%;
				}

				&__equipment {
					width: 40%;
					display: flex;
					align-items: center;

					.color-square {
						margin-right: $val-spacing-xs;
					}
				}
			}
		}
	}
}

&__signatures {
	display: flex;
	margin-top: $val-spacing-m;

	&__column {
		width: 50%;
	}

	&__no-signature {
		cursor: pointer;
		height: $signature-height;
		border-radius: $val-borderRadius;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px dashed $col-greyConnection;
		color: $col-greyFontLight;
	}

	&__add-signature {
		margin-top: $val-spacing-m;
		display: flex;
		justify-content: flex-start;
		padding: 0 $val-spacing-l;
	}

	&__signature {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 $val-spacing-l;

		&+& {
			padding-top: $val-spacing-l;
		}
	}

	&__signature-image {
		width: $signature-width;
		height: $signature-height;
		padding-bottom: $val-spacing-s;
	}

	&__signature-name {
		padding-top: $val-spacing-s;
		border-top: 2px solid $col-black;
		font-size: $val-fontSizeSmall;
		width: 100%;
	}
}
}

@media only screen and (max-width: $tablet-width) {
	.public-report {
		padding: $val-spacing-m 0;

		&__loading {
			padding: 0;

			.form-segment {
				width: 100%;

				.form-box {
					width: 100%;
				}
			}

			.p-xl {
				padding: 0px !important;
			}
		}

		&__segment {
			&__item {
				&--weather {
					width: calc(100% / 4);
					text-align: center;
					padding: $val-spacing-s $val-spacing-xs;
					justify-content: space-between;
				}
			}
		}

		&__content-container {
			padding: $val-spacing-xl $val-spacing-s;
		}

		&__content {
			width: 100%;
			padding: $val-spacing-s;
		}

		&__report-info {
			&__title-row {
				flex-direction: column;

				&__status {
					margin-left: 0px;
				}

				&__date {
					padding: $val-spacing-s 0px;
					flex: none;
					width: 100%;
					justify-content: flex-start;
				}
			}
		}

		&__time-sheet {
			&__user-and-signature-container {
				width: 30%;
			}

			&__user-and-signature {
				&__signature-image {
					max-width: 100%;
				}
			}

			&__time-and-equipment {
				&__totals-breakdown {
					&__item {
						display: flex;
						flex-direction: column;
					}
				}
			}
		}

		&__signatures {
			&__signature-image {
				max-width: 100%;
			}
		}
	}
}

@media only screen and (max-width: $tablet-width) {
	.public-report {
		&__content-container {
			margin-bottom: $val-spacing-xl * 2;
		}

		&__footer {
			display: flex;
			justify-content: space-between;
			width: 100%;

			&__button-container {
				&>div>button {
					width: 40vw;
				}
			}

			&__print-sign {
				&__signings {
					display: flex;
					justify-content: center;
					width: 100%;

					>button {
						width: 50vw;
					}
				}

				&__label {
					padding: $val-spacing-s 0 0 0;

					& [class^="icon-"] {
						padding-left: 0;
					}
				}
			}

			&__previous-next {
				>button {
					width: auto;

					>span {
						font-size: $val-fontSizeXBig;
						font-weight: bold;
					}
				}
			}
		}
	}
}

@keyframes open-sidebar {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0);
	}
}