@import "./Constants.scss";
@import "./Colors.scss";

$var-fontBigHeight: 18px;
$var-fontBigWidth: 142px;
$var-fontRegularHeight: 16px;
$var-areaHeight: 96px;
$var-fieldHeight: 32px;
$var-animationLength: 1s;
$var-colHeight: 130px;
$var-colWidth: 20px;

.loading-content {
	@extend .twinkle-animation;

	margin-bottom: $val-spacing-l;

	&.standalone {
		margin-bottom: 0;
	}
}

.loading-element {
	@extend .twinkle-animation;

	display: block;
	background: $col-greyBackground;
}

.loading-floaters {
	@extend .loading-content;

	content: '';
	display: block;
	background: $col-greyBackground;
	width: 100%;

	&.dark {
		background: $col-greyConnection;
	}
}

.loading-row {
	width: 100%;
}

.loading-header {
	@extend .loading-content;

	background: $col-greyConnection;
	height: $var-fontBigHeight;
	width: $var-fontBigWidth;
}

.loading-box-header {
	background: $col-greyBackground !important;
	margin: (-$val-spacing-l) !important;
	margin-bottom: $val-spacing-l !important;
	padding: $val-spacing-l $val-spacing-l 0 $val-spacing-l !important;
}

.loading-box {
	padding: $val-spacing-l;

	& .row {
		margin: 0;
		background: $col-white;
	}

	&.headerless {
		padding-top: 0;
	}
}

.loading-box-label::after,
.loading-box-row::after {
	@extend .loading-floaters;

	height: $var-fontRegularHeight;
}

.loading-box-col {
	&::after {
		@extend .loading-floaters;

		height: $var-colHeight;
		width: $var-colWidth;
	}

	&.stretched::after {
		width: 100% !important;
	}
}

.loading-box-area {
	&::after {
		@extend .loading-floaters;

		height: $var-areaHeight;
	}

	&.stretched::after {
		height: 100% !important;
	}
}

.loading-box-field {
	&::after {
		@extend .loading-floaters;

		height: $var-fieldHeight;
	}

	&.rounded::after {
		border-radius: $var-fieldHeight !important;
	}
}
.loading-box-radio::after {
	@extend .loading-floaters;

	height: $var-fontRegularHeight;
	width: $var-fontRegularHeight;
	border-radius: 50%;
}

.loading-box-nav-link {
	@extend .loading-floaters;

	height: 50%;
	width: 100%;
	align-self: center;
	margin: 0;
}

@for $i from 1 through $val-total-columns {
	.loading-col-#{$i} {
		min-width: percentage(math.div($i, $val-total-columns));
	}
}
