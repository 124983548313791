@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';

.label {
	color: $col-greyFontLight;
	padding: $val-spacing-s;
	padding-left: 0;
	padding-top: $val-spacing-l;
}

.value {
	padding-left: $val-spacing-s;
	padding-top: $val-spacing-s;
	padding-bottom: $val-spacing-l;
}

.grey-text {
	color: $col-greyFontLight;
}

.valid-cell-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 5px;
}

.link {
	font-size: $val-fontSizeXBig;
	color: $col-blue;
}

.reason {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.table-box {
	background-color: white;
	padding-top: $val-spacing-s;
	padding: $val-spacing-s;
	box-shadow: 0 2px 2px $col-greyConnection;
}

.table-box-header {
	color: $col-greyFontLight;
	font-size: $val-fontSizeBig;
	padding: $val-spacing-s;
}

.table {
	height:min-content;
}

.model-label {
	background-color: white;
	width: 100%;
	padding: $val-spacing-s;
	padding-left: $val-spacing-m;
	margin-bottom: $val-spacing-s;
	box-shadow: 0 2px 2px $col-greyConnection;
	display: flex;
	justify-content: space-between;
}

.model-number {
	margin-top: $val-spacing-xs;
	font-size: $val-fontSizeBig;
	font-weight: bold;
	text-transform: uppercase;
}

.serial-number {
	margin-top: $val-spacing-xs;
	font-size: $val-fontSizeBig;
	font-weight: bold;
	text-transform: uppercase;
	color: $col-design-grey10;
}

.radio-buttons {
	margin-top: 10px;
	margin-bottom: 10px;
}

.status-icon {
	padding-right: 5px;
	padding-bottom: $val-spacing-xs;
}

.title {
	display: flex;
	flex-direction: row;
	gap: $val-spacing-s;
}

.modal-title{
	font-weight: bold;
}

.asterisk {
	color: $col-orangeBorder;
}

.required-label {
	display: flex;
	flex-direction: row;
	gap: $val-spacing-xs;
}