$date-input-arrow-offset: 50px;
$date-input-popper-top-translate-y: -358px; // 10px margin (for arrow), 32px input, 1px border, 315px popper

.date-input {

	&__wrapper {
		display: flex;
	}

	&__popper {
		&--fixed {
			position: fixed !important;
			z-index: 100;

			& .react-datepicker-popper[data-placement^="bottom"] {
				transform: unset !important;
			}
			& .react-datepicker-popper[data-placement^="top"] {
				transform: translate3d($val-spacing-s, $date-input-popper-top-translate-y, 0px) !important;
			}
		}
	}

	&--connected {
		& input {
			background-color: $col-orangeBackground !important;
		}
	}

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
		
		& .react-datepicker-popper {
			right: 0;
			left: auto !important;
	
			& .react-datepicker__triangle {
				right: $date-input-arrow-offset;
				left: auto !important;
			}
		}
	}

	&--standalone {
		margin-bottom: 0;
	}
}
