@import "../../../src/styles/Colors.scss";
@import "../../../src/styles/Constants.scss";

.notificationContainer {
	position: fixed;
	right: $val-spacing-s;
	top: $val-spacing-l;
	overflow: hidden;
	transition: 300ms ease-out;
	z-index: 10000;

	.success {
		background-color: $col-green;
	}

	.info {
		background-color: $col-darkBlueBorder;
	}

	.loading {
		background-color: $col-darkBlueBorder;
	}

	.warning {
		background-color: $col-orange-600;
	}

	.error {
		background-color: $col-canceledRed;
	}
}

.notification {
	margin: $val-spacing-s;
	color: $col-greyDark;
	display: flex;
	justify-content: center;
	align-items: baseline;
	align-content: center;
	width: $val-notification;
	border-radius: $val-borderRadius;
	padding: $val-spacing-s $val-spacing-xs;
	background-color: $col-blue; // In case unknown type is sent

	animation: 500ms slide-left;
	@keyframes slide-left {
		from {
			margin-left: 100%;
		}
		to {
			margin-left: 0%;
		}
	}
}

.notificationDetails {
	padding: $val-spacing-s;
	color: $col-white;
	position: relative;
	flex: 2;
	align-self: center;
	overflow: hidden;
	text-overflow: ellipsis;
}

.notificationText {
	font-size: $val-fontSizeBig;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-height: 1.25; /* fallback */
}

.notificationDate {
	padding-top: $val-spacing-xxs;
	font-size: $val-fontSizeSmall;
}

.icon {
	width: $val-spacing-xxl;
	align-self: center;
	display: flex;
	justify-content: center;
}

.notificationIcon {
	font-size: $val-fontSizeXXXBig;
	color: $col-white;
	align-self: center;

	&.iconLoading {
		animation: $val-loadingCircleAnimation;
		animation-timing-function: linear;

		@keyframes spin-icon {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
}

.iconClose {
	font-size: $val-fontSizeXXXBig;
	color: $col-white;
	cursor: pointer;
}
