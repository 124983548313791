@import "../../../../../styles/Constants";
@import "../../../../../styles/Colors.scss";

.section {
	margin-top: $val-spacing-l;
	margin-bottom: $val-spacing-l;
	margin-left: $val-spacing-l;
	margin-right: $val-spacing-l;

	&__title {
		text-transform: uppercase;
		color: $col-design-gray5;
		font-size: $val-fontSizeBig;
		margin-bottom: $val-spacing-l;
	}
}