.pills {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	padding: $val-spacing-m 0;

	&--non-padded {
		padding: 0;
	}

	&__empty {
		font-weight: bold;
		color: $col-greyFontLight;
	}

	&__item {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: $val-spacing-xs $val-spacing-s;
		margin-right: $val-spacing-xs;
		margin-bottom: $val-spacing-xs;
		background-color: $col-greyBackground;
		border: 1px solid $col-greyConnection;
		border-radius: $val-borderRadius;
		cursor: default;
		user-select: none;
		height: $val-fieldSize;

		&--clickable {
			cursor: pointer;
		}

		& [class^="icon-"] {
			margin-left: $val-spacing-m;
			cursor: pointer;
			color: $col-greyInactive;
			font-size: $val-fontSizeBig;

			&:hover {
				color: $col-greyDark;
			}
		}

		& .color-square {
			margin-right: $val-spacing-s;
		}

		&--transparent {
			opacity: .5;
		}
	}

	&__label {
		display: flex;
		align-items: center;

		&--text-wrap {
			display: initial;
			align-items: center;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}