@import "../../../../../styles/Colors.scss";
@import "../../../../../styles/Constants.scss";

.empty-time-sheet {
	padding-bottom: $val-headerHeight;
	background-color: $col-white;
	padding: $val-spacing-m;
	color: $col-grey;
	font-style: italic;
}

.banner {
	padding: $val-spacing-l;
	background: $col-greyInactive;
	color: $col-white;
	font-weight: bold;
}