@import "./Constants.scss";
@import "./Colors.scss";

$var-iconWidth: 37px;
$var-iconHeight: 34px;
$var-borderSize: 4px;
$var-widthOffset: 0px;
$var-maxTreeViewWidth: 400px;
$var-minTreeViewWidth: 200px;
$var-treeNodePadding: 19px;
$var-inputPadding: 33px;
$var-backgroundIconSize: 13px;
$var-backgroundPositionX: 10px;
$val-borderRadius: 1px;
$val-treeNodeTopBottomPadding: 10px;
$val-ccIconWidth: 14px;
$val-ccIconHeight: 13px;
$val-treeNodeHeight: 50px;
$var-activeLineShadow: 5px 0px 0px -3px $col-greyConnection inset;

.task-cost-code-select {
	background: $col-blueBackground;
	padding: $val-spacing-m 0;
	border-left: $var-borderSize solid $col-blue;

	& input {
		padding-left: $var-inputPadding;
		background-size: $var-backgroundIconSize;
		background-color: $col-white;
		background-repeat: no-repeat;
		background-position-x: $var-backgroundPositionX;
		background-position-y: center;
		background-image: url(/images/sidebar/ic_cc_inactive.svg);
	}
}

.treeview-select {
	& label {
		float: left;
		width: 100%;
	}

	& .form-group {
		margin: 0;

		& > div {
			display: flex;
			width: 100%;
			position: relative;
			top: 2px;
		}

		& > .form-group {
			& > .input-group {
				display: none;
			}

			& > .help-block {
				width: 100%;
			}
		}
	}

	& .bootstrap-typeahead {
		width: calc(100% - #{$var-iconWidth + $var-widthOffset});
		float: left;

		& .bootstrap-typeahead-input input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	& .rbt.clearfix.open {
		width: 100%;
		left: 1px;
		position: relative;
		display: inline-table;
	}
}

.treebeard-item-label {
	display: flex;
	align-items: center;
}
