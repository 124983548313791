@import "./Colors.scss";
@import "./Constants.scss";

$var-prodAndBillingRowHeight: 50px;
$var-verticalPercentageIndicator: 1px;

.dashboard-card {
	background: $col-white;
	border-radius: $val-spacing-xxs;
	border: 1px solid $col-greyConnection;
	box-shadow: 0 2px 2px 0 $col-shadow;
	flex: 1;
	display: flex;
	flex-direction: column;

	& sup {
		font-size: $val-fontSizeRegular;
	}
}

.dashboard-card-header {
	padding: $val-spacing-l;
	color: $col-greyFontLight;
	font-size: $val-fontSizeSmall;
	text-transform: uppercase;

	&.dashboard-card-header--highlighted-gray {
		background-color: $col-greyBackground;
	}
}

.dashboard-card-content {
	padding: $val-spacing-l 0 !important;
	margin: 0 !important;
	flex: 1;

	& .row {
		margin: 0;
	}

	&.dashboard-card-content--highlighted-gray {
		background-color: $col-greyBackground;
	}

	&.dashboard-card-content--middle {
		display: flex;
		align-items: center;
	}
}

.dashboard-card-header + .dashboard-card-content {
	padding-top: 0 !important;
}

.dasboard-card-text-item {
	display: flex;
	align-items: center;
	padding-bottom: $val-spacing-m;

	& .dashboard-card-text-label,
	& .dashboard-card-text-value {
		flex: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-right: $val-spacing-s;
	}

	& .dashboard-card-text-label {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		flex: 1;
		white-space: normal;

		& .dashboard-dot {
			margin-right: $val-spacing-s;
		}
	}
}

.dashboard-card-divider {
	height: 1px;
	width: 100%;
	margin-bottom: $val-spacing-m;
	background-color: $col-greyConnection;
}

.dashboard-card-text {
	display: flex;
	align-items: center;

	& img {
		margin-right: $val-spacing-s;
	}
}

.dashboard-dot {
	height: $val-spacing-s;
	width: $val-spacing-s;
	border-radius: 50%;
	background-color: $col-black;
	flex: initial;

	&.dashboard-dot--blue {
		background-color: $col-blue;
	}

	&.dashboard-dot--light-blue {
		background-color: $col-blueHighlight;
	}

	&.dashboard-dot--orange {
		background-color: $col-orange;
	}
}

.dashboard-column-percentage-item {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	& .percentage-value {
		font-size: $val-fontSizeXXBig;
		
		& .percentage-sign {
			font-size: $val-fontSizeSmall;
			position: relative;
			top: -$val-fontSizeRegular;
		}
	}

	& > div {
		margin: $val-spacing-m 0;
	}	
}

.percentage-value {
	font-size: $val-fontSizeXXBig;
	
	& span {
		font-size: $val-fontSizeXXBig;	
	}
	
	&.green {
		color: $col-green;
	}

	&.orange {
		color: $col-orange;
	}
	
	&.red {
		color: $col-red;
	}

	& .percentage-sign {
		font-size: $val-fontSizeSmall;
		position: relative;
		top: -$val-fontSizeRegular;

		&.green {
			color: $col-green;
		}
	
		&.orange {
			color: $col-orange;
		}
		
		&.red {
			color: $col-red;
		}
	}
}

.percentage-container {
	flex: 1;
	height: $var-prodAndBillingRowHeight;
	display: flex;
	align-items: center;
	border-left: 1px solid $col-greyBackground;
	border-right: 1px solid $col-greyBackground;
	margin: 0 $val-spacing-m;

	& .progress {
		margin-left: -12 * $var-verticalPercentageIndicator;
		z-index: 2;
		flex: 1;
		background-color: white;
		box-shadow: none !important;
	
		&.green {
			& .progress-bar {
				background-color: $col-green;
				box-shadow: 0;
			}
		}
	
		&.orange {
			& .progress-bar {
				background-color: $col-orange;
				box-shadow: 0;
			}
		}
		
		&.red {
			& .progress-bar {
				background-color: $col-red;
				box-shadow: 0;
			}
		}
	}

	& .vertical-percentage-indicator {
		position: relative;
		height: $var-prodAndBillingRowHeight;
		padding: 0 $var-verticalPercentageIndicator;
		border-right: $var-verticalPercentageIndicator solid $col-greyBackground;

		&.percent-20 {
			left: 20%;
		}
	
		&.percent-40 {
			left: 40%;
		}
	
		&.percent-60 {
			left: 60%;
		}
	
		&.percent-80 {
			left: 80%;
		}
	}
}

.dashboard-solid-line {
	width: $val-spacing-l;
	border-top: $val-spacing-xxs solid;
	margin-right: $val-spacing-s;
	margin-top: $val-spacing-xs * 1.5;

	&.dashboard-solid-line--blue {
		border-color: $col-blue;
	}

	&.dashboard-solid-line--orange {
		border-color: $col-orange;
	}
}

.dashboard-dashed-line {
	width: $val-spacing-l;
	border-top: $val-spacing-xxs dashed;
	margin-right: $val-spacing-s;
	margin-top: $val-spacing-xs * 1.5;

	&.dashboard-dashed-line--orange {
		border-color: $col-orange;
	}
}

.dasboard-card-production-chart-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: $val-spacing-l;

	& .dasboard-card-production-chart-label {
		color: $col-greyFontLight;
		font-size: $val-fontSizeSmall;
	}

	& .dasboard-card-production-chart-value {
		font-weight: bold;
		font-size: $val-fontSizeXBig;
	}
}

.dashboard-card-chart {
	margin-bottom: $val-spacing-l;
	position: relative;

	&.dashboard-card-chart--production-rate {
		display: flex;
		align-items: center;
	}

	& .dashboard-card-chart-value {
		font-size: $val-fontSizeXXBig;
		font-weight: bold;

		& .dashboard-card-chart-value--negative {
			color: $col-red;
		}

		& .dashboard-card-chart-value--positive {
			color: $col-green;
		}
	}

	& .dashboard-card-chart-value--pie-chart {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		pointer-events: none;
	}
}

.dashboard-card-pending-calculations {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	height: 100%;

	& .dashboard-card-pending-text {
		font-size: $val-fontSizeRegular;
		font-weight: bold;
		color: $col-greyFontLight;
		margin-top: $val-spacing-m;
	}
}

.dashboard-navigation {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding-bottom: $val-spacing-s;

	& .dashboard-navigation-back,
	& .dashboard-navigation-date {
		font-weight: bold;
		font-size: $val-fontSizeBig;
	}
}

.no-jobs-in-progress {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-weight: bold;
	color: $col-greyFontLight;
	font-size: $val-fontSizeRegular;
	margin-bottom: $val-spacing-xxxl;

	& img {
		margin: $val-spacing-l;
	}
}
