.contact-action-modal {

	.modal-body > div {
		margin-top: $val-spacing-s;
		margin-bottom: $val-spacing-l;
	}

	&__description-container {
		font-size: $val-fontSizeBig;
	}

	&__form-switcher {
		display: flex;

		& > .radio-button {
			padding-right: $val-spacing-l;
		}
	}

	&__form-container {
		background-color: $col-design-gray1;
		padding-top: $val-spacing-s;
		padding-bottom: $val-spacing-s;

		& > div > .row {
			padding-top: $val-spacing-s;
			padding-bottom: $val-spacing-s;
		}
	}

	&__new-contact-form {
		$var-input-row-height: 48px;

		height: #{2 * $var-input-row-height + $val-fieldSize + 2 * $val-spacing-s};

		&__input-row {
			height: $var-input-row-height;
		}
	}

	&__saved-contact-form {
		&__contact-method-description {
			padding-top: $val-spacing-xs;
			padding-bottom: $val-spacing-s;
		}
	}
}