@import '../../../styles/Colors.scss';
@import '../../../styles/Constants.scss';

.row-edit-mode {
	border: 1px solid $col-orangeBorder !important;
	background-color: $col-design-orange5 !important;

	& + & {
		border-top: none !important;
		margin-top: 0 !important;
	}
}


.delete-cell {
	& > [class^="icon-delete"] {
		color: $col-canceledRed;
		font-size: $val-fontSizeBig;
		cursor: pointer;

		&:hover {
			color: $color-red;
		}
	}
}

.edit-cell {
	& > [class^="icon-"] {
		color: $col-blue;
		font-size: $val-fontSizeBig;
		cursor: pointer;

		&:hover {
			color: $col-darkBlueBorder;
		}
	}
}

.newly-saved-row {
	animation: newly-added 2000ms normal;
}

.row-with-error {
	& > span {
		&:has(.edit-cell) {
			& > span > [class^="icon-"] {
				color: $col-design-blue3 !important;
				cursor: not-allowed;
			}
		}
	}
}

@keyframes newly-added {
	0% {
		background: $col-design-orange6;
	}

	100% {
		background: $col-design-grey13;
	}
}