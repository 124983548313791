@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

@mixin status-label {
	text-align: center;
	padding: 0 $val-spacing-m;
	border-radius: $val-borderRadius;
	font-weight: bold;
	font-size: $val-fontSizeSmall;
	min-width: 100px;
	text-overflow: hidden;
	background-color: $col-design-grey12;
}

.order-table {

	&__sub-text {
		color: $col-greyFontLight;
		font-size: $val-fontSizeSmall;
	}

	&__new-status {
		@include status-label;
		color: $col-blue;
	}

	&__in-progress-status {
		@include status-label;
		color: $col-orangeBorder;
	}

	&__completed-status {
		@include status-label;
		color: $col-green;
	}
}

.table-filter {
	display: flex;
}

.completed-filter {
	margin-right: 8px;
	display: flex;
	flex-direction: row;
}

.completed-filter-button {
	border: 1px solid $col-greyMid;
	border-radius: $val-borderRadius;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	display: flex;
	align-items: center;
	background: $col-white;
	width: 200px;
}