$var-border: 1px solid $col-greyConnection;
$var-table-header-height: 64px;
$var-activeUnderlineShadow: 0px -6px 0px -3px $col-orange inset;
$var-search-outline-offset: -3px;
$var-animation-duration: 2s;
$var-outline-border: 2px;

@mixin default-tab {
	font-size: $val-fontSizeRegular;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 $val-spacing-l;
	flex-shrink: 0;
}

@mixin active-tab ($color-background, $color-text) {
	background: $color-background;
	box-shadow: $var-activeUnderlineShadow;
	color: $color-text;
}

.tabs-navigation {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	height: $var-table-header-height;
	border-bottom: none;
	overflow-y: hidden;
	flex: 1;
	background-color: $col-white;
	padding: 0;
	user-select: none;

	&::-webkit-scrollbar {
		height: 0px !important;
		background: transparent !important;
	}

	&__tab {
		@include default-tab;

		&--active {
			@include active-tab($col-orangeHover, $col-black)
		}
	}

	&__work-summary-tab {
		@include default-tab;
		background: $col-greyDark;
		color: $col-white;

		&--active {
			@include active-tab($col-greyDark, $col-white);
		}

	}

	&.tabs-navigation-uniform {
		.tabs-navigation__tab {
			flex: 1;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		background-color: $col-white;
		padding: 0;
		border-bottom: $var-border;

		& .icon-edit,
		& .icon-left,
		& .icon-right {
			padding: 0 $val-spacing-m;
			color: $col-greyFontLight;
			cursor: pointer;

			&:hover {
				color: $col-greyDark;
			}
		}

		& .icon-edit {
			font-size: $val-fontSizeXBig;
		}

		& .icon-right,
		& .icon-left {
			font-size: $val-fontSizeXXBig;
		}

		& .icon-right:not(:last-child) {
			// icon-edit or something else is after the right arrow
			padding-right: 0;
		}
	}
}