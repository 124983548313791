$button-radius: calc(#{$val-fieldSize} / 2);
$button-min-width: 80px;
$small-button-height: 26px;
$big-button-size: 48px;
$val-export-button-width: 132px;

button,
button:hover,
button:active,
button:focus,
.btn,
.btn:hover,
.btn:active,
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
}

.btn-default {
	&, &:hover, &:active {
		border: none !important;
		background: none !important;
		border-radius: 0;
	}
}

.btn {
	@extend .background-color-animation;

	border-radius: $button-radius;
	height: $val-fieldSize;
	padding: 0 $val-spacing-m;
	min-width: $button-min-width;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;

	& [class^="icon-"] {
		font-size: $val-fontSizeBig;
		margin-right: $val-spacing-s;
	}

	& + & {
		margin-left: $val-spacing-s;
	}

	&--non-padded {
		& + & {
			margin-left: 0;
		}
	}

	&--justify-start {
		justify-content: start;
	}

	&--full {
		width: 100%;
	}

	&--rectangle {
		border-radius: $val-borderRadius;
		border: 1px solid $col-greyMid;
		background: $col-greyBackground;
		color: $col-greyDark;
		font-weight: normal;

		&.btn--active:hover {
			color: $col-white !important;
		}
	}

	&--open-left {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&--flat {
		outline: none;
		border: none !important;
		border-radius: 0;
		display: flex;
		align-items: center;
		background: transparent;
		background-color: transparent !important;
		color: $col-greyInactive;
		
		&:hover, &:focus, &:active {
			color: $col-black !important;
		}

		&.btn--disabled, &:disabled, &.disabled {
			&:hover {
				background: inherit;
			}
		}
	}

	&--icon {
		min-width: $val-fieldSize;
		width: $val-fieldSize;
		padding: 0;
		overflow: hidden;

		& [class^="icon-"] {
			margin-right: 0;
		}

		&.btn--small {
			height: $small-button-height;
			width: $small-button-height;
			min-width: $small-button-height;
		}
	}

	&--small {
		height: $small-button-height;
	}

	&--big {
		min-width: $big-button-size;
		height: $big-button-size;
		font-size: $val-fontSizeBig;
		border-radius: calc(#{$big-button-size} / 2);
	}

	&--big-icon {
		min-width: $big-button-size;
		width: $big-button-size;
		height: $big-button-size;
		border-radius: calc(#{$big-button-size} / 2);
		padding: 0;
		overflow: hidden;
		
		& [class^="icon-"] {
			font-size: $val-fontSizeXBig;
			margin-right: 0;
		}
	}

	&--huge-icon {
		min-width: $big-button-size;
		width: $big-button-size;
		height: $big-button-size;
		border-radius: calc(#{$big-button-size} / 2);
		padding: 0;
		overflow: hidden;
		
		& [class^="icon-"] {
			font-size: $val-fontSizeHuge;
			margin-right: 0;
		}
	}

	&--active {
		color: $col-white;
		background: $col-blue;
		border-color: $col-blue;
	}

	&--upload {
		&, &:active, &:focus {
			color: $col-blue;
		}

		&:hover {
			color: $col-blueHover;
			background: $col-transparent;
		}
	}

	&--link {
		&, &:active {
			color: $col-blue;
			background-color: transparent !important;
			border: none !important;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			font-weight: normal;
			cursor: pointer;
			padding: 0;
		}

		&:hover, &:focus, &:focus:active {
			text-decoration: none;
			color: $col-blueHover;
		}
	}
	
	&--link-disabled {
		color: $col-greyFontLight !important;
		cursor: not-allowed;
	}

	&--flex {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	&--red-hover {
		&:hover, &:focus {
			color: $col-white !important;
			background-color: $col-red !important;
			border-color: $col-red !important;
		}
	}

	&--blue-hover {
		&:hover, &:focus {
			border-color: $col-blue !important;
			color: $col-blue !important;
		}
	}

	&--white {
		color: $col-white !important;
	}

	&--disabled, &:disabled, &.disabled {
		border: 1px solid $col-greyMid;
		background-color: $col-greyLight;
		color: $col-greyMid;
		opacity: 1;

		&:hover {
			border: 1px solid $col-greyMid;
			background-color: $col-greyBackground;
			color: $col-greyFontLight;
		}
	}

	&--floating {
		position: fixed;
		right: $val-spacing-xl;
		bottom: $val-spacing-xl;
		z-index: 3;
	}

	&--floating-above {
		position: fixed;
		right: $val-spacing-xl;
		bottom: $val-spacing-xl * 3;
		z-index: 3;
	}

	&--filter {
		border-radius: $val-borderRadius;
		border: 1px solid $col-greyMid;
		background: $col-white;

		.btn {
			padding: 0;
			min-width: auto;
			display: flex;
			justify-content: center;
		}

		&.btn--icon {
			background: $col-greyBackground;
		}
	}

	&--filter-right {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&--filter-left {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&--filter-middle {
		border-radius: 0;
		border-left: none;
	}

	&__rectangle-group {
		display: flex;

		.btn--rectangle {
			margin: 0 !important;
			border-radius: 0;
			border-right: none;
		}

		& > .tooltip__trigger-wrapper:last-child > .btn--rectangle,
		& > .btn--rectangle:last-child {
			border-right: 1px solid $col-greyMid;
			border-top-right-radius: $val-borderRadius;
			border-bottom-right-radius: $val-borderRadius;
		}

		& > .tooltip__trigger-wrapper:first-child > .btn--rectangle,
		& > .btn--rectangle:first-child {
			border-top-left-radius: $val-borderRadius;
			border-bottom-left-radius: $val-borderRadius;
		}

		&--flat-left {
			& > .tooltip__trigger-wrapper:first-child > .btn--rectangle,
			& > .btn--rectangle:first-child {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	&__inline-group {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	
		&--left {
			justify-content: flex-start;
		}
	
		&--right {
			justify-content: flex-end;
		}
	}

	&__switch-group-item {
		&, &:focus, &:hover, &:active {
			font-size: $val-fontSizeSmall;
			flex-grow: initial;
			background-color: $col-greyLight !important;
			color: $col-greyFontLight !important;
			border: 1px solid $col-greyMid !important;
			flex: 1 !important;
			border-radius: 0;
			text-align: center;
			align-self: center;
			height: $val-fieldSize;

			&:first-child {
				border-top-left-radius: $val-borderRadius !important;
				border-bottom-left-radius: $val-borderRadius !important;
			}

			&:last-child {
				border-top-right-radius: $val-borderRadius !important;
				border-bottom-right-radius: $val-borderRadius !important;
			}
		}

		// Needs to stay with a non-standard modifier - used as bootstrap defined class
		&.active {
			background-color: $col-greyBackground !important;
			color: $col-greyDark !important;
		}
	}

	&--open-left {
		border-left: none !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		margin-left: 0 !important;
	}

	&--open-right {
		border-right: none !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		margin-right: 0 !important;
	}

	&--flat-left {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}

	&--flat-right {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	&--control {
		font-weight: normal;
		padding: 0;
		font-size: $val-fontSizeSmall;
		line-height: $val-lineHeightSmall;
		color: $col-blue;
		cursor: pointer;

		& + & {
			margin-left: $val-spacing-s;
		}
	}
}

.btn-success {
	border: 1px solid $col-blue;
	background-color: $col-blue;

	&:hover, &:active {
		border: 1px solid $col-blueHover;
		background-color: $col-blueHover;
	}
}

.btn-toggle {
	background-color: $col-transparent;
	border: 1px solid $col-greyMid;
	color: $col-greyDark;

	&:active, &.active {
		border: 1px solid $col-blue;
		background-color: $col-blue;
		color: $col-white;
	}

	&.active:hover {
		border: 1px solid $col-blueHover;
		background-color: $col-blueHover;
		color: $col-white;
	}

	&:hover {
		border: 1px solid $col-blue;
		color: $col-blueHover;
	}
}

.btn-info {
	&, &:focus, &:active {
		background-color: $col-transparent !important;
		border: 1px solid $col-greyMid !important;
		color: $col-greyDark !important;
	}

	&.btn--disabled, &:disabled, &.disabled {
		background-color: $col-greyLight !important;
		color: $col-greyMid !important;
	}

	&:hover {
		background-color: $col-greyBackground;
	}

	&--blue-outline {
		border-color: $col-blue !important;
		color: $col-blue !important;
	}

	&--blue-fill {
		&, &:active {
			border-color: $col-blue !important;
			background-color: $col-blue !important;
			color: $col-white !important;
		}

		&:hover, &:focus {
			border-color: $col-blue !important;
			background-color: $col-blueHover !important;
			color: $col-white !important;
		}
	}
}

.btn-primary {
	&, &:focus, &:active:focus, &:active:hover {
		border: 1px solid $col-orange;
		background-color: $col-orange;
	}

	&:hover, &:active {
		border: 1px solid $col-orange;
		background-color: $col-orangeBorder;
	}

	// Override for default disabled fetching state (we want it colored)
	&.btn--disabled, &:disabled, &.disabled {
		&.btn--fetching {
			background-color: $col-orange;
			color: $col-white;
			border: 1px solid $col-orange;

			&:hover, &:active {
				border: 1px solid $col-orange;
				background-color: $col-orangeBorder;
			}
		}
	}
}

.btn-danger {
	border: 1px solid $col-red;
	background: $col-red;

	&.btn--disabled, &:disabled, &.disabled {
		border: 1px solid $col-greyMid;
		background-color: $col-greyLight;
		color: $col-greyMid;
		opacity: 1;
	}
}

.btn-dropdown {
	&.open {
		& .btn-info {
			@extend .btn-info;
		}

		&.right ul {
			left: auto;
			right: 0;
		}

		hr {
			margin: $val-spacing-s 0;
		}
	}
}