@import "./Colors.scss";

$var-grabHandleSize: 16px;

.resizable-container {
	width: 100%;
	height: 100%;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;
	will-change: width, height;

	& .grab-handle {
		position: absolute;
		right: 0;
		bottom: 0;
		border-left: $var-grabHandleSize solid transparent;
		border-bottom: $var-grabHandleSize solid $col-greyMid;
		cursor: nwse-resize;
	}
}

#image-edit-modal {
	& .modal-dialog {
		margin: auto;
		width: fit-content;

		& .modal-content {
			max-width: 100vw !important;
			max-height: 100vh !important;

			& .resizable-container {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.resizable-image-edit-container {
	display: flex;
	flex-flow: column nowrap;

	& .modal-body {
		flex-grow: 1;
		overflow: hidden;

		& .modal-edit-container {
			width: 100%;
			height: 100%;

			& .crop-container {
				width: 100% !important;
				height: 100% !important;
				max-height: none !important;
			}
		}
	}
}
