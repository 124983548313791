@import "../../../../src/styles/Colors.scss";
@import "../../../../src/styles/Constants.scss";

.icon {
	width: $val-spacing-l;
	align-self: center;
	justify-content: center;
	font-size: $val-icon16,
}

.container {
	display: flex;
	flex-direction: column;
	gap: $val-spacing-s;

	&__padded {
		padding: $val-spacing-l 0;
	}
}

.pwRequirementsElement {
	p {
		margin: 0;
	}
	
	padding: $val-spacing-s 0;
}

.pwRequirementsContainer {
	display: flex;
 	align-items: center;

	p {
		margin: 0;
	}
}

.textGrey {
	color: $col-design-grey13 !important;
}

.submitButton {
	margin: 0px $val-spacing-xxxl
}

.formPadding {
	padding: 0px $val-spacing-s;
}

.marginBottom {
	&__s {
		margin-bottom: $val-spacing-s;
	}

	&__l {
		margin-bottom: $val-spacing-xl;
	}
}