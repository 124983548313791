@import "../../../../../../src/styles/Colors.scss";
@import "../../../../../../src/styles/Constants.scss";

.modal {
	width: $val-modal-xl !important;
	display: flex !important;
	justify-content: center !important;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&__big-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		overflow: initial !important;

		&::before,
		&::after {
			display: none;
		}

		&__thin {
			font-size: $val-fontSizeXBig;
			font-weight: normal;
		}
	}

	&__body {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	}

	.img-preview {
		max-height: 500px;
		max-width: 600px;
	}

	.duplicate-name-error {
		margin-top: $val-spacing-s;
		color: $col-red;
	}
}

.settings-actions {
	font-size: $val-fontSizeXXXBig;

	&__dropdown-toggle {
		color: $col-black;
		justify-content: center;
		cursor: pointer;

		&:focus,
		&:hover,
		&:active,
		&:active:hover,
		&:active:focus {
			color: $col-black;
		}
	}
}

input {
	&.input-text-field {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		background-color: $col-white;
		font-size: $val-fontSizeRegular;
		line-height: $val-lineHeightRegular;
		outline: none;
		height: $val-fieldSize;
		width: -webkit-fill-available;
	}
}