@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

.job-work-summary {

	&__empty-cell {
		color: $col-greyFontLight;

		&--right-aligned {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
}