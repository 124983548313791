.duration-input {
	&__inputs {
		display: flex;

		.form-group {
			.input-group-append {
				border-left: 1px solid $col-greyMid !important;

				&:not(:last-child) {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}

				&.with-error {
					border-color: $col-red;
				}
			}

			input {
				text-align: right;
				border-right: none;

				&:not(:first-child) {
					border-left: none;
				}
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}
}
