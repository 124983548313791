
.schedule-board-view-switcher {
	margin-left: $val-spacing-s;

	&__toggle {
		@extend .btn;
		@extend .btn-primary;
		@extend .btn--icon;

		&.btn.btn-primary {
			&, &:not([disabled]), &:active, &:focus, &:hover, &:active:focus {
				// Required for specificity - to override dropdown-toggle
				padding: 0;
				height: $val-fieldSize;
				width: $val-fieldSize;
				border-radius: $val-fieldSize;
				background-color: $col-orange !important;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	&__menu {
		width: max-content;
	}
}
