.time-sheet-signature-status-label {
	$var-borderRadius: 2px;
	$var-imageHeight: 40px;
	$var-imageMaxWidth: 200px;

	color: $col-greyDark;
	font-size: $val-fontSizeSmall;
	font-weight: normal;

	&+& {
		margin-top: $val-spacing-m;
	}

	&__superintendent {
		color: $col-red;
		margin: $val-spacing-s 0;
	}

	&__pill {
		border-radius: $var-borderRadius;
		color: $col-white;
		display: flex;
		width: fit-content;
		font-size: $val-fontSizeSmall;
		font-weight: bold;
		line-height: $val-lineHeightRegular;
		padding: 0 $val-spacing-s;
		align-items: center;

		&--outdated {
			background-color: $col-orangeBorder;
		}

		&--signed {
			background-color: $col-green;
		}

		&--unsigned {
			background-color: $col-greyMid;
		}

		&--approved {
			background-color: $col-blue;
		}

		&--rejected {
			background-color: $col-red;
		}

		&--modal {
			margin-left: $val-spacing-s;
		}
	}


	&__date {
		margin-left: $val-spacing-xs;

		&--block {
			display: block;
		}
	}

	&__name {
		margin-left: $val-spacing-xs;
	}

	&__image {
		margin-top: $val-spacing-s;
		height: $var-imageHeight;
		max-width: $var-imageMaxWidth;
	}

	&--inline {
		display: flex;
		margin-bottom: $val-spacing-m;

		.text-red {
			margin-left: $val-spacing-m;
		}
	}
}