.breadcrumbs {
	font-size: $val-fontSizeBig;
	font-weight: bold;
	margin: $val-spacing-s 0 $val-spacing-l $val-spacing-s;
	text-transform: uppercase;
	user-select: none;
	
	&__separator {
		margin: 0 $val-spacing-s;
		color: $col-grey !important;
		cursor: default;
	}
	
	&__item {
		cursor: default;
		color: $col-grey !important;

		&--current {
			color: $col-greyDark !important;
		}

		&--link {
			cursor: pointer;

			&:hover {
				color: $col-blue !important;
			}
		}
	}
}