@import "../../../../../src/styles/Colors.scss";
@import "../../../../../src/styles/Constants.scss";

.icon {
	width: $val-spacing-l;
	align-self: center;
	justify-content: center;
	font-size: $val-icon16,
}

.pwRequirementsElement {
	p {
		margin: 0px;
	}

	padding-bottom: 10px;
}

.pwRequirementsContainer {
	display: flex;
 	align-items: center;

	p {
		margin: 0px;
	}
}

.textGrey {
	color: $col-design-grey13 !important;
}
