.time-picker {
	padding: $val-spacing-xs $val-spacing-s;

	&__controls {
		display: flex;
		align-items: center;

		&__time-input-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1;
		}

		&__spacer {
			margin: 0 $val-spacing-xs;
		}

		&__shift-control {
			cursor: pointer;
			font-size: $val-fontSizeBig;
		}

		&__time-input {
			@extend .form-control;
			width: 40px;
			text-align: center;
			margin: 2px 0;
		}

		&__ampm {
			margin: 0 $val-spacing-s;

			.radio-button + .radio-button {
				margin-top: $val-spacing-s;
			}
		}
	}

	&__options {
		display: flex;
		margin-top: $val-spacing-s;
		padding: $val-spacing-xs $val-spacing-s;

		button {
			justify-content: center;
		}

		button:first-child {
			margin-right: $val-spacing-s;
		}
	}

	&__current-time-button {
		color: $col-darkBlueBorder;
		cursor: pointer;
		font-size: $val-fontSizeRegular;
		text-align: center;
		margin-top: $val-spacing-m;

		&__icon {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
			position: relative;
			top: 2px;
		}
	}
}
