$min-modal-height: 15vh;
$max-modal-height: 75vh;
$header-footer-z-index: 10;

.modal {
	width: 100%;
	display: flex !important;
	justify-content: center;
	align-items: center;
	align-content: center;
	height: 100vh;

	&--visible {
		opacity: 1;
	}

	&--sm {
		.modal-dialog .modal-content {
			width: $val-modal-sm;
		}
	}

	&--md {
		.modal-dialog .modal-content {
			width: $val-modal-md;
		}
	}

	&--lg {
		.modal-dialog .modal-content {
			width: $val-modal-lg;
		}
	}

	&--xl {
		.modal-dialog .modal-content {
			width: $val-modal-xl;
		}
	}

	&--xxl {
		.modal-dialog .modal-content {
			width: $val-modal-xxl;
		}
	}

	&--non-selectable {
		user-select: none;
	}

	&__scrollable-wrapper {
		display: flex;
		flex-flow: column nowrap;
		height: 100%;

		.modal-body {
			flex: 1;
		}
	}

}

.modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	min-height: 3 * $val-spacing-l;
	background: $col-greyBackground;
	padding: $val-spacing-l;
	position: sticky;
	top: 0;
	z-index: $header-footer-z-index;
	border-bottom: 1px solid $col-greyConnection !important;

	&__close {
		margin: 0 !important;
		opacity: 0.5 !important;
		color: $col-greyDark;
		border: none;
		background: none;
		font-weight: bold;

		&:hover {
			opacity: 1 !important;
		}

		&:after {
			vertical-align: middle;
			height: $var-closeModalSize;
			width: $var-closeModalSize;
			content: '\e90b';
			font-family: 'icons';
			font-size: $val-fontSizeBig;
			margin-top: $val-spacing-xs;
		}

		&>span:first-child {
			display: none;
		}
	}
}

.modal-title {
	font-size: $val-fontSizeBig;
	align-items: center;
	display: flex;
	width: 100%;
	color: $col-greyDark;
	font-weight: bold;
	text-align: left;

	&--filler {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.modal-body {
	@extend .custom-scrollbar;

	padding: $val-spacing-l;
	overflow-x: hidden;
	overflow-y: auto;

	hr {
		margin: 0;
		margin-left: -$val-spacing-l;
		margin-right: -$val-spacing-l;
	}

	&--all-but-top {
		padding-top: 0;
	}

	&--visible-overflow {
		overflow: visible !important;
	}

	&--padded-none {
		padding: 0 !important;
	}

	&--padded-horizontal {
		padding: 0 $val-spacing-l !important;
	}

	&--padded-vertical {
		padding: $val-spacing-l 0 !important;
	}

	&--padded-all {
		padding: $val-spacing-l !important;
	}

	&--margin-none {
		margin: 0 !important;
	}

	&--margin-top {
		margin-top: $val-headerHeight;
	}

	&--margin-double-top {
		margin-top: 2 * $val-headerHeight;
	}
}

.modal-dialog {
	height: 100%;
	width: 100%;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	transform: translate(0, 0) !important; // override for bootstrap
}

.modal-content {
	display: flex;
	flex-direction: column;
	pointer-events: all;
	min-height: $min-modal-height;
	max-height: $max-modal-height;
	border-radius: $val-borderRadius !important;
	height: initial;
	overflow-y: scroll;
	transition: height .5s ease-in;

	/* Width */
	&::-webkit-scrollbar {
		width: 0;
	}

	/* Track, Handle, Handle on hover */
	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:hover {
		border-radius: 0;
		background: $col-transparent !important;
	}

	&>div:not([class^="modal-"]):not(.resizable-container) {
		height: 100% !important;
		height: -moz-available !important;
		height: -webkit-fill-available !important;
		height: stretch !important;
		overflow: hidden;
	}

	.modal-text {
		&--highlighted {
			color: $col-orange;
			font-weight: bold;
		}
	}
}

.modal-backdrop {
	&.show {
		opacity: 1;
		background: $col-curtain !important;
	}
}

.modal-footer {
	padding: $val-spacing-m $val-spacing-l;
	border: none !important;
	display: flex;
	justify-content: flex-end;
	position: sticky;
	bottom: 0;
	z-index: $header-footer-z-index;
	background: $col-white;
	border-top: 1px solid $col-greyConnection !important;
}

.modal-scrollbar {
	position: absolute;
	right: 0;
	width: $val-spacing-s;
}

// Modal styles

.danger-modal {

	.modal-header,
	.modal-title {
		background-color: $col-red;
		color: $col-white;
	}

	.modal-header__close {
		color: $col-white !important;
	}
}

.warning-modal {

	.modal-header,
	.modal-title {
		background-color: $col-orange;
		color: $col-white;
	}

	.modal-header__close {
		color: $col-white !important;
	}
}

.grey-warning-modal {

	.modal-header,
	.modal-title {
		background-color: $col-greyInactive;
		color: $col-white;
	}

	.modal-header__close {
		color: $col-white !important;
	}
}