@import '../../../../../styles/Colors.scss';
@import '../../../../../styles/Constants.scss';
@import '../../../../../components/Table/styles.module.scss';

$var-shadow: 0 1px 4px 0 $col-shadow;
$var-submit-action-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;
$var-lost-for-reason-width: 250px;

.invoice {

	&__table-header {
		font-size: $val-fontSizeSmall;

		&>span {
			text-transform: uppercase;
		}

		&>b {
			margin-left: $val-spacing-xs;
			margin-right: $val-spacing-l;
		}
	}

	&__sub-row-cell {
		color: $col-greyFontLight;
	}

	&__no-billing-code {
		color: $col-greyFontLight;
		font-style: italic;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__empty-cell {
		color: $col-greyFontLight;

		&--right-aligned {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}

	&__status-cell {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&--draft {
			[class^="icon-draft"] {
				margin-right: $val-spacing-s;
				color: $col-design-gray6;
			}
		}

		&--invoiced {
			[class^="icon-invoice_status_invoiced"] {
				margin-right: $val-spacing-s;
				color: $col-orange;
			}
		}

		&--partially-paid {
			[class^="icon-check"] {
				margin-right: $val-spacing-s;
				color: $col-darkBlueBorder;
				font-weight: bold;
			}
		}

		&--paid {
			[class^="icon-check"] {
				margin-right: $val-spacing-s;
				color: $col-green;
				font-weight: bold;
			}
		}

		&--overpaid {
			[class^="icon-check"] {
				margin-right: $val-spacing-s;
				color: $col-red;
				font-weight: bold;
			}
		}

		&--sub-row-cell {
			color: $col-greyFontLight;
		}

		&>b {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&__download-cell {
		color: $col-blue;
		
		&__text {
			padding-left: $val-spacing-s;	
		}
	}

	.table-container__table__body {
		height: calc(100vh - $var-company-header-height - $var-breadcrumbs-header-height - $var-tabs-navigation-height - $var-table-header-height - $var-table-action-header-height - $var-table-footer-height - 4 * $val-spacing-xl - 6 * $val-spacing-m);
	}
}

.invoice-form {

	&__submit-section {
		display: flex;
		align-items: end;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 2px solid $col-greyBackground;
		top: $var-submit-action-top-offset;
		position: sticky;

		&__hint {
			color: $col-greyFontLight;
			font-style: italic;
			flex: 1;
		}
	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
		z-index: 3;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;

		&__form-section {
			background-color: $col-white;
			box-shadow: $var-shadow;
			padding: $val-spacing-m $val-spacing-l;

			&__title {
				text-transform: uppercase;
				color: $col-design-gray5;
				font-size: $val-fontSizeSmall;
				margin-bottom: $val-spacing-m;
			}

			&__row {
				display: grid;
				gap: $val-spacing-l;
				grid-template-columns: repeat(8, 1fr);

				&__column-1 {
					grid-column: span 1;
				}

				&__column-2 {
					grid-column: span 2;
				}

				&__column-4 {
					grid-column: span 4;
				}

				&__column-6 {
					grid-column: span 6;
				}

				&__column-8 {
					grid-column: span 8;
				}

				&__centered-column {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&__delete-button {
		margin-top: $val-spacing-m;

		& > [class^="icon-delete"] {
			color: $col-grey;
			font-size: $val-fontSizeBig;
			cursor: pointer;
			margin-top: calc($val-spacing-l + $val-spacing-s);

			&:hover {
				color: $col-black;
			}
		}
	}

	&__tab {
		display: flex;
		gap: $val-spacing-xs;
		align-items: center;

		& > [class^="icon-warning"] {
			color: $col-canceledRed;
			font-size: $val-fontSizeBig;

		}
	}

	&__add-field-button {
		width: fit-content;
		color: $col-blue;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-top: $val-spacing-m;

		& [class^="icon-plus"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
		&:hover {
			color: $col-darkBlueBorder;
		}
	}

} 

.attachments-preview {

	&__back-to-list {
		background-color: $col-white !important;
		margin-bottom: $val-spacing-m;
	}

	&__submit-section {
		display: flex;
		align-items: center;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 2px solid $col-greyBackground;
		top: $var-submit-action-top-offset;
		position: sticky;

		&__status {
			flex: 1;
			display: flex;
			align-items: center;

			&__dropdown {
				>div>div {
					z-index: 100 !important;
				}
			}
		}

		&__actions {
			display: flex;
			gap: $val-spacing-s;
		}

	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;

		&__section {
			display: grid;
			row-gap: $val-spacing-l;
			background-color: $col-white;
			box-shadow: $var-shadow;
			padding: $val-spacing-m $val-spacing-l;

			&__title {
				text-transform: uppercase;
				color: $col-design-grey11;
				row-gap: 0;
				font-size: $val-fontSizeSmall;
				margin-bottom: $val-spacing-m;
			}

			&__row {
				display: grid;
				grid-template-columns: repeat(8, 1fr);

				&__column-1 {
					grid-column: span 1;
				}

				&__column-2 {
					grid-column: span 2;
				}

				&__column-4 {
					grid-column: span 4;
				}

				&__column-6 {
					grid-column: span 6;
				}

				&__column-8 {
					grid-column: span 8;
				}

				&__centered-column {
					display: flex;
					align-items: center;
				}
			}
		}
	}


}

@mixin cell-text {
	font-size: $val-fontSizeSmall;
}

.attachments-preview-table {
	&__cell-text {
		@include cell-text;

		&__blue {
			@include cell-text;
			color: $col-blue;
			text-transform: uppercase;
		}

		&__uppercase {
			@include cell-text;
			text-transform: uppercase;
		}
	}

	&__light-text {
		@include cell-text;
		color: $col-design-grey10;
		text-transform: uppercase;
	}
}

.billing-contacts {

	&__hint {
		color: $col-greyInactive;
		font-style: italic;
		padding-bottom: $val-spacing-m;
	}

	&__list {
		margin-top: $val-spacing-s;
		padding-bottom: $val-spacing-s;

		&__header {
			color: $col-greyDark;
			margin-bottom: $val-spacing-s;

			&__number {
				color: $col-design-gray5;
			}
		}

		&__items {
			padding-bottom: 20px;
		}

		&__item {

			& + & {
				padding-top: $val-spacing-l;
			}

			&__actions {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				font-size: $val-fontSizeBig;
				padding-top: $val-spacing-xs;
				padding-bottom: $val-spacing-xs;
				padding-right: $val-spacing-m;

				span {
					color: $col-design-gray6;
					cursor: pointer;

					&.disabled {
						color: $col-design-gray5;
						cursor: not-allowed;
					}

					&:not(:first-child) {
						margin-left: $val-spacing-s;
					}
				}
			}

			&__preview {

				&__header-number {
					padding-left: 8px;
					padding-bottom: $val-spacing-xs;
				}

				&__field {
					font-weight: bold;
					padding-top: $val-spacing-xs;
					padding-bottom: $val-spacing-xs;
					padding-right: 15px;
					margin-left: 8px;
					margin-bottom: $val-spacing-m;
					background-color: $col-greyBackground;
				}
			}

			&__edit {
				display: flex;
				flex-direction: row;
				width: 33%;

				&__asterisk {
					color: $col-orange;
					margin-left: $val-spacing-xs;
				}

				& + & {
					padding-top: $val-spacing-l;
				}

				&__actions {
					padding-top: 30px;
					padding-left: 16px;
					font-size: $val-fontSizeBig;
					color: $col-blue;

					&--disabled {
						[class^="icon-check"] {
							color: $col-design-gray5;
							cursor: not-allowed;
						}
					}

					&--red {
						& > span {
							color: $col-canceledRed !important;
						}
					}

					span {
						color: $col-design-gray6;
						cursor: pointer;


						&:not(:first-child) {
							margin-left: $val-spacing-s;
						}
					}
				}
			}
		}
	}
}

.invoices-table {

	&__filter-button {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		display: flex;
		align-items: center;
		background: $col-white;
		width: 120px;
	}

}

