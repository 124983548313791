.fieldset {
	border: 1px solid;
	border-color: $col-red;
	box-sizing: border-box;
	padding-top: $val-spacing-s;

	&--inactive {
		border-color: $col-greyFontLight;
	}

	&__legend {
		position: absolute;
		transform: translate(-50%, 50%);
		padding: 0 $val-spacing-xs;
		background: $col-white;
		color: $col-red;
		font-size: $val-fontSizeSmall;
		line-height: $val-lineHeightSmall;
		width: fit-content;
		box-shadow: none;
		border: 0;
		top: 80%;
		left: 25%;
	}
}

