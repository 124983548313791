@import "./Colors.scss";
@import "./Constants.scss";

$var-skillBoxSize: 16px;

#popover-custom, .popover-custom {
	border-radius: $val-borderRadius;

	& .popover-content {
		padding: $val-spacing-s;

		& .skills__row {
			font-size: $val-fontSizeSmall;
			line-height: $val-fontSizeSmall;
			display: flex;
			align-items: center;

			& .color-square {
				margin-right: $val-spacing-s;
			}

			& + .skills__row {
				margin-top: $val-spacing-xs;
			}
		}
	}

	& .employee-item-skill {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-top: $val-spacing-xs;

		& span {
			margin-right: $val-spacing-s;
			display: block;
			height: $var-skillBoxSize;
			width: $var-skillBoxSize;
		}
	}
}

.sidebar-popover {
	left: $val-sidebarWidthMin !important;
}

.order-info-modal-added-data-tooltip {
	max-width: 100vw;

	.popover-content {
		.added-work-order-resource-info {
			margin-bottom: $val-spacing-xs;
			padding: $val-spacing-xs;
			display: inline-block;
		}

		.added-address {
			&__info, &__content {
				margin-bottom: $val-spacing-xs;
				padding: $val-spacing-xs;
			}

			&__info {
				display: inline-block;
			}
		}

		.resource-lookup-group {
			.equipment-item, .employee-item, .placeholder-item {
				width: 30vw;
			}
		}
	}
}

.labor-popover {
	& .location-nickname {
		text-transform: uppercase;
		font-weight: bold;
	}

	& hr {
		margin-top: $val-spacing-xs;
		margin-bottom: $val-spacing-xs;
	}

	& .employee-item-skill {
		font-size: $val-fontSizeXSmall;

		& span {
			height: $val-icon8 !important;
			width: $val-icon8 !important;
		}
	}
}