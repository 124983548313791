$var-resource-width: 250px;
$var-company-info-height: 50px;
$var-font-size-header: 7px;
$var-font-size-body: 8px;
$var-line-height-body: $var-font-size-body + 2px;

.confirmation-preview-container {
	
	&__padded {
		padding: $val-spacing-m;
	}
}

// Only used for WO confirmation. Should be changed to public-work-order to match new naming and keep from overlapping with public-report
.confirmation-preview {
	display: flex;
	flex-flow: column nowrap;

	&__general,
	&__resources,
	&__attachments {
		background-color: $col-greyLight;
		border: 1px solid $col-greyConnection;
	}

	&__resources,
	&__attachments {
		margin-bottom: $val-spacing-l;
	}

	&__general-content {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
		padding: $val-spacing-s $val-spacing-m;
		
		&__title {
			display: flex;
			flex-flow: row;
			gap: $val-spacing-s;
		}

		&--primary-header {
			font-weight: bold;
			text-transform: uppercase;
		}

		&--centered {
			align-items: center;
		}

		&--reduced-padding {
			padding: 0;

			div {
				padding-left: 0;
			}
		}

		&--section-subheader {
			font-weight: bold;
			padding: $val-spacing-xxs;
			text-transform: uppercase;
		}

		&--main.confirmation-preview__general-content--primary {
			background: $col-blue;
			color: $col-white;
		}

		&--main.confirmation-preview__general-content--secondary {
			background-color: $col-blueBackground;
			border: 0.5px solid $col-blueHover;
			color: $col-blueHover;
			font-size: $val-fontSizeXSmall;
		}

		&--regular.confirmation-preview__general-content--primary {
			background-color: $col-greyConnection;
			border: 0.5px solid $col-black;
			color: $col-greyDark;
			font-size: $val-fontSizeXSmall;
		}

		&--regular.confirmation-preview__general-content--secondary {
			background-color: $col-greyLight;
			border: 0.5px solid $col-black;
			color: $col-greyDark;
			font-size: $val-fontSizeXSmall;
		}

		&--primary {
			padding: $val-spacing-xxs;
			font-size: $val-fontSizeXSmall;
		}
	}

	&__order-info,
	&__job-info,
	&__work-locations,
	&__site-contact {
		flex: 1;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		&:first-child {
			align-items: flex-start;
		}

		&:last-child {
			align-items: flex-end;
		}

		&--big {
			font-size: $val-fontSizeBig;
		}

		&--small {
			font-size: $val-fontSizeSmall;
		}
	}

	&__site-contact {
		align-items: flex-start;
	}

	&__notes,
	&__deliverables,
	&__info {
		display: flex;
		flex-flow: row wrap;
		width: 100%;

	}
	
	&__notes-content,
	&__deliverables-content {
		display: flex;
		flex-flow: column nowrap;
		padding-top: $val-spacing-xxs;
		flex: 1;

		&--align-end {
			align-items: flex-end;
		}
	}

	&__completion-content {
		width: fit-content;
	}

	&__work-locations {
		& .map-link {
			color: $col-blue;
			text-decoration: underline;
		}
	}

	&__resources {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		padding: $val-spacing-m;
		
	}
	
	&__resource-content {
		min-width: $var-resource-width;
		padding: $val-spacing-s $val-spacing-m;
		border-radius: $val-borderRadius;
		display: flex;
		align-items: center;
		position: relative;

		&:not(:first-child) {
			margin-top: $val-spacing-s;
		}

		.down-equipment-badge {
			top: $val-spacing-s !important;
		}
	}

	&__no-cdl {
		background-image: $var-noCDLBackground;
		background-size: cover;
		
		& > span {
			padding: 0 $val-spacing-xxs;
			background: $col-white;
			border-radius: $val-borderRadius;
		}
	}

	&__attachments {
		& table {
			margin: 0;
		}

		& th,
		& td {
			padding: $val-spacing-s $val-spacing-m !important;
		}
	}

	&__attachments-label {
		padding: $val-spacing-m;
	}

	& label {
		color: $col-greyFontLight;
		text-transform: uppercase;
		font-weight: normal;
	}

	& .show-on-mobile {
		display: none;
	}

	& .hide-on-mobile {
		display: table-cell;
	}
}