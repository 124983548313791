.temp-labor-assign-modal {

	&__employee-option {

		&__name {
			margin-right: $val-spacing-s;
		}

		&__unique-id {
			text-transform: uppercase;
		}

		&__agency {
			font-size: $val-fontSizeSmall;
		}
	}

	&__create-employee-container {
		background-color: $col-greyLight;

		&__header {
			display: flex;
			justify-content: flex-end;
			padding: $val-spacing-s;
		}

		&__close {
			font-size: $val-fontSizeBig;
			cursor: pointer;
		}
	}

	&__address {
		background-color: $col-white;
	}

	&__warning {
		background-color: $col-orange;
		color: $col-white;
		padding: $val-spacing-s $val-spacing-s;
		font-size: $val-fontSizeSmall;
		text-align: center;
	}
}