.contact-preview {
	user-select: none;
	position: relative;

	&__user {
		font-size: $val-fontSizeXXXBig;
		line-height: $val-lineHeightXXXBig;
		text-transform: uppercase;
		font-weight: bold;
	}

	&__actions {
		position: absolute;
		top: $val-spacing-l;
		right: $val-spacing-l;
		display: flex;
	}

	&__label {
		@extend .segment-label;
		padding-bottom: 0 !important;
	}

	&__method {
		display: flex;
	}

	&__method-copy {
		cursor: pointer;
		color: $col-blue;
		margin-left: $val-spacing-s;
		font-size: $val-fontSizeSmall;
	}

	&__method-value {
		font-weight: bold;
	}

	&__method-type {
		margin-right: $val-spacing-s;
		background: $col-greyBackground;
	}

	&__new {
		margin-left: $val-spacing-s;
		color: $col-orange;
	}
}