.tag {
	@extend .pills__item;
	margin: 0;
	height: auto;

	&--small {
		padding: $val-spacing-xxs $val-spacing-s;
	}

	&--medium {
		padding: $val-spacing-s $val-spacing-m;
	}

	&--large {
		padding: $val-spacing-m $val-spacing-l;
	}

	&--transparent {
		background-color: transparent;
		border: none;
	}

	& &__content {
		display: flex;
		align-items: center;
	}
}
