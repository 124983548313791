.filter-modal {
	&__group {
		display: flex;
		flex-flow: column;
		background: $col-greyBackground;
		border-left: 4px solid $col-greyConnection;

		&:nth-child(2n + 1) {
			border-left: 4px solid $col-greyInactive;
		}

		& + & {
			border-top: 1px solid $col-greyConnection;
		}
	}

	&__group-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $val-spacing-m;
	}

	&__group-header-buttons {
		display: flex;
		align-items: center;

		.btn {
			margin-left: $val-spacing-s;
		}
	}

	&__group-header-label {
		display: flex;
		align-items: center;
		cursor: pointer;

		.btn--control {
			font-size: $val-fontSizeRegular;
		}

		.segment-label {
			padding: 0;
			margin-right: $val-spacing-s;
		}
	}

	&__options {
		padding: $val-spacing-m;
		display: flex;
		flex-wrap: wrap;

		&--hidden {
			display: none;
		}
	}

	&__option {
		width: 50%;

		@media (max-width: $bp-xxs-high) {
			width: 100%;
		}
	}
}