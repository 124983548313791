$utilization-info-card-bar-meter-height: 6px;
$utilization-info-card-bar-meter-max-width: 275px;
$utilization-info-card-group-min-width: 200px;
$utilization-info-card-group-max-width: 250px;
$utilization-info-card-chart-height: 130px;
$utilization-info-card-chart-max-width: 310px;

.utilization-info-card {
	&__labor-title {
		font-weight: bold;
		font-size: $val-fontSizeXXXBig;
		line-height: $val-lineHeightXXXBig;
	}

	&__equipment-title {
		max-width: $utilization-info-card-bar-meter-max-width;
		height: $val-lineHeightXXXBig;

		&__control {
			border: none !important;
			min-height: $val-lineHeightXXXBig;
		}

		&__value-container {
			padding: 0;
			height: $val-lineHeightXXXBig;
		}

		&__single-value {
			margin: 0;
			font-weight: bold;
			font-size: $val-fontSizeXXXBig;
			line-height: $val-lineHeightXXXBig;
		}

		&__menu {
			z-index: 6;	// FIXME: move the `z-index: 5` value from ReactTable to constants and use that value + 1 here
		}

		& .icon-down {
			font-size: $val-icon20;
		}
	}

	&__group {
		display: flex;
		flex-direction: column;
		min-width: $utilization-info-card-group-min-width;
		max-width: $utilization-info-card-group-max-width;
		margin-top: $val-spacing-s;
	}

	&__group-entry {
		display: flex;
		justify-content: space-between;

		& .group-value {
			font-weight: bold;
		}

		&--padded-right {
			padding-right: $val-spacing-s;
		}

		&--padded-verticaly {
			margin-top: $val-spacing-m;
			margin-bottom: $val-spacing-m;
		}
	}

	&__charts-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
	}

	&__chart-container {
		height: $val-lineHeightRegular * 2 + $val-spacing-m + $utilization-info-card-chart-height;
		width: 100%;
		max-width: $utilization-info-card-chart-max-width;
		margin-bottom: $val-spacing-s;
		float: right;
	}

	&__chart-title {
		height: $val-lineHeightRegular;
		padding-left: $val-spacing-xxl;
		font-size: $val-fontSizeRegular;
		text-align: center;
		color: $col-greyFontLight;
		text-transform: uppercase;
	}

	&__chart-footer {
		height: $val-lineHeightRegular;
		width: 100%;
		margin-top: $val-spacing-m;
		padding-left: $val-spacing-xxl;
		padding-right: $val-spacing-s;
		display: flex;
		flex-direction: column;
		font-size: $val-fontSizeRegular;

		&__entry {
			display: flex;
			justify-content: space-around;

			&__value {
				font-weight: bold;
			}
		}
	}

	&__chart {
		height: $utilization-info-card-chart-height;
		width: 100%;

		&--loading {
			height: $utilization-info-card-chart-height;
			margin-left: $val-spacing-xxl;

			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-weight: bold;

			color: $col-greyFontLight;
			font-size: $val-fontSizeRegular;
		
			& img {
				margin-bottom: $val-spacing-s;
			}

			&__info {
				display: flex;
				flex-flow: column nowrap;
			}
		}
	}

	& .bar-meter {
		height: $utilization-info-card-bar-meter-height;
		max-width: $utilization-info-card-bar-meter-max-width;
		margin-top: $val-spacing-xs;
	}
}