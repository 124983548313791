.field-report-status-label {
	background-color: $col-greyBackground;
	display: flex;
	align-items: center;
	border-radius: $val-borderRadius;
	padding: $val-spacing-xs $val-spacing-s;
	user-select: none;

	&__label {
		font-size: $val-fontSizeSmall;
		text-transform: uppercase;
		line-height: $val-lineHeightSmall;
		font-weight: bold;
		position: relative;
		top: 1px; // Compensating for half pixel when centering
	}

	&__icon {
		font-size: $val-fontSizeXBig !important;
		margin-right: $val-spacing-xs;
	}

	&--small &__label {
		font-size: $val-fontSizeXSmall;
		line-height: $val-fontSizeXSmall;
		top: 0;
	}

	&--small &__icon {
		font-size: $val-fontSizeRegular !important;
	}
}