$wo-sticky-header-top-offset: -17px;
$wo-sticky-header-right-width: 550px;
$wo-sticky-dropdown-width: 150px;
$wo-sticky-dropdown-width-locked: 110px;
$job-toggle-button-width: 133px; // Set due to flex taking priority in active state
$header-2-center-height: 60px;
$job-code-margin: 32px;
$order-additional-info-width: 300px;
$job-detail-action-width: 100px;

.work-order-upsert {
	height: fit-content;

	&__sticky-header {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: $val-spacing-m $val-spacing-l;
		position: sticky;
		top: $wo-sticky-header-top-offset;
		width: 100%;
		z-index: 5;
		border-collapse: collapse;
		background: $col-white;
		border-top-left-radius: $val-borderRadius;
		border-top-right-radius: $val-borderRadius;
		box-shadow: none;
	}

	&__code {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: $val-fontSizeXBig;
		margin-right: $val-spacing-m;

		& [class^="icon-"] {
			font-size: $val-fontSizeXXBig;
			color: $col-greyDark;
		}

		&--extended {
			flex: 1;
		}
	}

	&__status-icon {
		margin-right: $val-spacing-s;

		&--draft,
		&--canceled {
			background: none !important;
			border: none;
		}

		&--published,
		&--outdated {
			border: none;

			& [class^="icon-"] {
				line-height: normal;
			}
		}
	}

	&__additional-info {
		color: $col-greyMid;
		font-size: $val-fontSizeSmall;
		min-width: $order-additional-info-width;

		&--extended {
			flex: 1;
		}
	}

	&__info {
		display: grid;
		grid-template-columns: auto $job-detail-action-width;
		padding: $val-spacing-m $val-spacing-l;
	}

	&__job-picker-option {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		mark {
			background-color: $col-orangeBorder;
			color: $col-white;
			border-radius: $val-borderRadius;
		}
	}

	&__job-picker-create-label {
		color: $col-blue;
		margin-right: $val-spacing-xs;
	}

	&__warning {
		color: $col-canceledRed;
		display: flex;
		flex-direction: row;
	}

	&__project-label {
		color: $col-greyMid;
		font-style: oblique;
		margin-left: $val-spacing-s;
	}

	&__project-banner {
		display: flex;
		flex-direction: row;
	}

	&__project-flag {
		background-color: $col-blue;
		padding-left: $val-spacing-xs;
		padding-right: $val-spacing-xs;
		color: $col-white;
		font-weight: bold;
		margin-right: $val-spacing-s;
		margin-bottom: $val-spacing-s;
	}

	&__associate-button {
		color: $col-white;
		margin-top: $val-spacing-m;
		padding: $val-spacing-s $val-spacing-m;
		background-color: $col-blue;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 20px;
		font-weight: bold;
		cursor: pointer;
		width: fit-content;
	}
	
	&__associate-button-icon {
		color: $col-blue;
		background-color: $col-white;
		border-radius: 50%;
		font-size: $val-fontSizeSmall;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		margin-right: $val-spacing-xs;
	}
	

	&__job-info {
		display: flex;
		flex-direction: column;
	}

	&__job-title {
		display: flex;
		align-items: center;
		flex-direction: row;

		.rt-status-icon {
			margin-right: $val-spacing-s;
		}

		.title-name {
			margin-right: $val-spacing-s;
		}

		.internal-badge {
			margin-right: $val-spacing-s;
		}
	}

	&__job-code {
		color: $col-greyMid;
		font-weight: normal;
		margin-left: $job-code-margin;
	}

	&__section {
		border-bottom: 1px solid $col-greyConnection;
	}

	&__report-type-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	&__draggable-indicator {
		margin-right: $val-spacing-s !important;
		margin-left: -$val-spacing-xs;
		margin-bottom: $val-spacing-m;
	}

	&__add-report-type {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		padding: 0;
	}

	&__remove-icon {
		cursor: pointer;
		font-size: $val-fontSizeBig !important;
		margin-right: 0 !important;
		margin-left: $val-spacing-s;
		margin-bottom: $val-spacing-m;
		color: $col-greyInactive;

		&:hover {
			color: $col-greyDark;
		}
	}

	&__site-contact {
		background: $col-white;
		padding: 0;
	}

	&__non-sticky-header {
		border-top: none;
		position: relative;
	}

	&__header {
		padding: $val-spacing-m $val-spacing-l;
		background-color: $col-greyLight;
	}

	&__header-1-left {
		flex: 1;
		display: flex;
		align-items: center;

		div+div {
			margin-left: 0 !important;
		}
	}

	&__header-1-center {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-size: $val-fontSizeBig;

		.work-order-upsert__sticky-date {
			margin-left: $val-spacing-m !important;
		}

		.work-order-upsert__sticky-date--locked {
			margin-left: 0 !important;
		}

		@media (max-width: $bp-xxs-high) {
			margin-top: $val-spacing-s;
		}
	}

	&__header-1-right {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@media (max-width: $bp-xxs-high) {
			margin-top: $val-spacing-m;
		}
	}


	&__sticky-actions {
		display: flex;
		flex-flow: row nowrap;
	}

	&__sticky-date {
		min-width: $wo-sticky-dropdown-width;
		margin: 0 $val-spacing-m;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		&--locked {
			min-width: $wo-sticky-dropdown-width-locked;
		}
	}

	&__sticky-date-locked {
		font-weight: bold;
		font-size: $val-fontSizeXBig;
		line-height: $val-lineHeightXBig;
	}

	&__user-type-dropdown,
	&__report-type-dropdown {
		&>li>a {
			height: auto;
			min-height: $val-fieldSize;
		}

		small {
			color: $col-greyFontLight;
			white-space: normal;
		}
	}

	&__crew-type-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;

		@media (min-width: $bp-xs-low) {
			margin-left: $val-spacing-m;
		}
	}

	&__job-picker {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__job-dropdown-container {
		margin-bottom: 0;
	}

	&__job-separator {
		margin: 0 $val-spacing-s;
	}

	&__job-toggle {
		margin-right: $val-spacing-m;
		min-width: $job-toggle-button-width;
	}

	&__job-info {
		display: flex;
		flex-direction: column;
	}

	&__job-title {
		font-size: $val-fontSizeBig;
		font-weight: bold;
	}

	&__job-details {
		font-size: $val-fontSizeRegular;
	}

	&__header-2-left {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__header-2-center {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		font-size: $val-fontSizeBig;
		font-weight: bold;
		text-align: center;
		height: $header-2-center-height;

		@media (max-width: $bp-xxs-high) {
			align-items: flex-start;
			margin-top: $val-spacing-s;
		}

		@media (min-width: $bp-xs-low) {
			align-items: center;
		}
	}

	&__header-2-right {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		font-weight: bold;

		@media (max-width: $bp-xxs-high) {
			align-items: flex-start;
			margin-top: $val-spacing-s;
		}

		@media (min-width: $bp-xs-low) {
			align-items: flex-end;
		}
	}

	&__header-row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	&__header-value {
		font-weight: bold;
		margin-left: $val-spacing-s;
	}

	&__shift-container {
		& .radio-button-group {
			margin-top: $val-spacing-xs;
			display: flex;
			align-items: center;
			min-height: $val-fieldSize;

			& .async-select-input {
				flex-grow: 1;
				margin-bottom: 0;
				margin-left: $val-spacing-m;
			}
		}
	}

	&__canceled-ribbon {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: $val-spacing-m $val-spacing-l;
		background-color: $col-canceledRed;
		color: $col-white;
	}

	&__paused-ribbon {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: $val-spacing-m $val-spacing-l;
		background-color: $col-greyInactive;
		color: $col-white;
	}

	&__ribbon-right {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		flex-flow: column;
		align-items: flex-end;
		padding-left: $val-spacing-l;
	}

	&__ribbon-left {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		padding-right: $val-spacing-l;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		text-transform: uppercase;
	}
}