$radio-input-size: 20px;

input[type=radio] {
	@extend .noappearance;

	display: block;
	height: 0;
	padding: 0;
	margin: 0;

	&::before {
		content: '';
		height: $radio-input-size;
		width: $radio-input-size;
		display: block;
		position: absolute;
		background: url("/images/inputs/ic_radio_inactive.svg") no-repeat center center;
		left: 0;
		cursor: pointer;
	}

	&:checked::before {
		background: url("/images/inputs/ic_radio_active.svg") no-repeat center center;
	}

	&:disabled::before {
		cursor: no-drop;
	}
	
	&:disabled:checked:before {
		background: url("/images/inputs/ic_radio_active_grey_light.svg") no-repeat center center;
	}
}

.radio-button-group {
	&--padded {
		padding-top: $val-spacing-m;
		padding-left: $val-spacing-m;
	}

	&--inline {
		display: flex;

		.radio-button + .radio-button {
			margin-left: $val-spacing-m;
		}
	}
}

.radio-button {
	display: flex;

	&__label {
		margin-left: 0;
		position: relative;
		font-weight: normal;
	}

	&.form-check-inline {
		align-items: center;
	}

	&--no-label {
		width: $val-fontSizeXBig;
		height: $val-fontSizeXBig;
		padding: 0;
		margin-top: 0 !important;

		& > label {
			height: $val-fontSizeXBig;
			width: $val-fontSizeXBig;
		}
	}

	&--disabled {
		cursor: not-allowed;
		
		.radio-button__label {
			cursor: not-allowed;
		}
	}
}