$val-thread-bottom: 38px;
$val-thread-min-width: 266px;
$val-thread-arrow-border: 10px;
$val-thread-arrow-width: 47px;
$val-thread-arrow-bottom: -10px;
$val-sidebar-shadow: 0px 6px 12px $col-shadow;
$thread-arrow-offset: 6px;
$scrollbar-border-radius: 6px;

$max-resolved-container-height: 296px;
$max-container-height: 211px;

$comment-sidebar-width: 266px;
$comment-sidebar-header-height: 64px;
$filter-button-width: 200px;

.comment {

	&__btn-container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		padding-top: $val-spacing-s;
	}

	&__button {
		padding: 0 !important;
		min-width: 0 !important;
		font-size: $val-fontSizeSmall;
		text-transform: none;
		font-weight: normal;
		font-style: normal;
		cursor: pointer;
		height: auto;
		display: flex;
		align-items: center;
		border: none !important;
		background-color: transparent !important;

		&--disabled {
			color: $col-grey;
			cursor: none;

			&:hover {
				color: $col-grey !important;
			}
		}

		&--active {
			color: $col-orange;
			
			&:hover {
				color: $col-orange !important;
			}
		}

		&--right-padded {
			padding-right: $val-spacing-m;
		}
	}

	&__header {
		padding-bottom: $val-spacing-s;
		display: flex;
		align-items: center;

		&--with-image {
			padding-bottom: $val-spacing-m;
			flex-flow: column;
			align-items: flex-start;
		}

		&--non-padded {
			padding-bottom: 0;
		}
	}

	&__title {
		color: $col-white;
		font-size: $val-fontSizeSmall;
		text-transform: none;

		&--date {
			font-weight: normal;
			color: $col-greyFontLight;
			width: 100%;
		}
	}

	&__content {
		color: $col-greyBackground;
		font-size: $val-fontSizeSmall;
		text-transform: none;
		font-weight: normal;
		display: flex;
		align-items: center;

		& + .comment__header {
			padding-top: $val-spacing-s;
			margin-top: $val-spacing-s;
			border-top: 1px solid $col-grey;
		}
	}

	&__action-button-container {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	&__action-button {
		margin: 0 !important;
		font-size: $val-fontSizeSmall;
		color: $col-white;
		text-transform: none;
		font-weight: normal;
		cursor: pointer;

		& + & {
			padding-left: $val-spacing-s;
			margin-left: $val-spacing-s !important;
			border-left: 1px solid $col-grey;
		}
	}

	&__action-button-item {
		text-transform: none;
	}

	&__textarea-container {
		padding: $val-spacing-s $val-spacing-m;

		.input-description {
			margin-bottom: $val-spacing-xs;
		}
	}

	&__textarea {
		overflow: hidden;
		font-weight: normal;
	}
	
	&__container {
		min-width: $val-icon16;
		padding: 0 !important;
	}

	&__header-user {
		display: flex;
		align-items: center;
	}

	&__header-user-logo {
		@extend .background-circle;

		height: $var-companyLogoBigSize;
		width: $var-companyLogoBigSize;
		margin-right: $val-spacing-s;
	}

	&__divider {
		margin: $val-spacing-s 0 !important;
	}
}

.comment-thread {
	&__comment-container {
		width: 100%;
		max-height: $max-container-height;
		padding-right: $val-spacing-m;
		padding-left: $val-spacing-m;

		&--resolved {
			max-height: $max-resolved-container-height;
			
			& > div:last-child {
				padding-bottom: $val-spacing-l;
			}
		}
	}

	&__container {
		position: absolute;
		background: $col-greyDark;
		border-color: $col-greyDark;
		border-radius: $val-borderRadius;
		color: $col-white;
		font-size: $val-fontSizeSmall;
		bottom: $val-thread-bottom;
		padding: 0;
		min-width: $val-thread-min-width;
		z-index: 3;

		&__arrow {
			background: transparent;
			position: absolute;
			bottom: $val-thread-arrow-bottom;
			padding: 0;
			border-width: $val-thread-arrow-width;
			border-left: $val-thread-arrow-border solid transparent;
			border-top: $val-thread-arrow-border solid $col-greyDark;
			border-right: $val-thread-arrow-border solid transparent;
			left: $thread-arrow-offset;
		}
	}

	&__collapse-btn-container {
		display: flex;
		justify-content: center;
		padding: $val-spacing-s;
		border-top: 1px solid $col-grey;
		border-bottom: 1px solid $col-grey;
		margin: $val-spacing-s 0;
	}

	&__scroll-container {
			
		& > div:first-of-type {
			position: relative !important;
			display: flex;
		}
	}

	&__scrollbar {
		background: $col-whiteShadow;
		border-radius: $scrollbar-border-radius;
	}

	&__resolve-header {
		display: flex;
		flex-direction: row nowrap;
		justify-content: flex-end;
		padding: $val-spacing-m;
		padding-bottom: 0;

		&__title {
			margin-left: $val-spacing-s;
			line-height: $val-lineHeightSmall;
		}
	}
}

.comment-modal {
	&__btn-container {
		display: flex;
		flex-direction: row nowrap;
	}
}

.comment-sidebar {

	&__overlay {
		position: fixed;
		pointer-events: none;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 8;
	}

	&__container {
		display: flex;
		flex-flow: column;
		pointer-events: auto;
		width: $comment-sidebar-width;
		height: 100%;
		background-color: $col-white;
		float: right;
		box-shadow: $val-sidebar-shadow;

		&--show {
			animation: slide-up $val-toggleAnimation;

		}

		&--hide {
			animation: slide-down $val-toggleAnimation;
		}

		.comment {
			padding: $val-spacing-m $val-spacing-m $val-spacing-l;
			border-bottom: 1px solid $col-greyConnection;
			cursor: pointer;

			&__name {
				font-weight: bold;
			}
	
			&__date {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				color: $col-greyFontLight;
				padding-bottom: $val-spacing-s;
			}
	
			&__position {
				color: $col-greyFontLight;
				text-transform: uppercase;
				padding-bottom: $val-spacing-m;
			}

			&--self-locked {
				background-color: $col-orangeHover;

				.icon-lock {
					color: $col-orange;
					font-size: $val-spacing-l;
				}
			}

			&--locked {
				background-color: $col-greyConnection;

				.icon-lock {
					color: $col-greyDark;
					font-size: $val-spacing-l;
				}
			}
		}
	}

	&__filter {
		display: flex;
		justify-content: flex-end;
		padding: $val-spacing-l $val-spacing-m; 
	}

	&__filter-button {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		display: flex;
		align-items: center;
		background: $col-white;
		width: $filter-button-width;
	}
	
	&__header {
		height: $comment-sidebar-header-height;
		cursor: pointer;

		.item {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			&--center {
				justify-content: center;
			}

			&--end {
				justify-content: flex-end;
			}
		}

		.title {
			font-size: $val-fontSizeRegular;
			color: $col-greyFontLight
		}

		.collapse-icon {
			font-size: $val-fontSizeXBig;
			color: $col-blackDark;
		}
	}

	&__tabs {
		padding: 0 !important;

		.navigation {
			padding: 0;
		}
		
		.tab {
			padding: 0;
		}
	}

	&__scrollbar {
		background: $col-greyFontLight;
		border-radius: $scrollbar-border-radius;
	}

	&__scroll-container {
		& > div:first-of-type {
			position: relative !important;
			display: flex;
		}
	}
}