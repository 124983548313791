$multiselect-container-height: 200px;
$skill-dimension: 6px;
$skill-padding: 1px;

.skills {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: calc(#{$val-fieldSize} / 2);

	&__skill {
		height: 100%;
		width: 100%;
		border-radius: $val-borderRadius;

		&--square {
			border-radius: 0;
		}
	}

	&__row {
		display: flex;
	}

	&__wrapper {
		width: $skill-dimension;
		height: $skill-dimension;
		padding: $skill-padding;

		&--medium {
			width: 4 * $skill-dimension;
			height: 4 * $skill-dimension;
		}

		&--large {
			width: 6 * $skill-dimension;
			height: 6 * $skill-dimension;
		}

	}

	&__grid-container {
		display: flex;
		flex-direction: column;
	}

	&__grid-row {
		display: flex;

		& > div {
			margin: $val-spacing-xxs;
		}
	}

	&__multiselect {
		height: $multiselect-container-height;
		margin-bottom: 0;
	}

	&--with-background {
		padding: 0 $val-spacing-xxs;
		background: $col-white;
		border-radius: $val-borderRadius;
	}

}