.color-square {
	height: $val-colorSquareSize;
	width: $val-colorSquareSize;
	min-width: $val-colorSquareSize;
	border-radius: $val-borderRadius;
	display: inline-block;

	& + & {
		margin-left: $val-spacing-xs;
	}

	&--small {
		height: $val-colorSquareSizeS;
		width: $val-colorSquareSizeS;
		min-width: $val-colorSquareSizeS;
	}

	&.tooltip {
		margin-right: $val-spacing-s;
	}
}