@import "../../../../styles/Colors.scss";
@import "../../../../styles/Constants.scss";

.resource {
	&__popover {
		max-width: none;
		width: initial;

		& .popover-content {
			padding: $val-spacing-s;
		}
	}

	&-tooltip {
		&__container {
			display: flex;
			align-items: center;
			flex-flow: row nowrap;
			flex: 1;
		}

		&__resource-container {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			font-size: $val-fontSizeSmall;
			align-self: stretch;

			&:first-child {
				text-align: left;
				margin-right: $val-spacing-m;
				padding-right: $val-spacing-m;
				border-right: 1px solid $col-white;
			}

			&:last-child {
				text-align: right;
				margin-left: $val-spacing-m;
				padding-left: $val-spacing-m;
				border-left: 1px solid $col-white;
			}
		}

		&__resource-item {
			white-space: nowrap;
		}
	}
}
