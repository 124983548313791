@import '../../../../styles/Constants.scss';

.lost-for-reason-modal {
	display: flex;
	flex-direction: column;
	gap: $val-spacing-m;
}

.copy-convert-modal {
	display: flex;
	flex-direction: column;
	gap: $val-spacing-m;

	&__info {
		padding: $val-spacing-s 0;
	}

	&__job-id {
		width: 60%;

		> input {
			margin-top: $val-spacing-s;
		}
	}
}