$avatar-size: 210px;
$no-cdl-background: repeating-linear-gradient(45deg, transparent, transparent 5px, $col-orange 5px, $col-orange 10px);

// Shared classes among the schedule board modals: Copy WO, Per Diem, WO Info, Employee, Equipment, Placeholders, Bulk Publish

.schedule-board-modal {
	&__codes-list {
		display: flex;
		flex-flow: row wrap;
		padding: $val-spacing-m;

		.icon-dot, .icon-dot-outline {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
	}

	&__codes-group {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: $val-spacing-m;

		& > span {
			margin-bottom: $val-spacing-xs;
			margin-right: $val-spacing-l;

			& > span {
				margin-right: $val-spacing-xs;
			}

			.icon-dot {
				font-size: $val-fontSizeXSmall;
			}
		}
	}

	&__big-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		overflow: initial !important;

		.color-square {
			width: $val-colorSquareSizeL;
			height: $val-colorSquareSizeL;
			border-radius: $val-borderRadius;
			display: inline-block;
			margin-right: $val-spacing-s;
		}


		&::before, &::after {
			display: none;
		}
	}

	&__big-header-left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	&__big-header-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	&__big-header-title {
		font-size: $val-fontSizeXXXBig;
		line-height: $val-lineHeightXXXBig;
		text-transform: uppercase;
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-left: $val-spacing-s;
		margin-right: $val-spacing-l;
	}

	&__big-header-details {
		font-size: $val-fontSizeXXXBig;
		line-height: $val-fontSizeXXXBig;
		font-weight: bold;
	}

	&__resource {
		// For employees / equipment / placeholders

		&__row {
			padding-bottom: $val-spacing-l;
			&--flex {
				display: flex;
			}

			&--even {
				display: flex;
				justify-content: space-evenly;
			}
		}

		&__secondary-row {
			background: $col-greyLight;
			margin-left: -$val-spacing-l;
			margin-right: -$val-spacing-l;
			padding-left: $val-spacing-l;
			padding-right: $val-spacing-l;

			&--padded-bottom {
				padding-bottom: $val-spacing-xl;
			}

			&--final {
				margin-bottom: -$val-spacing-l;
			}
		}

		&__assignment {
			display: flex;
			justify-content: space-between;

			@media (min-width: $bp-xs-low) {
				width: 50%;
			}

			&__name {
				&__work-order {
					font-weight: bold;
					font-size: $val-fontSizeBig;
					margin-bottom: $val-spacing-xs;
				}
			}

			&__controls {
				display: flex;
				align-items: center;

				.input-checkbox {
					margin-right: $val-spacing-m;
				}
			}

			&__dropdown {
				width: 100%;
				border: 1px solid $col-greyMid;
				border-radius: $val-borderRadius;

				.dropdown-item {
					padding: $val-spacing-s $val-spacing-m !important;
				}
			}

			& + & {
				margin-top: $val-spacing-l;
			}

		}

		&__add-assignment {
			&, &:focus, &:active, &:hover {
				display: flex;
				width: fit-content;
				padding: $val-spacing-l $val-spacing-m $val-spacing-xl 0;
			}
		}

		&__avatar-wrapper {
			display: flex;
			position: relative;
			margin-right: $val-spacing-m;

			&__no-cdl {
				position: absolute;
				background-image: $no-cdl-background;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
				width: 100%;
				height: $val-spacing-xl;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;

				& > span {
					padding: $val-spacing-xxs $val-spacing-xs;
					background: $col-white;
					font-weight: bold;
				}
			}

			&--non-marginated {
				margin: 0;
			}
		}

		&__avatar {
			user-select: none;
			height: $avatar-size;
			width: $avatar-size;
		}

		&__main-details {
			&__item {
				& + & {
					margin-top: $val-spacing-m;
				}
			}
		}

		&__header {
			font-size: $val-fontSizeXXXBig;
			font-weight: bold;

			&__title {
				display: flex;
				align-items: center;
			}

			&__subtitle {
				font-size: $val-fontSizeXBig;
				color: $col-greyFontLight;
				text-transform: uppercase;
				margin-left: $val-spacing-s;
			}

			&__icon {
				margin-right: $val-spacing-m;
			}
		}
	}

	&__work-order-pill {
		display: flex;
		align-items: center;
		margin-top: $val-spacing-s;

		&__reason {
			font-size: $val-fontSizeSmall;
			color: $col-greyFontLight;
			margin-left: $val-spacing-m;
			line-height: $val-lineHeightRegular;
		}
	}

	.skills__multiselect .multiselect-option {
		// Used for selecting skills in add labor placeholder modal
		margin: 0;
	}
}