@import '../../../../../styles/Colors.scss';
@import '../../../../../styles/Constants.scss';

$var-shadow: 0 1px 4px 0 $col-shadow;

.section {
	background-color: white;
	margin: $val-spacing-s;
	padding: $val-spacing-m;
	max-width: $val-maxBoxSize;
	margin: 0 auto;
	box-shadow: $var-shadow;
	border: 1px solid $col-shadow;
}

.section-title {
	color: $col-greyFontLight;
	padding-bottom: $val-spacing-s;
	text-transform: uppercase;
	font-size: $val-fontSizeBig;
}
