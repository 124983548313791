$form-box-mini-width: 504px;
$form-box-midi-width: calc(2 * #{$form-box-mini-width});

$var-shadow: 0 1px 4px 0 $col-shadow;
$var-formControlMargin: 15px;
$var-fieldSize: 34px;
$var-contactHeaderIconSize: 19px;
$var-wr-table-header-height: 64px;
$var-wr-table-header-filter-width: 350px;
$var-formBoxMiniMobileWidth: 304px;
$var-formBoxMediumMobileWidth: 404px;
$var-formBoxMediumWidth: 850px;
$val-workOrderInfoMinWidth: 100px;
$var-sideBorderSize: 4px;
$var-pagination-button-size: 34px;
$var-maxHeaderTextPortion: 60%;
$var-prodAndBillingRowHeight: 50px;
$var-verticalPercentageIndicator: 1px;
$var-percentageIndicatorWidth: 20px;
$val-colorSquareSize: 20px;
$var-colorSquareSize: 16px;
$var-droppableDefaultHeight: 24px;
$var-dragOverOffset: 4px;
$var-smileyIconSize: 80px;
$var-wo-danger-text-width: 61%;

.form-segment {
	padding: 0;
	width: 100%;
	left: 0;
	right: 0;
	margin: 0 auto;
	max-width: $val-maxBoxSize;

	&--mini {
		max-width: $form-box-mini-width;
	}

	&--midi {
		max-width: $form-box-midi-width;
	}

	&--maxi {
		max-width: inherit;
	}

	& h3 {
		text-transform: uppercase;
		margin-top: $val-spacing-s;
		margin-bottom: $val-spacing-l;
		margin-left: $val-spacing-s;

		& span {
			text-transform: none;
			font-weight: normal;
			color: $col-greyFontLight;
		}
	}

}

.form-box {
	background: $col-white;
	border-radius: $val-borderRadius;
	-webkit-box-shadow: $var-shadow;
	box-shadow: $var-shadow;
	margin-bottom: $val-spacing-l;
	border: 1px solid $col-shadow;
	width: 100%;

	& &__label {
		font-size: $val-fontSizeSmall;
		text-transform: uppercase;
		color: $col-greyFontLight;
	}

	&__after {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: $val-spacing-l;

		& > div {
			display: flex;
		}

		& span {
			color: $col-greyFontLight;
		}
	}

	&__icon-container {
		margin: $val-spacing-l auto;
		display: flex;
		justify-content: center;
	}

	&__icon {
		color: $col-white;
		background: $col-orange;
		font-size: $val-fontSizeHuge;
		padding: $val-spacing-m;
		border-radius: 50%;
		border: none;

		&--success {
			background: $col-green;
		}
	}

	&__footer {
		display: flex;
		justify-content: center;
		margin-bottom: $val-spacing-l;
	}

	&__header {
		background-color: $col-greyLight;
		padding: $val-spacing-m;
		margin-bottom: 0;
		height: $val-headerHeight;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__header-left {
		float: left;
		width: 100%;
	}

	&__header-right {
		float: right;
		display: flex;
		justify-content: flex-end;
	}

	&__header-title {
		display: flex;
		align-items: center;
		font-size: $val-fontSizeBig;
		
		& [class^="icon-"] {
			font-size: $val-fontSizeXBig;
			margin-right: $val-spacing-m;
		}
	}

	&--standalone {
		margin-top: $val-spacing-l;
	}

	&--full-padded {
		padding: $val-spacing-l;
	}

	&--padded {
		padding: 0 $val-spacing-l;
	}

	&--padded-inv {
		padding: $val-spacing-l 0;
	}

	&--padded-top {
		padding-top: $val-spacing-l;
	}

	&--with-background {
		-webkit-box-shadow: none;
		box-shadow: none;
		border: none;
		background: $col-greyBackground;
	}

	&--loading-only {
		min-height: 48vh;
		display: grid;
		place-items: center;
	}

	&--midi {
		width: $var-formBoxMediumWidth;
		
		@media (max-width: $var-formBoxMediumWidth) {
			width: $var-formBoxMediumMobileWidth;
		}

		@media (max-width: $form-box-mini-width) {
			width: $var-formBoxMiniMobileWidth;
		}
	}

	& h2 {
		$margin-top: $val-spacing-l;
		font-size: $val-fontSizeXBig;
		text-align: center;
	}

	& h4 {
		font-size: $val-fontSizeRegular;
		color: $col-greyFontLight;
		text-align: center;
		margin-bottom: $val-spacing-l;
		font-weight: normal;

		& + h4 {
			margin-top: 0;
		}
	}
}

.fullscreen {
	position: fixed;
	background-color: $col-greyBackground;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $val-sidenavZIndex + 2;
	display: flex;
	flex-flow: column nowrap;
}