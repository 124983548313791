.login-tabs {
	display: flex;
	height: $val-headerHeight;
	flex-direction: flex-start;
	padding: 0 $val-spacing-l;
	margin-bottom: $val-spacing-l;

	&__tab {
		font-size: $val-fontSizeRegular;
		font-weight: bold;
		text-transform: uppercase;
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&--active {
			box-shadow: 0px -6px 0px -3px $col-orange inset;
		}
	}
}