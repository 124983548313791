$app-z-index: 3;

.app {
	display: flex;
	height: 100%;

	&__container {
		z-index: $app-z-index;
	}

	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;
	}

	&__main {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		top: $val-headerHeight;
		float: right;
		right: 0;
		width: 100vw;
		height: calc(100vh - #{$val-headerHeight});
		overflow-y: auto;

		&--without-navigation {
			top: 0;
			height: 100vh;
		}

		&--header-small {
			top: $val-headerHeightS
		}
	}

	&__with-floating-sidenav {
		padding-left: $val-sidebarWidthMin;
		width: inherit;
		height: inherit;
	}
}