@import "../../../../../../../../styles/Colors.scss";
@import "../../../../../../../../styles/Constants.scss";

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-border: 1px solid $col-greyConnection;
$var-dot-icon-size: 6px;

.gap {
	margin: 0px $val-spacing-xxxs;
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.section {
	height: 8px;
	border-right: 1px solid $col-white;

	&__icon {
		display: flex;
		justify-content: center;
		height: $val-fontSizeBig;
		font-size: $val-fontSizeBig;
		color: $col-blue;
		margin-bottom: $val-spacing-xs;

		&.unsigned-manual {
			color: $col-orange;
		}

		&.si-signed {
			color: $col-green;
		}

		&.overlap {
			color: $col-canceledRed;
		}
	}

	&__duration {
		display: flex;
		justify-content: center;
		font-weight: bold;
		margin-top: $val-spacing-xs;
	}

	&.entry {
		background-color: $col-blue;
	}

	&.occupied {
		background-color: $col-greyFontLight;
	}

	&.unsigned-manual {
		background-color: $col-orange;
	}

	&.si-signed {
		background-color: $col-green;
	}

	&.overlap {
		background-color: $col-canceledRed;
	}

	&.rounded-left {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&.rounded-right {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}
