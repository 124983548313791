$var-dropzone-height: 80px;

.dropzone-element {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: $var-dropzone-height;
	cursor: pointer;
	border: 2px dashed $col-greyConnection;
	color: $col-greyFontLight;
	width: 100%;


	&--disabled {
		cursor: no-drop;
	}

	&--column {
		flex-flow: column nowrap;
	}
	
	&__text {
		color: $col-greyFontLight;
	}

	&__icon-upload {
		color: $col-greyFontLight;
		margin-right: $val-spacing-xs;
	}
	
	&__error {
		color: $col-red;
	}
}

.progress-bar {
	background-color: $col-blueBorder;
}
