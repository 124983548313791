@import "./Constants.scss";
@import "./Colors.scss";

.rbt-input-hint-container {
	& > input.rbt-input-hint {
		background-color: transparent !important;
		background-image: none !important;
		border-color: transparent !important;
		top: 0 !important;
		pointer-events: none !important;
	}

	& > input {
		height: $val-fieldSize;
		box-shadow: none;
	}
}

.rbt-menu.dropdown-menu {
	border-radius: $val-borderRadius;
	padding: 0;
	margin: 0;

	& > li {
		
		&:last-child {
			margin-bottom: 0!important;
		}

		&:first-child {
			margin-top: 0!important;
		}

		& a {
			padding-top: $val-spacing-s;
			padding-bottom: $val-spacing-s;
			width: 100%;
		}
		
		&:hover {
			background-color: $col-orangeHover;
	
			& a:hover {
				background-color: $col-transparent;
			}
		}
	}


	& .rbt-menu-custom-option, .disabled {
		& a {
			padding: $val-spacing-s $val-spacing-m;
		}

		& a:hover {
			background-color: inherit;
		}
	}
}

.rbt-sr-status {
	padding-left: $val-spacing-m;
}
