$method-control-width: 40px;

.contact {
	height: 100%;
	width: 100%;
	padding: $val-spacing-l;
	background: $col-greyLight;

	&__info {
		color: $col-greyFontLight;
		font-weight: normal;
		font-size: $val-fontSizeRegular;
		padding-left: $val-inputPadding-lr;
		margin-bottom: $val-spacing-m;
	}

	&__methods {
		display: grid;
		row-gap: $val-spacing-s;
		padding-bottom: $val-spacing-l;
	}

	&__methods-row {
		display: grid;
		grid-template-columns: $method-control-width auto;
		column-gap: $val-spacing-m;

		&--heading {
			padding-top: $val-spacing-m;
		}

		&--address {
			grid-template-columns: $method-control-width 20% 10% 15% 15% 10% 10% 15%;
			column-gap: 0;

			& > div:not(:first-child) {
				padding-left: $val-spacing-m;
			}
		}

		// &--option {
		// 	grid-area: option;
		// }
		// display: flex;
		// flex-flow: row nowrap;
		// align-items: center;
		// justify-content: flex-start;
		// width: 100%;
		// height: $val-fieldSize;

		// & + &--heading {
		// 	margin-top: $val-spacing-m;
		// }

		// &--unbound {
		// 	height: auto;
		// }

		&--not-selected {
			color: $col-greyFontLight;
		}
	
		// &--selected {
		// 	margin-left: -$val-spacing-l;
		// }
	}

	&__method-control {
		display: flex;
		justify-content: center;
	}

	&__method-item {
		display: flex;
		align-items: center;

		& [class^="icon-"] {
			margin-left: $val-spacing-m;
			font-size: $val-fontSizeBig;
			cursor: pointer;
			color: $col-blue;
		}
	}

	&__method-item-label {
		margin-right: $val-spacing-m;
	}

	&__dropdown {
		margin: 0;
	}
	
	&__dropdown-container {
		width: 50% !important;
	}

	&__dropdown-item {
		display: block;
	}

	&__select-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__address {
		display: flex;
		align-items: center;

		.radio {
			margin: -1px $val-spacing-m -1px 0 !important;
		}

		& [class^="icon-"] {
			margin-left: $val-spacing-m;
			font-size: $val-fontSizeBig;
			cursor: pointer;
			color: $col-blue;
		}
	}

	&__modal {
		.dropdown {
			background: $col-white;
		}

		.dropdown-menu {
			width: 100%;
		}

		.dropdown-menu-item {
			a {
				height: auto;
				width: 100%;
			}
		}

		.dropdown-option {
			display: flex;
			flex-direction: column;

			&__details {
				font-size: $val-fontSizeSmall;
				color: $col-greyFontLight;
			}
		}
	}

	&__address-list {
		&:not(:last-child) {
			border-bottom: 1px solid $col-greyConnection;;
		}
	}
}

.contact-create-modal {
	& .modal-body {
		padding: 0;
	}
}