@import "../../../../../../styles/Constants.scss";

.save-signature-modal {

	&__text {
		padding-bottom: $val-spacing-l;

		&--margin-top {
			margin-top: $val-spacing-m;
		}
	}
}