$twinkle-animation-length: 1s;
$comment-sidebar-width: 266px;

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.spin-animation {
	-webkit-animation: spin 1000ms infinite linear;
	animation: spin 1000ms infinite linear;
}

@keyframes twinkle {
	25% {
		opacity: 1;
	}

	50% {
		opacity: 0.8;
	}

	75% {
		opacity: 1;
	}
}

.twinkle-animation {
	animation-name: twinkle;
	animation-duration: $twinkle-animation-length;
	animation-iteration-count: infinite;
	border-radius: $val-borderRadius;
}

@keyframes slide-down {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX($comment-sidebar-width);
	}
}

@keyframes slide-up {
	0% {
		transform: translateX($comment-sidebar-width);
	}
	100% {
		transform: translateX(0);
	}
}