.job-upsert {
	&__billing-jobs {
		&__add_btn {
			margin-top: $val-spacing-l;
		}

		&__list {
			margin-top: $val-spacing-l;
			padding-bottom: $val-spacing-m;

			&__header {
				color: $col-greyDark;
				margin-bottom: $val-spacing-xs;
			}

			&__accordion {
				padding-left: 20px;
				padding-bottom: 20px;
			}

			&__items {
				padding-bottom: 20px;
			}

			&__item {
				&__actions {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					font-size: $val-fontSizeBig;
					padding-top: $val-spacing-xs;
					padding-bottom: $val-spacing-xs;
					padding-right: $val-spacing-m;

					span {
						color: $col-design-gray6;
						cursor: pointer;

						&.disabled {
							color: $col-design-gray5;
							cursor: not-allowed;
						}

						&:not(:first-child) {
							margin-left: $val-spacing-s;
						}
					}
				}

				&__preview {
					padding-top: $val-spacing-xs;
					padding-bottom: $val-spacing-xs;

					&__field {
						color: $col-greyFontLight;

						&--strong {
							color: $col-greyDark;
						}

						&--invalid {
							color: $col-canceledRed !important;
						}
					}

					&--invalid {
						background-color: $col-orangeHover;
					}
				}

				&__edit {
					&:not(:first-child) {
						margin-top: $val-spacing-l;
					}

					&__actions {
						height: 70px;
					}
				}
			}
		}
	}
}
